import React from 'react'
import  TextareaAutosize  from "@mui/material/TextareaAutosize";
import { Link, withRouter, Prompt } from 'react-router-dom';
import RouteLeavingGuard from '../../Utils/RouteLeavingGuard';
import UploadFile from './UploadFile';
import CommentSection from './CommentsSection';
import DatePicker from "react-datepicker";
import { changeDateFormate, DatePickerFormate, displayDateFormate, changeDateTimeFormat } from '../../Helpers/DateFormat';
import { checkValidGSTNo, validateCreateEvidence, validateUpdateEvidence, validateInventoryModeData } from './Validation'
import MasterService from '../../Services/MasterService';
import SnapshotService from '../../Services/Snapshot/SnapshotService';
import { ToggleButtonGroup, ToggleButton, Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { setPaymentInformation, setSmsForEvidence } from '../../Redux/Actions/Snapshot';
import PaymentMethod from '../../Components/Snapshot/PaymentMethod';
import InventoryAccounting from '../../Components/Snapshot/InventoryAccounting';
import DeletePopUp from '../../Components/BoilerPlate/DeletePopUp';
import { setAppilcationLoder } from "../../Redux/Actions/App";
import StorageData from "../../Helpers/StorageData";
import { setTempLinkedEvidenceList, setCostCategoryList } from '../../Redux/Actions/MoneyLink';
import ConfirmPopup from '../../Components/BoilerPlate/ConfirmPopup';
import {
	CREATE_BUSSINESS_SNAPSHOT_CODE, MANAGE_OTHER_USER_SNAPSHOT_CODE, VIEW_ONLY_SNAPSHOT_CODE, CREATE_REIMBUSRMENT_SNAPSHOT_CODE,
	APPROVE_REIMBURSMENT_SNAPSHOT_CODE, ACCESS_BUSSINESS_SMS_SNAPSHOT_CODE, SMART_SCAN_SNAPSHOT_CODE, SNAPSHOT_LEDGER_ENTRIES_CODE,
	LEDGER_ENTRIES_CODE, LINKING_CODE
} from '../../Helpers/Constants';
import { createLog, formatLogData, sendLog } from '../../Helpers/CreateLogs';
import ChangeLog from '../../Components/BoilerPlate/ChangeLog';
import SessionManagement from '../../Utils/SessionManagement';
import { confirm } from '../../Components/BoilerPlate/Confirm';
import CurrencyInput from 'react-currency-input-field';
import AddCategoryModal from '../../Components/BoilerPlate/AddCategoryModal';
import MerchantDropdown from '../../Components/Snapshot/MerchantDropdown';
import SnapshotLedger from './SnapshotLedger';
import CustomDatepicker from '../../Components/BoilerPlate/CustomDatepicker';
import MoneyLinkService from '../../Services/MoneyLink/MoneyLinkService';
import { setTempVoucherList, setTempInventoryData } from '../../Redux/Actions/Snapshot';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Form } from 'react-bootstrap';
import Select from '@mui/material/Select';
import { areGuidsUnique, decodeHtml } from '../../Helpers/HelperFunction';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Steps } from "intro.js-react";
import _lodash, { cloneDeep, has } from "lodash";
import { setTransactionFilterData } from '../../Redux/Actions/MoneyLink';
import Mousetrap from 'mousetrap';
import { roundOffValue } from './Roundoff';
import { confirm as oldconfirm } from '../../Components/BoilerPlate/Confirm';
class CreateEvidenceForm extends React.Component {
	constructor(props) {
		super(props);

		this.PERMISSION_LIST = this.props.features_permissions_list;
		this.CREATE_BUSSINESS_SNAPSHOT = this.PERMISSION_LIST.includes(CREATE_BUSSINESS_SNAPSHOT_CODE);
		this.MANAGE_OTHER_USER_SNAPSHOT = this.PERMISSION_LIST.includes(MANAGE_OTHER_USER_SNAPSHOT_CODE);
		this.VIEW_ONLY_SNAPSHOT = this.PERMISSION_LIST.includes(VIEW_ONLY_SNAPSHOT_CODE);
		this.CREATE_REIMBUSRMENT_SNAPSHOT = this.PERMISSION_LIST.includes(CREATE_REIMBUSRMENT_SNAPSHOT_CODE);
		this.APPROVE_REIMBURSMENT_SNAPSHOT = this.PERMISSION_LIST.includes(APPROVE_REIMBURSMENT_SNAPSHOT_CODE);
		this.ACCESS_BUSSINESS_SMS_SNAPSHOT = this.PERMISSION_LIST.includes(ACCESS_BUSSINESS_SMS_SNAPSHOT_CODE);
		this.SMART_SCAN_SNAPSHOT = this.PERMISSION_LIST.includes(SMART_SCAN_SNAPSHOT_CODE);
		this.SNAPSHOT_LEDGER_ENTRIES_CODE = this.PERMISSION_LIST.includes(SNAPSHOT_LEDGER_ENTRIES_CODE);
		this.LINKING_CODE = this.PERMISSION_LIST.includes(LINKING_CODE);
		this.LEDGER_ENTRIES = this.PERMISSION_LIST.includes(LEDGER_ENTRIES_CODE);

		this.VIEW_ONLY_SNAPSHOT = this.VIEW_ONLY_SNAPSHOT &&
			!(this.CREATE_REIMBUSRMENT_SNAPSHOT || this.MANAGE_OTHER_USER_SNAPSHOT);

		let is_sms_evidence = this.props.match.params.evidence_type === 'sms' ? true : false
		let snapshot_id = (!is_sms_evidence && this.props.match.params.snapshot_id) ? this.props.match.params.snapshot_id : 0;

		if (!!!snapshot_id && !(this.CREATE_BUSSINESS_SNAPSHOT || this.CREATE_REIMBUSRMENT_SNAPSHOT)) {
			this.props.history.push(`/snapshot/home`)
		}
		if (!!!snapshot_id && (this.CREATE_BUSSINESS_SNAPSHOT || this.CREATE_REIMBUSRMENT_SNAPSHOT)) {
			this.VIEW_ONLY_SNAPSHOT = false;
		}

		let initialPaymentObj = [{ method: 0, id: 0, amount: 0, payment_id: 0 }];

		// bifurcate help popups according to create or update page
		let help_steps = [];

		if (!!snapshot_id) {
			help_steps = [
				{
					element: ".quick_smart_snapcr",
					intro: "Add the transaction document here. ‘Smart’ option triggers AI and captures key data from the document (System is learning ! With time it will give you accuracy) ‘Quick’ requires you to fill in the data.",
					// position: 'right',	
					// hideNext: true 	
				},
				{
					element: ".cattag_snapcr",
					intro: "After you select the Type of the document, you can add the Category to the document. Tags help you further label the transaction for a particular project or department.  If you have rights, you can add a new category by clicking on ‘+’ sign and simply type and enter to add a new Tag.",
					position: 'left',
				},
				{
					element: ".addinfo_snapcr",
					intro: "Additional information that you need to give to accountant can be provided here.",
					position: 'left',
				},
				{
					element: ".payment_snapcr",
					intro: "Optionally you can give the payment information. In case of ‘Cash’ Expenses you can select the same from the dropdown.",
				},
				{
					element: ".gsttds_snapcr",
					intro: "Provide GST and TDS details here. Summary of the same can also be looked at under Reports tab.",
					position: 'left',
				},
				{
					element: ".ledger_snapcr",
					intro: "After you import ledgers from your accounting system, you can make ledger entries from here.",
					position: 'left',
				},
				{
					element: ".commbar_snapcr",
					intro: "Communication bar runs across different modules on the platform. You can have a Private Chat (within your organization) or Public chat (across all the users). You can also use this section to add notes or assign tasks.",
				},
				{
					element: ".redyDraft_snapcr",
					intro: "While saving the snapshot, you can decide the status for the same. **Draft :- It's like a draft mode where you have not added all the details. **Ready :- This indicates that the Accountant can now pick this up for Processing.",
				},
				{
					element: ".status_snapcr",
					intro: "**Accounted :- When accountant makes the ledger entries, he can mark it as accounted and these will be picked up for exporting to accounting software. **Pending :- Junior accountant can make the ledger entries and keep it in this status for senior’s approval. **Synced :- Once the entries are exported to accounting software, they get automatically marked as synced.",
					position: 'left'
				},
				{
					element: ".save_snapcr",
					intro: "You can save the sanpshot by clicking here. Document should always be in Draft state for editing it.",
					position: 'left'
				},
				{
					element: ".download_snapcr",
					intro: "You can also download the snapshot as a voucher by clicking here.",
				},
				{
					element: ".filler_snapcr",
					intro: "You can use this filter to change your focus area based on different parameters.",
				},
			];
		} else {
			help_steps = [
				{
					element: ".quick_smart_snapcr",
					intro: "Add the transaction document here. ‘Smart’ option triggers AI and captures key data from the document (System is learning ! With time it will give you accuracy) ‘Quick’ requires you to fill in the data.",
					// position: 'right',	
					// hideNext: true 	
				},
				{
					element: ".cattag_snapcr",
					intro: "After you select the Type of the document, you can add the Category to the document. Tags help you further label the transaction for a particular project or department.  If you have rights, you can add a new category by clicking on ‘+’ sign and simply type and enter to add a new Tag.",
					position: 'left',
				},
				{
					element: ".addinfo_snapcr",
					intro: "Additional information that you need to give to accountant can be provided here.",
					position: 'left',
				},
				{
					element: ".payment_snapcr",
					intro: "Optionally you can give the payment information. In case of ‘Cash’ Expenses you can select the same from the dropdown.",
				},
				{
					element: ".gsttds_snapcr",
					intro: "Provide GST and TDS details here. Summary of the same can also be looked at under Reports tab.",
					position: 'left',
				},
				{
					element: ".ledger_snapcr",
					intro: "After you import ledgers from your accounting system, you can make ledger entries from here.",
					position: 'left',
				},
				{
					element: ".redyDraft_snapcr",
					intro: "While saving the snapshot, you can decide the status for the same. **Draft :- It's like a draft mode where you have not added all the details. **Ready :- This indicates that the Accountant can now pick this up for Processing.",
				},
				{
					element: ".status_snapcr",
					intro: "**Accounted :- When accountant makes the ledger entries, he can mark it as accounted and these will be picked up for exporting to accounting software. **Pending :- Junior accountant can make the ledger entries and keep it in this status for senior’s approval. **Synced :- Once the entries are exported to accounting software, they get automatically marked as synced.",
					position: 'left'
				},
				{
					element: ".save_snapcr",
					intro: "You can save the sanpshot by clicking here. Document should always be in Draft state for editing it.",
					position: 'left'
				},
				{
					element: ".filler_snapcr",
					intro: "You can use this filter to change your focus area based on different parameters.",
				},
			];
		}

		let is_reimbursement = (this.CREATE_REIMBUSRMENT_SNAPSHOT && !this.CREATE_BUSSINESS_SNAPSHOT) ? true : false;

		this.state = {
			is_base_amt_changed: false,
			closeForm: false,
			loading: false,
			is_dirty: false,
			show_delete_popup: false,
			toggleBackConfirm: false,
			show_leave_money_link_popup: false,
			show_leave_task_manager_popup: false,
			money_link_evidence: {},
			showCategory: false,
			categoryName: '',
			errors: {},
			inventory_errors: {},
			line_item_errors: [],
			ledger_errors: [],
			listed_tags: [],
			selected_tags: [],
			hashtag: "",
			new_tags: [],
			payment_details: [],
			categories: [],
			sms_list: [],
			selected_sms_list: [],
			merchants: [],
			all_merchant_list: [],
			merchant_option: {},
			snapshot_id: snapshot_id,
			expense: true,
			type: is_reimbursement ? 6 : "",
			date: "",
			merchant_name: "",
			gst_no: "",
			invoice_number: "",
			total_amount: "",
			category_id: "",
			tags: [],
			additional_information: "",
			payment_status: false,
			payment_total: 0,
			db_payment_status_val: 0,
			payment_information: initialPaymentObj,
			gst_status: false,
			sgst_amount: "",
			cgst_amount: "",
			igst_amount: "",
			gst_total_amount: "",
			tds_status: false,
			//tds_percentage: "",
			tds_amount: "",
			tds_details: [], 
			voucher_status: false,
			file_url: "",
			pan_number: "",
			snapshot_prediction_status: 1,
			igst_amount_prediction_status: 0,
			sgst_amount_prediction_status: 0,
			cgst_amount_prediction_status: 0,
			total_gst_amount_prediction_status: 0,
			date_prediction_status: 0,
			merchant_name_prediction_status: 0,
			gst_no_prediction_status: 0,
			snapshot_type_prediction_status: 0,
			snapshot_number_prediction_status: 0,
			total_amount_prediction_status: 0,
			merchant_id_prediction_status: 0,
			pan_number_prediction_status: 0,
			expense_prediction_status: 0,
			category_prediction_status: 0,
			category_id_prediction_status: 0,
			tds_percentage_prediction_status: 0,
			tags_prediction_status: 0,
			is_msme_prediction_status: 0,
			is_entity_mismatch_prediction_status: 0,
			snapshot_no: "",
			approval_comment: null,
			approval_status: null,
			approved_amount: null,
			is_smart: this.SMART_SCAN_SNAPSHOT ? true : false,
			qualified_url: "",
			is_sms_evidence: is_sms_evidence,
			old_data: {},
			logs_list: {},
			save_and_redirect: false,
			next_route: false,
			closed: false,
			editable: true,
			ledgers_editable: true,
			communication_bar_tasks_dirty: false,
			communication_bar_notes_dirty: false,
			communication_bar_msgs_dirty: false,
			communication_bar_private_msgs_dirty: false,
			created_by: "",
			ledgerNameList: {
				ledger_list: [],
				last_updated_at: null
			},
			ledgers: [],
			remove_ledgers: [],
			voucher_list_from_db: [],
			is_valid_ledger: true,
			is_valid_voucher: true,
			read_only: false,
			submit_click: 0,
			transactionDate: "",
			transactionAmount: "",
			is_duplicate: 0,
			igst_amount_prediction_value: "",
			sgst_amount_prediction_value: "",
			cgst_amount_prediction_value: "",
			total_gst_amount_prediction_value: "",
			pan_number_prediction_value: "",
			date_prediction_value: "",
			merchant_name_prediction_value: "",
			gst_no_prediction_value: "",
			snapshot_number_prediction_value: "",
			total_amount_prediction_value: "",
			merchant_id_prediction_value: "",
			snapshot_type_prediction_value: "",
			category_id_prediction_value: "",
			tds_percentage_prediction_value: "",
			tags_prediction_value: "",
			is_msme_prediction_value: "",
			is_entity_mismatch_prediction_value: "",
			is_edit_supplier: false,
			prediction_id: null,
			save_and_next: true,
			entry_status: 1,
			accounting_status: 3,
			is_leder_recommended: 0,
			is_customer: true,
			is_supplier: true,
			is_perquisite: false,
			is_gst_claim_eligible: false,
			snapshot_type: false,
			snapshot_type_update: 0,
			is_new: false,
			new_value: null,
			// Intro js user guid	
			stepsEnabled: false,
			initialStep: 0,
			snapshot_types: [],
			merchant_type: 2,
			is_linked: 0,
			synced_by_user: null,
			remove_cost_category: [],
			remove_cost_center: [],
			remove_tags: [],
			remove_payment_information: [],
			remove_tds_details: [],
			linked_amount: 0,
			transaction_ids: [],
			is_same_invoice: 0,
			is_entity_mismatch: false,
			is_reimbursement_match: false,
			show_entity_mismatch_box: true,
			steps: help_steps,
			created_at: "",
			save_button_clicked: false,
			hide_payment_status: false,
			hide_tds: false,
			//snapshot inventory state variables
			inventory_mode: false,
			line_items: [],
			gst_summary: [],
			line_item_total: "",
			line_item_gst_total: "",
			display_file_url: "",
			line_items_list: [],
			line_item_discount_total: "",
			item_ledger_list: [],
			inventory_details: false,
			is_reimbursement: is_reimbursement,
			reimbursement_narration: null,
			approval_button: null,

			itemsToShow: 2,
			expanded: false,
			checkCurrentDate:"",
			line_items_data : [],
			line_items_prediction_status:0,
			global_preidction_status:0,
			request_id : null,
			prediction_purchase_ledger_guid : null,
			prediction_sales_ledger_guid : null,
			prediction_party_ledger_guid : null,
			showInventory : 0,
			bulk_prediction_status:0,
			combined_inventory_array : [],
			inventory_party_ledger_guid:null,
			inventory_party_ledger_id:null,
			inventory_party_ledger_name:null,
			inventory_common_item_ledger_guid:null,
			inventory_common_item_ledger_id:null,
			inventory_common_item_ledger_name:null,
			inventory_party_ledger_guid_prediction:0,
			inventory_party_ledger_id_prediction:0,
			inventory_party_ledger_name_prediction:0,
			inventory_common_item_ledger_guid_prediction:0,
			inventory_common_item_ledger_id_prediction:0,
			inventory_common_item_ledger_name_prediction:0,
			inventory_voucher_creation_date:"",
			inventory_voucher_creation_date_prediction:0,
			is_gst_override : false,
			hide_gst_override: true,
			auto_ready:false,
			auto_accounted:false,
			auto_ready_accounted:false,
			voucherTypeList :[],
			parent_voucher_type_id:"",
			voucher_type_guid:"",
			voucher_type_name:"",
			voucher_type_id:"",
			prediction_voucher_type_guid:null,
			get_amount_round_off : "",
			vaild_voucher: false,
			xml_upload: false,

			// tagInputShow: 0
		}

		this.showMore = this.showMore.bind(this);


		this.inventoryChildRef = React.createRef();
		this.paymentChildRef = React.createRef();
		this.imageUploadChildRef = React.createRef();


		let inventory_details = {
			"line_item_total": "",
			"line_item_gst_total": "",
			"line_item_discount_total": "",
			"inventory_voucher_status": this.SNAPSHOT_LEDGER_ENTRIES_CODE ? true : false,
			"is_common_item_ledger": "",
			"accounting_narration": "",
			"line_items":[],
			// "line_items": [{
			// 	"line_item_id": "",
			// 	"line_item_id_prediction_status" : 0,
			// 	"line_item_name": "",
			// 	"line_item_name_prediction_status" : 0,
			// 	"hsn_sac": "",
			// 	"hsn_sac_prediction_status" : 0,
			// 	"rate": "",
			// 	"rate_prediction_status" : 0,
			// 	"per": "",
			// 	"per_prediction_status" : 0,
			// 	"quantity": "",
			// 	"quantity_prediction_status" : 0,
			// 	"gst_rate": "",
			// 	"gst_rate_prediction_status" : 0,
			// 	"discount_rate": "",
			// 	"discount_rate_prediction_status" : 0,
			// 	"amount": "",
			// 	"amount_prediction_status" : 0,
			// 	"item_ledger_guid": "",
			// 	"item_ledger_guid_prediction_status" : 0,
			// 	"item_ledger_id": null,
			// 	"item_ledger_id_prediction_status" : 0,
			// 	"item_ledger_name": "",
			// 	"item_ledger_name_prediction_status" : 0,
			// 	"common_item_ledger_guid": "",
			// 	"common_item_ledger_guid_prediction_status" : 0,
			// 	"common_item_ledger_id": "",
			// 	"common_item_ledger_id_prediction_status" : 0,
			// 	"common_item_ledger_name": "",
			// 	"common_item_ledger_name_prediction_status" : 0,
			// 	"line_item_update": 0
			// }],
			"ledgers": [{
				"amount": "",
				"rate": "",
				"guid": "",
				"ledger_id": "",
				"name": "",
			}],
			"sync_datetime": null,
			"sync_error": null,
			"sync_status": 0,
			"voucher_total_amount": "",
			"voucher_creation_date": "",
			"voucher_creation_date_prediction_status":0,
			"voucher_type": "",
			"party_ledger_guid": null,
			"party_ledger_guid_prediction_status":0,
			"party_ledger_id": null,
			"party_ledger_id_prediction_status":0,
			"party_ledger_name": null,
			"party_ledger_name_prediction_status":0,
			"common_item_ledger_guid": null,
			"common_item_ledger_guid_prediction_status":0,
			"common_item_ledger_id": null,
			"common_item_ledger_id_prediction_status":0,
			"common_item_ledger_name": null,
			"common_item_ledger_name_prediction_status":0,
			"parent_voucher_type_id":null,
			"voucher_type_guid":null,
			"voucher_type_name":null,
			"voucher_type_id":null,
		}

		this.props.dispatch(setTempInventoryData(inventory_details));
	}


	showMore() {
		this.state.itemsToShow === 2 ? (
			this.setState({ itemsToShow: this.state.listed_tags.length, expanded: true })
		) : (
			this.setState({ itemsToShow: 2, expanded: false })
		)
	}

	// tagInputShowtoggle = () => this.setState((currentState) => ({ tagInputShow: !currentState.tagInputShow }));




	arrayFindObjectByProp = (arr, prop, val) => {
		return arr.find((obj) => obj[prop] == val);
	}

	convertToDate = (dateString) => {
		const [day, month, year] = dateString.split('-');
		const date = new Date(year, month - 1, day);
		if (isNaN(date.getTime())) {
			console.error("Invalid date format:", dateString);
			return null;
		}
		return date;
	};

	handleXmlData = (xmlData,file_url,file_type) => {
		this.setState({
			is_dirty:true,
				file_url:file_url,
				file_type:file_type,
		})
		this.setState({
			xml_upload: false,
			is_base_amt_changed: false,
			auto_accounted:false,
			auto_ready_accounted:false,
			auto_ready:false,
			date: "",
			invoice_number: "",
			total_amount: "",
			merchant_name: "",
			sgst_amount: "",
			cgst_amount: "",
			igst_amount: "",
			gst_total_amount: "",
			merchant_id: "",
			gst_no: "",
			pan_number: "",
			//tds_percentage: "",
			tds_amount: "",
			tds_details: [],
			selected_tags: [],
			category_id: "",
			//type: "",
			merchant_option: {},
			snapshot_prediction_status: 0,
			igst_amount_prediction_status: 0,
			sgst_amount_prediction_status: 0,
			cgst_amount_prediction_status: 0,
			total_gst_amount_prediction_status: 0,
			date_prediction_status: 0,
			merchant_name_prediction_status: 0,
			gst_no_prediction_status: 0,
			snapshot_type_prediction_status: 0,
			snapshot_number_prediction_status: 0,
			total_amount_prediction_status: 0,
			merchant_id_prediction_status: 0,
			pan_number_prediction_status: 0,
			expense_prediction_status: 0,
			category_prediction_status: 0,
			category_id_prediction_status: 0,
			tds_percentage_prediction_status: 0,
			tags_prediction_status: 0,
			is_msme_prediction_status: 0,
			is_entity_mismatch_prediction_status: 0,
			//tds_precentage_status: 0,
			igst_amount_prediction_value: "",
			sgst_amount_prediction_value: "",
			cgst_amount_prediction_value: "",
			total_gst_amount_prediction_value: "",
			pan_number_prediction_value: "",
			date_prediction_value: "",
			merchant_name_prediction_value: "",
			gst_no_prediction_value: "",
			snapshot_number_prediction_value: "",
			total_amount_prediction_value: "",
			merchant_id_prediction_value: "",
			snapshot_type_prediction_value: "",
			category_id_prediction_value: "",
			tds_percentage_prediction_value: "",
			tags_prediction_value: "",
			is_msme_prediction_value: "",
			is_entity_mismatch_prediction_value: "",
			prediction_id: null,
			is_igst: false,
			disable_igst: false,
			gst_summary: {},
			hsn_sac_summary: {},
			global_preidction_status:0,
			combined_inventory_array:[],
			bulk_prediction_status : 0,
			inventory_party_ledger_id:null,
			inventory_party_ledger_name:null,
			inventory_common_item_ledger_guid:null,
			inventory_common_item_ledger_id:null,
			inventory_common_item_ledger_name:null,
			inventory_party_ledger_guid_prediction:0,
			inventory_party_ledger_id_prediction:0,
			inventory_party_ledger_name_prediction:0,
			inventory_common_item_ledger_guid_prediction:0,
			inventory_common_item_ledger_id_prediction:0,
			inventory_common_item_ledger_name_prediction:0,
			inventory_voucher_creation_date:"",
			inventory_voucher_creation_date_prediction:0,
			line_items_data : [],
			prediction_party_ledger_guid : null,
			prediction_purchase_ledger_guid : null,
			prediction_sales_ledger_guid : null,
			global_preidction_status : 0,
			
			is_gst_override:false,
			hide_gst_override:true,
			parent_voucher_type_id:"",
			voucher_type_guid:"",
			voucher_type_name:"",
			voucher_type_id:"",
			prediction_voucher_type_guid:null
		})

		let tds_information = [];
			if(this.state.snapshot_id)
			tds_information.push({
				id:0,
				base_amount: "",
				tds_percentage:"",
				tds_amount:"",
				});
			else
			tds_information.push({
				base_amount:"",
				tds_percentage: "",
				tds_amount: "",
			});
			this.setState({tds_details:tds_information});


			this.handleSupplierCustomerToggle('is_supplier', true)
			this.handleSupplierCustomerToggle('is_customer', true)

			const obj = {
				REFERENCEDATE: "",
				REFERENCE: "",
				PARTYGSTIN: "",
				PARTYNAME: "",
				AMOUNT: null,
				SGST: 0,
				CGST: 0,
				IGST: 0,
				line_items: []
			  };
			  
			  // Check if any property has a meaningful value
			  const hasValue = Object.entries(xmlData).some(([key, value]) => {
				if (Array.isArray(value)) {
				  return value.length > 0; // Check if arrays have elements
				}
				if (key === "SGST" || key === "CGST" || key === "IGST") {
				  return value !== 0; // Ignore SGST, CGST, IGST when they're 0
				}
				return value !== null && value !== undefined && value !== ""; // Check for non-empty values
			  });
			  
			  console.log("hasValue ", hasValue);
			  
		if (hasValue) {
			
			let matchingMerchant = null;

			if(xmlData.PARTYGSTIN || xmlData.PARTYNAME){
				console.log('this.state.all_merchant_list',this.state.all_merchant_list)
				matchingMerchant = this.state.all_merchant_list.find(
				merchant => (xmlData.PARTYGSTIN && merchant.gst_no && merchant.gst_no === xmlData.PARTYGSTIN) || (xmlData.PARTYNAME && merchant.label && merchant.label.toLowerCase() === xmlData.PARTYNAME.toLowerCase())
			)}
		    
			// let updatedMerchants = [...this.state.all_merchant_list];
			// if (!matchingMerchant && xmlData.PARTYNAME) {
			// 	updatedMerchants.push({
			// 		label: xmlData.PARTYNAME,
			// 		value: xmlData.PARTYNAME,
			// 		gst_no: xmlData.PARTYGSTIN,
			// 	});
			// }

			if(matchingMerchant != undefined){
				this.setState({merchant_option: { label: matchingMerchant.label, value: matchingMerchant.value,is_new:false }})
			}else{
				this.setState({merchant_option: { label: xmlData.PARTYNAME, value: 0 },is_new:true ,new_value: xmlData.PARTYNAME})
			}

			console.log('matchingMerchant',matchingMerchant)
			console.log('xmlData',xmlData)
			const totalAmount = xmlData.AMOUNT ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,xmlData.AMOUNT): xmlData.AMOUNT : '';
			if (this.state.is_reimbursement) {
				this.setState({ approved_amount: totalAmount })
			}

			const listedTags = matchingMerchant && matchingMerchant.tags ? matchingMerchant.tags : [];
            const selectedTags = listedTags.map(tag => tag.id);

			const category = matchingMerchant ? this.state.categories.find(category => category.id === matchingMerchant.category_id) : null;
            const categoryName = category ? category.name : '';

			const igstAmount = xmlData.IGST ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,xmlData.IGST): xmlData.IGST : '';
			const sgstAmount = xmlData.SGST ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,xmlData.SGST): xmlData.SGST : '';
			const cgstAmount = xmlData.CGST ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,xmlData.CGST): xmlData.CGST : '';
			const gstTotalAmount = sgstAmount + cgstAmount;
			if(!this.state.is_reimbursement){
				this.setState({type: matchingMerchant && matchingMerchant.snapshot_type ? matchingMerchant.snapshot_type : ''})
			}
			this.setState({ 
				
				xmlData, 
				date: xmlData.REFERENCEDATE ? this.convertToDate(xmlData.REFERENCEDATE) : '',
				invoice_number: xmlData.REFERENCE ? xmlData.REFERENCE : '',
				merchant_id: matchingMerchant ? matchingMerchant.value : '',
				pan_number: matchingMerchant ? matchingMerchant.pan_number : '',
				selected_tags: selectedTags,
				category_id: matchingMerchant ? matchingMerchant.category_id : '',
				category_name: matchingMerchant ? categoryName : '',
				total_amount: totalAmount,
				// type: matchingMerchant && matchingMerchant.snapshot_type ? matchingMerchant.snapshot_type : '',
				is_msme: matchingMerchant ? matchingMerchant.is_msme : '',
				is_perquisite: matchingMerchant ? matchingMerchant.is_perquisite : '',
				expense: true,
				sgst_amount: sgstAmount,
				sgst_amount_prediction_value: sgstAmount,
				cgst_amount: cgstAmount,
				cgst_amount_prediction_value: cgstAmount,
				igst_amount: igstAmount,
				igst_amount_prediction_value: igstAmount,
				gst_total_amount: gstTotalAmount,
				global_preidction_status: 1,
				date_prediction_status: xmlData.REFERENCEDATE ? 1 : 0,
				snapshot_number_prediction_status: xmlData.REFERENCE ? 1 : 0,
				total_amount_prediction_status: xmlData.AMOUNT ? 1 : 0,
				category_prediction_status: matchingMerchant && matchingMerchant.category_id ? 1 : 0,
				category_id_prediction_status: matchingMerchant && matchingMerchant.category_id ? 1 : 0,
				tags_prediction_status: 1,
				pan_number_prediction_status: matchingMerchant && matchingMerchant.pan_number ? 1 : 0,
				sgst_amount_prediction_status: xmlData.SGST ? 1 : 0,
				cgst_amount_prediction_status: xmlData.CGST ? 1 : 0,
				igst_amount_prediction_status: xmlData.IGST ? 1 : 0,
				merchant_name_prediction_status: xmlData.PARTYNAME ? 1 : 0,
				merchant_id_prediction_status: 1,
				snapshot_type_prediction_status: matchingMerchant && matchingMerchant.type ? 1 : 0,
				snapshot_prediction_status: 1,
				expense_prediction_status: 1,
				is_msme_prediction_status: 1,
				total_gst_amount_prediction_status: 1,
				xml_upload: true,
				is_entity_mismatch: false,
				// gst_no: matchingMerchant ? matchingMerchant.gst_no : xmlData.PARTYGSTIN,
				// is_gst_claim_eligible: matchingMerchant ? matchingMerchant.is_gst_claim_eligible : '',
				// gst_status: xmlData.SGST || xmlData.CGST || xmlData.IGST ? true : false,
				// gst_no_prediction_status: xmlData.PARTYGSTIN ? 1 : 0,
				// merchant_name: matchingMerchant ? matchingMerchant.label : '',
				// merchant_option: matchingMerchant ? { label: matchingMerchant.label, value: matchingMerchant.value} : { label: xmlData.PARTYNAME, value: 0 },
				// all_merchant_list: updatedMerchants,
				// selectedSnapshotType: matchingMerchant ? (this.state.snapshot_types.find(type => type.id === matchingMerchant.snapshot_type)) : '',
				// is_igst: xmlData.IGST ? true : false,
				// disable_igst: true,
			}, ()=>{
				if(this.state.type){
					const isExpense = this.state.snapshot_types.find(type => type.id == this.state.type)
					this.setState({expense: isExpense.expense})
				}
			});
			console.log('xmlDataa',xmlData)
			console.log('expensee',this.state.expense)

			if(xmlData.PARTYGSTIN && matchingMerchant && matchingMerchant.gst_no){
				// Adding GST logic
			this.setState({
				gst_no_prediction_status: xmlData.PARTYGSTIN ? 1 : 0,
				gst_no_prediction_value: matchingMerchant && matchingMerchant.gst_no ? matchingMerchant.gst_no : xmlData.PARTYGSTIN,
				gst_no: matchingMerchant && matchingMerchant.gst_no ? matchingMerchant.gst_no : xmlData.PARTYGSTIN,
				gst_status: xmlData.SGST || xmlData.CGST || xmlData.IGST ? true : false,
				is_gst_claim_eligible: matchingMerchant && matchingMerchant.is_gst_claim_eligible ? matchingMerchant.is_gst_claim_eligible : ''
			}, () => {
				console.log('gst_no',this.state.gst_no)
				if (xmlData.PARTYGSTIN || matchingMerchant.gst_no) {
					console.log('xmlData.PARTYGSTIN',xmlData.PARTYGSTIN)
					let customer_gst = this.state.gst_no ? this.state.gst_no.slice(0, 2) : null || null;
					let entity_gst = (!!this.props.entity_gst_no) ? this.props.entity_gst_no.slice(0, 2) : null;
	
					if (customer_gst == entity_gst && customer_gst && entity_gst) {
						this.setState({ is_igst: false, disable_igst: true, hide_gst_override: false }, () => {
							this.calculateTotalGstPrediction();
						});
						// this.setState({ is_igst: false, igst_amount: "", disable_igst: true, hide_gst_override: false }, () => {
						// 	this.calculateTotalGstPrediction();
						// });
					} else if (!customer_gst || !entity_gst) {
						if (this.state.cgst_amount && this.state.sgst_amount) {
							this.setState({ is_igst: false, igst_amount: "", disable_igst: false, hide_gst_override: true }, () => {
								this.calculateTotalGstPrediction();
							});
						} else if (this.state.igst_amount) {
							this.setState({ is_igst: true, disable_igst: false, hide_gst_override: true }, () => {
								this.calculateTotalGstPrediction();
							});
						} else {
							this.setState({ is_igst: true, igst_amount: "", disable_igst: false, hide_gst_override: true }, () => {
								this.calculateTotalGstPrediction();
							});
						}
					} else if (customer_gst != entity_gst && customer_gst && entity_gst) {
						// this.setState({ is_igst: true, disable_igst: true, sgst_amount: "", cgst_amount: "", hide_gst_override: false }, () => {
						// 	this.calculateTotalGstPrediction();
						// });
						this.setState({ is_igst: true, disable_igst: true,hide_gst_override: false }, () => {
							this.calculateTotalGstPrediction();
						});
					} else {
						if(this.state.cgst_amount && this.state.sgst_amount){
							this.setState({ is_igst: false, igst_amount: "", disable_igst: false,hide_gst_override:true }, () => {
								this.calculateTotalGstPrediction();
							})
						}else if(this.state.igst_amount){
							this.setState({ is_igst: true, disable_igst: false,hide_gst_override:true }, () => {
								this.calculateTotalGstPrediction();
							})
						}else{
							this.setState({ is_igst: true, igst_amount: "", disable_igst: false,hide_gst_override:true }, () => {
								this.calculateTotalGstPrediction();
							})
						}
					}
				}
			});
			}

			if(matchingMerchant && matchingMerchant.tds_percentage){
				let tds_arr = this.state.tds_details;
										console.log("Tds percentage --------------------------" ,this.state.tds_details, this.state.tds_details.length)
										if (this.state.tds_details.length == 0) {
											let amt = xmlData.AMOUNT?xmlData.AMOUNT:"";
											amt = amt == 0?"":amt;
											if (this.state.snapshot_id)
											tds_arr = [
											  {
												id: 0,
												base_amount: amt,
												tds_amount: "",
												tds_percentage: "",
											  },
											];
											else{
												tds_arr = [
													{
													  base_amount: amt,
													  tds_amount: "",
													  tds_percentage: "",
													},
												  ];
											}
										  }

										tds_arr[0].tds_percentage = !!matchingMerchant ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,matchingMerchant.tds_percentage):matchingMerchant.tds_percentage : "";
										tds_arr[0].base_amount = xmlData.AMOUNT?this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,xmlData.AMOUNT):xmlData.AMOUNT : "";
										console.log('tds_arr[0].tds_percentage',tds_arr[0].tds_percentage)
										console.log('tds_arr',tds_arr)
										this.setState(
										  {
											tds_details: tds_arr,
											tds_status: !!matchingMerchant ? true : false,
											tds_percentage_prediction_value: matchingMerchant.tds_percentage ? matchingMerchant.tds_percentage : '',
											tds_percentage_prediction_status: 1,
										  },
										  () => {
											this.calculatePredictionTDS();
										  }
										);
										console.log('tds_details',tds_arr)
										console.log('tds_details',this.state.tds_details)
			}

			let merchant_list = this.state.all_merchant_list;
			const merchantId = matchingMerchant && matchingMerchant.value ? matchingMerchant.value : '';
			let merchant_data = merchant_list.filter(merchant => merchant.value == merchantId)
			console.log('merchant_dataa',merchant_data)
			
		
			if((this.state.type == 1 || this.state.type == 2) && this.props.accounting_mode == 2 && this.state.inventory_mode){
				this.predictionlineitem(xmlData);
			}

			if (this.props.accounting_mode == 2 && merchant_data.length > 0) {
				let inventory_details = _.cloneDeep(this.props.temp_inventory_data);

				if (this.state.inventory_mode == 1 || this.state.inventory_mode) {

					let ledger = this.state.ledgerNameList.ledger_list.filter(ledger => ledger.guid == (this.state.type == 1 ? merchant_data[0].sales_ledger_guid : merchant_data[0].purchase_ledger_guid));
					//console.log(ledger)
					
					if (inventory_details.is_common_item_ledger == "")
						inventory_details.is_common_item_ledger = this.props.is_common_item_ledger;

					if (ledger.length > 0 && inventory_details.is_common_item_ledger) {
						this.setState({inventory_common_item_ledger_guid:ledger[0].guid,inventory_common_item_ledger_id:ledger[0].id,inventory_common_item_ledger_name:ledger[0].name})
						this.setState({inventory_common_item_ledger_guid_prediction:1,inventory_common_item_ledger_id_prediction:1,inventory_common_item_ledger_name_prediction:1})
						
					}

					if (ledger.length > 0 && inventory_details.is_common_item_ledger == 0) {
						this.setState({inventory_common_item_ledger_guid:"",inventory_common_item_ledger_id:"",inventory_common_item_ledger_name:""})
						this.setState({inventory_common_item_ledger_guid_prediction:0,inventory_common_item_ledger_id_prediction:0,inventory_common_item_ledger_name_prediction:0})
						
					}

					let party_ledger = this.state.ledgerNameList.ledger_list.filter(ledger => ledger.guid == merchant_data[0].party_ledger_guid);
					//console.log(party_ledger)
					if (party_ledger.length > 0) {

						this.setState({inventory_party_ledger_guid:party_ledger[0].guid,inventory_party_ledger_id:party_ledger[0].id,inventory_party_ledger_name:party_ledger[0].name})
						this.setState({inventory_party_ledger_guid_prediction:1,inventory_party_ledger_id_prediction:1,inventory_party_ledger_name_prediction:1})
					}
					if(this.state.date){
						this.setState({inventory_voucher_creation_date:this.state.date,inventory_voucher_creation_date_prediction:1})
					}

					let voucher_type_details = this.state.voucherTypeList.filter(voucher => voucher.guid == (this.state.type == 1 ? merchant_data[0].sales_voucher_type_guid : merchant_data[0].purchase_voucher_type_guid))
					if(voucher_type_details.length > 0){
						this.setState({prediction_voucher_type_guid:voucher_type_details[0].guid,voucher_type_guid:voucher_type_details[0].guid,voucher_type_id:voucher_type_details[0].id,voucher_type_name:voucher_type_details[0].name,parent_voucher_type_id:voucher_type_details[0].parent_voucher_type_id})
					}
				}
			}

			if((this.state.type == 2  || this.state.type == 1) && this.state.inventory_mode == 1 && this.props.accounting_mode == 2){
							
				this.inventoryChildRef.openInventoryEditView();

		    }

		    this.calculateTotalGstPrediction();
		    this.calculatePredictionTDS();
		    this.checkForReadyState();
		} else {
			this.setState({ xmlData });
		}
	};	

	predictionlineitem = (xmlData) => {
		console.log('line_item_prefill',xmlData);
		let inventory_details = _.cloneDeep(this.props.temp_inventory_data);
	    this.setState({line_items_prediction_status : 1})
		let line_item_xml = xmlData.line_items;
		console.log('line_items', line_item_xml);
		
		const new_lineItems =  this.state.line_items_data.filter(item =>  item.line_item_id === 0 );
		
			const line_item_prefill = line_item_xml.map((item) => {
			console.log('item_prefill', item);

			// Extract RATE and per from the RATE field
			let rateMatch = item.RATE.match(/^([\d.]+)\/(\w+)$/);
			let rate = rateMatch ? parseFloat(rateMatch[1]) : "";
			let per = rateMatch ? rateMatch[2] : "";

			// Calculate quantity by dividing amount by rate
			let amount = item.AMOUNT || 0;
			let quantity = rate > 0 ? parseInt(amount / rate) : "";	

			let line_item = {
				"line_item_id": item.line_item_id || 0,
				"line_item_id_prediction_status": !!item.line_item_id ? 1 : 0,
				"line_item_name": item.STOCKITEMNAME || "",
				"line_item_name_prediction_status": !!item.STOCKITEMNAME ? 1 : 0,
				"hsn_sac": item.hsn_sac || "",
				"hsn_sac_prediction_status": !!item.hsn_sac ? 1 : 0,
				"rate": rate,
				"rate_prediction_status": !!item.RATE ? 1 : 0,
				"per": per,
				"per_prediction_status": !!item.per ? 1 : 0,
				"quantity": quantity,
				"quantity_prediction_status": !!item.quantity ? 1 : 0,
				"gst_rate": parseFloat(item.gst_rate) || "",
				"gst_rate_prediction_status": !!item.gst_rate ? 1 : 0,
				"discount_rate": parseFloat(item.discount_rate) || "",
				"discount_rate_prediction_status": !!item.discount_rate ? 1 : 0,
				"amount": parseFloat(item.AMOUNT) || "",
				"amount_prediction_status": !!item.AMOUNT ? 1 : 0,
				"item_ledger_guid": item.item_ledger_guid || "",
				"item_ledger_guid_prediction_status": !!item.item_ledger_guid ? 1 : 0,
				"item_ledger_id": item.item_ledger_id || null,
				"item_ledger_id_prediction_status": !!item.item_ledger_id ? 1 : 0,
				"item_ledger_name": item.item_ledger_name || "",
				"item_ledger_name_prediction_status": !!item.item_ledger_name ? 1 : 0,
				"common_item_ledger_guid": item.common_item_ledger_guid || "",
				"common_item_ledger_guid_prediction_status": !!item.common_item_ledger_guid ? 1 : 0,
				"common_item_ledger_id": item.common_item_ledger_id || "",
				"common_item_ledger_id_prediction_status": !!item.common_item_ledger_id ? 1 : 0,
				"common_item_ledger_name": item.common_item_ledger_name || "",
				"common_item_ledger_name_prediction_status": !!item.common_item_ledger_guid ? 1 : 0,
				"line_item_update": 0
			};
			
			console.log('this.state.line_items_list',this.state.line_items_list)
			let line_items_filter = this.state.line_items_list.find(line =>
				line.name.toLowerCase() == line_item.line_item_name.toLowerCase()
			);
			console.log('line_items_filter',line_items_filter)
	
			if (line_items_filter != undefined) {
				line_item.line_item_name = line_items_filter.name;
				line_item.line_item_id = line_items_filter.id;
				line_item.hsn_sac = line_items_filter.hsn_sac;
				line_item.gst_rate = line_items_filter.gst_rate;
	
				if (line_items_filter.item_ledger_guid != "" && line_items_filter.item_ledger_guid != null) {
					let item_ledger = this.state.item_ledger_list.filter(item_ledger =>
						item_ledger.guid == line_items_filter.item_ledger_guid
					);
					if (item_ledger.length > 0) {
						line_item.item_ledger_guid = item_ledger[0].guid;
						line_item.item_ledger_id = item_ledger[0].id;
						line_item.item_ledger_name = item_ledger[0].name;
	
						// Prediction status
						line_item.item_ledger_guid_prediction_status = 1;
						line_item.item_ledger_id_prediction_status = 1;
						line_item.item_ledger_name_prediction_status = 1;
					}
					
					if (this.SNAPSHOT_LEDGER_ENTRIES_CODE) {
						if (inventory_details.is_common_item_ledger == 0 && this.state.type == 1 && item.sales_ledger_guid) {
							let ledger = this.state.ledgerNameList.ledger_list.filter(item_ledger => item_ledger.guid == item.sales_ledger_guid);
							if (ledger.length > 0) {
								line_item.common_item_ledger_guid = ledger[0].guid;
								line_item.common_item_ledger_id = ledger[0].id;
								line_item.common_item_ledger_name = ledger[0].name;
			
								line_item.common_item_ledger_guid_prediction_status = 1;
								line_item.common_item_ledger_id_prediction_status = 1;
								line_item.common_item_ledger_name_prediction_status = 1;
							}
						}
						if (inventory_details.is_common_item_ledger == 0 && this.state.type == 2 && item.purchase_ledger_guid) {
							let ledger = this.state.ledgerNameList.ledger_list.filter(item_ledger => item_ledger.guid == item.purchase_ledger_guid);
							if (ledger.length > 0) {
								line_item.common_item_ledger_guid = ledger[0].guid;
								line_item.common_item_ledger_id = ledger[0].id;
								line_item.common_item_ledger_name = ledger[0].name;
			
								line_item.common_item_ledger_guid_prediction_status = 1;
								line_item.common_item_ledger_id_prediction_status = 1;
								line_item.common_item_ledger_name_prediction_status = 1;
							}
						}
					}
				}
			}

			console.log('lineitem', line_item);
			return line_item;
		});
		console.log('line_item_prefill', line_item_prefill);
		this.setState({combined_inventory_array:line_item_prefill})

	if(this.SNAPSHOT_LEDGER_ENTRIES_CODE){
		console.log("inside otherfn")
				if (inventory_details.is_common_item_ledger == "")
					inventory_details.is_common_item_ledger = this.props.is_common_item_ledger;
		
				if(inventory_details.is_common_item_ledger && this.state.type == 2 && !!this.state.prediction_purchase_ledger_guid){
					let ledger = this.state.ledgerNameList.ledger_list.filter(ledger => ledger.guid == this.state.prediction_purchase_ledger_guid);
					if(ledger.length > 0){
						console.log("state of inve",ledger)
						this.setState({inventory_common_item_ledger_guid:ledger[0].guid,inventory_common_item_ledger_id:ledger[0].id,inventory_common_item_ledger_name:ledger[0].name})
						this.setState({inventory_common_item_ledger_guid_prediction:1,inventory_common_item_ledger_id_prediction:1,inventory_common_item_ledger_name_prediction:1})
		
					}
				}
				if(inventory_details.is_common_item_ledger && this.state.type == 1 && !!this.state.prediction_sales_ledger_guid){
				let ledger = this.state.ledgerNameList.ledger_list.filter(ledger => ledger.guid == this.state.prediction_sales_ledger_guid);
					if(ledger.length > 0){
		
						console.log("state of inve ",ledger)
		
						this.setState({inventory_common_item_ledger_guid:ledger[0].guid,inventory_common_item_ledger_id:ledger[0].id,inventory_common_item_ledger_name:ledger[0].name})
						this.setState({inventory_common_item_ledger_guid_prediction:1,inventory_common_item_ledger_id_prediction:1,inventory_common_item_ledger_name_prediction:1})
					}
				}
				if(!!this.state.prediction_party_ledger_guid){
					let party_ledger = this.state.ledgerNameList.ledger_list.filter(ledger => ledger.guid == this.state.prediction_party_ledger_guid);
					if(party_ledger.length > 0){
						this.setState({inventory_party_ledger_guid:party_ledger[0].guid,inventory_party_ledger_id:party_ledger[0].id,inventory_party_ledger_name:party_ledger[0].name})
						this.setState({inventory_party_ledger_guid_prediction:1,inventory_party_ledger_id_prediction:1,inventory_party_ledger_name_prediction:1})
					}
				}
		
		}
		else{
						this.setState({inventory_common_item_ledger_guid:"",inventory_common_item_ledger_id:"",inventory_common_item_ledger_name:""})
						this.setState({inventory_common_item_ledger_guid_prediction:0,inventory_common_item_ledger_id_prediction:0,inventory_common_item_ledger_name_prediction:0})
		
		
						this.setState({inventory_party_ledger_guid:"",inventory_party_ledger_id:"",inventory_party_ledger_name:""})
						this.setState({inventory_party_ledger_guid_prediction:0,inventory_party_ledger_id_prediction:0,inventory_party_ledger_name_prediction:0})
		
			
		}

		if(this.state.date){
			this.setState({inventory_voucher_creation_date:this.state.date,inventory_voucher_creation_date_prediction:1})
		}
	};
	

	uploadFileUrl = async (file_url, file_type) => {
		this.setState({ file_url, is_dirty: true, file_type })
		if (!!file_url && this.state.is_smart) {

			this.setState({
				xml_upload: false,
				is_base_amt_changed: false,
				auto_accounted:false,
				auto_ready_accounted:false,
				auto_ready:false,
				date: "",
				invoice_number: "",
				total_amount: "",
				merchant_name: "",
				sgst_amount: "",
				cgst_amount: "",
				igst_amount: "",
				gst_total_amount: "",
				merchant_id: "",
				gst_no: "",
				pan_number: "",
				//tds_percentage: "",
				tds_amount: "",
				tds_details: [],
				selected_tags: [],
				category_id: "",
				//type: "",
				merchant_option: {},
				snapshot_prediction_status: 0,
				igst_amount_prediction_status: 0,
				sgst_amount_prediction_status: 0,
				cgst_amount_prediction_status: 0,
				total_gst_amount_prediction_status: 0,
				date_prediction_status: 0,
				merchant_name_prediction_status: 0,
				gst_no_prediction_status: 0,
				snapshot_type_prediction_status: 0,
				snapshot_number_prediction_status: 0,
				total_amount_prediction_status: 0,
				merchant_id_prediction_status: 0,
				pan_number_prediction_status: 0,
				expense_prediction_status: 0,
				category_prediction_status: 0,
				category_id_prediction_status: 0,
				tds_percentage_prediction_status: 0,
				tags_prediction_status: 0,
				is_msme_prediction_status: 0,
				is_entity_mismatch_prediction_status: 0,
				//tds_precentage_status: 0,
				igst_amount_prediction_value: "",
				sgst_amount_prediction_value: "",
				cgst_amount_prediction_value: "",
				total_gst_amount_prediction_value: "",
				pan_number_prediction_value: "",
				date_prediction_value: "",
				merchant_name_prediction_value: "",
				gst_no_prediction_value: "",
				snapshot_number_prediction_value: "",
				total_amount_prediction_value: "",
				merchant_id_prediction_value: "",
				snapshot_type_prediction_value: "",
				category_id_prediction_value: "",
				tds_percentage_prediction_value: "",
				tags_prediction_value: "",
				is_msme_prediction_value: "",
				is_entity_mismatch_prediction_value: "",
				prediction_id: null,
				is_igst: false,
				disable_igst: false,
				gst_summary: {},
				hsn_sac_summary: {},
				global_preidction_status:0,
				combined_inventory_array:[],
				bulk_prediction_status : 0,
				inventory_party_ledger_id:null,
				inventory_party_ledger_name:null,
				inventory_common_item_ledger_guid:null,
				inventory_common_item_ledger_id:null,
				inventory_common_item_ledger_name:null,
				inventory_party_ledger_guid_prediction:0,
				inventory_party_ledger_id_prediction:0,
				inventory_party_ledger_name_prediction:0,
				inventory_common_item_ledger_guid_prediction:0,
				inventory_common_item_ledger_id_prediction:0,
				inventory_common_item_ledger_name_prediction:0,
				inventory_voucher_creation_date:"",
				inventory_voucher_creation_date_prediction:0,
				line_items_data : [],
				prediction_party_ledger_guid : null,
				prediction_purchase_ledger_guid : null,
				prediction_sales_ledger_guid : null,
				global_preidction_status : 0,
				
				is_gst_override:false,
				hide_gst_override:true,
				parent_voucher_type_id:"",
				voucher_type_guid:"",
				voucher_type_name:"",
				voucher_type_id:"",
				prediction_voucher_type_guid:null
			})

			let tds_information = [];
			if(this.state.snapshot_id)
			tds_information.push({
				id:0,
				base_amount: "",
				tds_percentage:"",
				tds_amount:"",
				});
			else
			tds_information.push({
				base_amount:"",
				tds_percentage: "",
				tds_amount: "",
			});
			this.setState({tds_details:tds_information});


			this.handleSupplierCustomerToggle('is_supplier', true)
			this.handleSupplierCustomerToggle('is_customer', true)

			await SnapshotService.getPredictionData(file_url, this.state.expense, this.state.type).then(
				(data) => {
					if (data.response_code === 200) {
						this.emptyInventoryData();
						this.setState({ file_url: data.result.file_path });
						this.setState({ snapshot_prediction_status: 1, prediction_id: data.result.prediction_id });
						let merchant_id = 0, merchant_name = "", gst_no = "";

						this.imageUploadChildRef.openPDF();

						// merchant_option: { label: data.result.merchant_name, value: data.result.merchant_id },
						data.result.payload.forEach((payload) => {
							console.log("payload ----------------" ,this.state.tds_details, payload.status, payload.displayName);
							if (payload.status == 1) {
								switch (payload.displayName) {
									case "INVOICE_DATE":
										var newdate = payload.value;//.split("-").reverse().join("-");
										this.setState({ date_prediction_status: payload.status, date: (!!newdate ? new Date(newdate) : ""), date_prediction_value: (!!newdate ? new Date(newdate) : "") });
										/*invalidate dateformat*/
										break;
									case "INVOICE_NUMBER": this.setState({ snapshot_number_prediction_status: payload.status, invoice_number: payload.value, snapshot_number_prediction_value: payload.value });
										break;
									case "TOTAL_AMOUNT":
										const total_amount = roundOffValue(this.props.amount_round_off,payload.value);
										 this.setState({ total_amount_prediction_status: payload.status, total_amount: total_amount, total_amount_prediction_value: total_amount }, () => {
										this.calculatePredictionTDS();
										if (this.state.is_reimbursement) {
											this.setState({ approved_amount: total_amount })
										}
									});
										break;
									case "sgst_final":
											const sgst_amount = roundOffValue(this.props.amount_round_off,payload.value);
										 this.setState({ sgst_amount_prediction_status: payload.status, sgst_amount: sgst_amount, gst_status: !!payload.value, sgst_amount_prediction_value: sgst_amount });
										break;
									case "cgst_final": 
										const cgst_amount = roundOffValue(this.props.amount_round_off,payload.value);
										this.setState({ cgst_amount_prediction_status: payload.status, cgst_amount: cgst_amount, gst_status: !!payload.value, cgst_amount_prediction_value: cgst_amount });
										break;
									case "IGST": 
										const igst_amount = roundOffValue(this.props.amount_round_off,payload.value);
										this.setState({ igst_amount_prediction_status: payload.status, igst_amount: igst_amount, gst_status: !!payload.value, igst_amount_prediction_value: igst_amount }, () => {
											if (!!payload.value) {
												this.setState({ is_igst: true })
											}
										});
										break;
									case "merchant_name": merchant_name = payload.value;
										this.setState({ merchant_name_prediction_status: payload.status })
										break;
									case "merchant_id": merchant_id = !!payload.value ? payload.value : 0;
										this.setState({ merchant_id_prediction_status: payload.status })
										break;
									case "gst_final": this.setState({ gst_no_prediction_status: payload.status, gst_no: payload.value, gst_no_prediction_value: payload.value, gst_status: !!payload.value, is_gst_claim_eligible: !!payload.value }, () => {
										if (!!payload.value) {
											let customer_gst = this.state.gst_no ? this.state.gst_no.slice(0, 2) : null || null;
											let entity_gst = (!!this.props.entity_gst_no) ? this.props.entity_gst_no.slice(0, 2) : null;

											if (customer_gst == entity_gst && customer_gst && entity_gst) {
												this.setState({ is_igst: false, igst_amount: "", disable_igst: true,hide_gst_override:false}, () => {
													this.calculateTotalGstPrediction();
												})
											} else if (!customer_gst || !entity_gst) {
												if(this.state.cgst_amount && this.state.sgst_amount){
													this.setState({ is_igst: false, igst_amount: "", disable_igst: false,hide_gst_override:true }, () => {
														this.calculateTotalGstPrediction();
													})
												}else if(this.state.igst_amount){
													this.setState({ is_igst: true, disable_igst: false,hide_gst_override:true }, () => {
														this.calculateTotalGstPrediction();
													})
												}else{
													this.setState({ is_igst: true, igst_amount: "", disable_igst: false,hide_gst_override:true }, () => {
														this.calculateTotalGstPrediction();
													})
												}
											} else if (customer_gst != entity_gst && customer_gst && entity_gst) {
												this.setState({ is_igst: true, disable_igst: true, sgst_amount: "", cgst_amount: "",hide_gst_override:false}, () => {
													this.calculateTotalGstPrediction();
												})
											} else {
												if(this.state.cgst_amount && this.state.sgst_amount){
													this.setState({ is_igst: false, igst_amount: "", disable_igst: false,hide_gst_override:true }, () => {
														this.calculateTotalGstPrediction();
													})
												}else if(this.state.igst_amount){
													this.setState({ is_igst: true, disable_igst: false,hide_gst_override:true }, () => {
														this.calculateTotalGstPrediction();
													})
												}else{
													this.setState({ is_igst: true, igst_amount: "", disable_igst: false,hide_gst_override:true }, () => {
														this.calculateTotalGstPrediction();
													})
												}
											}
										}
									});
										break;
									case "pan_number": this.setState({ pan_number_prediction_status: payload.status, pan_number: payload.value, pan_number_prediction_value: payload.value })
										break;
									case "snapshot_type":
										if (!this.state.is_reimbursement) {
											this.setState({ snapshot_type_prediction_status: payload.status, type: payload.value, snapshot_type_prediction_value: payload.value });
											this.hideShowOnTypeChange(payload.value)
										}
										break;
									case "expense": this.setState({ expense_prediction_status: payload.status, expense: !!payload.value });
										break;
									case "is_msme": this.setState({ is_msme: !!payload.value ? payload.value : false, is_msme_prediction_value: payload.value, is_msme_prediction_status: payload.status });
										break;
									case "tds_percentage":
										let tds_arr = this.state.tds_details;
										console.log("Tds percentage --------------------------" ,this.state.tds_details, this.state.tds_details.length)
										if (this.state.tds_details.length == 0) {
											let amt = this.state.total_amount?this.state.total_amount:"";
											amt = amt == 0?"":amt;
											if (this.state.snapshot_id)
											tds_arr = [
											  {
												id: 0,
												base_amount: amt,
												tds_amount: "",
												tds_percentage: "",
											  },
											];
											else{
												tds_arr = [
													{
													  base_amount: amt,
													  tds_amount: "",
													  tds_percentage: "",
													},
												  ];
											}
										  }

										tds_arr[0].tds_percentage = !!payload.value ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,payload.value):payload.value : "";
										tds_arr[0].base_amount = this.state.total_amount?this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,this.state.total_amount):this.state.total_amount : "";
										this.setState(
										  {
											tds_details: tds_arr,
											tds_status: !!payload.value ? true : false,
											tds_percentage_prediction_value: payload.value,
											tds_percentage_prediction_status: payload.status,
										  },
										  () => {
											this.calculatePredictionTDS();
										  }
										);
									
										break;
									case "is_entity_mismatch": this.setState({ is_entity_mismatch: !!payload.value ? payload.value : false, is_entity_mismatch_prediction_value: payload.value, is_entity_mismatch_prediction_status: payload.status });
										break;
									case "category_id": this.setState({ category_id: !!payload.value ? payload.value : "", category_prediction_status: payload.status, category_id_prediction_value: payload.value, category_id_prediction_status: payload.status });
										break;
									case "tags":
										let selected_tags = []
										selected_tags = payload.value.map((tag, index) => {
											return tag.id;
										})
										this.setState({ selected_tags: selected_tags, tags_prediction_value: selected_tags, tags_prediction_status: payload.status });
										break;
								}
								if (!!merchant_name) {
									this.setState({ snapshot_prediction_status: 1, merchant_option: { label: merchant_name, value: merchant_id }, merchant_name_prediction_value: merchant_name, merchant_id_prediction_value: merchant_id })
								}
								//console.log(merchant_id)
								if (merchant_id == 0) {
									this.setState({ is_new: true, new_value: merchant_name })
								} else {
									this.setState({ is_new: false })
								}
							}
						});
						if(this.state.date){
							this.setState({checkCurrentDate : ""})
						}

						//globaly decalred prediction status : 
						this.setState({global_preidction_status:1})

						if(this.state.date){
							this.setState({checkCurrentDate : ""})
						}

						let merchant_list = this.state.all_merchant_list;
						let merchant_data = merchant_list.filter(merchant => merchant.value == merchant_id)
						//console.log(merchant_list, merchant_data, merchant_id)
						if (this.props.accounting_mode == 2 && merchant_data.length > 0) {
							let inventory_details = _.cloneDeep(this.props.temp_inventory_data);

							if (this.state.inventory_mode == 1 || this.state.inventory_mode) {

								let ledger = this.state.ledgerNameList.ledger_list.filter(ledger => ledger.guid == (this.state.type == 1 ? merchant_data[0].sales_ledger_guid : merchant_data[0].purchase_ledger_guid));
								//console.log(ledger)
								if (inventory_details.is_common_item_ledger == "")
									inventory_details.is_common_item_ledger = this.props.is_common_item_ledger;

								if (ledger.length > 0 && inventory_details.is_common_item_ledger) {
									// inventory_details.common_item_ledger_guid = ledger[0].guid;
									// inventory_details.common_item_ledger_id = ledger[0].id;
									// inventory_details.common_item_ledger_name = ledger[0].name;
									// inventory_details.inventory_voucher_status = true;

									//prediction status 

									// inventory_details.common_item_ledger_guid_prediction_status = 1;
									// inventory_details.common_item_ledger_id_prediction_status = 1;
									// inventory_details.common_item_ledger_name_prediction_status = 1;
									this.setState({inventory_common_item_ledger_guid:ledger[0].guid,inventory_common_item_ledger_id:ledger[0].id,inventory_common_item_ledger_name:ledger[0].name})
									this.setState({inventory_common_item_ledger_guid_prediction:1,inventory_common_item_ledger_id_prediction:1,inventory_common_item_ledger_name_prediction:1})
								}

								let party_ledger = this.state.ledgerNameList.ledger_list.filter(ledger => ledger.guid == merchant_data[0].party_ledger_guid);
								//console.log(party_ledger)
								if (party_ledger.length > 0) {
									// inventory_details.party_ledger_guid = party_ledger[0].guid;
									// inventory_details.party_ledger_id = party_ledger[0].id;
									// inventory_details.party_ledger_name = party_ledger[0].name;
									// inventory_details.inventory_voucher_status = true;

									//predicton status

									// inventory_details.party_ledger_guid_prediction_status = 1;
									// inventory_details.party_ledger_id_prediction_status = 1;
									// inventory_details.party_ledger_name_prediction_status = 1;
									this.setState({inventory_party_ledger_guid:party_ledger[0].guid,inventory_party_ledger_id:party_ledger[0].id,inventory_party_ledger_name:party_ledger[0].name})
									this.setState({inventory_party_ledger_guid_prediction:1,inventory_party_ledger_id_prediction:1,inventory_party_ledger_name_prediction:1})
								}
								//console.log(inventory_details)
								// inventory_details.voucher_creation_date = inventory_details.voucher_creation_date ? new Date(inventory_details.voucher_creation_date) : this.state.date
								// if(inventory_details.voucher_creation_date){
								// 	inventory_details.voucher_creation_date_prediction_status = 1;
								// }
								// this.props.dispatch(setTempInventoryData(inventory_details));
								// this.setState({ inventory_details: true })
								if(this.state.date){
									this.setState({inventory_voucher_creation_date:this.state.date,inventory_voucher_creation_date_prediction:1})
								}
								let voucher_type_details = this.state.voucherTypeList.filter(voucher => voucher.guid == (this.state.type == 1 ? merchant_data[0].sales_voucher_type_guid : merchant_data[0].purchase_voucher_type_guid))
								if(voucher_type_details.length > 0){
									this.setState({prediction_voucher_type_guid:voucher_type_details[0].guid,voucher_type_guid:voucher_type_details[0].guid,voucher_type_id:voucher_type_details[0].id,voucher_type_name:voucher_type_details[0].name,parent_voucher_type_id:voucher_type_details[0].parent_voucher_type_id})
								}
							}
						}

						this.calculateTotalGstPrediction();
						this.calculatePredictionTDS();
						this.checkForReadyState();
					}
				})
		}
	}

// when we select new model will exceute following function;
	newUploadFileUrl = async (data,file_type) => {
		console.log(":::::::::::::new file url function",data)

		

		 this.setState({is_dirty: true, file_type })
		if (this.state.is_smart && data) {

			this.setState({
				xml_upload: false,
				is_base_amt_changed: false,
				auto_accounted:false,
				auto_ready_accounted:false,
				auto_ready:false,
				date: "",
				invoice_number: "",
				total_amount: "",
				merchant_name: "",
				sgst_amount: "",
				cgst_amount: "",
				igst_amount: "",
				gst_total_amount: "",
				merchant_id: "",
				gst_no: "",
				pan_number: "",
				//tds_percentage: "",
				tds_amount: "",
				tds_details: [],
				selected_tags: [],
				category_id: "",
				//type: "",
				merchant_option: {},
				snapshot_prediction_status: 0,
				igst_amount_prediction_status: 0,
				sgst_amount_prediction_status: 0,
				cgst_amount_prediction_status: 0,
				total_gst_amount_prediction_status: 0,
				date_prediction_status: 0,
				merchant_name_prediction_status: 0,
				gst_no_prediction_status: 0,
				snapshot_type_prediction_status: 0,
				snapshot_number_prediction_status: 0,
				total_amount_prediction_status: 0,
				merchant_id_prediction_status: 0,
				pan_number_prediction_status: 0,
				expense_prediction_status: 0,
				category_prediction_status: 0,
				category_id_prediction_status: 0,
				tds_percentage_prediction_status: 0,
				tags_prediction_status: 0,
				is_msme_prediction_status: 0,
				is_entity_mismatch_prediction_status: 0,
				//tds_precentage_status: 0,
				igst_amount_prediction_value: "",
				sgst_amount_prediction_value: "",
				cgst_amount_prediction_value: "",
				total_gst_amount_prediction_value: "",
				pan_number_prediction_value: "",
				date_prediction_value: "",
				merchant_name_prediction_value: "",
				gst_no_prediction_value: "",
				snapshot_number_prediction_value: "",
				total_amount_prediction_value: "",
				merchant_id_prediction_value: "",
				snapshot_type_prediction_value: "",
				category_id_prediction_value: "",
				tds_percentage_prediction_value: "",
				tags_prediction_value: "",
				is_msme_prediction_value: "",
				is_entity_mismatch_prediction_value: "",
				prediction_id: null,
				is_igst: false,
				disable_igst: false,
				// gst_summary: {},
				// hsn_sac_summary: {},
				line_items_data:[],
				line_items_prediction_status:0,
				global_preidction_status:0,
				request_id : null,
				prediction_party_ledger_guid: null,
				prediction_purchase_ledger_guid : null,
				prediction_sales_ledger_guid : null,
				combined_inventory_array:[],
				inventory_party_ledger_guid:null,
			inventory_party_ledger_id:null,
			inventory_party_ledger_name:null,
			inventory_common_item_ledger_guid:null,
			inventory_common_item_ledger_id:null,
			inventory_common_item_ledger_name:null,
			inventory_party_ledger_guid_prediction:0,
			inventory_party_ledger_id_prediction:0,
			inventory_party_ledger_name_prediction:0,
			inventory_common_item_ledger_guid_prediction:0,
			inventory_common_item_ledger_id_prediction:0,
			inventory_common_item_ledger_name_prediction:0,
			inventory_voucher_creation_date:"",
			inventory_voucher_creation_date_prediction:0,
			bulk_prediction_status :0,
				is_gst_override:false,
				hide_gst_override:true,
				parent_voucher_type_id:"",
				voucher_type_guid:"",
				voucher_type_name:"",
				voucher_type_id:"",
				prediction_voucher_type_guid:null
			})


			let tds_information = [];
				if(this.state.snapshot_id)
				tds_information.push({
					id:0,
					base_amount: "",
					tds_percentage:"",
					tds_amount:"",
				  });
				else
				tds_information.push({
				  base_amount:"",
				  tds_percentage: "",
				  tds_amount: "",
				});
this.setState({tds_details:tds_information});
			this.handleSupplierCustomerToggle('is_supplier', true)
			this.handleSupplierCustomerToggle('is_customer', true)

		
					if (data.response_code === 200) {
						console.log("::::::predictiondata",data.result)
						this.emptyInventoryData();
						this.setState({ file_url: data.result.file_path });
						this.setState({ snapshot_prediction_status: 1, prediction_id: null , request_id : data.result.request_id,global_preidction_status : 1 });
						let merchant_id = 0, merchant_name = "", gst_no = "";
						let party_ledger_guid = null , purchase_ledger_guid = null , sales_ledger_guid = null ;
						let line_items = [];
						this.imageUploadChildRef.openPDF();

						

						let prediction_data = data.result.predictions;
						 console.log("prediction daa --- ::::::::::",prediction_data);

						if(Object.keys(prediction_data).length > 0){
							if(prediction_data.hasOwnProperty('category_id') && !!prediction_data.category_id){
								this.setState({ category_id: !!prediction_data.category_id ? prediction_data.category_id : "", category_prediction_status: 1, category_id_prediction_value: prediction_data.category_id, category_id_prediction_status: 1 });
							}
							if(prediction_data.hasOwnProperty('cgst_amount') && !!prediction_data.cgst_amount){
								const cgst_amount = roundOffValue(this.props.amount_round_off,prediction_data.cgst_amount);
								console.log("round offf",cgst_amount)
								this.setState({ cgst_amount_prediction_status: 1, cgst_amount: cgst_amount, gst_status: !!prediction_data.cgst_amount, cgst_amount_prediction_value: cgst_amount });
							}
							if(prediction_data.hasOwnProperty('sgst_amount') && !!prediction_data.sgst_amount){
								const sgst_amount = roundOffValue(this.props.amount_round_off,prediction_data.sgst_amount);
								this.setState({ sgst_amount_prediction_status: 1, sgst_amount: sgst_amount, gst_status: !!prediction_data.sgst_amount, sgst_amount_prediction_value: sgst_amount });
							}
							if(prediction_data.hasOwnProperty('igst_amount') && !!prediction_data.igst_amount){
								const igst_amount = roundOffValue(this.props.amount_round_off,prediction_data.igst_amount);
								this.setState({ igst_amount_prediction_status: 1, igst_amount: igst_amount, gst_status: !!prediction_data.igst_amount, igst_amount_prediction_value: igst_amount }, () => {
													if (!!prediction_data.igst_amount) {
														this.setState({ is_igst: true })
													}
												});
							}
							if(prediction_data.hasOwnProperty('expense')){
								this.setState({ expense_prediction_status: 1, expense: prediction_data.expense });
							}
							if(prediction_data.hasOwnProperty('gst_no') && !!prediction_data.gst_no){
								this.setState({ gst_no_prediction_status: 1, gst_no: prediction_data.gst_no, gst_no_prediction_value: prediction_data.gst_no, gst_status: !!prediction_data.gst_no, is_gst_claim_eligible: !!prediction_data.gst_no }, () => {
													if (!!prediction_data.gst_no) {
														let customer_gst = this.state.gst_no ? this.state.gst_no.slice(0, 2) : null || null;
														let entity_gst = (!!this.props.entity_gst_no) ? this.props.entity_gst_no.slice(0, 2) : null;
			
														if (customer_gst == entity_gst && customer_gst && entity_gst) {
															this.setState({ is_igst: false, igst_amount: "", disable_igst: true, hide_gst_override:false}, () => {
																this.calculateTotalGstPrediction();
															})
														} else if (!customer_gst || !entity_gst) {
															if(this.state.cgst_amount && this.state.sgst_amount){
																this.setState({ is_igst: false, igst_amount: "", disable_igst: false,hide_gst_override:true }, () => {
																	this.calculateTotalGstPrediction();
																})
															}else if(this.state.igst_amount){
																this.setState({ is_igst: true, disable_igst: false,hide_gst_override:true }, () => {
																	this.calculateTotalGstPrediction();
																})
															}else{
																this.setState({ is_igst: true, igst_amount: "", disable_igst: false,hide_gst_override:true }, () => {
																	this.calculateTotalGstPrediction();
																})
															}
															
														} else if (customer_gst != entity_gst && customer_gst && entity_gst) {
															this.setState({ is_igst: true, disable_igst: true, sgst_amount: "", cgst_amount: "", hide_gst_override:false}, () => {
																this.calculateTotalGstPrediction();
															})
														} else {
															if(this.state.cgst_amount && this.state.sgst_amount){
																this.setState({ is_igst: false, igst_amount: "", disable_igst: false,hide_gst_override:true }, () => {
																	this.calculateTotalGstPrediction();
																})
															}else if(this.state.igst_amount){
																this.setState({ is_igst: true, disable_igst: false,hide_gst_override:true }, () => {
																	this.calculateTotalGstPrediction();
																})
															}else{
																this.setState({ is_igst: true, igst_amount: "", disable_igst: false,hide_gst_override:true }, () => {
																	this.calculateTotalGstPrediction();
																})
															}
														}
													}
												});
							}
							if(prediction_data.hasOwnProperty('invoice_date') && !!prediction_data.invoice_date ){
								var newdate = prediction_data.invoice_date;//.split("-").reverse().join("-");
										this.setState({ date_prediction_status: 1, date: (!!newdate ? new Date(newdate) : ""), date_prediction_value: (!!newdate ? new Date(newdate) : "") });
										/*invalidate dateformat*/
							}
							if(prediction_data.hasOwnProperty('invoice_number') && !!prediction_data.invoice_number){
								this.setState({ snapshot_number_prediction_status: 1, invoice_number: prediction_data.invoice_number, snapshot_number_prediction_value: prediction_data.invoice_number });
							}
							if(prediction_data.hasOwnProperty('is_entity_mismatch')){
								this.setState({ is_entity_mismatch: !!prediction_data.is_entity_mismatch ? prediction_data.is_entity_mismatch : false, is_entity_mismatch_prediction_value: prediction_data.is_entity_mismatch, is_entity_mismatch_prediction_status: 1 });
							}
							if(prediction_data.hasOwnProperty('is_msme') && !!prediction_data.is_msme){
								this.setState({ is_msme: !!prediction_data.is_msme ? prediction_data.is_msme : false, is_msme_prediction_value: prediction_data.is_msme, is_msme_prediction_status: 1 });
							}
							if(prediction_data.hasOwnProperty('pan_number') && !!prediction_data.pan_number){
								this.setState({ pan_number_prediction_status: 1, pan_number: prediction_data.pan_number, pan_number_prediction_value: prediction_data.pan_number })
							}
							if(prediction_data.hasOwnProperty('tds_percentage') && !!prediction_data.tds_percentage){
								console.log("tds details --------------",this.state.tds_details);
								let tds_arr = this.state.tds_details;
								let amt = this.state.total_amount?this.state.total_amount:"";
								amt = amt == 0?"":amt;
								if (this.state.tds_details.length == 0) {
									if (this.state.snapshot_id)
									tds_arr = [
									  {
										id: 0,
										base_amount:amt ,
										tds_amount: "",
										tds_percentage: "",
									  },
									];
									else{
										tds_arr = [
											{
											  base_amount: amt,
											  tds_amount: "",
											  tds_percentage: "",
											},
										  ];
									}
								  }
								
								tds_arr[0].tds_percentage = !!prediction_data.tds_percentage ? roundOffValue(this.props.amount_round_off,prediction_data.tds_percentage): "";
								this.setState(
								  {
									tds_details: tds_arr,
									tds_status: !!prediction_data.tds_percentage ? true : false,
									tds_percentage_prediction_value: prediction_data.tds_percentage,
									tds_percentage_prediction_status: 1,
								  },
								  () => {
									this.calculatePredictionTDS();
								  }
								);
							}
							if(prediction_data.hasOwnProperty('snapshot_type') && !!prediction_data.snapshot_type){
								if (!this.state.is_reimbursement) {
										this.setState({ snapshot_type_prediction_status: 1, type: prediction_data.snapshot_type, snapshot_type_prediction_value: 1 });
										this.hideShowOnTypeChange(prediction_data.snapshot_type)
									}
							}
							if(prediction_data.hasOwnProperty('total_amount') && !!prediction_data.total_amount){
								const total_amount = roundOffValue(this.props.amount_round_off,prediction_data.total_amount);
								this.setState({ total_amount_prediction_status: 1, total_amount: total_amount, total_amount_prediction_value: total_amount }, () => {
													this.calculatePredictionTDS();
													if (this.state.is_reimbursement) {
														this.setState({ approved_amount: total_amount })
													}
												});
							}
							if(prediction_data.hasOwnProperty('tags') && prediction_data.tags.length > 0){
								console.log("tags",prediction_data.tags.length)
								let selected_tags = []
										selected_tags = prediction_data.tags.map((tag, index) => {
											return tag.id;
										})
										this.setState({ selected_tags: selected_tags, tags_prediction_value: selected_tags, tags_prediction_status: 1 });
							}
							if(prediction_data.hasOwnProperty('party_ledger_guid') && !!prediction_data.party_ledger_guid){
								this.setState({prediction_party_ledger_guid :prediction_data.party_ledger_guid})
							}
							if(prediction_data.hasOwnProperty('purchase_ledger_guid') && !!prediction_data.purchase_ledger_guid){
								
								this.setState({prediction_purchase_ledger_guid : prediction_data.purchase_ledger_guid})
							}
							if(prediction_data.hasOwnProperty('sales_ledger_guid') && !!prediction_data.sales_ledger_guid){
								
								this.setState({prediction_sales_ledger_guid:prediction_data.sales_ledger_guid})
							}
							if(prediction_data.hasOwnProperty('line_items') && prediction_data.line_items.length > 0){
								 this.setState({line_items_data : prediction_data.line_items })
							}
							if(prediction_data.hasOwnProperty('merchant_id')){
								merchant_id = !!prediction_data.merchant_id ? prediction_data.merchant_id : 0;
									this.setState({ merchant_id_prediction_status: 1 })
							}
							if(prediction_data.hasOwnProperty('merchant_name') && !!prediction_data.merchant_name){
								merchant_name = prediction_data.merchant_name;
								this.setState({ merchant_name_prediction_status: 1 })
							}
										if (!!merchant_name) {
												this.setState({ snapshot_prediction_status: 1, merchant_option: { label: merchant_name, value: merchant_id }, merchant_name_prediction_value: merchant_name, merchant_id_prediction_value: merchant_id })
										}
										
										if (merchant_id == 0) {
											this.setState({ is_new: true, new_value: merchant_name })
										} else {
											this.setState({ is_new: false })
											}
						}


						if(this.state.date){
							this.setState({checkCurrentDate : ""})
						}


						let merchant_list = this.state.all_merchant_list;
						let merchant_data = merchant_list.filter(merchant => merchant.value == merchant_id)
						//preeiction status of main_line_items 
						// let line_items  = [];
						//line items logic ----- when line_items has id = 0(means new line items)
						// let line_items = this.state.line_items_data;
						if((this.state.type == 1 || this.state.type == 2) && this.props.accounting_mode == 2 && this.state.inventory_mode){
							this.newLineitemPrediction();
						}

						
						
						if (this.props.accounting_mode == 2 && merchant_data.length > 0) {
							let inventory_details = _.cloneDeep(this.props.temp_inventory_data);

							if (this.state.inventory_mode == 1 || this.state.inventory_mode) {

								let ledger = this.state.ledgerNameList.ledger_list.filter(ledger => ledger.guid == (this.state.type == 1 ? merchant_data[0].sales_ledger_guid : merchant_data[0].purchase_ledger_guid));
								//console.log(ledger)
								
								if (inventory_details.is_common_item_ledger == "")
									inventory_details.is_common_item_ledger = this.props.is_common_item_ledger;

								if (ledger.length > 0 && inventory_details.is_common_item_ledger) {
									// inventory_details.common_item_ledger_guid = ledger[0].guid;
									// inventory_details.common_item_ledger_id = ledger[0].id;
									// inventory_details.common_item_ledger_name = ledger[0].name;
									// inventory_details.inventory_voucher_status = true;

									// //prediction status
									// inventory_details.common_item_ledger_guid_prediction_status = 1;
									// inventory_details.common_item_ledger_id_prediction_status = 1;
									// inventory_details.common_item_ledger_name_prediction_status = 1;
									this.setState({inventory_common_item_ledger_guid:ledger[0].guid,inventory_common_item_ledger_id:ledger[0].id,inventory_common_item_ledger_name:ledger[0].name})
									this.setState({inventory_common_item_ledger_guid_prediction:1,inventory_common_item_ledger_id_prediction:1,inventory_common_item_ledger_name_prediction:1})
									
								}

								if (ledger.length > 0 && inventory_details.is_common_item_ledger == 0) {
									// inventory_details.common_item_ledger_guid = "";
									// inventory_details.common_item_ledger_id = "";
									// inventory_details.common_item_ledger_name = "";
									// inventory_details.inventory_voucher_status = true;
									this.setState({inventory_common_item_ledger_guid:"",inventory_common_item_ledger_id:"",inventory_common_item_ledger_name:""})
									this.setState({inventory_common_item_ledger_guid_prediction:0,inventory_common_item_ledger_id_prediction:0,inventory_common_item_ledger_name_prediction:0})
									
								}

								let party_ledger = this.state.ledgerNameList.ledger_list.filter(ledger => ledger.guid == merchant_data[0].party_ledger_guid);
								//console.log(party_ledger)
								if (party_ledger.length > 0) {

									this.setState({inventory_party_ledger_guid:party_ledger[0].guid,inventory_party_ledger_id:party_ledger[0].id,inventory_party_ledger_name:party_ledger[0].name})
									this.setState({inventory_party_ledger_guid_prediction:1,inventory_party_ledger_id_prediction:1,inventory_party_ledger_name_prediction:1})
									// inventory_details.party_ledger_guid = party_ledger[0].guid;
									// inventory_details.party_ledger_id = party_ledger[0].id;
									// inventory_details.party_ledger_name = party_ledger[0].name;
									// inventory_details.inventory_voucher_status = true;

									//predicton status

									// inventory_details.party_ledger_guid_prediction_status = 1;
									// inventory_details.party_ledger_id_prediction_status = 1;
									// inventory_details.party_ledger_name_prediction_status = 1;
								}
								//console.log(inventory_details)
								// inventory_details.voucher_creation_date = inventory_details.voucher_creation_date ? new Date(inventory_details.voucher_creation_date) : this.state.date
								// if(inventory_details.voucher_creation_date){
								// 	inventory_details.voucher_creation_date_prediction_status = 1;
								// }

								// this.props.dispatch(setTempInventoryData(inventory_details));
								// console.log('inventory details',inventory_details)
								// this.setState({ inventory_details: true,})
								if(this.state.date){
									this.setState({inventory_voucher_creation_date:this.state.date,inventory_voucher_creation_date_prediction:1})
								}

								let voucher_type_details = this.state.voucherTypeList.filter(voucher => voucher.guid == (this.state.type == 1 ? merchant_data[0].sales_voucher_type_guid : merchant_data[0].purchase_voucher_type_guid))
								if(voucher_type_details.length > 0){
									this.setState({prediction_voucher_type_guid:voucher_type_details[0].guid,voucher_type_guid:voucher_type_details[0].guid,voucher_type_id:voucher_type_details[0].id,voucher_type_name:voucher_type_details[0].name,parent_voucher_type_id:voucher_type_details[0].parent_voucher_type_id})
								}
							}
						}


					

						if((this.state.type == 2  || this.state.type == 1) && this.state.inventory_mode == 1 && this.props.accounting_mode == 2){
							
								this.inventoryChildRef.openInventoryEditView();
	
							
						}

						this.calculateTotalGstPrediction();
						this.calculatePredictionTDS();
						this.checkForReadyState();
					
				
		}
	}
}

newLineitemPrediction = () =>{
	console.log("inside lineitems")
	//jsut considered prediction status to 1 just for testing
	let inventory_details = _.cloneDeep(this.props.temp_inventory_data);
	this.setState({line_items_prediction_status : 1})
	let combined_inventory_array = [];
	if(this.state.line_items_data.length > 0){
	
	
	
		const new_lineItems =  this.state.line_items_data.filter(item =>  item.line_item_id === 0 );

	   console.log("check ::: new_lineItems",new_lineItems)
									
			if(new_lineItems.length > 0 ){
				if (inventory_details.is_common_item_ledger == "")
					inventory_details.is_common_item_ledger = this.props.is_common_item_ledger;

					const newlineData = new_lineItems.map((item,index)=>{
							
								
								let line_item = {
														// "line_item_id": 0,
														// "line_item_name": item.line_item_name || null,
														// "hsn_sac": item.hsn_sac || null,
														// "rate": parseFloat(item.rate) || null,
														// "per": item.per || null,
														// "quantity": item.quantity || null,
														// "gst_rate": parseFloat(item.gst_rate) || null,
														// "discount_rate": parseFloat(item.discount_rate) || null,
														// "amount": parseFloat(item.amount) || null,
														// "item_ledger_guid": item.item_ledger_guid || null,
														// "item_ledger_id": item.item_ledger_id || null,
														// "item_ledger_name": item.item_ledger_name || null,
														// "common_item_ledger_guid": item.common_item_ledger_guid || null,
														// "common_item_ledger_id": item.common_item_ledger_id || null,
														// "common_item_ledger_name": item.common_item_ledger_name || null,
														// "line_item_update": 1
														"line_item_id": 0,
														"line_item_id_prediction_status" : 1,
														"line_item_name": item.line_item_name || "",
														"line_item_name_prediction_status" : !!item.line_item_name ? 1 : 0,
														"hsn_sac": item.hsn_sac || "",
														"hsn_sac_prediction_status" : !!item.hsn_sac ? 1 : 0,
														"rate": parseFloat(item.rate) || "",
														"rate_prediction_status" : !!item.rate ? 1 : 0,
														"per": item.per || "",
														"per_prediction_status" : !!item.per ? 1 : 0,
														"quantity": item.quantity || "",
														"quantity_prediction_status" : !!item.quantity ? 1 : 0,
														"gst_rate": parseFloat(item.gst_rate) || "",
														"gst_rate_prediction_status" :  !!item.gst_rate  ? 1 : 0,
														"discount_rate": parseFloat(item.discount_rate) || "",
														"discount_rate_prediction_status" : !!item.discount_rate ? 1 : 0,
														"amount": parseFloat(item.amount) || "",
														"amount_prediction_status" : !!item.amount ? 1 : 0,
														"item_ledger_guid": item.item_ledger_guid || "",
														"item_ledger_guid_prediction_status" : !!item.item_ledger_guid ? 1 : 0,
														"item_ledger_id": item.item_ledger_id || null,
														"item_ledger_id_prediction_status" : !!item.item_ledger_id ? 1 : 0,
														"item_ledger_name": item.item_ledger_name || "",
														"item_ledger_name_prediction_status" : !!item.item_ledger_name ? 1 : 0,
														"common_item_ledger_guid": item.common_item_ledger_guid || "",
														"common_item_ledger_guid_prediction_status" : !!item.common_item_ledger_guid ? 1 : 0,
														"common_item_ledger_id": item.common_item_ledger_id || "",
														"common_item_ledger_id_prediction_status" : !!item.common_item_ledger_id ? 1 : 0,
														"common_item_ledger_name": item.common_item_ledger_name || "",
														"common_item_ledger_name_prediction_status" : !!item.common_item_ledger_name ? 1 : 0,
														"line_item_update": 1
													}
						if(this.SNAPSHOT_LEDGER_ENTRIES_CODE){
								if(inventory_details.is_common_item_ledger == 0 && this.state.type == 1 && item.sales_ledger_guid){
									let ledger = this.state.ledgerNameList.ledger_list.filter(item_ledger => item_ledger.guid == item.sales_ledger_guid);
									if(ledger.length > 0){
										
										line_item.common_item_ledger_guid = ledger[0].guid;
										line_item.common_item_ledger_id  = ledger[0].id;
										line_item.common_item_ledger_name = ledger[0].name;
										// prediction status
										line_item.common_item_ledger_guid_prediction_status = 1;
										line_item.common_item_ledger_id_prediction_status = 1;
										line_item.common_item_ledger_name = 1;
									}

								}
								if(inventory_details.is_common_item_ledger == 0 && this.state.type == 2 && item.purchase_ledger_guid){
									let ledger = this.state.ledgerNameList.ledger_list.filter(item_ledger => item_ledger.guid == item.purchase_ledger_guid);
									if(ledger.length > 0){
										
										line_item.common_item_ledger_guid = ledger[0].guid;
										line_item.common_item_ledger_id  = ledger[0].id;
										line_item.common_item_ledger_name = ledger[0].name;
										// prediction status
										line_item.common_item_ledger_guid_prediction_status = 1;
										line_item.common_item_ledger_id_prediction_status = 1;
										line_item.common_item_ledger_name = 1;

									}

								}
						}else{
							console.log("out of")
										line_item.common_item_ledger_guid = "";
										line_item.common_item_ledger_id  = "";
										line_item.common_item_ledger_name = "";
										// prediction status
										line_item.common_item_ledger_guid_prediction_status = 0;
										line_item.common_item_ledger_id_prediction_status = 0;
										line_item.common_item_ledger_name = 0;

											line_item.item_ledger_name = "";
											line_item.item_ledger_id = "";
											line_item.item_ledger_guid = "";
											//prediction status
											line_item.item_ledger_name_prediction_status =  0;
											line_item.item_ledger_id_prediction_status = 0;
											line_item.item_ledger_guid_prediction_status = 0;
						}
								console.log("line item",line_item)

								return line_item;
							

							});

					combined_inventory_array = [...newlineData];
			}




			//check line items present in db or not if exist then give prediction of line items
		const db_lineItems = this.state.line_items_list.flatMap(dbItem=>{
			const matched_items = this.state.line_items_data.filter(item => item.line_item_id == dbItem.id );
			return matched_items;
		})

	console.log("db item",db_lineItems)

			if(db_lineItems.length > 0){
				if (inventory_details.is_common_item_ledger == "")
					inventory_details.is_common_item_ledger = this.props.is_common_item_ledger;
				const dbLineItems = db_lineItems.map((item,index)=>{
						
							
							let line_item = {
														"line_item_id": item.line_item_id,
														"line_item_id_prediction_status" : !!item.line_item_id ? 1 : 0,
														"line_item_name": item.line_item_name || "",
														"line_item_name_prediction_status" : !!item.line_item_name ? 1 : 0,
														"hsn_sac": item.hsn_sac || "",
														"hsn_sac_prediction_status" : !!item.hsn_sac ? 1 : 0,
														"rate": parseFloat(item.rate) || "",
														"rate_prediction_status" : !!item.rate ? 1 : 0,
														"per": item.per || "",
														"per_prediction_status" : !!item.per ? 1 : 0,
														"quantity": item.quantity || "",
														"quantity_prediction_status" : !!item.quantity ? 1 : 0,
														"gst_rate": parseFloat(item.gst_rate) || "",
														"gst_rate_prediction_status" :  !!item.gst_rate  ? 1 : 0,
														"discount_rate": parseFloat(item.discount_rate) || "",
														"discount_rate_prediction_status" : !!item.discount_rate ? 1 : 0,
														"amount": parseFloat(item.amount) || "",
														"amount_prediction_status" : !!item.amount ? 1 : 0,
														"item_ledger_guid": item.item_ledger_guid || "",
														"item_ledger_guid_prediction_status" : !!item.item_ledger_guid ? 1 : 0,
														"item_ledger_id": item.item_ledger_id || null,
														"item_ledger_id_prediction_status" : !!item.item_ledger_id ? 1 : 0,
														"item_ledger_name": item.item_ledger_name || "",
														"item_ledger_name_prediction_status" : !!item.item_ledger_name ? 1 : 0,
														"common_item_ledger_guid": item.common_item_ledger_guid || "",
														"common_item_ledger_guid_prediction_status" : !!item.common_item_ledger_guid ? 1 : 0,
														"common_item_ledger_id": item.common_item_ledger_id || "",
														"common_item_ledger_id_prediction_status" : !!item.common_item_ledger_id ? 1 : 0,
														"common_item_ledger_name": item.common_item_ledger_name || "",
														"common_item_ledger_name_prediction_status" : !!item.common_item_ledger_name ? 1 : 0,
														"line_item_update": 0
											}
												console.log("db index",line_item)
								//selecting item-ledger based on Prediction item_ledger_guid
								 let item_ledger = this.state.item_ledger_list.filter(item_ledger => item_ledger.guid == item.item_ledger_guid);
								 if(item_ledger.length > 0){
									line_item.item_ledger_guid = item_ledger[0].guid;
									line_item.item_ledger_id = item_ledger[0].id;
									line_item.item_ledger_name = item_ledger[0].name;

									//prediction status
									line_item.item_ledger_guid_prediction_status = 1;
									line_item.item_ledger_id_prediction_status = 1;
									line_item.item_ledger_name_prediction_status = 1;
								 }
			
								console.log("ledger entries",this.SNAPSHOT_LEDGER_ENTRIES_CODE)

								if(this.SNAPSHOT_LEDGER_ENTRIES_CODE){
									if(inventory_details.is_common_item_ledger == 0 && this.state.type == 1 && item.sales_ledger_guid){
										let ledger = this.state.ledgerNameList.ledger_list.filter(item_ledger => item_ledger.guid == item.sales_ledger_guid);
										if(ledger.length > 0){
										
											line_item.common_item_ledger_guid = ledger[0].guid;
											line_item.common_item_ledger_id  = ledger[0].id;
											line_item.common_item_ledger_name = ledger[0].name;
											// prediction status
											line_item.common_item_ledger_guid_prediction_status = 1;
											line_item.common_item_ledger_id_prediction_status = 1;
											line_item.common_item_ledger_name = 1;
										}
									}
									if(inventory_details.is_common_item_ledger == 0 && this.state.type == 2 && item.purchase_ledger_guid){
										let ledger = this.state.ledgerNameList.ledger_list.filter(item_ledger => item_ledger.guid == item.purchase_ledger_guid);
										if(ledger.length > 0){
											
											line_item.common_item_ledger_guid = ledger[0].guid;
											line_item.common_item_ledger_id  = ledger[0].id;
											line_item.common_item_ledger_name = ledger[0].name;
											// prediction status
											line_item.common_item_ledger_guid_prediction_status = 1;
											line_item.common_item_ledger_id_prediction_status = 1;
											line_item.common_item_ledger_name = 1;
	
										}
	
									}

								}else{
										console.log("out of")
										line_item.common_item_ledger_guid = "";
										line_item.common_item_ledger_id  = "";
										line_item.common_item_ledger_name = "";
										// prediction status
										line_item.common_item_ledger_guid_prediction_status = 0;
										line_item.common_item_ledger_id_prediction_status = 0;
										line_item.common_item_ledger_name = 0;

										// line_item.item_ledger_name = "";
										// line_item.item_ledger_id = "";
										// line_item.item_ledger_guid = "";
										// 	//prediction status
										// line_item.item_ledger_name_prediction_status =  0;
										// line_item.item_ledger_id_prediction_status = 0;
										// line_item.item_ledger_guid_prediction_status = 0;
								}
			

				

					return line_item;

				});

				combined_inventory_array = [...combined_inventory_array,...dbLineItems]
					console.log("combined",combined_inventory_array)


			}

			// inventory_details.line_items = [];
			// inventory_details.line_items = [...combined_inventory_array];
			// this.props.dispatch(setTempInventoryData(inventory_details))
			// this.setState({ inventory_details: true})

			this.setState({combined_inventory_array:combined_inventory_array})
}
console.log("inventoymode",this.state.inventory_mode)

	if(this.SNAPSHOT_LEDGER_ENTRIES_CODE){
console.log("inside otherfn")
		if (inventory_details.is_common_item_ledger == "")
			inventory_details.is_common_item_ledger = this.props.is_common_item_ledger;

		if(inventory_details.is_common_item_ledger && this.state.type == 2 && !!this.state.prediction_purchase_ledger_guid){
			let ledger = this.state.ledgerNameList.ledger_list.filter(ledger => ledger.guid == this.state.prediction_purchase_ledger_guid);
			if(ledger.length > 0){
				// inventory_details.common_item_ledger_guid = ledger[0].guid;
				// inventory_details.common_item_ledger_id = ledger[0].id;
				// inventory_details.common_item_ledger_name = ledger[0].name;
				// inventory_details.inventory_voucher_status = true;

				//prediction status
				// inventory_details.common_item_ledger_guid_prediction_status = 1;
				// inventory_details.common_item_ledger_id_prediction_status = 1;
				// inventory_details.common_item_ledger_name_prediction_status = 1;
				console.log("state of inve",ledger)
				this.setState({inventory_common_item_ledger_guid:ledger[0].guid,inventory_common_item_ledger_id:ledger[0].id,inventory_common_item_ledger_name:ledger[0].name})
				this.setState({inventory_common_item_ledger_guid_prediction:1,inventory_common_item_ledger_id_prediction:1,inventory_common_item_ledger_name_prediction:1})

			}
		}
		if(inventory_details.is_common_item_ledger && this.state.type == 1 && !!this.state.prediction_sales_ledger_guid){
		let ledger = this.state.ledgerNameList.ledger_list.filter(ledger => ledger.guid == this.state.prediction_sales_ledger_guid);
			if(ledger.length > 0){
				// inventory_details.common_item_ledger_guid = ledger[0].guid;
				// inventory_details.common_item_ledger_id = ledger[0].id;
				// inventory_details.common_item_ledger_name = ledger[0].name;
				// inventory_details.inventory_voucher_status = true;

				//prediction status
				// inventory_details.common_item_ledger_guid_prediction_status = 1;
				// inventory_details.common_item_ledger_id_prediction_status = 1;
				// inventory_details.common_item_ledger_name_prediction_status = 1;

				console.log("state of inve ",ledger)

				this.setState({inventory_common_item_ledger_guid:ledger[0].guid,inventory_common_item_ledger_id:ledger[0].id,inventory_common_item_ledger_name:ledger[0].name})
				this.setState({inventory_common_item_ledger_guid_prediction:1,inventory_common_item_ledger_id_prediction:1,inventory_common_item_ledger_name_prediction:1})
			}
		}
		if(!!this.state.prediction_party_ledger_guid){
			let party_ledger = this.state.ledgerNameList.ledger_list.filter(ledger => ledger.guid == this.state.prediction_party_ledger_guid);
			if(party_ledger.length > 0){
				// inventory_details.party_ledger_guid = party_ledger[0].guid;
				// inventory_details.party_ledger_id = party_ledger[0].id;
				// inventory_details.party_ledger_name = party_ledger[0].name;
				// inventory_details.inventory_voucher_status = true;

				//predicton status

				// inventory_details.party_ledger_guid_prediction_status = 1;
				// inventory_details.party_ledger_id_prediction_status = 1;
				// inventory_details.party_ledger_name_prediction_status = 1;

				
				this.setState({inventory_party_ledger_guid:party_ledger[0].guid,inventory_party_ledger_id:party_ledger[0].id,inventory_party_ledger_name:party_ledger[0].name})
				this.setState({inventory_party_ledger_guid_prediction:1,inventory_party_ledger_id_prediction:1,inventory_party_ledger_name_prediction:1})
			}
		}

}
else{

				// inventory_details.common_item_ledger_guid = "";
				// inventory_details.common_item_ledger_id = "";
				// inventory_details.common_item_ledger_name = "";

				//prediction status
				// inventory_details.common_item_ledger_guid_prediction_status = 0;
				// inventory_details.common_item_ledger_id_prediction_status = 0;
				// inventory_details.common_item_ledger_name_prediction_status = 0;

				// inventory_details.party_ledger_guid = "";
				// inventory_details.party_ledger_id = "";
				// inventory_details.party_ledger_name = "";

				//predicton status

				// inventory_details.party_ledger_guid_prediction_status = 0;
				// inventory_details.party_ledger_id_prediction_status = 0;
				// inventory_details.party_ledger_name_prediction_status = 0;
				this.setState({inventory_common_item_ledger_guid:"",inventory_common_item_ledger_id:"",inventory_common_item_ledger_name:""})
				this.setState({inventory_common_item_ledger_guid_prediction:0,inventory_common_item_ledger_id_prediction:0,inventory_common_item_ledger_name_prediction:0})


				this.setState({inventory_party_ledger_guid:"",inventory_party_ledger_id:"",inventory_party_ledger_name:""})
				this.setState({inventory_party_ledger_guid_prediction:0,inventory_party_ledger_id_prediction:0,inventory_party_ledger_name_prediction:0})

	
}

	if(this.state.date){
		this.setState({inventory_voucher_creation_date:this.state.date,inventory_voucher_creation_date_prediction:1})
	}

	// this.props.dispatch(setTempInventoryData(inventory_details))
	console.log("inventory",inventory_details)
	console.log("state of inve",this.state.inventory_common_item_ledger_guid,this.state.inventory_common_item_ledger_id)
	// this.setState({ inventory_details: true})

	
}



	bulkLineItemPrediction = async(bulk_prediction_data) =>{
		console.log("BulkNew",this.state.inventory_mode,this.state.type)
		if(bulk_prediction_data !== null){
			this.setState({bulk_prediction_status : 1})
			if((bulk_prediction_data.hasOwnProperty('category_id') && !!bulk_prediction_data.category_id)){
				this.setState({category_id : bulk_prediction_data.category_id});
			}
			if(bulk_prediction_data.hasOwnProperty('expense')){
				this.setState({expense: bulk_prediction_data.expense})
			}
			if(bulk_prediction_data.hasOwnProperty('snapshot_type') && !!bulk_prediction_data.snapshot_type){
				this.setState({snapshot_type : bulk_prediction_data.snapshot_type})
			}
			if(bulk_prediction_data.hasOwnProperty('tags') && bulk_prediction_data.tags.length > 0){
				let selected_tags = []
				selected_tags = bulk_prediction_data.tags.map((tag, index) => {
					return tag.id;
				})
				this.setState({selected_tags : selected_tags})
			}
			if(bulk_prediction_data.hasOwnProperty('party_ledger_guid') && !!bulk_prediction_data.party_ledger_guid){
				this.setState({prediction_party_ledger_guid :bulk_prediction_data.party_ledger_guid})
			}
			if(bulk_prediction_data.hasOwnProperty('purchase_ledger_guid') && !!bulk_prediction_data.purchase_ledger_guid){
					
				this.setState({prediction_purchase_ledger_guid : bulk_prediction_data.purchase_ledger_guid})
			}
			if(bulk_prediction_data.hasOwnProperty('sales_ledger_guid') && !!bulk_prediction_data.sales_ledger_guid){
					
				this.setState({prediction_sales_ledger_guid:bulk_prediction_data.sales_ledger_guid})
			}

			if(bulk_prediction_data.hasOwnProperty('line_items') && bulk_prediction_data.line_items.length > 0){
				this.setState({line_items_data : bulk_prediction_data.line_items},()=>{
					if((this.state.type == 2 || this.state.type == 1) && this.state.inventory_mode){
						this.newLineitemPrediction()

					}
				})
			}

		}
		if((this.state.type == 2 || this.state.type == 1) && this.state.inventory_mode){
			console.log("inside inventory")
			// await this.newLineitemPrediction();
			setTimeout(()=>{
				this.inventoryChildRef.openInventoryEditView()
			},1000)
		}
		// }
		// if((this.state.type == 2 || this.state.type == 1) && this.state.inventory_mode){
		// 	console.log("inside inventory")
		// 	await this.newLineitemPrediction();
		// 	setTimeout(()=>{
		// 		this.inventoryChildRef.openInventoryEditView()
		// 	},1000)
			 
		// }
	}

	emptyPredictionLineItems = () =>{
		this.setState({combined_inventory_array : []})
		this.setState({inventory_party_ledger_guid:null,
					    inventory_party_ledger_id:null,
						inventory_party_ledger_name:null,
						inventory_common_item_ledger_guid:null,
						inventory_common_item_ledger_id:null,
						inventory_common_item_ledger_name:null,
						inventory_party_ledger_guid_prediction:0,
						inventory_party_ledger_id_prediction:0,
						inventory_party_ledger_name_prediction:0,
						inventory_common_item_ledger_guid_prediction:0,
						inventory_common_item_ledger_id_prediction:0,
						inventory_common_item_ledger_name_prediction:0,
						inventory_voucher_creation_date:"",
						inventory_voucher_creation_date_prediction:0,
						voucher_type_guid:"",
						voucher_type_id:"",
						voucher_type_name:"",
						parent_voucher_type_id:""
					})
	
			
			
			
			
			
			
	}

	toggleSavePopUp = () => {
		this.props.history.push(`/snapshot/${this.props.match.params.evidence_type}`)
	}

	snapshotFilterOnMoneyLink = () => {
		localStorage.setItem('snapshot_id', JSON.stringify(this.state.snapshot_id));

		let filter_data = {
			transaction_id: '',
			type: '',
			from_amount: '',
			to_amount: '',
			modified_by: '',
			is_star: '',
			voucher_status: '',
			is_evidence_linked: '',
			is_personal_filter: '',
			snapshot_id: JSON.stringify(this.state.snapshot_id),
			messages: ''
		}
		
		 StorageData.setDefaultFI(0)
		// console.log(StorageData.getDefaultFI())
		this.props.dispatch(setTransactionFilterData(filter_data));
		window.open("/money-link")
	}
	selectedType = () => {
		switch (parseInt(this.state.type)) {
			case 1: return "Sales Invoice";
			case 2: return "Purchase Invoice";
			case 3: return "Sales Return";
			case 4: return "Purchase order";
			case 5: return "E-way bill";
			case 6: return "Expense Invoice";
			case 7: return "Purchase Return";
			case 8: return "Other";
			default: return "Other";
		}
	}

	checkForReadyState = (valid = false) => {
		let errors = {}
		
		let data = {
			"type": this.state.type,
			"date": changeDateFormate(this.state.date),
			"merchant_name": this.state.merchant_option.label || null,
			"invoice_number": this.state.invoice_number,
			"total_amount": parseFloat(this.state.total_amount),
			"gst_status": this.state.gst_status ? 1 : 0,
			"sgst_amount": this.state.gst_status ? parseFloat(this.state.sgst_amount) : null,
			"cgst_amount": this.state.gst_status ? parseFloat(this.state.cgst_amount) : null,
			"igst_amount": this.state.gst_status ? parseFloat(this.state.igst_amount) : null,
			"gst_total_amount": this.state.gst_status ? parseFloat(this.state.gst_total_amount) : null,
			"tds_status": this.state.tds_status ? 1 : 0,
			// "tds_percentage": this.state.tds_status ? parseFloat(this.state.tds_percentage) : null,
			// "tds_amount": this.state.tds_status ? parseFloat(this.state.tds_amount) : null,
			"tds_details": this.state.tds_details.map((item, index) => {
				return {
				  id: item.id,
				  tds_percentage: this.state.tds_status ? parseFloat(item.tds_percentage) : "",
				  base_amount: this.state.tds_status ? parseFloat(item.base_amount) : "",
				  tds_amount: this.state.tds_status ? parseFloat(item.tds_amount) : "",
				};
			  }),
			"payment_status": this.state.payment_status,
			"payment_information": this.state.payment_information,
			"accounting_mode": this.props.accounting_mode,
			"is_igst": this.state.is_igst,
			"party_ledger_guid": this.state.party_ledger_guid,
			"inventory_mode":this.state.inventory_mode
		}
		if (this.state.inventory_mode == 1 || this.state.inventory_mode) {
			data.sgst_amount = this.state.gst_status ? this.state.sgst_amount && this.state.sgst_amount != 0 ? parseFloat(this.state.sgst_amount) : null : null;
			data.cgst_amount = this.state.gst_status ? this.state.cgst_amount && this.state.cgst_amount != 0 ? parseFloat(this.state.cgst_amount) : null : null;
			data.igst_amount = this.state.gst_status ? this.state.igst_amount && this.state.igst_amount != 0 ? parseFloat(this.state.igst_amount) : null : null;
				

			
		}
		data.amount_round_off = this.props.amount_round_off;
		if (this.state.snapshot_id)
			errors = validateUpdateEvidence(data);
		else
			errors = validateCreateEvidence(data);
		console.log(data)
		console.log(errors, valid);

		if(! this.state.is_reimbursement){

			if (Object.keys(errors).length === 0) {
				this.setState({ errors: {} });
			
				if (valid == false){
					if(this.state.entry_status !== 2){
						this.setState({entry_status: 2, is_dirty : true,auto_ready:true});	
					}
					if(this.props.temp_snap_voucher_list.length > 0 && this.state.accounting_status !== 5){
						if(this.state.accounting_status !== 4){
							console.log("here in auto accounted")
							this.setState({auto_accounted : true , 
								accounting_status : this.props.temp_snap_voucher_list.length > 0 ? 4 : this.state.accounting_status,
							})	
						}
						this.setState({auto_ready_accounted: this.state.entry_status !== 2 ? true : false,entry_status: 2})
						
					}
					
					
				}
					
			} else {
				if (valid === true) {
					let errors_result = { ...errors, entry_status: 'Please fill the mandatory details.' };
					this.setState({ errors: errors_result });
				}
			}

		}

		

	}
	checkForAccountedState =  (updatedToAccounted) =>{


		let errors = {}
		const oldData = { ...this.state.old_data };

		let data = {
			"type": this.state.type,
			"date": changeDateFormate(this.state.date),
			"merchant_name": this.state.merchant_option.label || null,
			"invoice_number": this.state.invoice_number,
			"total_amount": parseFloat(this.state.total_amount),
			"gst_status": this.state.gst_status ? 1 : 0,
			"sgst_amount": this.state.gst_status ? parseFloat(this.state.sgst_amount) : null,
			"cgst_amount": this.state.gst_status ? parseFloat(this.state.cgst_amount) : null,
			"igst_amount": this.state.gst_status ? parseFloat(this.state.igst_amount) : null,
			"gst_total_amount": this.state.gst_status ? parseFloat(this.state.gst_total_amount) : null,
			"tds_status": this.state.tds_status ? 1 : 0,
			"tds_details": this.state.tds_details.map((item, index) => {
				if (this.state.snapshot_id)
				  return {
					id: item.id,
					tds_percentage: this.state.tds_status ? parseFloat(item.tds_percentage) : "",
					base_amount: this.state.tds_status ? parseFloat(item.base_amount) : "",
					tds_amount: this.state.tds_status ? parseFloat(item.tds_amount) : "",
				  };
				else
				  return {
					tds_percentage: this.state.tds_status ? parseFloat(item.tds_percentage) : "",
					base_amount: this.state.tds_status ? parseFloat(item.base_amount) : "",
					tds_amount: this.state.tds_status ? parseFloat(item.tds_amount) : "",
				  };
			  }),
			//"tds_percentage": this.state.tds_status ? parseFloat(this.state.tds_percentage) : null,
			//"tds_amount": this.state.tds_status ? parseFloat(this.state.tds_amount) : null,
			"payment_status": this.state.payment_status,
			"payment_information": this.state.payment_information,
			"accounting_mode": this.props.accounting_mode,
			"is_igst": this.state.is_igst,
			"party_ledger_guid": this.state.party_ledger_guid,
			"inventory_mode":this.state.inventory_mode
		}
		if (this.state.inventory_mode == 1 || this.state.inventory_mode) {
			data.sgst_amount = this.state.gst_status ? this.state.sgst_amount && this.state.sgst_amount != 0 ? parseFloat(this.state.sgst_amount) : null : null;
			data.cgst_amount = this.state.gst_status ? this.state.cgst_amount && this.state.cgst_amount != 0 ? parseFloat(this.state.cgst_amount) : null : null;
			data.igst_amount = this.state.gst_status ? this.state.igst_amount && this.state.igst_amount != 0 ? parseFloat(this.state.igst_amount) : null : null;
				

			
		}
		data.amount_round_off = this.props.amount_round_off;
		console.log("we are in the ")
		if (!!this.state.snapshot_id) {
			errors = validateUpdateEvidence(data);
		} else {
			errors = validateCreateEvidence(data);
		}
		console.log(this.props.temp_snap_voucher_list)
		if (Object.keys(errors).length === 0) {
			
			this.setState({ 
				is_dirty: true, 
			});
			if(updatedToAccounted){
				if(this.state.accounting_status !== 4 && this.state.accounting_status !== 5){
					this.setState({
						auto_accounted : true,
						accounting_status :  4 ,
						
					})
				}
				this.setState({auto_ready_accounted : this.state.entry_status !== 2 ? true : false , entry_status : 2})
				
			}else{
				if(this.state.snapshot_id){
					this.setState({auto_accounted : false , 
						auto_ready_accounted : false,
						accounting_status : oldData.accounting_status,
						entry_status : oldData.entry_status,
						auto_ready:false
					})
				}else{
					this.setState({auto_accounted : false , 
						auto_ready_accounted : false,
						accounting_status : 3,
						entry_status : 1,
						auto_ready:false
					})
				}
				
			}
		} 

	}

	checkForReadyAccountedState = () => {
		
		let errors = {}

		let data = {
			"type": this.state.type,
			"date": changeDateFormate(this.state.date),
			"merchant_name": this.state.merchant_option.label || null,
			"invoice_number": this.state.invoice_number,
			"total_amount": parseFloat(this.state.total_amount),
			"gst_status": this.state.gst_status ? 1 : 0,
			"sgst_amount": this.state.gst_status ? parseFloat(this.state.sgst_amount) : null,
			"cgst_amount": this.state.gst_status ? parseFloat(this.state.cgst_amount) : null,
			"igst_amount": this.state.gst_status ? parseFloat(this.state.igst_amount) : null,
			"gst_total_amount": this.state.gst_status ? parseFloat(this.state.gst_total_amount) : null,
			"tds_status": this.state.tds_status ? 1 : 0,
			"tds_details": this.state.tds_details.map((item, index) => {
				if (this.state.snapshot_id)
				  return {
					id: item.id,
					tds_percentage: this.state.tds_status ? parseFloat(item.tds_percentage) : "",
					base_amount: this.state.tds_status ? parseFloat(item.base_amount) : "",
					tds_amount: this.state.tds_status ? parseFloat(item.tds_amount) : "",
				  };
				else
				  return {
					tds_percentage: this.state.tds_status ? parseFloat(item.tds_percentage) : "",
					base_amount: this.state.tds_status ? parseFloat(item.base_amount) : "",
					tds_amount: this.state.tds_status ? parseFloat(item.tds_amount) : "",
				  };
			  }),
			//"tds_percentage": this.state.tds_status ? parseFloat(this.state.tds_percentage) : null,
			//"tds_amount": this.state.tds_status ? parseFloat(this.state.tds_amount) : null,
			"payment_status": this.state.payment_status,
			"payment_information": this.state.payment_information,
			"accounting_mode": this.props.accounting_mode,
			"is_igst": this.state.is_igst,
			"party_ledger_guid": this.state.party_ledger_guid,
			"inventory_mode":this.state.inventory_mode
		}
		if (this.state.inventory_mode == 1 || this.state.inventory_mode) {
			data.sgst_amount = this.state.gst_status ? this.state.sgst_amount && this.state.sgst_amount != 0 ? parseFloat(this.state.sgst_amount) : null : null;
			data.cgst_amount = this.state.gst_status ? this.state.cgst_amount && this.state.cgst_amount != 0 ? parseFloat(this.state.cgst_amount) : null : null;
			data.igst_amount = this.state.gst_status ? this.state.igst_amount && this.state.igst_amount != 0 ? parseFloat(this.state.igst_amount) : null : null;
				

			
		}
		data.amount_round_off = this.props.amount_round_off;
		console.log("we are in the ")
		if (!!this.state.snapshot_id) {
			errors = validateUpdateEvidence(data);
		} else {
			errors = validateCreateEvidence(data);
		}
		console.log(this.props.temp_snap_voucher_list)
		if (Object.keys(errors).length === 0) {
			
			this.setState({ 
				is_dirty: true, 
			});
			if(this.props.temp_snap_voucher_list.length > 0 && this.state.accounting_status !== 5){
				if(this.state.accounting_status !== 4){
					this.setState({auto_accounted : true , 
						accounting_status : this.props.temp_snap_voucher_list.length > 0 ? 4 : this.state.accounting_status,
					})	
				}
				this.setState({auto_ready_accounted: this.state.entry_status !== 2 ? true : false,entry_status: 2})
				
			}
		} 

	}

	setIsdirty = () => {
		this.setState({ is_dirty: true });
	}

	handleChangeExpense = async (e) => {
		if (this.CREATE_REIMBUSRMENT_SNAPSHOT && !this.CREATE_BUSSINESS_SNAPSHOT && !e.target.checked) {
			return true;
		}
		// if (this.state.prediction_id) {
		// on change expense yes/no,set module-id
		let merchant_option = this.state.merchant_option;
		if (Object.keys(merchant_option).length != 0) {
			let merchant_list = this.state.all_merchant_list;
			let merchant_data = merchant_list.find(merchant => merchant.gst_no == this.state.gst_no && merchant.label == merchant_option.label);
			merchant_option.value = !merchant_data ? 0 : merchant_data.value;

			this.setState({ merchant_option })
		}

		if (!this.state.payment_information.length) {
			this.handleChange(e);
			this.setState({ type: '' })
		} else
			if (!e.target.checked && this.state.payment_information[0].method == 3) {
				if (await confirm({
					confirmation: 'If you change expense from yes to no your receipt data will get lost. You cannot create reimbursement in expense - no. Still you want continue ?'
				})) {
					let payment_information = [{ method: 0, id: 0, amount: 0, payment_id: 0 }];
					this.setState({
						payment_information: payment_information
					}, () => {
						this.paymentChildRef.setChildPaymentDetail(payment_information);
						this.setState({ type: '', expense: false })
					});
				}
			} else {
				this.handleChange(e);
				this.setState({ type: '' })
			}
	}

	handlePaymentStatus = async (e) => {
		if ((this.CREATE_REIMBUSRMENT_SNAPSHOT && !this.CREATE_BUSSINESS_SNAPSHOT)) {
			return true;
		}
		if (e.target.checked && this.state.payment_information.length == 0) {

			this.setState({
				payment_information: [{ method: 0, id: 0, amount: 0, payment_id: 0 }]
			});

		}
		this.handleChange(e);

	}
	changeTdsInformation = (merchant_option, is_edit) =>{
		console.log('on merchant Changed Event call -----',merchant_option,is_edit);
		if (!!merchant_option.tds_percentage && !is_edit) {
			console.log("on merchant changed -------------------" , this.state.tds_details,merchant_option , merchant_option.tds_percentage)
			let total_amt = parseFloat(this.state.total_amount || 0) - parseFloat(this.state.gst_total_amount || 0);

			let tds_amount = (total_amt * parseFloat(merchant_option.tds_percentage || 0)) / 100;
			if (tds_amount < 0) tds_amount = 0;
			let tds_information;
			total_amt = total_amt == 0?"":total_amt;
			if(this.state.snapshot_id)
			tds_information=[{
				id:0,
				base_amount: total_amt?total_amt:"",
				tds_percentage: merchant_option.tds_percentage?merchant_option.tds_percentage:"",
				tds_amount: tds_amount?tds_amount:"",
			}];
			else
			tds_information=[{
			base_amount: total_amt?total_amt:"",
			tds_percentage: merchant_option.tds_percentage?merchant_option.tds_percentage:"",
			tds_amount: tds_amount?tds_amount:"",
			}];
			console.log("tds information ------------",tds_information);

			this.setState({ tds_status: true, tds_details:tds_information,tds_amount:tds_amount?tds_amount:""}, () => {
				console.log('tds details after tds information 666 ------------------' ,this.state.tds_status, this.state.tds_details);
				this.calculateTDS();
				this.checkForReadyState();
				if (!is_edit) {
					this.hideShowOnTypeChange(this.state.type)
				}

			})

		} else {

			this.setState({ tds_status: false, tds_details: [], tds_amount: "" }, () => {
				this.checkForReadyState();
				if (!is_edit) {
					this.hideShowOnTypeChange(this.state.type)
				}
			})
		}
	}
	onMerchantChanged = async (merchant_option, is_edit = false) => {
		let merchant = merchant_option || {}
		let selected_tags = [];

	
		if (Object.keys(merchant_option).length > 0 && merchant_option.snapshot_type != null && !is_edit) {
			let expense = true;
			// let snapshot_type ;
			// if(this.state.is_reimbursement){
			// 	snapshot_type = this.state.snapshot_types.find(type => type.id == this.state.type);
			// 	expense = snapshot_type.expense == 1 ? true : false;
			// 	console.log("reimbersement",this.state.type)
			// }else{
			// 	snapshot_type = this.state.snapshot_types.find(type => type.id == merchant_option.snapshot_type);
			// 	expense = snapshot_type.expense == 1 ? true : false;
			// }
			
			let snapshot_type = this.state.snapshot_types.find(type => type.id == merchant_option.snapshot_type);
			expense = snapshot_type.expense == 1 ? true : false;

			if (expense == false && this.state.is_perquisite == true) {
				if (await confirm({
					confirmation: 'This action will uncheck the perquisite checkbox. Do you still want to continue?',
				})) {
					this.setState({
						expense: false,
						is_perquisite: false,
						type: merchant_option.snapshot_type
					})

				} else {
					return
				}
			}

			selected_tags = merchant_option.tags.map((tag, index) => {
				return tag.id;
			})
		}

		if (merchant_option.__isNew__) {
			let inventory_details = _.cloneDeep(this.props.temp_inventory_data);
			if (this.state.accounting_status == 3) {
				// inventory_details.common_item_ledger_guid = "";
				// inventory_details.common_item_ledger_id = "";
				// inventory_details.common_item_ledger_name = "";
				// inventory_details.party_ledger_guid = "";
				// inventory_details.party_ledger_id = "";
				// inventory_details.party_ledger_name = "";
				this.setState({inventory_common_item_ledger_guid:"",inventory_common_item_ledger_id:"",inventory_common_item_ledger_name:""})
				this.setState({inventory_common_item_ledger_guid_prediction:0,inventory_common_item_ledger_id_prediction:0,inventory_common_item_ledger_name_prediction:0})


				this.setState({inventory_party_ledger_guid:"",inventory_party_ledger_id:"",inventory_party_ledger_name:""})
				this.setState({inventory_party_ledger_guid_prediction:0,inventory_party_ledger_id_prediction:0,inventory_party_ledger_name_prediction:0})
				this.setState({voucher_type_guid:"",voucher_type_id:"",voucher_type_name:"",parent_voucher_type_id:""})
			}

			// this.props.dispatch(setTempInventoryData(inventory_details));
			// this.setState({ inventory_details: true })
		}
		if (Object.keys(merchant_option).length > 0 && !merchant_option.__isNew__ && !is_edit && (this.state.selected_tags.length > 0 || !!this.state.category_id || !!this.state.type) && merchant_option.value != 0) {
			console.log("this.props",this.props.accounting_mode)
			if (this.props.accounting_mode == 2) {
				let inventory_details = _.cloneDeep(this.props.temp_inventory_data);
				if (this.state.accounting_status > 3) {
					if (this.state.inventory_mode == 1 || this.state.inventory_mode) {
						let show_msg = false;
						//console.log(merchant_option, inventory_details.common_item_ledger_guid, inventory_details.party_ledger_guid)
						if (this.state.type == 1) {
							if ((inventory_details.common_item_ledger_guid || inventory_details.common_item_ledger_guid == "")  && merchant_option.sales_ledger_guid && inventory_details.common_item_ledger_guid != merchant_option.sales_ledger_guid) {
								show_msg = true;
							}
						} else if (this.state.type == 2) {
							if ((inventory_details.common_item_ledger_guid  || inventory_details.common_item_ledger_guid == "") && merchant_option.purchase_ledger_guid && inventory_details.common_item_ledger_guid != merchant_option.purchase_ledger_guid) {
								show_msg = true;
							}
						}

						if (inventory_details.party_ledger_guid && merchant_option.party_ledger_guid && inventory_details.party_ledger_guid != merchant_option.party_ledger_guid) {
							show_msg = true;
						}

						if (show_msg) {
							inventory_details.common_item_ledger_guid = "";
							inventory_details.common_item_ledger_id = "";
							inventory_details.common_item_ledger_name = "";
							inventory_details.party_ledger_guid = "";
							inventory_details.party_ledger_id = "";
							inventory_details.party_ledger_name = "";
							inventory_details.parent_voucher_type_id="";
							inventory_details.voucher_type_guid="";
							inventory_details.voucher_type_id="";
							inventory_details.voucher_type_name=""
						//console.log(inventory_details)
						this.props.dispatch(setTempInventoryData(inventory_details));
						this.setState({ inventory_details: true })
							
							// await confirm({

							// 	confirmation: "Party Ledger or Purchase/Sales Ledger is different. Please verify.",
							// 	options: { type: "alert" }
							// })
							// setTimeout(()=>{
							// 	this.inventoryChildRef.openInventoryEditView();
							// },1000)
							await confirm({
								confirmation: "Existing Tags, Category," + (this.state.is_reimbursement ? "" : "Type") + " TDS values, Party Ledger, Purchase/Sales Ledger may change.",
								options: { type: "alert" }
							})
								setTimeout(()=>{
									this.inventoryChildRef.openInventoryEditView();
								},1000)
							
							

						}
					}
				} else {

					if (this.state.inventory_mode == 1 || this.state.inventory_mode) {

						if (this.SNAPSHOT_LEDGER_ENTRIES_CODE) {
							let ledger = this.state.ledgerNameList.ledger_list.filter(ledger => ledger.guid == (merchant_option.snapshot_type == 1 ? merchant_option.sales_ledger_guid : merchant_option.purchase_ledger_guid));
							console.log("merchant ledger",ledger)
							if (inventory_details.is_common_item_ledger == "")
								inventory_details.is_common_item_ledger = this.props.is_common_item_ledger;

							if (ledger.length > 0 && inventory_details.is_common_item_ledger) {
								// inventory_details.common_item_ledger_guid = ledger[0].guid;
								// inventory_details.common_item_ledger_id = ledger[0].id;
								// inventory_details.common_item_ledger_name = ledger[0].name;
								// inventory_details.inventory_voucher_status = true;
								this.setState({inventory_common_item_ledger_guid:ledger[0].guid,inventory_common_item_ledger_id:ledger[0].id,inventory_common_item_ledger_name:ledger[0].name})
								this.setState({inventory_common_item_ledger_guid_prediction:1,inventory_common_item_ledger_id_prediction:1,inventory_common_item_ledger_name_prediction:1})

							}

							let party_ledger = this.state.ledgerNameList.ledger_list.filter(ledger => ledger.guid == merchant_option.party_ledger_guid);
							//console.log(party_ledger)
							if (party_ledger.length > 0) {
								// inventory_details.party_ledger_guid = party_ledger[0].guid;
								// inventory_details.party_ledger_id = party_ledger[0].id;
								// inventory_details.party_ledger_name = party_ledger[0].name;
								// inventory_details.inventory_voucher_status = true;
								this.setState({inventory_party_ledger_guid:party_ledger[0].guid,inventory_party_ledger_id:party_ledger[0].id,inventory_party_ledger_name:party_ledger[0].name})
									this.setState({inventory_party_ledger_guid_prediction:1,inventory_party_ledger_id_prediction:1,inventory_party_ledger_name_prediction:1})

							}
							let voucher_type_details = this.state.voucherTypeList.filter(voucher => voucher.guid == (merchant_option.snapshot_type == 1 ? merchant_option.sales_voucher_type_guid : merchant_option.purchase_voucher_type_guid))
							if(voucher_type_details.length > 0){
								this.setState({voucher_type_guid:voucher_type_details[0].guid,voucher_type_id:voucher_type_details[0].id,voucher_type_name:voucher_type_details[0].name,parent_voucher_type_id:voucher_type_details[0].parent_voucher_type_id})
							}
						} else if (this.state.accounting_status == 3) {
							// inventory_details.common_item_ledger_guid = "";
							// inventory_details.common_item_ledger_id = "";
							// inventory_details.common_item_ledger_name = "";
							// inventory_details.party_ledger_guid = "";
							// inventory_details.party_ledger_id = "";
							// inventory_details.party_ledger_name = "";
							this.setState({inventory_common_item_ledger_guid:"",inventory_common_item_ledger_id:"",inventory_common_item_ledger_name:""})
							this.setState({inventory_common_item_ledger_guid_prediction:0,inventory_common_item_ledger_id_prediction:0,inventory_common_item_ledger_name_prediction:0})


							this.setState({inventory_party_ledger_guid:"",inventory_party_ledger_id:"",inventory_party_ledger_name:""})
							this.setState({inventory_party_ledger_guid_prediction:0,inventory_party_ledger_id_prediction:0,inventory_party_ledger_name_prediction:0})
						}
							inventory_details.common_item_ledger_guid = "";
							inventory_details.common_item_ledger_id = "";
							inventory_details.common_item_ledger_name = "";
							inventory_details.party_ledger_guid = "";
							inventory_details.party_ledger_id = "";
							inventory_details.party_ledger_name = "";
							inventory_details.parent_voucher_type_id="";
							inventory_details.voucher_type_guid="";
							inventory_details.voucher_type_id="";
							inventory_details.voucher_type_name=""
						//console.log(inventory_details)
						this.props.dispatch(setTempInventoryData(inventory_details));
						this.setState({ inventory_details: true })
						

					}
					//console.log(inventory_details)
					
					
					await confirm({
						confirmation: "Existing Tags, Category," + (this.state.is_reimbursement ? "" : "Type") + " TDS values, Party Ledger, Purchase/Sales Ledger may change.",
						options: { type: "alert" }
					})
					if((merchant_option.snapshot_type == 1 || merchant_option.snapshot_type == 2) && !this.state.is_reimbursement){
						console.log("hereree")
						setTimeout(()=>{
							this.inventoryChildRef.openInventoryEditView();
						},1000)
					
						
					}
					
						
					
				}
			} else {
				await confirm({
					confirmation: "Existing Tags, Category," + (this.state.is_reimbursement ? "" : "Type") + " TDS values, may change.",
					options: { type: "alert" }
				})
				
			}
		}
		console.log("edit ", merchant_option)
		if(merchant_option.__isNew__ == 1 || merchant_option.is_edit == 1){
			if(this.state.gst_no){
				console.log("edit gst present")
			}else{
				console.log("edit gst empty")
			}
		}else{
			if(merchant_option.gst_no){
				console.log("edit gst merchnat")
			}else{
				console.log("edit gst empy merchnat")
			}
		}
		this.setState({
			is_gst_override:false,
			merchant_option: merchant,
			// gst_no: merchant_option.gst_no || (merchant_option.__isNew__ || merchant_option.is_edit == 1 ? this.state.gst_no : ""),
			// pan_number: merchant_option.pan_number || (merchant_option.__isNew__ || merchant_option.is_edit == 1 ? this.state.pan_number : ""),
			gst_no: (merchant_option.__isNew__ || merchant_option.is_edit == 1) ? this.state.gst_no ? this.state.gst_no : "" :   merchant_option.gst_no || "",
			pan_number: (merchant_option.__isNew__ || merchant_option.is_edit == 1) ? this.state.pan_number ? this.state.pan_number : "" : merchant_option.pan_number || "",
			is_dirty: true,
			is_edit_supplier: is_edit,
			is_msme: merchant_option.is_msme || (merchant_option.__isNew__ || merchant_option.is_edit == 1 ? this.state.is_msme : false),
		}, () => {
			let customer_gst = this.state.gst_no ? this.state.gst_no.slice(0, 2) : null || null;
			let entity_gst = (!!this.props.entity_gst_no) ? this.props.entity_gst_no.slice(0, 2) : null;
			console.log(customer_gst, entity_gst);
			if(customer_gst == null || entity_gst == null){
				if(this.state.sgst_amount || this.state.cgst_amount){

				}
				else{
					this.setState({ is_igst: true, disable_igst: false,hide_gst_override:true }, () => {
						this.calculateTotalGst();
					})
				}
			}
			else if (customer_gst == entity_gst && customer_gst && entity_gst) {
				this.setState({ is_igst: false, igst_amount: "", disable_igst: true, hide_gst_override:false}, () => {
					this.calculateTotalGst();
				})
			} else if (!customer_gst || !entity_gst) {
				this.setState({ is_igst: true, sgst_amount: "", cgst_amount: "", disable_igst: false,hide_gst_override:true }, () => {
					this.calculateTotalGst();
				})
			} else if (customer_gst != entity_gst && customer_gst && entity_gst) {
				this.setState({ is_igst: true, disable_igst: true, sgst_amount: "", cgst_amount: "", hide_gst_override:false}, () => {
					this.calculateTotalGst();
				})
			} else {
				this.setState({ is_igst: false, igst_amount: "", disable_igst: false,hide_gst_override:true }, () => {
					this.calculateTotalGst();
				})
			}


			if (!merchant_option.__isNew__ && merchant_option.value != 0 && !is_edit) {
				this.setState({
					category_id: merchant_option.category_id || "",
					selected_tags: selected_tags
				},()=>{
					if (Object.keys(merchant_option).length > 0) {
						if (!this.state.is_reimbursement)
							this.setState({ type: merchant_option.snapshot_type || "" },()=>{
								this.changeTdsInformation(merchant_option,is_edit);
							});
		
						let expense = true;
		
						console.log("reimbersement here")
						let snapshot_type ;
						if(this.state.is_reimbursement){
							snapshot_type = this.state.snapshot_types.find(type => type.id == this.state.type)
						}else{
							snapshot_type  = this.state.snapshot_types.find(type => type.id == merchant_option.snapshot_type);
						}

						//console.log(snapshot_type);
						let merchant_type = 2;
		
						if (snapshot_type != null) {
							expense = snapshot_type.expense == 1 ? true : false;
							merchant_type = snapshot_type.merchant_type;
						} else {
							expense = true;
						}
		
						this.setState({ expense, merchant_type, is_perquisite: (expense && this.state.is_perquisite) ? true : false },()=>{
							if (!!this.state.is_reimbursement)
							this.changeTdsInformation(merchant_option,is_edit);
						})
					} else {
						if (!this.state.is_reimbursement)
							this.setState({ type: "" },()=>{
								this.changeTdsInformation(merchant_option,is_edit);
							});	
						this.setState({ expense: true, merchant_type: 2, is_perquisite: (this.state.is_perquisite) ? true : false },()=>{
							if (!!this.state.is_reimbursement)
							this.changeTdsInformation(merchant_option,is_edit);
						})
					}
				});			
			}
		});	

		if (is_edit) {
			return;
		}

		if (!!merchant_option.gst_no) {
			this.setState({ gst_status: true, is_gst_claim_eligible: true })
		}
		//chnage the prediction status
		this.setState({merchant_id_prediction_status : 0})
		
	}
	returnTDsDetailsData = () => {
		let amt = this.state.total_amount?this.state.total_amount:"";
		amt = amt == 0?"":amt;
		if (this.state.tds_details.length == 0) {
		  return [
			{
			  id: 0,
			  base_amount:amt ,
			  tds_amount: "",
			  tds_percentage: "",
			},
		  ];
		} else return this.state.tds_details;
	  };
	amountChange = (name, value, ind = 0) => {
		console.log("value -----------" , value);
		if (value > 999999999.99) {
			return;
		}
		this.setState({ [name]: value, is_dirty: true },
			() => {
				this.checkForReadyState();
			});
			if(name == "total_amount"){
				this.setState({total_amount_prediction_status : 0})
			}
			if(name == "cgst_amount"){
				this.setState({cgst_amount_prediction_status : 0})
			}
			if(name == "sgst_amount"){
				this.setState({sgst_amount_prediction_status : 0})
			}
			if(name == "igst_amount"){
				this.setState({igst_amount_prediction_status : 0})
			}
			if(name == "gst_total_amount"){
				this.setState({total_gst_amount_prediction_status : 0})
			}
			if (name == "tds_sub_amount") {
				let tds_arr = [...this.state.tds_details];
				tds_arr[ind].tds_amount = value;
				this.setState({ tds_details: tds_arr, tds_percentage_prediction_status: 0 }, () => {
					// this.calculateTDS();
				});
				}
			  if (name == "base_amount") {
				let tds_arr = [...this.state.tds_details];
				tds_arr[ind].base_amount = value;
		  
				if (ind == 0) this.setState({ is_base_amt_changed: true });
				this.setState({ tds_details: tds_arr, tds_percentage_prediction_status: 0 }, () => {
				  // this.calculateTDS();
				});
			  }
			  if (name == "tds_percentage") {
				let tds_arr = [...this.state.tds_details];
				tds_arr[ind].tds_percentage = value;
				this.setState({ tds_details: tds_arr, tds_percentage_prediction_status: 0 }, () => {
				  // this.calculateTDS();
				});
			  }
	}

	hideShowOnTypeChange = (value) => {
		let hide_tds = false;
		let hide_payment_status = false;
		let tds_status = this.state.tds_status;
		let payment_status = this.state.payment_status;
		let tds_details = this.state.tds_details;
		let tds_amount = this.state.tds_amount;

		if (value == 5 || value == 4 || value == 9 || value == 10) {
			hide_tds = true;
			hide_payment_status = true;
			tds_status = false;
			payment_status = false;
			tds_details = [];
			tds_amount = "";

			let initialPaymentObj = [{ method: 0, id: 0, amount: 0, payment_id: 0 }];

			this.paymentChildRef.setChildPaymentDetail(initialPaymentObj);
			this.paymentChildRef.setChildPaymentTotal(0);
		}
		this.setState({ hide_tds, hide_payment_status, tds_status, payment_status, tds_details, tds_amount })
	}

	handleTypeChange = async (e) => {

		const { name, value } = e.target;
		
		if (this.state.inventory_mode && this.state.inventory_details && this.state.inventory_mode && !(value == 2 || value == 1)) {
			let confirm_msg = await confirm({ confirmation: 'Inventory data will be lost if any. Do you want to continue?' });

			if (!confirm_msg) {
				return true
			} else {
				this.emptyInventoryData();
			}
		}
		if((this.state.inventory_mode && this.state.inventory_details && this.state.inventory_mode && (value == 2 || value == 1)) ){
			await oldconfirm({
				confirmation: "Changing snapshot types may invalidate accounting voucher(s).Kindly review before saving !",
				options: { type: "alert" }
			})
			let inventory_details = _.cloneDeep(this.props.temp_inventory_data);
							inventory_details.common_item_ledger_guid = "";
							inventory_details.common_item_ledger_id = "";
							inventory_details.common_item_ledger_name = "";
							inventory_details.common_item_ledger_guid_prediction_status=0;
							inventory_details.common_item_ledger_id_prediction_status=0;
							inventory_details.common_item_ledger_name_prediction_status =0;
							inventory_details.voucher_type_guid = "";
							inventory_details.voucher_type_id = "";
							inventory_details.voucher_type_name = "";
							inventory_details.parent_voucher_type_id = "";
							this.props.dispatch(setTempInventoryData(inventory_details));
				
		}

		let expense = true;
		let isPerquisiteDissable = false

		let snapshot_type = this.state.snapshot_types.find(type => type.id == value);		
		
		expense = snapshot_type.expense == 1 ? true : false;
		let merchant_type = snapshot_type.merchant_type;

		if (expense == false && this.state.is_perquisite == true) {
			if (await confirm({
				confirmation: 'This action will uncheck the perquisite checkbox. Do you still want to continue?',
			})) {
				this.setState({
					expense: false,
					is_perquisite: false
				})
				isPerquisiteDissable = true
			} else {
				return
			}
		}
		this.setState({ expense, merchant_type })

		let merchant_option = this.state.merchant_option;

		if (Object.keys(merchant_option).length != 0) {
			let merchant_list = this.state.all_merchant_list;
			let merchant_data = merchant_list.find(merchant => merchant.gst_no == this.state.gst_no && merchant.label == merchant_option.label);
			merchant_option.value = !merchant_data ? 0 : merchant_data.value;
			this.setState({ merchant_option })
		}

		if (merchant_type == 2) {
			this.setState({ is_customer: false }, () => {
				if (!this.state.is_supplier) this.handleSupplierCustomerToggle('is_supplier', true)
			})
		} else {
			this.setState({ is_supplier: false }, () => {
				if (!this.state.is_customer) this.handleSupplierCustomerToggle('is_customer', true)
			})
		}


		if (!expense && this.state.payment_information.length && this.state.payment_information[0].method == 3) {
			if (await confirm({
				confirmation: 'The type selected is not available for reimbursement. Do you still want to proceed?'
			})) {

				let payment_information = [{ method: 0, id: 0, amount: 0, payment_id: 0 }];
				this.setState({ payment_information: payment_information }, () => {
					this.paymentChildRef.setChildPaymentDetail(payment_information);
					this.setState({ [name]: value, expense: false, is_dirty: true }, () => {
						this.hideShowOnTypeChange(value)
						this.checkForReadyState();
					});
				});
			}
			else {
				if (isPerquisiteDissable) {
					this.setState({ expense: false })
				} else {
					this.setState({ expense: true })
				}
			}
		} else {
			console.log("handlechnage",name,value)
			if(name == "type" && (value == 2 || value == 1) && this.props.is_new_model && (this.state.global_preidction_status || this.state.bulk_prediction_status)){
				this.setState({showInventory: 1})
			}
			this.setState({ [name]: value, expense, is_dirty: true,snapshot_type_prediction_status : 0 }, () => {
				this.hideShowOnTypeChange(value)
				this.checkForReadyState();
			});
		}
	}

	resetMerchantList = () => {
		let merchant_list = this.state.all_merchant_list.filter((merchant, index) => {
			if (merchant.type == 3 || (this.state.is_customer && merchant.type == 1) || (this.state.is_supplier && merchant.type == 2) || (!this.state.is_customer && !this.state.is_supplier))
				return merchant;
		})

		this.setState({ merchants: merchant_list })
	}

	handleChange = (e) => {

		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		if (name === "tds_status") {
			if (this.state.tds_details.length == 0) {
			  console.log("total amount val ------------------", this.state.total_amount);
			  let tds_information;
			let amt = parseFloat(this.state.total_amount || 0) - parseFloat(this.state.gst_total_amount || 0);
			amt = amt == 0?"":amt;
			// amt = this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,amt) : amt.toFixed(2)
			amt = this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,amt) : amt ? amt.toFixed(2) : amt;
			if(amt < 0) amt = 0;
			  if(this.state.snapshot_id)
			  tds_information= [
				{
				  id: 0,
				  base_amount:amt ,
				  tds_amount: "",
				  tds_percentage: "",
				},
			  ];
			  else
			  tds_information= [
				{
				  base_amount: amt,
				  tds_amount: "",
				  tds_percentage: "",
				},
			  ];
			  // tds_information.push({base_amount: 0, tds_percentage: "", tds_amount: 0 });
			  this.setState({ tds_details: tds_information }, () => {});
			} else {
				console.log("here in else tds")
				let amt = parseFloat(this.state.total_amount || 0) - parseFloat(this.state.gst_total_amount || 0);
				amt = amt == 0?"":amt;
				amt = this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,amt) : amt ? amt.toFixed(2) : amt;
			  let tds_arr = [...this.state.tds_details];
			  if(amt < 0) amt = 0;
			  tds_arr[0].base_amount = amt;
			  this.setState({ tds_details: tds_arr });
			}
		  }

		if (name === "gst_status") {
			if (value) {
				this.setState({ is_gst_claim_eligible: true })
			} else {
				this.setState({ is_gst_claim_eligible: false })
			}
		}

		this.setState({ [name]: value, is_dirty: true },
			() => {
				if ( name === "gst_status") {
					this.calculateTDS();
				}
			});
			if(name == 'is_gst_override' && value == true){
				this.setState({disable_igst : false})
			}if(name == 'is_gst_override' && value == false){
				let customer_gst = this.state.gst_no ? this.state.gst_no.slice(0, 2) : null || null;
			let entity_gst = (!!this.props.entity_gst_no) ? this.props.entity_gst_no.slice(0, 2) : null;
			console.log(customer_gst, entity_gst)
			if (customer_gst == entity_gst && customer_gst && entity_gst) {
				this.setState({ is_igst: false, igst_amount: "", disable_igst: true,}, () => {
					this.calculateTotalGst();
				})
			} else if (!customer_gst || !entity_gst) {
				this.setState({ is_igst: true, sgst_amount: "", cgst_amount: "", disable_igst: false, }, () => {
					this.calculateTotalGst();
				})
			} else if (customer_gst != entity_gst && customer_gst && entity_gst) {
				this.setState({ is_igst: true, disable_igst: true, sgst_amount: "", cgst_amount: "",}, () => {
					this.calculateTotalGst();
				})
			} else {
				this.setState({ is_igst: false, igst_amount: "", disable_igst: false, }, () => {
					this.calculateTotalGst();
				})
			}
			}
			if(name == 'invoice_number'){
				this.setState({snapshot_number_prediction_status : 0})
			}
			if(name == "category_id"){
				this.setState({category_id_prediction_status : 0})
			}
			if(name == "gst_no"){
				this.setState({gst_no_prediction_status : 0})
			}
			if(name == "pan_number"){
				this.setState({pan_number_prediction_status : 0})
			}
	}

	handleEntryStatusChange = (e) => {
		let entry_status = e.target.value;

		this.setState({
			auto_ready:false,
			auto_ready_accounted:false,
			is_dirty: true,
			entry_status: e.target.value,
			system_suggested_status: ""
		})
	}

	handleAccountingStatusChange = (e) => {
		let accounting_status = e.target.value;

		this.setState({
			auto_accounted:false,
			is_dirty: true,
			accounting_status: e.target.value,
			system_suggested_status: ""
		})

		if (accounting_status == 4 || accounting_status == 5) {
			this.setState({ entry_status: 2 })
		}
	}
	handleAccountingStatusChangeSyncToNotSync = () => {

		this.setState({
			auto_accounted:false,
			// is_dirty: true,
			accounting_status: 4,
			system_suggested_status: ""
		})

	}

	setDate = (date, key) => {	
		if (date) {
			this.setState({ [key]: date, is_dirty: true,checkCurrentDate : "",date_prediction_status:0 }, () => {
				this.fetchSMS()
				this.checkForReadyState()
			})
		}
	}
	todaysDate = () =>{
		console.log("todays date");
		this.setState({date:new Date()})
	}
	
	emptyInventoryData = () => {
		let inventory_details = {
			"line_item_total": "",
			"line_item_gst_total": "",
			"line_item_discount_total": "",
			"inventory_voucher_status": this.SNAPSHOT_LEDGER_ENTRIES_CODE ? true : false,
			"is_common_item_ledger": "",
			"accounting_narration": "",
			// "line_items": [{
			// 	// "line_item_id": "",
			// 	// "line_item_name": "",
			// 	// "hsn_sac": "",
			// 	// "rate": "",
			// 	// "per": "",
			// 	// "quantity": "",
			// 	// "gst_rate": "",
			// 	// "discount_rate": "",
			// 	// "amount": "",
			// 	// "item_ledger_guid": "",
			// 	// "item_ledger_id": null,
			// 	// "item_ledger_name": "",
			// 	// "common_item_ledger_guid": "",
			// 	// "common_item_ledger_id": "",
			// 	// "common_item_ledger_name": "",
			// 	// "line_item_update": 0
			// 	"line_item_id": "",
			// 	"line_item_id_prediction_status" : 0,
			// 	"line_item_name": "",
			// 	"line_item_name_prediction_status" : 0,
			// 	"hsn_sac": "",
			// 	"hsn_sac_prediction_status" : 0,
			// 	"rate": "",
			// 	"line_item_rate_prediction_status" : 0,
			// 	"per": "",
			// 	"per_prediction_status" : 0,
			// 	"quantity": "",
			// 	"quantity_prediction_status" : 0,
			// 	"gst_rate": "",
			// 	"gst_rate_prediction_status" : 0,
			// 	"discount_rate": "",
			// 	"discount_rate_prediction_status" : 0,
			// 	"amount": "",
			// 	"amount_prediction_status" : 0,
			// 	"item_ledger_guid": "",
			// 	"item_ledger_guid_prediction_status" : 0,
			// 	"item_ledger_id": null,
			// 	"item_ledger_id_prediction_status" : 0,
			// 	"item_ledger_name": "",
			// 	"item_ledger_name_prediction_status" : 0,
			// 	"common_item_ledger_guid": "",
			// 	"common_item_ledger_guid_prediction_status" : 0,
			// 	"common_item_ledger_id": "",
			// 	"common_item_ledger_id_prediction_status" : 0,
			// 	"common_item_ledger_name": "",
			// 	"common_item_ledger_name_prediction_status" : 0,
			// 	"line_item_update": 0
			// }],
			"line_items":[],
			"ledgers": [{
				"amount": "",
				"rate": "",
				"guid": "",
				"ledger_id": "",
				"name": "",
			}],
			"sync_datetime": null,
			"sync_error": null,
			"sync_status": 0,
			"voucher_total_amount": "",
			"voucher_creation_date": "",
			// "voucher_type": "",
			// "party_ledger_guid": null,
			// "party_ledger_id": null,
			// "party_ledger_name": null,
			// "common_item_ledger_guid": null,
			// "common_item_ledger_id": null,
			// "common_item_ledger_name": null
			"voucher_creation_date_prediction_status":0,
			"voucher_type": "",
			"party_ledger_guid": null,
			"party_ledger_guid_prediction_status":0,
			"party_ledger_id": null,
			"party_ledger_id_prediction_status":0,
			"party_ledger_name": null,
			"party_ledger_name_prediction_status":0,
			"common_item_ledger_guid": null,
			"common_item_ledger_guid_prediction_status":0,
			"common_item_ledger_id": null,
			"common_item_ledger_id_prediction_status":0,
			"common_item_ledger_name": null,
			"common_item_ledger_name_prediction_status":0,
			"parent_voucher_type_id":null,
			"voucher_type_guid":null,
			"voucher_type_name":null,
			"voucher_type_id":null,
		}
		this.props.dispatch(setTempInventoryData(inventory_details));
		this.setState({ inventory_details: false })
	}


	emptyStateValue = () => {
		let payment_information = [{ method: 0, id: 0, amount: 0, payment_id: 0 }];
		this.emptyPaymentMethodArray();
		this.emptyInventoryData();
		this.setState({
			snapshot_id: 0,
			errors: {},
			inventory_errors: {},
			line_item_errors: [],
			ledger_errors: [],
			selected_tags: [],
			sms_list: [],
			selected_sms_list: [],
			expense: true,
			type: (this.CREATE_REIMBUSRMENT_SNAPSHOT && !this.CREATE_BUSSINESS_SNAPSHOT) ? 6 : "",
			date: "",
			merchant_name: "",
			merchant_option: {},
			gst_no: "",
			invoice_number: "",
			total_amount: "",
			category_id: "",
			tags: [],
			additional_information: "",
			payment_status: false,
			payment_total: 0,
			payment_information: payment_information,
			gst_status: false,
			sgst_amount: "",
			cgst_amount: "",
			igst_amount: "",
			gst_total_amount: "",
			tds_status: false,
			 tds_details: [],
			tds_amount: "",
			file_url: "",
			qualified_url: "",
			pan_number: "",
			snapshot_prediction_status: 1,
			igst_amount_prediction_status: 0,
			sgst_amount_prediction_status: 0,
			cgst_amount_prediction_status: 0,
			total_gst_amount_prediction_status: 0,
			date_prediction_status: 0,
			gst_no_prediction_status: 0,
			merchant_name_prediction_status: 0,
			gst_no_prediction_status: 0,
			snapshot_type_prediction_status: 0,
			total_amount_prediction_status: 0,
			snapshot_number_prediction_status: 0,
			pan_number_prediction_status: 0,
			expense_prediction_status: 0,
			category_prediction_status: 0,
			category_id_prediction_status: 0,
			tds_percentage_prediction_status: 0,
			tags_prediction_status: 0,
			is_msme_prediction_status: 0,
			is_entity_mismatch_prediction_status: 0,
			snapshot_no: "",
			is_smart: this.SMART_SCAN_SNAPSHOT ? true : false,
			report_url_pdf: "",
			is_sms_evidence: false,
			editable: true,
			voucher_status: false,
			is_duplicate: 0,
			is_dirty: false,
			system_suggested_status: "",
			is_edit_supplier: false,
			is_leder_recommended: 0,
			is_msme: false,
			is_perquisite: false,
			is_gst_claim_eligible: false,
			snapshot_type_update: 0,
			is_new: false,
			new_value: null,
			is_customer: true,
			is_supplier: true,
			hashtag: "",
			ledgers_editable: true,
			synced_by_user: null,
			voucher_list_from_db: [],
			payment_info_data_from_db: [],
			tds_info_data_from_db: [],
			remove_ledgers: [],
			remove_cost_category: [],
			remove_cost_center: [],
			remove_tags: [],
			remove_payment_information: [],
			remove_tds_details: [],
			is_linked: 0,
			accounting_status: 3,
			entry_status: 1,
			is_same_invoice: 0,
			is_entity_mismatch: false,
			show_entity_mismatch_box: true,
			new_tags: [],
			all_merchant_list: _.cloneDeep(this.state.all_old_merchant_list),
			igst_amount_prediction_value: "",
			sgst_amount_prediction_value: "",
			cgst_amount_prediction_value: "",
			total_gst_amount_prediction_value: "",
			pan_number_prediction_value: "",
			date_prediction_value: "",
			merchant_name_prediction_value: "",
			gst_no_prediction_value: "",
			snapshot_number_prediction_value: "",
			total_amount_prediction_value: "",
			merchant_id_prediction_value: "",
			snapshot_type_prediction_value: "",
			category_id_prediction_value: "",
			tds_percentage_prediction_value: "",
			tags_prediction_value: "",
			is_msme_prediction_value: "",
			is_entity_mismatch_prediction_value: "",
			created_at: "",
			save_button_clicked: false,
			hide_payment_status: false,
			hide_tds: false,
			inventory_voucher_status: false,
			inventory_mode: false,
			disable_igst: false,
			gst_summary: {},
			hsn_sac_summary: {},
			is_reimbursement: (this.CREATE_REIMBUSRMENT_SNAPSHOT && !this.CREATE_BUSSINESS_SNAPSHOT) ? true : false,
			reimbursement_narration: null,
			approval_button: null,
			approval_comment: null,
			approval_status: null,
			approved_amount: null,
			created_by: "",
			line_items_prediction_status: 0,
			global_preidction_status:0,
			bulk_prediction_status : 0,
			showInventory : 0,
			inventory_party_ledger_id:null,
			inventory_party_ledger_name:null,
			inventory_common_item_ledger_guid:null,
			inventory_common_item_ledger_id:null,
			inventory_common_item_ledger_name:null,
			inventory_party_ledger_guid_prediction:0,
			inventory_party_ledger_id_prediction:0,
			inventory_party_ledger_name_prediction:0,
			inventory_common_item_ledger_guid_prediction:0,
			inventory_common_item_ledger_id_prediction:0,
			inventory_common_item_ledger_name_prediction:0,
			inventory_voucher_creation_date:"",
			inventory_voucher_creation_date_prediction:0,
			line_items_data:[],
			combined_inventory_array:[],
			is_gst_override:false,
			auto_accounted:false,
			auto_ready:false,
			auto_ready_accounted:false,
			is_base_amt_changed:false,
			xml_upload: false
		});

		this.props.dispatch(setTempVoucherList([]));

		//adding empty value to child payment component
		this.paymentChildRef.setChildPaymentDetail(payment_information);

		if (this.CREATE_BUSSINESS_SNAPSHOT || this.CREATE_REIMBUSRMENT_SNAPSHOT) {
			this.VIEW_ONLY_SNAPSHOT = false;
		}
		this.imageUploadChildRef.removeFile();
	}

	removeQualifiedUrl = () => {
		this.setState({ qualified_url: "", file_url: "", is_dirty: true, prediction_id: null, file_type: "",request_id : null })
	}

	componentWillReceiveProps(nextProps) {
		if ((nextProps.match.params.snapshot_id !== this.props.match.params.snapshot_id)) {

			if (!!nextProps.match.params.snapshot_id) {
				this.props.dispatch(setTempVoucherList([]));
				this.emptyStateValue()
				let temp_ledgerNameList = this.state.ledgerNameList;
				this.setState({
					snapshot_id: nextProps.match.params.snapshot_id ? nextProps.match.params.snapshot_id : 0,
					is_dirty: false,
					ledgerNameList: { ledger_list: [], last_updated_at: null },
				}, () => {
					this.setState({ ledgerNameList: temp_ledgerNameList });
					this.getSnapshotDetail(this.state.snapshot_id)
				})
			} else {
				this.props.dispatch(setTempVoucherList([]));
				this.emptyStateValue()
			}
		}

	}

	componentDidUpdate = async (prevProps, prevState) => {
		if (prevState.type !== this.state.type && this.state.type != "" && (this.props.accounting_mode == 2 || this.state.inventory_mode)) {
			console.log(prevState.type);
			// if snapshot type is purchase(2) or sales(1)
			if (this.state.type == 2 || this.state.type == 1) {
				this.setState({ inventory_mode: true }, () => {
					//this.emptyInventoryData();
				});
			} else {
				this.setState({ inventory_mode: false });
			}
		}

		console.log("inside",this.state.inventory_mode)
		if((prevState.type !== this.state.type && (this.state.type == 2 || this.state.type == 1 )) && (this.state.global_preidction_status || this.state.bulk_prediction_status) &&
		(this.props.accounting_mode == 2 || this.state.inventory_mode) && this.props.is_new_model ){
			if((this.state.type == 2 || this.state.type == 1)){
				if(this.state.xml_upload){
					await this.predictionlineitem(this.state.xmlData);
				}else{
					await this.newLineitemPrediction();
				}
				
			if(this.state.showInventory){
				this.inventoryChildRef.openInventoryEditView();
			}
			}
			
		}

		
		
	}

	emptyPaymentMethodArray = () => {
		this.props.dispatch(setPaymentInformation([{ method: 0, id: 0, amount: 0, payment_id: 0 }]))
	}

	onGSTChange = (e) => {
		let gst_no = e.target.value;
		this.setState({is_gst_override:false})
		if (!!gst_no) {
			if (/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(gst_no)) {
				this.setState({ "pan_number": gst_no.substring(2, 12), gst_status: true });

				let customer_gst = gst_no ? gst_no.slice(0, 2) : null || null;
				let entity_gst = (!!this.props.entity_gst_no) ? this.props.entity_gst_no.slice(0, 2) : null;

				if (customer_gst == entity_gst && customer_gst && entity_gst) {
					this.setState({ is_igst: false, igst_amount: "", disable_igst: true,hide_gst_override:false}, () => {
						this.calculateTotalGst();
					})
				} else if (!customer_gst || !entity_gst) {
					this.setState({ is_igst: true, sgst_amount: "", cgst_amount: "", disable_igst: false,hide_gst_override:true }, () => {
						this.calculateTotalGst();
					})
				} else if (customer_gst != entity_gst && customer_gst && entity_gst) {
					this.setState({ is_igst: true, disable_igst: true, sgst_amount: "", cgst_amount: "",hide_gst_override:false}, () => {
						this.calculateTotalGst();
					})
				} else {
					this.setState({ is_igst: false, igst_amount: "", disable_igst: false,hide_gst_override:true }, () => {
						this.calculateTotalGst();
					})
				}
			} else {
				this.setState({ "pan_number": "" })
			}
		} else {
			let customer_gst = gst_no ? gst_no.slice(0, 2) : null || null;
			let entity_gst = (!!this.props.entity_gst_no) ? this.props.entity_gst_no.slice(0, 2) : null;

			if (customer_gst == entity_gst && customer_gst && entity_gst) {
				this.setState({ is_igst: false, igst_amount: "", disable_igst: true,hide_gst_override:false}, () => {
					this.calculateTotalGst();
				})
			} else if (!customer_gst || !entity_gst) {
				this.setState({ is_igst: true, sgst_amount: "", cgst_amount: "", disable_igst: false,hide_gst_override:true }, () => {
					this.calculateTotalGst();
				})
			} else if (customer_gst != entity_gst && customer_gst && entity_gst) {
				this.setState({ is_igst: true, disable_igst: true, sgst_amount: "", cgst_amount: "",hide_gst_override:false}, () => {
					this.calculateTotalGst();
				})
			} else {
				this.setState({ is_igst: false, igst_amount: "", disable_igst: false,hide_gst_override:true }, () => {
					this.calculateTotalGst();
				})
			}
		}
	}

	componentWillUnmount() {
		this.emptyPaymentMethodArray()
		this.props.dispatch(setTempVoucherList([]));
		this.props.resetSaveAndNext()

		Mousetrap.unbind('ctrl+alt+s')
	}

	fetchSMS = () => {
		return true;
		if (this.props.match.params.evidence_type === 'evidence') {
			if (this.state.date && this.state.total_amount) {
				let detail = {
					date: changeDateFormate(this.state.date),
					amount: this.state.total_amount
				}
				SnapshotService.getEvidenceSMS(detail).then(data => {
					if (data.response_code == 200) {
						this.setState({ sms_list: data.result })
					}
				})
			}
		}
	}

	calculateTotalGst = () => {
		let gst_total_amount = parseFloat(this.state.sgst_amount || 0) + parseFloat(this.state.cgst_amount || 0) + parseFloat(this.state.igst_amount || 0);
		this.setState({
			gst_total_amount : this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,gst_total_amount) : gst_total_amount
		}, () => { this.calculateTDS(0); })
	}

	calculateTdsTotal = (ind) => {
		let tds_arr = [...this.state.tds_details];
		let tdsTotalAmt = 0;
		tds_arr = tds_arr.map((item, index) => {
			if(ind === index){
				console.log("same index --------------",ind , item , this.props.amount_round_off,(((item.tds_amount || 0) * 100 )/(item.base_amount || 0)).toFixed(2));
				if(item.base_amount)
				  item.tds_percentage =  this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,(((item.tds_amount || 0) * 100 )/(item.base_amount || 0))):(((item.tds_amount || 0) * 100 )/(item.base_amount || 0)).toFixed(2);
				else if(!item.base_amount)
				  item.tds_amount = 0; 
				else if(item.tds_percentage && item.base_amount && !item.tds_amount)
				item.tds_amount = this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,(((item.base_amount || 0) * parseFloat(item.tds_percentage || 0)) / 100)):(((item.base_amount || 0) * parseFloat(item.tds_percentage || 0)) / 100).toFixed(2);
			  }
		  tdsTotalAmt += parseFloat(item.tds_amount || 0);
		  return item;
		});
		this.setState({ tds_amount: this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,tdsTotalAmt):tdsTotalAmt.toFixed(2)}, () => {});
	  };
	  calculateTDS = (ind =  null) => {
		console.log("calculate dts ------------------" , this.state.gst_status,this.state.tds_details);
		let tds_arr = [...this.state.tds_details];
		let tdsTotalAmt = 0;
		tds_arr = tds_arr.map((item, index) => {
			let total_amt = 0;
			if (!this.state.is_base_amt_changed && index == 0)
			  total_amt = parseFloat(this.state.total_amount || 0) - (this.state.gst_status? parseFloat(this.state.gst_total_amount || 0):0);
			else total_amt = parseFloat(item.base_amount || 0);
			if(total_amt < 0) total_amt = 0;
			let tds_amount = (total_amt * parseFloat(item.tds_percentage || 0)) / 100;
			if (tds_amount < 0) tds_amount = 0;
			tdsTotalAmt += tds_amount;
	  
			total_amt = total_amt == 0?"":total_amt;
		if(index != null && ind == index){
		
			if (this.state.snapshot_id)
			  return {
				id: item.id,
				tds_percentage: item.tds_percentage?this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,parseFloat(item.tds_percentage || 0)):parseFloat(item.tds_percentage || 0).toFixed(2):"",
				base_amount: total_amt?this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,(total_amt||0)):(total_amt||0).toFixed(2):"", //item.base_amount,
				tds_amount: tds_amount?this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,(tds_amount||0)):(tds_amount||0).toFixed(2):"",
			  };
			else
			  return {
				tds_percentage: item.tds_percentage?this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,parseFloat(item.tds_percentage || 0)):parseFloat(item.tds_percentage || 0).toFixed(2):"",
				base_amount: total_amt?this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,(total_amt||0)):(total_amt||0).toFixed(2):"", //item.base_amount,
				tds_amount: tds_amount?this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,(tds_amount||0)):(tds_amount||0).toFixed(2):"",
			};	
		}
		else {
				if(item.base_amount < 0){
					item.base_amount = 0;
				}
				return item;
		}
		});
		
		console.log("tds array --------------", tds_arr);
	
		this.setState({ tds_details: tds_arr, tds_amount:tdsTotalAmt?this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,tdsTotalAmt):tdsTotalAmt.toFixed(2):"" }, () => {
		  this.checkForReadyState();
		});
	  };
	calculateTotalGstPrediction = () => {
		let gst_total_amount = parseFloat(this.state.sgst_amount || 0) + parseFloat(this.state.cgst_amount || 0) + parseFloat(this.state.igst_amount || 0);
		this.setState({
			gst_total_amount:roundOffValue(this.props.amount_round_off,gst_total_amount)
		}, () => { this.calculateTDS(); })
	}
	calculatePredictionTDS = () => {
		console.log("calculatePredictionTDS ----------------" , this.state.tds_details);
		let tds_arr = [...this.state.tds_details];
		let tdsTotalAmt = 0;
		tds_arr = tds_arr.map((item, index) => {
		  let total_amt = 0;
		  console.log("here in predid",this.state.is_base_amt_changed , index)
		  console.log('this.state.total_amount',this.state.total_amount)
		  console.log('this.state.total_amount',this.state.gst_total_amount)
		  if (!this.state.is_base_amt_changed && index == 0){
			total_amt = parseFloat(this.state.total_amount || 0) - parseFloat(this.state.gst_total_amount || 0);
			console.log("here in prediction tds",total_amt)
		  }else{
			
			total_amt = parseFloat(item.base_amount);
			console.log("here in prediction tds else",total_amt)
		  }
		
		  if(total_amt < 0) total_amt = 0;

		  let tds_amount = (total_amt * parseFloat(item.tds_percentage || 0)) / 100;
		  if (tds_amount < 0) tds_amount = 0;
		  tdsTotalAmt += tds_amount;
	
		  if (this.state.snapshot_id)
			return {
			  id: item.id,
			//   tds_percentage: item.tds_percentage?item.tds_percentage:"",
			//   base_amount: total_amt?total_amt:"", //item.base_amount,
			//   tds_amount: tds_amount?tds_amount:"",
			  tds_percentage: item.tds_percentage ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off , parseFloat(item.tds_percentage || 0)) : parseFloat(item.tds_percentage || 0).toFixed(2) :"",
			  base_amount: total_amt?this.props.amount_round_off ? roundOffValue(this.props.amount_round_off , (total_amt || 0)) : (total_amt || 0).toFixed(2) :"", //item.base_amount,
			  tds_amount: tds_amount? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,(tds_amount || 0)) : (tds_amount || 0).toFixed(2):"",
			
			};
		  else
			return {
			//   tds_percentage: item.tds_percentage?item.tds_percentage:"",
			//   base_amount: total_amt?total_amt:"", //item.base_amount,
			//   tds_amount: tds_amount?tds_amount:"",

			  tds_percentage: item.tds_percentage ? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off , parseFloat(item.tds_percentage || 0)) : parseFloat(item.tds_percentage || 0).toFixed(2) :"",
			  base_amount: total_amt?this.props.amount_round_off ? roundOffValue(this.props.amount_round_off , (total_amt || 0)) : (total_amt || 0).toFixed(2) :"", //item.base_amount,
			  tds_amount: tds_amount? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,(tds_amount || 0)) : (tds_amount || 0).toFixed(2):"",
			
			};
		});
		console.log("tds array --------------", tds_arr);
	
		this.setState({ tds_details: tds_arr, tds_amount: roundOffValue(this.props.amount_round_off,(tdsTotalAmt || 0)) }, () => {
		  this.checkForReadyState();
		});
	}

	addNewHashtag = (e) => {
		e.preventDefault();
		if (!!this.state.hashtag) {
			let existing_tag_index = this.state.listed_tags.findIndex(tag => tag.name.toLowerCase() === "#" + this.state.hashtag.toLowerCase());
			if (existing_tag_index != -1) {
				this.tagSelected(existing_tag_index);
			} else if (this.state.new_tags.findIndex(item => item.toLowerCase() === "#" + this.state.hashtag.toLowerCase()) == -1) {
				this.setState({
					new_tags: [...this.state.new_tags, "#" + this.state.hashtag],
				})
			}
			this.setState({
				hashtag: "", is_dirty: true
			})
		}
	}

	removeNewHashtag = (index) => {
		let new_tags = this.state.new_tags;
		new_tags.splice(index, 1);
		this.setState({ new_tags: new_tags, is_dirty: true });
	}

	tagSelected = (index) => {
		let tag = this.state.listed_tags[index];
		this.setState({ selected_tags: [...this.state.selected_tags, tag.id], is_dirty: true });
	}

	removeTag = (index, id) => {
		let selected_tags = [...this.state.selected_tags];
		selected_tags.splice(index, 1);

		let to_be_remove = this.state.remove_tags
		to_be_remove.push(id)

		this.setState({ selected_tags, is_dirty: true, remove_tags: to_be_remove });
	}

	getPaymentDetail = () => {
		MasterService.getPaymentDetail().then(
			(data) => {
				if (data.response_code == 200) {
					this.setState({
						payment_details: data.result.payment_details
					});
				}
			});
	}

	setPaymentDetail = (payment_information, payment_total) => {
		this.setState({
			payment_information, payment_total
		}, () => {
			this.checkForReadyState()
		})
	}

	setApprovalAmt = (e) => {
		if ((this.APPROVE_REIMBURSMENT_SNAPSHOT || this.CREATE_REIMBUSRMENT_SNAPSHOT) && !!!this.state.approved_amount && this.state.is_reimbursement) {
			this.setState({ approved_amount: this.state.total_amount });
		}
	}

	getCategryList = () => {
		MasterService.getCategryList().then(
			(data) => {
				if (data.response_code == 200) {
					this.setState({
						categories: data.result.categories
					});
				}
			});
	}

	addCategoryHandler = (category) => {
		this.setState({
			categoryName: category
		})
	}

	addCategory = (category) => {
		MasterService.addCategory(category).then(
			(data) => {
				if (data.response_code == 200) {
					let newList = this.state.categories
					let newData = {
						"id": data.result.id,
						"name": this.state.categoryName,
					}
					newList.push(newData)
					this.setState({
						categories: newList
					})
					toast.success("Category added successfully")
					this.hideCategoryModal()
				}
				else {
					if (data.response_code == 400) {
						toast.error(data.reason.name)
						this.hideCategoryModal()
					}
				}
			}
		)
	}

	getTags = () => {
		MasterService.getTags().then(
			(data) => {
				if (data.response_code == 200) {
					this.setState({
						listed_tags: data.result.tags
					});
				}
			});
	}

	validateVouchers = () => {
		let valid = true
		if (this.props.temp_snap_voucher_list.length > 0) {
			this.props.temp_snap_voucher_list.forEach(voucher => {
				if(voucher.voucher_type_guid == null){
					valid = false
					this.setState({vaild_voucher:true})
				}
				if (!voucher.total_credit_amount || !voucher.total_debit_amount) {
					valid = false;
				}
				if (parseFloat(voucher.total_credit_amount) !== parseFloat(voucher.total_debit_amount))
					valid = false;
			});
		}
		console.log("valid ",true)
		return valid
	}

	removeEmptyVouchersAndLedgers = () => {
		let valid = true, temp_snap_voucher_list = [...this.props.temp_snap_voucher_list];

		if (temp_snap_voucher_list.length > 0) {
			temp_snap_voucher_list.forEach((voucher, index) => {

				let ledgers = voucher.ledgers, temp_ledgers = []

				// if(areGuidsUnique(voucher.ledgers))
				// valid = false;
				temp_ledgers = ledgers.filter((ledger, index1) => {
					if (ledger.amount !== 0 && ledger.amount !== '' && (ledger.ledger_id !== 0 && ledger.ledger_id !== '')) {
						return ledger
					}
					if (ledger.ledger_id === 0 || ledger.ledger_id === '' || ledger.amount === 0 || ledger.amount === '') {
						valid = false
						return ledger
					}
				})
				temp_snap_voucher_list[index].ledgers = temp_ledgers
				this.props.dispatch(setTempVoucherList(temp_snap_voucher_list))
				//}
			});
		}
		console.log("valid",valid)
		return valid;
	}

	handleRemoveVoucher = async () => {
		let temp_snap_voucher_list = [...this.props.temp_snap_voucher_list], temp = [];
		let voucher_list = [...this.state.voucher_list_from_db];
		let showDeleteVoucherPopup = false;

		if (voucher_list.length > 0) {
			let to_be_remove = voucher_list.filter((voucher, index1) => {
				let index = temp_snap_voucher_list.findIndex(temp_voucher =>
					(temp_voucher.voucher_id || temp_voucher.transaction_voucher_ledger_id) == (voucher.voucher_id || voucher.transaction_voucher_ledger_id));
				if (index == -1)
					return voucher
			})

			temp = to_be_remove.map((voucher, index) => {
				if (voucher.transaction_voucher_ledger_id != 0) {
					if (voucher.sync_status == 1 || voucher.sync_status == 2) {
						showDeleteVoucherPopup = true;
					}
					return voucher.voucher_id
				}
			})

			this.setState({ showDeleteVoucherPopup })

			for (var i = 0; i < temp.length; i++) {
				if (temp[i] == 0)
					temp.splice(i, 1);
			}

			return temp
		} else {

			this.setState({ showDeleteVoucherPopup });
			return temp
		}

	}

	handleRemoveTDsInformation = async () => {
		let tds_information_list = this.state.tds_status ? [...this.state.tds_details] : [],
		  temp = [];
		let tds_information_list_db = [...this.state.tds_info_data_from_db];
	
		let to_be_remove = tds_information_list_db.filter((info, index1) => {
		  let index = tds_information_list.findIndex((temp_info) => temp_info.id == info.id);
		  if (index == -1) return info;
		});
	
		temp = to_be_remove.map((tds, index) => {
		  if (tds.id != 0) {
			return tds.id;
		  }
		});
		//console.log(temp)
		for (var i = 0; i < temp.length; i++) {
		  if (temp[i] == 0) temp.splice(i, 1);
		}
	
		return _.uniq(temp);
	  };

	handleRemovePaymentInformation = async () => {
		console.log(this.state.payment_info_data_from_db)
		let payment_information_list = this.state.payment_status ? [...this.state.payment_information] : [], temp = [];
		let payment_information_list_db = [...this.state.payment_info_data_from_db];

		//console.log(payment_information_list, payment_information_list_db)
		let to_be_remove = payment_information_list_db.filter((info, index1) => {
			let index = payment_information_list.findIndex(temp_info => temp_info.payment_id == info.payment_id);
			if (index == -1)
				return info
		})

		temp = to_be_remove.map((payment, index) => {
			if (payment.payment_id != 0) {
				return payment.payment_id
			}
		})
		//console.log(temp)
		for (var i = 0; i < temp.length; i++) {
			if (temp[i] == 0)
				temp.splice(i, 1);
		}
		console.log(temp)
		return _.uniq(temp)
	}

	manipulateVouchersData = (vouchers) => {

		let formated_vouchers = vouchers.map((voucher, key) => {

			delete voucher.sync_datetime;
			delete voucher.total_credit_amount;
			delete voucher.total_debit_amount;
			delete voucher.voucher_id;
			delete voucher.voucher_type;

			voucher.voucher_date = voucher.voucher_creation_date;
			delete voucher["voucher_creation_date"];

			voucher.ledgers = voucher.ledgers.map((ledger) => {
				delete ledger["guid"];
				delete ledger["transaction_voucher_ledger_id"];
				delete ledger["ledger_id"];
				delete ledger["type"];
				if (!!ledger["voucher_ledger_id"]) {
					delete ledger["voucher_ledger_id"];
				}

				ledger.ledger = ledger.name;
				delete ledger["name"];

				ledger.ledger_amount = ledger.amount;
				delete ledger["amount"];

				if (ledger.cost_categories !== undefined) {

					ledger.cost_categories.map((cost_category) => {

						delete cost_category["category_guid"];
						delete cost_category["category_id"];
						delete cost_category["cost_category"];
						delete cost_category["transaction_voucher_ledger_cost_category_id"];
						delete cost_category["voucher_ledger_cost_category_id"];

						cost_category.cost_category = cost_category.category_name;
						delete cost_category["category_name"];

						if (cost_category.cost_centers !== undefined) {

							cost_category.cost_centers.map((cost_centre) => {

								delete cost_centre["cost_center_guid"];
								delete cost_centre["cost_center_id"];
								delete cost_centre["transaction_voucher_ledger_cost_category_cost_centre_id"];
								delete cost_centre["voucher_ledger_cost_category_cost_centre_id"];

								// cost_centre.cost_center_type = cost_centre.type;
								// delete cost_centre["type"];

								cost_centre.cost_center = cost_centre.cost_center_name;
								delete cost_centre["cost_center_name"]

								return cost_centre;
							})
						}


						return cost_category;
					})
				}

				return ledger;
			})

			return voucher;
		});
		//console.log(formated_vouchers)
		return formated_vouchers;
	}

	manipulateNewVouchersData = (vouchers) => {

		let formated_vouchers = vouchers.map((voucher, key) => {

			delete voucher.sync_datetime;
			delete voucher.total_credit_amount;
			delete voucher.total_debit_amount;
			delete voucher.voucher_id;
			delete voucher.voucher_type;

			voucher.voucher_date = voucher.voucher_creation_date;
			delete voucher["voucher_creation_date"];

			voucher.ledgers = voucher.ledgers.map((ledger) => {
				delete ledger["guid"];
				delete ledger["transaction_voucher_ledger_id"];
				delete ledger["ledger_id"];
				delete ledger["type"];
				delete ledger["voucher_ledger_id"];


				ledger.ledger = ledger.name;
				delete ledger["name"];

				ledger.ledger_amount = ledger.amount;
				delete ledger["amount"];

				if (ledger.cost_categories !== undefined) {

					ledger.cost_categories.map((cost_category) => {

						delete cost_category["cost_category"];
						delete cost_category["transaction_voucher_ledger_cost_category_id"];
						delete cost_category["guid"];
						delete cost_category["cost_category_id"];
						delete cost_category["voucher_ledger_cost_category_id"];

						cost_category.cost_category = cost_category.name;
						delete cost_category["name"];

						cost_category.cost_centers = cost_category.cost_centres;
						delete cost_category["cost_centres"]


						if (cost_category.cost_centers !== undefined) {

							cost_category.cost_centers.map((cost_centre) => {
								delete cost_centre["guid"];
								delete cost_centre["cost_centre_id"];
								delete cost_centre["transaction_voucher_ledger_cost_category_cost_centre_id"];
								delete cost_centre["voucher_ledger_cost_category_cost_centre_id"];

								// cost_centre.cost_center_type = cost_centre.type;
								delete cost_centre["type"];

								cost_centre.cost_center = cost_centre.name;
								delete cost_centre["name"]

								cost_centre.cost_center_amount = cost_centre.amount;
								delete cost_centre["amount"]

								return cost_centre;
							})
						}
						return cost_category;
					})
				}

				return ledger;
			})

			return voucher;
		});
		//console.log(formated_vouchers)
		return formated_vouchers;
	}

	showGstError = async (customerGst, cgst_amount, sgst_amount, igst_amount) => {
		customerGst = customerGst.slice(0, 2);
		let entityGst = (!!this.props.entity_gst_no) ? this.props.entity_gst_no.slice(0, 2) : null
		let gstErrorMsg = ''
		let gstErrorFlag = false
		if ((customerGst == entityGst) && !!igst_amount) {
			gstErrorFlag = true
			gstErrorMsg = ' IGST cannot be charged as invoice is within the same state. Do you still want to proceed?'
		} else if ((customerGst != entityGst) && (!!cgst_amount || !!sgst_amount)) {
			// gstErrorFlag = true
			// gstErrorMsg = 'CGST/SGST cannot be charged on interstate invoices. Do you still want to proceed?'
		}
		else if ((customerGst == entityGst) && (!!cgst_amount && !!sgst_amount) && (cgst_amount != sgst_amount)) {
			gstErrorFlag = true
			gstErrorMsg = 'CGST & SGST amounts must match. Do you still want to proceed?'
		}

		if (gstErrorFlag) {
			this.props.dispatch(setAppilcationLoder(false));
			if (!await confirm({
				confirmation: gstErrorMsg
			})) {
				return true
			} else {
				this.props.dispatch(setAppilcationLoder(true));
				return false
			}
		}
	}

	updateEvidence = async (e, showpopups = true) => {

		let valid = true;

		if (this.state.entry_status == 2) {
			valid = this.checkForReadyStateValidity()
		}

		let valid_inventory = true;
		if (this.state.inventory_mode == 1 || this.state.inventory_mode) {
			valid_inventory = this.validateInventoryModeData();
		}
		//console.log(valid, valid_inventory, this.state.inventory_errors, this.state.line_item_errors)

		if (valid && valid_inventory) {

			this.props.dispatch(setAppilcationLoder(true));
			let merchant_update = this.state.merchant_update || 0;

			let merchant_tags = [];

			if (Object.keys(this.state.merchant_option).length > 0) {
				if (!!this.state.merchant_option.tags) {
					this.state.merchant_option.tags.forEach((tag, index) => {
						merchant_tags.push(tag.id)
					})
				}
			}

			let category_id = this.state.category_id;

			if (category_id == "") {
				category_id = null;
			}

			let tagsEqual = _lodash.isEqual(merchant_tags, this.state.selected_tags);


			let tds_percentage = this.state.tds_percentage;

			if (tds_percentage == "") {
				tds_percentage = null;
			}

			if (this.state.merchant_option.is_edit == 1 || this.state.merchant_option.value == 0 || this.state.merchant_option.category_id != category_id || this.state.merchant_option.tds_percentage != tds_percentage || !tagsEqual || this.state.new_tags.length > 0) {
				merchant_update = 1;
			}

			let is_perquisite = 0;
			let is_gst_claim_eligible = 0;

			if (this.state.is_perquisite != undefined) {
				is_perquisite = this.state.is_perquisite ? 1 : 0;
			} else {
				is_perquisite = 0;
			}

			if (this.state.is_gst_claim_eligible != undefined) {
				is_gst_claim_eligible = this.state.gst_status && this.state.is_gst_claim_eligible ? 1 : 0;
			} else {
				is_gst_claim_eligible = 0;
			}
			//console.log(is_gst_claim_eligible)

			// change prediction statuses
			let gst_no_prediction_status = this.state.gst_no_prediction_status;
			let total_amount_prediction_status = this.state.total_amount_prediction_status;
			let igst_amount_prediction_status = this.state.igst_amount_prediction_status;
			let sgst_amount_prediction_status = this.state.sgst_amount_prediction_status;
			let cgst_amount_prediction_status = this.state.cgst_amount_prediction_status;
			let date_prediction_status = this.state.date_prediction_status;
			let merchant_name_prediction_status = this.state.merchant_name_prediction_status;
			let snapshot_number_prediction_status = this.state.snapshot_number_prediction_status;
			let merchant_id_prediction_status = this.state.merchant_id_prediction_status;
			let pan_number_prediction_status = this.state.pan_number_prediction_status;
			let expense_prediction_status = this.state.expense_prediction_status;
			let snapshot_type_prediction_status = this.state.snapshot_type_prediction_status;
			let category_id_prediction_status = this.state.category_id_prediction_status;
			let tds_percentage_prediction_status = this.state.tds_percentage_prediction_status;
			let tags_prediction_status = this.state.tags_prediction_status;
			let is_msme_prediction_status = this.state.is_msme_prediction_status;
			let is_entity_mismatch_prediction_status = this.state.is_entity_mismatch_prediction_status;


			let remove_vouchers = await this.handleRemoveVoucher()
			let is_valid_ledger = true;
			let vouchers = _.cloneDeep(this.props.temp_snap_voucher_list);
			// let remove_cost_category = this.state.remove_cost_category;
			// let remove_cost_center = this.state.remove_cost_center;
			let remove_cost_center = [];
			remove_cost_center = [...this.state.remove_cost_center]
			let remove_cost_category = [];
			remove_cost_category = [...this.state.remove_cost_category]

			let removed = [];
			let billwise_allocation = 0;

			let remove_gst_summary = [];
			let remove_hsn_sac_summary = [];
			let remove_line_items = [];
			let remove_ledgers = [];

			//console.log(remove_cost_category);
			console.log("renoved",remove_cost_center);
			//console.log(vouchers);
			vouchers = vouchers.map((voucher, index) => {
				
				// is_valid_ledger = !areGuidsUnique(voucher.ledgers);
				
				let temp_voucher = {
					"accounting_narration": voucher.accounting_narration,
					"parent_voucher_type_id":voucher.parent_voucher_type_id,
					"voucher_type_guid":voucher.voucher_type_guid,
					"voucher_type_name":voucher.voucher_type_name,
					"voucher_type_id":voucher.voucher_type_id,
					"voucher_creation_date": voucher.voucher_creation_date,
					"total_credit_amount": voucher.total_credit_amount,
					"total_debit_amount": voucher.total_debit_amount,
					"sync_status": voucher.sync_status || 0,
					"sync_datetime": changeDateTimeFormat(new Date),
					"voucher_id": voucher.voucher_id || 0,
					"ledgers": voucher.ledgers.map((ledger, index) => {
						let temp_ledger = {
							"amount": ledger.amount,
							"guid": ledger.guid,
							"name": ledger.name,
							"type": ledger.type,
							"ledger_id": ledger.ledger_id,
							"voucher_ledger_id": ledger.voucher_ledger_id || 0
						}

						let has_billwise = !!this.state.ledgerNameList.ledger_list.find((temp_ledger) => temp_ledger.guid == ledger.guid && temp_ledger.has_billwise == 1);
						//console.log(has_billwise+"========"+ledger.name)
						//console.log(voucher)
						if (has_billwise && (voucher.voucher_type == 4 || voucher.voucher_type == 5 || voucher.voucher_type == 6)) {
							billwise_allocation = billwise_allocation + 1;
						}

						//console.log(billwise_allocation)
						if (ledger.cost_categories.length == 1 && !ledger.cost_categories[0].category_id) {
							temp_ledger.cost_categories = [];
							return temp_ledger;
						}
						temp_ledger.cost_categories = ledger.cost_categories.map((category, category_index) => {
							if (!category.category_id || !category.category_guid || !category.category_name) {
								is_valid_ledger = false;
							}
							let cost_center_total = 0;
							let temp_category = {
								"cost_category_id": category.category_id,
								"guid": category.category_guid,
								"name": category.category_name,
								"voucher_ledger_cost_category_id": category.voucher_ledger_cost_category_id || 0,
								"cost_centres": category.cost_centers.map((cost_center, cc_index) => {
									if (!cost_center.cost_center_name || !cost_center.cost_center_guid || !cost_center.cost_center_amount) {
										is_valid_ledger = false;
									}
									cost_center_total += parseFloat(cost_center.cost_center_amount);
									let tmp_cost_center = {
										"amount": cost_center.cost_center_amount,
										"cost_centre_id": cost_center.cost_center_id,
										"guid": cost_center.cost_center_guid,
										"name": cost_center.cost_center_name,
										"type": temp_ledger.type,
										"voucher_ledger_cost_category_cost_centre_id": cost_center.voucher_ledger_cost_category_cost_centre_id || 0
									}
									console.log("renoved",cost_center.voucher_ledger_cost_category_cost_centre_id)
									if (remove_cost_center.indexOf(cost_center.voucher_ledger_cost_category_cost_centre_id) != -1) {
										remove_cost_center.splice(remove_cost_center.indexOf(cost_center.voucher_ledger_cost_category_cost_centre_id), 1);
									}

									return tmp_cost_center;
								})
							}

							if(temp_ledger.amount != undefined){
								if(this.countDecimalDigits(temp_ledger.amount) == 1){
									cost_center_total = cost_center_total.toFixed(1)
								}else{
									cost_center_total = cost_center_total.toFixed(2)
								}
							}
							
							cost_center_total = this.props.amount_round_off ? roundOffValue(this.props.amount_round_off, cost_center_total) : cost_center_total
							if(temp_ledger.amount != undefined){
								if(this.countDecimalDigits(temp_ledger.amount) == 0 && (this.countDecimalDigits(cost_center_total) == 2 || this.countDecimalDigits(cost_center_total) == 1)){
									cost_center_total = Math.floor(cost_center_total)
								}
							}
							

							if (cost_center_total != temp_ledger.amount) {
								is_valid_ledger = false;
							}

							if (remove_cost_category.indexOf(category.voucher_ledger_cost_category_id) != -1) {
								remove_cost_category.splice(remove_cost_category.indexOf(category.voucher_ledger_cost_category_id), 1);
							}

							return temp_category;
						})
						return temp_ledger;
					})
				}
				return temp_voucher;
			});

			if (!is_valid_ledger) {
				this.setState({ is_valid_ledger: false, save_and_redirect: false, next_route: false })
				this.props.dispatch(setAppilcationLoder(false));
				toast.error('Ledger data is missing')
				return;
			}

			let tags = []
			//console.log(this.state.selected_tags, this.state.old_data.tags)
			this.state.selected_tags.forEach((tag, index) => {
				//console.log(this.state.old_data.tags.includes(tag))
				if (this.state.old_data.tags.includes(tag) == false) {
					tags.push(tag);
				}
			});

			let approval_status = null;
			if (this.state.is_reimbursement) {
				if (this.CREATE_REIMBUSRMENT_SNAPSHOT && this.APPROVE_REIMBURSMENT_SNAPSHOT && this.state.entry_status == 2) {
					approval_status = 1;
				} else if (this.state.accounting_status == 4 && this.APPROVE_REIMBURSMENT_SNAPSHOT && this.SNAPSHOT_LEDGER_ENTRIES_CODE) {
					approval_status = 1;
				} else if (this.state.approval_button) {
					approval_status = parseInt(this.state.approval_button);
				}
			}

			let type_error = {};

			if (!this.state.type) {
				type_error.type = 'Type is required';
			 	this.props.dispatch(setAppilcationLoder(false));
				let errors_result = { ...type_error, snapshot_status: 'Please fill the mandatory details.' };
				this.setState({
					loading: false,
					errors: errors_result,
					save_and_redirect: false,
					next_route: false
				});
				return;
			}

			let snapshot_type = this.state.snapshot_types.find(type => type.id == this.state.type);
			//console.log(snapshot_type)
			//return;
			let data =
			{
				"expense": this.state.expense ? 1 : 0,
				"type": this.state.type,
				"merchant_type": this.state.merchant_type || snapshot_type != undefined ? snapshot_type.merchant_type : "",
				"date": !!this.state.date ? changeDateFormate(this.state.date) : changeDateFormate(new Date()),
				"merchant_update": merchant_update,//this.state.merchant_option.is_edit == 1 ? 1 : 0,//0 add,1-edit
				"merchant_name": this.state.merchant_option.label || null,
				"merchant_id": this.state.merchant_option.value || 0,
				"gst_no": this.state.gst_no,
				"invoice_number": this.state.invoice_number,
				"total_amount": parseFloat(this.state.total_amount),
				"category_id": !!this.state.category_id ? parseInt(this.state.category_id) : null,
				"tags": tags,
				"additional_information": this.state.additional_information,
				"payment_status": this.state.is_reimbursement ? 2 : this.state.payment_status ? 1 : 2,
				"payment_total": this.state.is_reimbursement ? 0 : this.state.payment_status ? parseFloat(this.state.payment_total) : 0,
				"payment_information": this.state.is_reimbursement ? [] : this.state.payment_status ? this.state.payment_information : [],
				"mode": this.state.is_smart && !!this.state.file_url ? 2 : 1,
				"file_url": this.state.file_url,
				"pan_number": this.state.pan_number,
				"gst_no_prediction_status": gst_no_prediction_status,
				"total_amount_prediction_status": total_amount_prediction_status,
				"igst_amount_prediction_status": igst_amount_prediction_status,
				"sgst_amount_prediction_status": sgst_amount_prediction_status,
				"cgst_amount_prediction_status": cgst_amount_prediction_status,
				"date_prediction_status": date_prediction_status,
				"merchant_name_prediction_status": merchant_name_prediction_status,
				"snapshot_number_prediction_status": snapshot_number_prediction_status,
				"merchant_id_prediction_status": merchant_id_prediction_status,
				"pan_number_prediction_status": pan_number_prediction_status,
				"snapshot_type_prediction_status": snapshot_type_prediction_status,
				"expense_prediction_status": expense_prediction_status,
				"category_id_prediction_status": category_id_prediction_status,
				"tds_percentage_prediction_status": tds_percentage_prediction_status,
				"tags_prediction_status": tags_prediction_status,
				"is_msme_prediction_status": is_msme_prediction_status,
				"is_entity_mismatch_prediction_status": is_entity_mismatch_prediction_status,
				"approval_comment": this.state.is_reimbursement ? this.state.approval_comment : null,
				"approval_status": approval_status,
				"approved_amount": this.state.is_reimbursement ? this.state.approved_amount ? parseFloat(this.state.approved_amount) : null : null,
				"id": this.state.snapshot_id,
				"new_tags": this.state.new_tags,
				"voucher_status": this.state.voucher_status && this.props.temp_snap_voucher_list.length > 0 ? 1 : 0,
				"is_duplicate": this.state.is_duplicate,
				"prediction_id": this.state.prediction_id,
				"expense_prediction_status": 0,
				"is_msme": this.state.is_msme ? 1 : 0,
				"is_perquisite": is_perquisite,
				//"is_gst_claim_eligible": is_gst_claim_eligible,
				"synced_by_user": this.state.accounting_status == 5 ? SessionManagement.getUserDetail().id : null,
				"entry_status": this.state.is_reimbursement ? (this.state.accounting_status == 4 && this.APPROVE_REIMBURSMENT_SNAPSHOT && this.SNAPSHOT_LEDGER_ENTRIES_CODE) ? 2 : this.state.approval_button == '1' ? 2 : this.state.entry_status : this.state.entry_status,
				"accounting_status": this.state.accounting_status,
				"remove_vouchers": remove_vouchers,
				"remove_ledgers": this.state.remove_ledgers,
				"remove_cost_categories": remove_cost_category,
				"remove_cost_centres": remove_cost_center,
				"remove_tags": this.state.remove_tags,
				"remove_payment_information": await this.handleRemovePaymentInformation(),
				"is_same_invoice": this.state.is_same_invoice,
				"is_entity_mismatch": this.state.is_entity_mismatch ? 1 : 0,
				"remove_inventory_voucher": 0,
				"is_reimbursement": this.state.is_reimbursement ? 1 : 0,
				"reimbursement_narration": this.state.reimbursement_narration,
				"request_id":this.state.request_id,
				//"is_gst_override":this.state.is_gst_override ? 1 : 0,
				"remove_tds_details": await this.handleRemoveTDsInformation(),
				"amount_round_off":this.state.get_amount_round_off,
				// "source": this.state.xml_upload ? 7 : this.state.source
			}

			if (this.state.is_reimbursement && this.state.approval_button == 2) {
				data.entry_status = 1;
			}

			data.inventory_mode = this.state.inventory_mode ? 1 : 0;

			if (!data.inventory_mode && this.state.old_data.inventory_mode) {
				let inventory_details = this.props.temp_inventory_data;
				data.remove_inventory_voucher = this.state.old_data.inventory_details.inventory_voucher_id || 0;
			}

			// SOC::add code to fix BU1-I385
			if (!this.state.inventory_mode) {
				if (!this.state.tds_status) {
					this.setState({ tds_percentage: null, tds_amount: null })
				}

				if (!this.state.gst_status) {
					this.setState({ sgst_amount: null, cgst_amount: null, igst_amount: null, gst_total_amount: null })
				}
			}
			// EOC::add code to fix BU1-I385
			let tdsStats = parseFloat(this.state.tds_amount) ? 1 : 0;
			if (this.state.inventory_mode) {
				let gstStus = ((this.state.sgst_amount && this.state.sgst_amount != 0) || (this.state.cgst_amount && this.state.cgst_amount != 0) || (this.state.igst_amount && this.state.igst_amount != 0)) ? 1 : 0;
				data.has_igst = gstStus == 0?0:this.state.is_igst ? 1 : 0;
				data.gst_status = gstStus;
				data.sgst_amount = gstStus == 0?null:this.state.sgst_amount && this.state.sgst_amount != 0 ? parseFloat(this.state.sgst_amount) : null;
				data.cgst_amount =  gstStus == 0?null:this.state.cgst_amount && this.state.cgst_amount != 0 ? parseFloat(this.state.cgst_amount) : null;
				data.igst_amount =  gstStus == 0?null:this.state.igst_amount && this.state.igst_amount != 0 ? parseFloat(this.state.igst_amount) : null;
				data.gst_total_amount = this.state.gst_total_amount ? parseFloat(this.state.gst_total_amount) : null;
				data.tds_status = parseFloat(this.state.tds_amount) ? 1 : 0;
				data.tds_details = tdsStats?this.state.tds_details : [];
				data.tds_total_amount = parseFloat(this.state.tds_amount) || null;

				let inventory_details = this.props.temp_inventory_data;

				if (!inventory_details.inventory_voucher_status && this.state.old_data.inventory_mode) {
					data.remove_inventory_voucher = this.state.old_data.inventory_details.inventory_voucher_id || 0;
				}

				let ledgers = this.SNAPSHOT_LEDGER_ENTRIES_CODE ? inventory_details.ledgers : []

				ledgers = inventory_details.ledgers.filter((led, index1) => {
					if (parseFloat(led.amount) > 0 || led.rate || led.guid) {
						return led;
					}
				})

				let line_items = inventory_details.line_items.filter((item, index1) => {
					if (item.line_item_name || item.hsn_sac || item.rate || item.per || item.quantity || item.gst_rate || item.discount_rate || item.item_ledger_guid || item.common_item_ledger_guid) {
						return item
					}
				})
				//console.log(inventory_details)
				if (inventory_details.remove_line_items) {
					remove_line_items = inventory_details.remove_line_items
				}

				data.inventory_details = {
					"line_item_total": parseFloat(inventory_details.line_item_total) || null,
					"line_item_gst_total": parseFloat(inventory_details.line_item_gst_total) || null,
					"line_item_discount_total": parseFloat(inventory_details.line_item_discount_total) || null,
					"inventory_voucher_status": inventory_details.inventory_voucher_status ? 1 : 0,
					"inventory_voucher_id": inventory_details.inventory_voucher_status ? inventory_details.inventory_voucher_id || 0 : null,
					"is_common_item_ledger": inventory_details.is_common_item_ledger ? 1 : !inventory_details.inventory_voucher_status ? this.props.is_common_item_ledger : 0,
					"accounting_narration": inventory_details.inventory_voucher_status ? inventory_details.accounting_narration || null : null,
					"line_items": line_items.map((item, index) => {
						if (item.line_item_name || item.hsn_sac || item.rate || item.per || item.quantity || item.gst_rate || item.discount_rate || item.item_ledger_guid || item.common_item_ledger_guid) {

							let line_item_update;

							if (item.line_item_update == 1 || item.line_item_update == 0) {
								line_item_update = item.line_item_update
							} else {
								line_item_update = 0;
							}

							let line_item = {
								"id": item.id || 0,
								"line_item_id": parseInt(item.line_item_id) >= 0 ? item.line_item_id : null,
								"line_item_name": item.line_item_name || "",
								"hsn_sac": item.hsn_sac || null,
								"rate": parseFloat(item.rate) || null,
								"per": item.per || null,
								"quantity": parseFloat(item.quantity) || null,
								"gst_rate": parseFloat(item.gst_rate),
								"discount_rate": parseFloat(item.discount_rate) || null,
								"amount": parseFloat(item.amount) || null,
								"item_ledger_guid": inventory_details.inventory_voucher_status ? item.item_ledger_guid || null : null,
								"item_ledger_id": inventory_details.inventory_voucher_status ? item.item_ledger_id || null : null,
								"item_ledger_name": inventory_details.inventory_voucher_status ? item.item_ledger_name || "" : "",
								"common_item_ledger_guid": item.common_item_ledger_guid || null,
								"common_item_ledger_id": item.common_item_ledger_id || null,
								"common_item_ledger_name": item.common_item_ledger_name || "",
								"line_item_update": line_item_update,
								"line_item_id_prediction_status":item.line_item_id_prediction_status || 0,
								"line_item_name_prediction_status":item.line_item_name_prediction_status || 0,
								"hsn_sac_prediction_status":item.hsn_sac_prediction_status || 0,
								"rate_prediction_status": item.rate_prediction_status || 0,
								"per_prediction_status":item.per_prediction_status || 0,
								"quantity_prediction_status":item.quantity_prediction_status || 0,
								"gst_rate_prediction_status":item.gst_rate_prediction_status || 0,
								"discount_rate_prediction_status":item.discount_rate_prediction_status || 0,
								"amount_prediction_status":item.amount_prediction_status || 0,
								"item_ledger_guid_prediction_status":inventory_details.inventory_voucher_status ? item.item_ledger_guid_prediction_status || 0 : 0,
								"item_ledger_id_prediction_status":inventory_details.inventory_voucher_status ? item.item_ledger_id_prediction_status || 0 : 0,
								"item_ledger_name_prediction_status":inventory_details.inventory_voucher_status ? item.item_ledger_name_prediction_status || 0 : 0,
								"common_item_ledger_guid_prediction_status":item.common_item_ledger_guid_prediction_status || 0,
								"common_item_ledger_id_prediction_status":item.common_item_ledger_id_prediction_status || 0,
								"common_item_ledger_name_prediction_status":item.common_item_ledger_name_prediction_status || 0,

							}
							//console.log(line_item)
							return line_item;
						}
					}) || [],
					"ledgers": ledgers.map((led, index) => {
						if (led.amount || led.rate || led.name) {
							let ledger = {
								"id": led.id || 0,
								"amount": parseFloat(led.amount),
								"rate": parseFloat(led.rate),
								"guid": led.guid,
								"ledger_id": led.ledger_id,
								"name": led.name,
							}
							return ledger;
						}
					}),
					// "sync_datetime": inventory_details.inventory_voucher_status ? changeDateTimeFormat(new Date(inventory_details.sync_datetime)) : null,
					"sync_datetime": inventory_details.inventory_voucher_status ? inventory_details.sync_datetime ? changeDateTimeFormat(new Date(inventory_details.sync_datetime)) : null : null,
					"sync_error": inventory_details.inventory_voucher_status ? inventory_details.sync_error : null,
					"sync_status": inventory_details.inventory_voucher_status ? inventory_details.sync_status : null,
					"voucher_total_amount": inventory_details.inventory_voucher_status ? parseFloat(inventory_details.voucher_total_amount) || null : null,
					"voucher_creation_date": inventory_details.voucher_creation_date ? changeDateFormate(new Date(inventory_details.voucher_creation_date)) : null,
					// "voucher_type": this.state.type == 1 ? 5 : 6,
					"party_ledger_guid": inventory_details.inventory_voucher_status ? inventory_details.party_ledger_guid || null : null,
					"party_ledger_id": inventory_details.inventory_voucher_status ? inventory_details.party_ledger_id || null : null,
					"party_ledger_name": inventory_details.inventory_voucher_status ? inventory_details.party_ledger_name || null : null,
					"common_item_ledger_guid": inventory_details.inventory_voucher_status ? inventory_details.common_item_ledger_guid || null : null,
					"common_item_ledger_id": inventory_details.inventory_voucher_status ? inventory_details.common_item_ledger_id || null : null,
					"common_item_ledger_name": inventory_details.inventory_voucher_status ? inventory_details.common_item_ledger_name || null : null,
					"remove_line_items": inventory_details.remove_line_items || [],
					"remove_ledgers": inventory_details.remove_ledgers || [],
					// "voucher_creation_date_prediction_status":inventory_details.voucher_creation_date_prediction_status,
					"party_ledger_guid_prediction_status":inventory_details.inventory_voucher_status ? inventory_details.party_ledger_guid_prediction_status || 0 : 0,
					"party_ledger_id_prediction_status":inventory_details.inventory_voucher_status ? inventory_details.party_ledger_id_prediction_status || 0 : 0,
					"party_ledger_name_prediction_status":inventory_details.inventory_voucher_status ? inventory_details.party_ledger_name_prediction_status || 0 : 0,
					"common_item_ledger_guid_prediction_status":inventory_details.inventory_voucher_status ? inventory_details.common_item_ledger_guid_prediction_status || 0 : 0,
					"common_item_ledger_id_prediction_status":inventory_details.inventory_voucher_status ? inventory_details.common_item_ledger_id_prediction_status || 0 : 0,
					"common_item_ledger_name_prediction_status":inventory_details.inventory_voucher_status ? inventory_details.common_item_ledger_name_prediction_status|| 0 : 0,
					"parent_voucher_type_id":inventory_details.inventory_voucher_status ? inventory_details.parent_voucher_type_id || null : null ,
					"voucher_type_guid": inventory_details.inventory_voucher_status ?inventory_details.voucher_type_guid || null : null,
					"voucher_type_name": inventory_details.inventory_voucher_status ? inventory_details.voucher_type_name || null : null,
					"voucher_type_id": inventory_details.inventory_voucher_status ? inventory_details.voucher_type_id || null : null,
				
				}

				if (this.state.old_data.inventory_mode) {
					let present_line_item_ids = [];
					let present_additional_ledger_ids = [];

					line_items.forEach((item, index) => {
						if (item.id)
							present_line_item_ids.push(item.id)
					})

					ledgers.forEach((led, index) => {
						if (led.id)
							present_additional_ledger_ids.push(led.id)
					})

					this.state.old_data.inventory_details.line_items.forEach((item, index) => {
						let item_id = item.id;
						if (!present_line_item_ids.includes(item_id)) {
							remove_line_items.push(item.id)
						}
					})

					this.state.old_data.inventory_details.ledgers.forEach((led, index) => {
						let led_id = led.id;
						if (!present_additional_ledger_ids.includes(led_id)) {
							remove_ledgers.push(led.id)
						}
					})

					data.inventory_details.remove_line_items = remove_line_items;
					data.inventory_details.remove_ledgers = remove_ledgers;
				}


				//SOC:: gst and hsn sac summary
				let gst_summary = [];
				let hsn_summary = [];
				let gst_details = [];
				let hsn_details = [];

				line_items.forEach((item, index) => {
					if (item.gst_rate) {
						if (!gst_summary[item.gst_rate]) {
							gst_summary[item.gst_rate] = 0;
						}
						gst_summary[item.gst_rate] += parseFloat(item.amount) || 0;
					}

					if (item.hsn_sac) {
						let hsn_sac = item.hsn_sac;
						if (!hsn_summary[item.hsn_sac]) {

							hsn_summary[hsn_sac] = {};
							hsn_summary[hsn_sac].amt = 0;
						}

						hsn_summary[hsn_sac].amt += parseFloat(item.amount) || 0;

						if (!hsn_summary[hsn_sac].cgst_rate)
							hsn_summary[hsn_sac].cgst_rate = this.state.is_igst ? 0 : item.gst_rate * 0.5;

						if (!hsn_summary[hsn_sac].sgst_rate)
							hsn_summary[hsn_sac].sgst_rate = this.state.is_igst ? 0 : item.gst_rate * 0.5;

						if (!hsn_summary[hsn_sac].igst_rate)
							hsn_summary[hsn_sac].igst_rate = this.state.is_igst ? item.gst_rate * 1 : 0;

						hsn_summary[hsn_sac].hsn_sac = item.hsn_sac;
					}
				})

				let numeric_hsn_array = new Array();
				for (var items in hsn_summary) {
					numeric_hsn_array.push(hsn_summary[items]);
				}

				gst_summary.forEach((amt, index) => {
					let cgst_amount = !this.state.is_igst ? amt * 0.5 : null;
					let sgst_amount = !this.state.is_igst ? amt * 0.5 : null;
					let igst_amount = this.state.is_igst ? amt : null;

					gst_details.push({
						gst_rate: index,
						gst_amount: parseFloat(parseFloat(amt).toFixed(2)),
						cgst_amount: this.state.is_igst ? null : parseFloat(parseFloat(cgst_amount).toFixed(2)),
						sgst_amount: this.state.is_igst ? null : parseFloat(parseFloat(sgst_amount).toFixed(2)),
						igst_amount: !this.state.is_igst ? null : parseFloat(parseFloat(igst_amount).toFixed(2)),
					})
				})

				numeric_hsn_array.forEach((data, index) => {
					let gst_amount = 0;
					let cgst_amount = !this.state.is_igst ? data.amt * data.cgst_rate / 100 : null;
					let sgst_amount = !this.state.is_igst ? data.amt * data.sgst_rate / 100 : null;
					let igst_amount = this.state.is_igst ? data.amt * data.igst_rate / 100 : null;

					gst_amount += this.state.is_igst ? igst_amount || 0 : (cgst_amount || 0) + (sgst_amount || 0),

						hsn_details.push({
							"cgst_rate": data.cgst_rate || null,
							"sgst_rate": data.sgst_rate || null,
							"igst_rate": data.igst_rate || null,
							"gst_amount": parseFloat(parseFloat(gst_amount).toFixed(2)),
							"cgst_amount": cgst_amount ? parseFloat(parseFloat(cgst_amount).toFixed(2)) : null,
							"sgst_amount": sgst_amount ? parseFloat(parseFloat(sgst_amount).toFixed(2)) : null,
							"igst_amount": igst_amount ? parseFloat(parseFloat(igst_amount).toFixed(2)) : null,
							"hsn_sac": data.hsn_sac,
							"basic_amount": parseFloat(parseFloat(data.amt).toFixed(2)),
						})
				})

				let gst_total = 0, total_cgst_amount = 0, total_sgst_amount = 0, total_igst_amount = 0;

				gst_details.map((data, index) => {
					total_cgst_amount += parseFloat(parseFloat(data.cgst_amount).toFixed(2)) || 0;
					total_sgst_amount += parseFloat(parseFloat(data.sgst_amount).toFixed(2)) || 0;
					total_igst_amount += parseFloat(parseFloat(data.igst_amount).toFixed(2)) || 0;
				})

				gst_total = parseFloat(parseFloat(total_cgst_amount + total_sgst_amount + total_igst_amount).toFixed(2));


				let hsn_sac_basic_total = 0, hsn_sac_cgst_total = 0, hsn_sac_sgst_total = 0, hsn_sac_igst_total = 0, hsn_sac_gst_total = 0;

				hsn_details.map((data, index) => {
					hsn_sac_basic_total += parseFloat(parseFloat(data.basic_amount).toFixed(2)) || 0;
					hsn_sac_cgst_total += parseFloat(parseFloat(data.cgst_amount).toFixed(2)) || 0;
					hsn_sac_sgst_total += parseFloat(parseFloat(data.sgst_amount).toFixed(2)) || 0;
					hsn_sac_igst_total += parseFloat(parseFloat(data.igst_amount).toFixed(2)) || 0;
					hsn_sac_gst_total += parseFloat(parseFloat(data.gst_amount).toFixed(2)) || 0;
				})



				inventory_details.gst_summary = gst_total > 0 ? {
					"cgst_total": this.state.is_igst ? null : total_cgst_amount,
					"sgst_total": this.state.is_igst ? null : total_sgst_amount,
					"igst_total": this.state.is_igst ? total_igst_amount : null,
					"gst_total": gst_total,
					"gst_details": gst_details
				} : null;

				inventory_details.hsn_sac_summary = hsn_sac_gst_total > 0 ? {
					"basic_total": hsn_sac_basic_total,
					"cgst_total": this.state.is_igst ? null : hsn_sac_cgst_total,
					"sgst_total": this.state.is_igst ? null : hsn_sac_sgst_total,
					"igst_total": this.state.is_igst ? hsn_sac_igst_total : null,
					"gst_total": hsn_sac_gst_total,
					"hsn_sac_details": hsn_details
				} : null;
				//EOC:: gst and hsn sac summary
				// console.log(inventory_details.hsn_sac_summary );
				// return

				if (inventory_details.gst_summary === undefined) {
					data.gst_summary = this.state.gst_summary;
					data.remove_gst_summary = [];
				} else {

					if (inventory_details.gst_summary != null) {
						let remove_gst_summary = [];
						inventory_details.gst_summary.gst_details.forEach((data, index) => {

							let data_present = false;
							//console.log(this.state.gst_summary);
							if (this.state.gst_summary != null) {
								data_present = this.arrayFindObjectByProp(this.state.gst_summary.gst_details, "gst_rate", data.gst_rate);
							}
							if (data_present) {
								if (data.cgst_amount == data_present.cgst_amount && data.sgst_amount == data_present.sgst_amount && data.igst_amount == data_present.igst_amount) {
									inventory_details.gst_summary.gst_details[index].id = data_present.id;
								} else {
									//console.log(data_present.id)
									inventory_details.gst_summary.gst_details[index].id = 0;
									remove_gst_summary.push(data_present.id);
								}
							} else {
								inventory_details.gst_summary.gst_details[index].id = 0;
							}
						})

						if (this.state.gst_summary != null) {
							this.state.gst_summary.gst_details.forEach((data, index) => {
								let data_present = this.arrayFindObjectByProp(inventory_details.gst_summary.gst_details, "gst_rate", data.gst_rate);

								if (!data_present) {
									remove_gst_summary.push(data.id);
								}
							})
						}

						data.gst_summary = inventory_details.gst_summary;
						data.remove_gst_summary = remove_gst_summary;
					} else {
						data.gst_summary = null;
						data.remove_gst_summary = []
					}
				}

				if (inventory_details.hsn_sac_summary === undefined) {
					data.hsn_sac_summary = this.state.hsn_sac_summary;
					data.remove_hsn_sac_summary = [];
				} else {

					if (inventory_details.hsn_sac_summary != null) {
						let remove_hsn_sac_summary = [];
						inventory_details.hsn_sac_summary.hsn_sac_details.forEach((data, index) => {

							let data_present = false;
							if (this.state.hsn_sac_summary != null) {
								data_present = this.arrayFindObjectByProp(this.state.hsn_sac_summary.hsn_sac_details, "hsn_sac", data.hsn_sac);
							}
							if (data_present) {
								if (data.cgst_amount == data_present.cgst_amount && data.sgst_amount == data_present.sgst_amount && data.igst_amount == data_present.igst_amount && data.basic_amount == data_present.basic_amount && data.cgst_rate == data_present.cgst_rate && data.sgst_rate == data_present.sgst_rate && data.igst_rate == data_present.igst_rate) {
									inventory_details.hsn_sac_summary.hsn_sac_details[index].id = data_present.id;
								} else {
									//console.log(data_present.id)
									inventory_details.hsn_sac_summary.hsn_sac_details[index].id = 0;
									remove_hsn_sac_summary.push(data_present.id);
								}
							} else {
								inventory_details.hsn_sac_summary.hsn_sac_details[index].id = 0;
							}
						})

						if (this.state.hsn_sac_summary != null) {
							this.state.hsn_sac_summary.hsn_sac_details.forEach((data, index) => {
								let data_present = this.arrayFindObjectByProp(inventory_details.hsn_sac_summary.hsn_sac_details, "hsn_sac", data.hsn_sac);

								if (!data_present) {
									remove_hsn_sac_summary.push(data.id);
								}
							})
						}

						data.hsn_sac_summary = inventory_details.hsn_sac_summary;
						data.remove_hsn_sac_summary = remove_hsn_sac_summary;
					} else {
						data.hsn_sac_summary = null;
						data.remove_hsn_sac_summary = []
					}
				}

				// console.log(data);
				// this.props.dispatch(setAppilcationLoder(false));
				// return;
				if (inventory_details.inventory_voucher_status && (this.state.merchant_option.party_ledger_guid != inventory_details.party_ledger_guid || (inventory_details.is_common_item_ledger && this.state.type == 1 && this.state.merchant_option.sales_ledger_guid != inventory_details.common_item_ledger_guid) || (inventory_details.is_common_item_ledger && this.state.type == 2 && this.state.merchant_option.purchase_ledger_guid != this.state.common_item_ledger_guid))) {
					data.merchant_update = 1;
				}

			} else {
				data.inventory_details = null
				data.gst_summary = null
				data.hsn_sac_summary = null;
				data.remove_gst_summary = [];
				data.remove_hsn_sac_summary = [];

				let gstStus = this.state.gst_status ? 1 : 0;
				data.gst_status = gstStus;
				data.sgst_amount = gstStus == 0 ? null :this.state.gst_status ? this.state.sgst_amount && this.state.sgst_amount != 0 ? parseFloat(this.state.sgst_amount) : null : null;
				data.cgst_amount = gstStus == 0 ? null :this.state.gst_status ? this.state.cgst_amount && this.state.cgst_amount != 0 ? parseFloat(this.state.cgst_amount) : null : null;
				data.igst_amount = gstStus == 0 ? null :this.state.gst_status ? this.state.igst_amount && this.state.igst_amount != 0 ? parseFloat(this.state.igst_amount) : null : null;
				data.gst_total_amount = this.state.gst_status ? this.state.gst_total_amount ? parseFloat(this.state.gst_total_amount) : null : null;
				data.tds_status = this.state.tds_status ? 1 : 0;
				data.tds_details = this.state.tds_status ? this.state.tds_details : [];
				data.tds_total_amount = this.state.tds_status ? parseFloat(this.state.tds_amount) : null;
		
				data.has_igst = gstStus == 0 ? 0:this.state.gst_status ? this.state.is_igst ? 1 : 0 : 0;
			}

			data.is_gst_override=data.gst_status == 0?0:this.state.is_gst_override ? 1 : 0;
			data.is_gst_claim_eligible= data.gst_status == 0?0:is_gst_claim_eligible;

			//return
			data.vouchers = this.SNAPSHOT_LEDGER_ENTRIES_CODE ? vouchers : [];
			data.amount_round_off = this.props.amount_round_off;
			let errors = validateUpdateEvidence(data);
			//console.log(errors)
			if (Object.keys(errors).length >= 1)
				toast.error("Wrong input provided")

			if (Object.keys(errors).length === 0) {

				if (!this.removeEmptyVouchersAndLedgers()) {
					toast.warning('Ledger data is missing')
					this.setState((prevState, props) => ({
						submit_click: prevState.submit_click + 1
					}));
					this.setState({ is_valid_ledger: false, save_and_redirect: false, next_route: false })
					this.props.dispatch(setAppilcationLoder(false));
					return true;
				}
				//check valid voucher
				if (!this.validateVouchers()) {
					this.setState({ is_valid_voucher: false, save_and_redirect: false, next_route: false })
					this.props.dispatch(setAppilcationLoder(false));
					return true;
				}
				//check valid voucher
				if (!this.validateVouchers()) {
					this.setState({ is_valid_voucher: false, save_and_redirect: false, next_route: false })
					this.props.dispatch(setAppilcationLoder(false));
					return true;
				}

				//checking for correct merchant name
				let merchant_list = this.state.all_merchant_list;
				let merchant_data = merchant_list.filter(merchant => (merchant.pan_number == data.pan_number && merchant.gst_no == data.gst_no) || (merchant.pan_number == data.pan_number && data.gst_no == ""));

				if (merchant_data.length && !!data.pan_number) {
					this.props.dispatch(setAppilcationLoder(false));
					if (merchant_data[0].pan_number == data.pan_number && data.merchant_id != merchant_data[0].value) {
						if (await confirm({
							confirmation: 'Duplicate PAN detected. Click Yes if you would like to select the current supplier.'
						})) {
							// this.props.dispatch(setAppilcationLoder(true));
							this.setState({
								merchant_option: { label: merchant_data[0].label, value: merchant_data[0].value, gst_no: merchant_data[0].gst_no, pan_number: merchant_data[0].pan_number, is_msme: merchant_data[0].is_msme, is_perquisite: merchant_data[0].is_perquisite || 0, is_gst_claim_eligible: merchant_data[0].is_gst_claim_eligible || 0, snapshot_type: merchant_data[0].snapshot_type },
								// errors: { merchant_name: 'supplier name corrected for PAN no.' }
							}, () => {
							});
							data.merchant_name = merchant_data[0].label;
							data.merchant_id = merchant_data[0].value;
							data.is_msme = merchant_data[0].is_msme;
							data.is_perquisite = merchant_data[0].is_perquisite || 0;
							data.is_gst_claim_eligible = merchant_data[0].is_gst_claim_eligible || 0;
							//data.snapshot_type = merchant_data[0].snapshot_type
							data.merchant_update = 1;
						} else {
							this.setState({
								errors: { gst_no: 'Duplicate GST/PAN detected.' }
							}, () => {
							});
							return true;
						}

					}
				}

				if (showpopups) {

					if(data.approval_status == 2 && this.state.old_data.approval_status == 2 && this.CREATE_REIMBUSRMENT_SNAPSHOT) {
						this.props.dispatch(setAppilcationLoder(false));

						if (await confirm({
							confirmation: 'Do you want to send this snapshot for re approval?'
						})) {
							data.approval_status = null;
							this.setState({ approval_status: null });
						}
					}

					if (this.state.old_data.entry_status == 2 && this.state.entry_status == 1 && this.state.approval_status == "1" && this.state.approval_button != "2") {
						this.props.dispatch(setAppilcationLoder(false));
						if (!await confirm({
							confirmation: 'Changing approved reimbursement snapshot to draft will reject the reimbursement. Do you want to continue?'
						})) {
							return true;
						}
						data.approval_status = 2;
						data.entry_status = 1;

						this.setState({ approval_status: 2, entry_status: 1 })
					}

					if (this.state.inventory_mode && this.state.old_data.inventory_mode) {

						if ((this.state.old_data.inventory_details.sync_status != 0 && this.state.old_data.inventory_details.sync_status != null) && data.inventory_details) {
							if (data.inventory_details.inventory_voucher_status) {
								let new_voucher_data = {
									"voucher_creation_date": data.inventory_details.voucher_creation_date,
									"parent_voucher_type_id":data.inventory_details.parent_voucher_type_id,
									"voucher_type_guid":data.inventory_details.voucher_type_guid,
									"voucher_type_name":data.inventory_details.voucher_type_name,
									"voucher_type_id":data.inventory_details.voucher_type_id,
									"party_ledger_guid": data.inventory_details.party_ledger_guid,
									"common_item_ledger_guid": data.inventory_details.common_item_ledger_guid,
									"accounting_narration": data.inventory_details.accounting_narration,
									"line_items_length": data.inventory_details.line_items.length,
									"additional_ledgers_length": data.inventory_details.ledgers.length,
									"line_items": data.inventory_details.line_items.map((item, index) => {
										if (item.line_item_name || item.hsn_sac || item.rate || item.per || item.quantity || item.gst_rate || item.discount_rate || item.item_ledger_guid || item.common_item_ledger_guid) {

											let line_item = {
												"per": item.per || null,
												"rate": parseFloat(item.rate) || null,
												"quantity": parseInt(item.quantity) || null,
												"amount": parseFloat(item.amount) || null,
												"item_ledger_guid": item.item_ledger_guid || null,
												"common_item_ledger_guid": item.common_item_ledger_guid || null,

											}
											return line_item;
										}
									}) || [],
									"ledgers": data.inventory_details.ledgers.map((led, index) => {
										if (led.amount || led.rate || led.name) {
											let ledger = {
												"amount": parseFloat(led.amount),
												"rate": parseFloat(led.rate),
												"guid": led.guid,
											}
											return ledger;
										}
									}),
								}

								let old_inventory_data = _.cloneDeep(this.state.old_data.inventory_details);

								let old_voucher_data = {
									"voucher_creation_date": old_inventory_data.voucher_creation_date,
									"parent_voucher_type_id":old_inventory_data.parent_voucher_type_id,
									"voucher_type_guid":old_inventory_data.voucher_type_guid,
									"voucher_type_name":old_inventory_data.voucher_type_name,
									"voucher_type_id":old_inventory_data.voucher_type_id,
									"party_ledger_guid": old_inventory_data.party_ledger_guid,
									"common_item_ledger_guid": old_inventory_data.common_item_ledger_guid,
									"accounting_narration": old_inventory_data.accounting_narration,
									"line_items_length": old_inventory_data.line_items.length,
									"additional_ledgers_length": old_inventory_data.ledgers.length,
									"line_items": old_inventory_data.line_items.map((item, index) => {
										if (item.line_item_name || item.hsn_sac || item.rate || item.per || item.quantity || item.gst_rate || item.discount_rate || item.item_ledger_guid || item.common_item_ledger_guid) {

											let line_item = {
												"per": item.per || null,
												"rate": parseFloat(item.rate) || null,
												"quantity": parseInt(item.quantity) || null,
												"amount": parseFloat(item.amount) || null,
												"item_ledger_guid": item.item_ledger_guid || null,
												"common_item_ledger_guid": item.common_item_ledger_guid || null,

											}
											return line_item;
										}
									}) || [],
									"ledgers": old_inventory_data.ledgers.map((led, index) => {
										if (led.amount || led.rate || led.name) {
											let ledger = {
												"amount": parseFloat(led.amount),
												"rate": parseFloat(led.rate),
												"guid": led.guid,
											}
											return ledger;
										}
									}),
								}

								if (!_.isEqual(old_voucher_data, new_voucher_data)) {
									this.props.dispatch(setAppilcationLoder(false));
									let confirm_msg = await confirm({ confirmation: 'Do you want to change inventory voucher status to Not Synced?' });

									if (confirm_msg) {
										let inventory_details = this.props.temp_inventory_data;
										inventory_details.sync_status = 0;
										inventory_details.sync_datetime = changeDateTimeFormat(new Date());

										data.inventory_details.sync_status = 0;
										data.inventory_details.sync_datetime = changeDateTimeFormat(new Date());
										this.props.dispatch(setTempInventoryData(inventory_details));
									}
								}
							}
						}
					}
					this.props.dispatch(setAppilcationLoder(true));
					let snapshot_type_update = 0;

					if (this.state.merchant_option.value && !this.state.is_reimbursement) {
						if (this.state.merchant_option.snapshot_type != null && this.state.merchant_option.snapshot_type != this.state.type && this.state.old_data.type != data.type) {
							this.props.dispatch(setAppilcationLoder(false));
							if (!await confirm({
								confirmation: "Do you want to update snapshot type for this party?"
							})) {
								snapshot_type_update = 0;
								this.props.dispatch(setAppilcationLoder(true));
							} else {
								snapshot_type_update = 1;
								this.props.dispatch(setAppilcationLoder(true));
							}
						} else {
							snapshot_type_update = 0;
						}

						if (!this.state.merchant_option.snapshot_type && this.state.merchant_option.value != 0) snapshot_type_update = 1;

					} else {
						snapshot_type_update = 0;
					}

					if (this.state.merchant_option.value && !this.state.merchant_option.snapshot_type && !this.state.is_reimbursement) {
						snapshot_type_update = 1;
					}

					data.snapshot_type_update = snapshot_type_update;
					this.setState({ snapshot_type_update: snapshot_type_update })
				} else {
					data.snapshot_type_update = this.state.snapshot_type_update
				}


				if (data.accounting_status == 5 && data.vouchers.length > 0 && showpopups) {
					this.props.dispatch(setAppilcationLoder(false));
					if (!await confirm({
						confirmation: "This action will save the record but vouchers if any will not be automatically synced with accounting software, do you want to continue?"
					})) {
						return true
					} else {
						this.props.dispatch(setAppilcationLoder(true));
					}
				}

				//START -  To show GST related error
				if (showpopups) {
					if (!!data.gst_no && !!this.props.entity_gst_no) {
						let oldAccStatus = this.state.old_data.accounting_status
						let newAccStatus = data.accounting_status
						if (((newAccStatus == 3) && (oldAccStatus == 3 || oldAccStatus == 4 || oldAccStatus == 5)) || ((newAccStatus == 4 || newAccStatus == 5) && (oldAccStatus == 3))) {
							let res = await this.showGstError(data.gst_no, data.cgst_amount, data.sgst_amount, data.igst_amount)
							if (res) {
								return
							}
						}
					}
				}
				//END -  To show GST related error


				this.setLogData(_.cloneDeep(this.state), this.props.temp_snap_voucher_list, 'new');

				if (billwise_allocation > 0) {
					this.props.dispatch(setAppilcationLoder(false));
					await confirm({
						confirmation: "As you have selected bill-wise enabled ledger, bill-wise allocation will be done on background, according to invoice no and ledger amount.",
						options: { type: "alert" }
					})
				}

				if (this.state.showDeleteVoucherPopup) {
					this.props.dispatch(setAppilcationLoder(false));
					if (!await confirm({
						confirmation: 'You have removed one or more vouchers. Once you delete, they will also be removed from your accounting software. Do you wish to continue?'
					})) {
						return true;
					}
				}

				if (this.SNAPSHOT_LEDGER_ENTRIES_CODE && showpopups) {


					if (data.entry_status == 1 && data.accounting_status != 3 && this.state.old_data.accounting_status != 3) {
						this.props.dispatch(setAppilcationLoder(false));
						if (!await confirm({
							confirmation: "Do you want to change accounting status to Pending?"
						})) {
							this.props.dispatch(setAppilcationLoder(true));
						} else {
							data.accounting_status = 3;
							data.synced_by_user = null;

							this.setState({ accounting_status: 3, synced_by_user: null })
							this.props.dispatch(setAppilcationLoder(true));
						}
					}

					let inventory_details_ledgers_length = 0;
					if (data.inventory_mode) {
						inventory_details_ledgers_length = data.inventory_details.ledgers.length;
					}

					if (data.accounting_status == 4 && data.vouchers.length == 0 && inventory_details_ledgers_length == 0 && (this.state.old_data.accounting_status == 3 || this.state.old_data.accounting_status == 5)) {
						this.props.dispatch(setAppilcationLoder(false));
						//console.log('ddd');
						if (!await confirm({
							confirmation: "No ledger entries specified. Do you want to proceed?"
						})) {
							return true;
						} else {
							this.props.dispatch(setAppilcationLoder(true));
						}
					}
				}
				this.props.dispatch(setAppilcationLoder(true));
				//console.log(data)
				//return;

				SnapshotService.updateSnapshot(data).then(
					async (resp_data) => {
						this.props.dispatch(setAppilcationLoder(false));
						this.setState({
							loading: false,
							errors: {},

						});

						this.imageUploadChildRef.closePdfPopup();
						this.imageUploadChildRef.closeUploadedPdfPopup();

						if (resp_data.response_code == 200) {

							this.setState({
								approval_status: data.approval_status || null,
								approval_button: data.approval_status || null,
								entry_status: data.entry_status,
								accounting_status: data.accounting_status,
								line_items_list: resp_data.result.line_item_master,
								qualified_url: resp_data.result.qualified_url,
								is_dirty: false,
								is_valid_ledger: true,
								is_smart: this.state.is_smart && !!this.state.file_url,
								is_leder_recommended: 0,
								is_duplicate: 0,
								system_suggested_status: "",
								is_edit_supplier: false,
								is_new: false,
								new_value: null,
								hashtag: "",
								prediction_id: resp_data.result.prediction_id,
								gst_no_prediction_status: gst_no_prediction_status,
								total_amount_prediction_status,
								igst_amount_prediction_status,
								sgst_amount_prediction_status,
								cgst_amount_prediction_status,
								date_prediction_status,
								merchant_name_prediction_status,
								snapshot_number_prediction_status,
								merchant_id_prediction_status,
								pan_number_prediction_status,
								snapshot_type_prediction_status,
								expense_prediction_status,
								category_id_prediction_status,
								tds_percentage_prediction_status,
								tags_prediction_status,
								is_msme_prediction_status,
								is_entity_mismatch_prediction_status,
								igst_amount_prediction_value: "",
								sgst_amount_prediction_value: "",
								cgst_amount_prediction_value: "",
								total_gst_amount_prediction_value: "",
								pan_number_prediction_value: "",
								date_prediction_value: "",
								merchant_name_prediction_value: "",
								gst_no_prediction_value: "",
								snapshot_number_prediction_value: "",
								total_amount_prediction_value: "",
								merchant_id_prediction_value: "",
								snapshot_type_prediction_value: "",
								category_id_prediction_value: "",
								tds_percentage_prediction_value: "",
								tags_prediction_value: "",
								is_msme_prediction_value: "",
								is_entity_mismatch_prediction_value: "",
								line_items_prediction_status: 0,
								global_preidction_status:0,
								bulk_prediction_status : 0,
								showInventory : 0,
								payment_information: resp_data.result.payment_information,
								inventory_mode: resp_data.result.inventory_mode,
								gst_summary: resp_data.result.gst_summary || null,
								hsn_sac_summary: resp_data.result.hsn_sac_summary || null,
								request_id : resp_data.result.request_id,
								line_items_data : [],
								prediction_party_ledger_guid:null,
								prediction_purchase_ledger_guid:null,
								prediction_sales_ledger_guid : null,
								inventory_party_ledger_id:null,
								inventory_party_ledger_name:null,
								inventory_common_item_ledger_guid:null,
								inventory_common_item_ledger_id:null,
								inventory_common_item_ledger_name:null,
								inventory_party_ledger_guid_prediction:0,
								inventory_party_ledger_id_prediction:0,
								inventory_party_ledger_name_prediction:0,
								inventory_common_item_ledger_guid_prediction:0,
								inventory_common_item_ledger_id_prediction:0,
								inventory_common_item_ledger_name_prediction:0,
								inventory_voucher_creation_date:"",
								inventory_voucher_creation_date_prediction:0,
								combined_inventory_array : [],
								auto_accounted:false,
								auto_ready_accounted:false,
								auto_ready:false,
								parent_voucher_type_id:"",
								voucher_type_guid:"",
								voucher_type_id:"",
								voucher_type_name:"",
								prediction_voucher_type_guid:null,
								is_base_amt_changed: false,
								vaild_voucher:false,
								xml_upload: false,
							}, () => {
								if (!this.state.snapshot_id || (!!this.state.snapshot_id && this.MANAGE_OTHER_USER_SNAPSHOT) || (!!this.state.snapshot_id && (this.CREATE_BUSSINESS_SNAPSHOT || this.CREATE_REIMBUSRMENT_SNAPSHOT) && this.state.created_by == SessionManagement.getUserDetail().id) || this.SNAPSHOT_LEDGER_ENTRIES_CODE
								) {
									this.VIEW_ONLY_SNAPSHOT = false;
								} else {
									this.VIEW_ONLY_SNAPSHOT = true;
								}


								if (this.state.is_linked == 1) {
									this.setState({ editable: false })
								} else if (this.SNAPSHOT_LEDGER_ENTRIES_CODE) {
									this.setState({ editable: true })
								} else if (this.state.entry_status == 1) {
									this.setState({ editable: true })
								} else {
									this.setState({ editable: false })
								}
							});

							if (this.state.payment_status) {
								this.paymentChildRef.setChildPaymentDetail(resp_data.result.payment_information);
								this.paymentChildRef.setChildPaymentTotal(data.payment_total);
							} else {
								let initialPaymentObj = [{ method: 0, id: 0, amount: 0, payment_id: 0 }];
								this.paymentChildRef.setChildPaymentDetail(initialPaymentObj);
								this.paymentChildRef.setChildPaymentTotal(0);
							}


							toast.success(resp_data.message);
							let category_index = this.state.categories.findIndex(cat => cat.id == this.state.category_id);
							let tags = this.state.listed_tags.filter((tag) => this.state.selected_tags.indexOf(tag.id) != -1);


							//Set merchant list received from api
							let merchants = resp_data.result.merchants.map(merchant => {
								return {
									value: merchant.id,
									label: merchant.name,
									gst_no: merchant.gst_no || "",
									pan_number: merchant.pan_number || "",
									tds_percentage: merchant.tds_percentage,
									snapshot_type: merchant.snapshot_type,
									is_gst_claim_eligible: merchant.is_gst_claim_eligible,
									is_msme: merchant.is_msme,
									is_perquisite: merchant.is_perquisite,
									type: merchant.type,
									category_id: merchant.category_id,
									tags: merchant.tags,
									party_ledger_guid: merchant.party_ledger_guid || "",
									purchase_ledger_guid: merchant.purchase_ledger_guid || "",
									sales_ledger_guid: merchant.sales_ledger_guid || "",
									purchase_voucher_type_guid : merchant.purchase_voucher_type_guid || "",
									sales_voucher_type_guid : merchant.sales_voucher_type_guid || ""
								}
							})

							this.setState({ all_merchant_list: merchants, all_old_merchant_list: _.cloneDeep(merchants) }, () => {
								let merchant_option = this.state.all_merchant_list.find(merchant => merchant.value == resp_data.result.merchant_id);
								if (resp_data.result.merchant_id) {
									this.setState({ merchant_option })
								} else {
									this.setState({ merchant_option: { value: "", label: "" } })
								}
							})

							if (resp_data.result.inventory_details == null) {
								this.emptyInventoryData();
								this.setState({ inventory_details: false })
							} else {
								this.props.dispatch(setTempInventoryData(resp_data.result.inventory_details));
								this.setState({ inventory_details: true })
							}

							let new_tags = resp_data.result.new_tags;
							let selected_tags = [...this.state.selected_tags, ...new_tags.map(tag => tag.id)];
							let listed_tags = [...this.state.listed_tags, ...new_tags];
							let type_index = this.state.snapshot_types.findIndex(type => type.id == data.type);

							let evidence = {
								id: data.id,
								attachment_status: data.file_url,
								category: category_index != -1 ? this.state.categories[category_index].name : "", // want from api
								date: data.date,
								expense: data.expense,
								gst_status: data.gst_status,
								is_star: 0,
								message_status: 0,
								name: data.merchant_name,
								merchant_name: data.merchant_name,
								payment_information: [], // want from api
								payment_status: data.payment_status,
								status: data.accounting_status == 3 ? data.entry_status : data.accounting_status,
								task_status: 0,
								tds_status: data.tds_status,
								total_amount: data.total_amount,
								type: this.state.snapshot_types[type_index].name || "",
								is_duplicate: resp_data.result.is_duplicate,
								pending_amount: resp_data.result.pending_amount,
								accounting_status: data.accounting_status,
								entry_status: data.entry_status,
								new_tags: new_tags,
								categories: this.state.categories,
								old_accounting_status: this.state.old_data.accounting_status,
								old_entry_status: this.state.old_data.entry_status,
								created_at: displayDateFormate(this.state.created_at),
								is_reimbursement: this.state.is_reimbursement,
								approval_status: data.approval_status,
								old_approval_status: this.state.old_data.approval_status,
								old_is_reimbursement: this.state.old_data.old_is_reimbursement
							}

							this.setState({
								//selected_tags: selected_tags,
								selected_tags: resp_data.result.tags.map(tag => tag.id),
								listed_tags: listed_tags,
								new_tags: [],
								remove_tags: [],
							})

							if (StorageData.getTransactionId()) {
								toast.info('Click "Back" button to go back to Money Link')
								this.setState({
									money_link_evidence: {
										snapshot_status: data.snapshot_status,
										id: data.id,
										is_update: true,

									}
								})
							}

							//console.log(this.state.old_data, new_log_data)

							let delete_log = ""
							if (data.remove_vouchers.length > 0) {
								delete_log += "Voucher with ID(s) ";
								data.remove_vouchers.forEach((voucher_id, index) => {
									if ((data.remove_vouchers.length - 1) == index) {
										delete_log += "#" + voucher_id;
									} else {
										delete_log += "#" + voucher_id + ",";
									}
								})
								delete_log += " deleted";
							}
							//console.log(JSON.stringify(this.state.old_data_log), JSON.stringify(this.state.new_data_log))
							let createdLog = createLog.map(this.state.old_data_log, this.state.new_data_log)
							//console.log(createdLog)
							let d = formatLogData(createdLog, ["sync_error", "sync_status"])
							sendLog({ module_type: 1, module_id: this.state.snapshot_id, attach_activity: delete_log })
							//send logs code

							this.props.dispatch(setTempVoucherList([]));
							let remove_cost_category = [];
							let remove_cost_center = [];

							let temp_snap_voucher_list = resp_data.result.vouchers.map((voucher, index) => {

								let temp_voucher = {
									"accounting_narration": voucher.accounting_narration,
									"parent_voucher_type_id":voucher.parent_voucher_type_id,
									"voucher_type_guid":voucher.voucher_type_guid,
									"voucher_type_name":voucher.voucher_type_name,
									"voucher_type_id":voucher.voucher_type_id,
									"voucher_id": voucher.voucher_id,
									"voucher_creation_date": voucher.voucher_creation_date,
									"total_credit_amount": voucher.total_credit_amount,
									"total_debit_amount": voucher.total_debit_amount,
									"sync_datetime": voucher.sync_datetime,
									"sync_status": voucher.sync_status,
									"sync_error": voucher.sync_error,
									"ledgers": voucher.ledgers.map((ledger, ledger_index) => {
										let empty_cost_category = [{
											"category_guid": "",
											"category_id": 0,
											"category_name": "",
											"transaction_voucher_ledger_cost_category_id": 0,
											"cost_centers": [{
												"cost_center_guid": "",
												"cost_center_id": 0,
												"cost_center_name": "",
												"cost_center_amount": "",
												"transaction_voucher_ledger_cost_category_cost_centre_id": 0
											}]
										}];
										let temp_ledger = {
											"amount": ledger.amount,
											"guid": ledger.guid,
											"name": ledger.name,
											"type": ledger.type,
											"ledger_id": ledger.ledger_id,
											"transaction_voucher_ledger_id": ledger.voucher_ledger_id,
											"voucher_ledger_id": ledger.voucher_ledger_id
										}

										let is_cost_category_enable = !!this.state.ledgerNameList.ledger_list.find((temp_ledger) => temp_ledger.guid == ledger.guid && temp_ledger.has_cost_centre == 1);
										if (!ledger.cost_categories.length && is_cost_category_enable) {
											temp_ledger.cost_categories = empty_cost_category;
										} else {
											temp_ledger.cost_categories = ledger.cost_categories.map((category, category_index) => {
												let temp_category = {
													"category_id": category.cost_category_id,
													"category_guid": category.guid,
													"category_name": category.name,
													"transaction_voucher_ledger_cost_category_id": category.transaction_voucher_ledger_cost_category_id,
													"voucher_ledger_cost_category_id": category.voucher_ledger_cost_category_id,
													"cost_centers": category.cost_centres.map((cost_center, cc_index) => {
														let tmp_cost_center = {
															"cost_center_amount": cost_center.amount,
															"cost_center_id": cost_center.cost_centre_id,
															"cost_center_guid": cost_center.guid,
															"cost_center_name": cost_center.name,
															"transaction_voucher_ledger_cost_category_cost_centre_id": cost_center.transaction_voucher_ledger_cost_category_cost_centre_id,
															"voucher_ledger_cost_category_cost_centre_id": cost_center.voucher_ledger_cost_category_cost_centre_id
														}

														remove_cost_center.push(cost_center.voucher_ledger_cost_category_cost_centre_id)
														return tmp_cost_center;
													})
												}
												remove_cost_category.push(category.voucher_ledger_cost_category_id);
												return temp_category;
											})
										}

										return temp_ledger;
									})
								}
								return temp_voucher;
							});
							// console.log("temp_snap_voucher_list", temp_snap_voucher_list)
							this.props.dispatch(setTempVoucherList(temp_snap_voucher_list))

							//save current data as old data
							let oldData = {};
							oldData.type = data.type;
							oldData.date = data.date;
							oldData.invoice_number = data.invoice_number;
							oldData.total_amount = data.total_amount;
							oldData.category_id = data.category_id;
							oldData.tags = resp_data.result.tags.map(tag => tag.id);
							oldData.new_tags = [];
							oldData.is_perquisite = data.is_perquisite;
							oldData.additional_information = data.additional_information;
							oldData.merchant_name = data.merchant_name;
							oldData.gst_no = data.gst_no;
							oldData.pan_number = data.pan_number;
							oldData.is_msme = data.is_msme;
							oldData.payment_information = data.payment_information;
							oldData.sgst_amount = data.sgst_amount;
							oldData.cgst_amount = data.cgst_amount;
							oldData.igst_amount = data.igst_amount;
							oldData.is_gst_claim_eligible = data.is_gst_claim_eligible;
							oldData.tds_details = data.tds_details;
							oldData.tds_amount = data.tds_amount;
							oldData.evidence_file = data.file_url;
							oldData.approval_comment = data.approval_comment;
							oldData.approval_status = data.approval_status;
							oldData.approved_amount = data.approved_amount;
							oldData.vouchers = this.manipulateVouchersData(_.cloneDeep(this.props.temp_snap_voucher_list));
							oldData.entry_status = data.entry_status;
							oldData.accounting_status = data.accounting_status;
							oldData.entity_mismatch = data.is_entity_mismatch;
							oldData.inventory_mode = data.inventory_mode;
							oldData.inventory_details = resp_data.result.inventory_details

							this.setState({ old_data: oldData, tds_info_data_from_db: data.tds_details, remove_cost_category, remove_cost_center, payment_info_data_from_db: resp_data.result.payment_information, voucher_list_from_db: resp_data.result.vouchers })

							data.tags = resp_data.result.tags;
							this.setLogData(data, this.props.temp_snap_voucher_list, 'old');

							if (this.state.save_and_redirect) {
								if (this.state.communication_bar_tasks_dirty) {
									this.comment_section.saveData('tasks', this.state.next_route);
								} else if (this.state.communication_bar_notes_dirty) {
									this.comment_section.saveData('notes', this.state.next_route);
								} else if (this.state.communication_bar_msgs_dirty) {
									this.comment_section.saveData('msgs', this.state.next_route);
								} else if (this.state.communication_bar_private_msgs_dirty) {
									this.comment_section.saveData('private_msgs', this.state.next_route);
								} else {
									this.props.history.push(this.state.next_route)
								}
							}

							let snapshot_id_found = true;

							let snapFound = this.props.snapshot_list.findIndex(snap => snap.id == this.props.match.params.snapshot_id);

							if (snapFound == -1) {
								snapshot_id_found = false
							} else {
								snapshot_id_found = true;
							}

							this.props.newSnapshot(evidence, 1, !snapshot_id_found ? false : this.state.save_and_next)
							this.props.updateSnapshotDetail(evidence)


						} else if (resp_data.response_code == 400) {

							if (resp_data.reason.is_duplicate) {
								if (await confirm({
									confirmation: 'The snapshot is a duplicate of another snapshot. Do you still want to save?'
								})) {
									this.setState({
										is_duplicate: 1
									}, () => this.updateEvidence(e, false));
								}
								return true;
							}

							if (!!resp_data.reason.is_same_invoice) {
								if (await confirm({
									confirmation: 'Same invoice number and same party already exist. Do you still want to save?'
								})) {
									this.setState({
										is_same_invoice: 1
									}, () => this.updateEvidence(e, false));
								}
								return true;
							}

							if (!!resp_data.reason.accounting_status) {
								await confirm({
									confirmation: "The record is modified by other user and it’s status is changed. The data will be refreshed now.",
									options: { type: "alert" }
								})
								this.getSnapshotDetail(this.state.snapshot_id)
								return
							}

							if (!!resp_data.reason.new_tags) {
								this.setState({ errors: { new_tags: resp_data.reason.new_tags } })
								return true;
							}
							//this.getSnapshotDetail(this.state.snapshot_id)
							toast.error(resp_data.message);
							this.setState({ errors: resp_data.reason, save_and_redirect: false, next_route: false });

							if (!!resp_data.reason.merchants) {

								let merchants = resp_data.result.merchants.map(merchant => {
									return {
										value: merchant.id,
										label: merchant.name,
										gst_no: merchant.gst_no || "",
										pan_number: merchant.pan_number || "",
										tds_percentage: merchant.tds_percentage,
										snapshot_type: merchant.snapshot_type,
										is_gst_claim_eligible: merchant.is_gst_claim_eligible,
										is_msme: merchant.is_msme,
										is_perquisite: merchant.is_perquisite,
										type: merchant.type,
										category_id: merchant.category_id,
										tags: merchant.tags,
										purchase_voucher_type_guid : merchant.purchase_voucher_type_guid || "",
										sales_voucher_type_guid : merchant.sales_voucher_type_guid || ""
									}
								})

								this.setState({ all_merchant_list: merchants }, () => {
									let merchant_option = this.state.all_merchant_list.find(merchant => merchant.value == resp_data.result.merchant_id);
									if (resp_data.result.merchant_id) {
										this.setState({ merchant_option })
									}
								})

								//this.setState({all_merchant_list})
							}
						} else {
							//this.getSnapshotDetail(this.state.snapshot_id)
							toast.error(resp_data.message);
							this.setState({ save_and_redirect: false, next_route: false });
						}
					});
			} else {
				this.props.dispatch(setAppilcationLoder(false));
				let errors_result = { ...errors, snapshot_status: 'Please fill the mandatory details.' };
				this.setState({
					loading: false,
					errors: errors_result,
					save_and_redirect: false,
					next_route: false
				});
			}
		}
	}

	checkForReadyStateValidity = () => {

		let errors = {}

		let data = {
			"type": this.state.type,
			"date": changeDateFormate(this.state.date),
			"merchant_name": this.state.merchant_option.label || null,
			"invoice_number": this.state.invoice_number,
			"total_amount": parseFloat(this.state.total_amount),
			"gst_status": this.state.gst_status ? 1 : 0,
			"sgst_amount": this.state.gst_status ? parseFloat(this.state.sgst_amount) : null,
			"cgst_amount": this.state.gst_status ? parseFloat(this.state.cgst_amount) : null,
			"igst_amount": this.state.gst_status ? parseFloat(this.state.igst_amount) : null,
			"gst_total_amount": this.state.gst_status ? parseFloat(this.state.gst_total_amount) : null,
			"tds_status": this.state.tds_status ? 1 : 0,
			"tds_details": this.state.tds_status ? this.state.tds_details : [],
			"tds_amount": this.state.tds_status ? parseFloat(this.state.tds_amount) : null,
			"payment_status": this.state.payment_status,
			"payment_information": this.state.payment_information,
			"accounting_mode": this.props.accounting_mode,
			"is_igst": this.state.is_igst,
			"party_ledger_guid": this.state.party_ledger_guid,
			"inventory_mode":this.state.inventory_mode
		}
		if (this.state.inventory_mode == 1 || this.state.inventory_mode) {
			data.sgst_amount = this.state.gst_status ? this.state.sgst_amount && this.state.sgst_amount != 0 ? parseFloat(this.state.sgst_amount) : null : null;
			data.cgst_amount = this.state.gst_status ? this.state.cgst_amount && this.state.cgst_amount != 0 ? parseFloat(this.state.cgst_amount) : null : null;
			data.igst_amount = this.state.gst_status ? this.state.igst_amount && this.state.igst_amount != 0 ? parseFloat(this.state.igst_amount) : null : null;
				

			
		}

		data.amount_round_off = this.props.amount_round_off;
		if (this.state.snapshot_id)
			errors = validateUpdateEvidence(data);
		else
			errors = validateCreateEvidence(data);

		if (Object.keys(errors).length === 0) {
			this.setState({ errors: {} });
			return true
		} else {
			let errors_result = { ...errors, entry_status: 'Please fill the mandatory details.' };
			this.setState({ errors: errors_result });
			return false
		}

	}

	validateInventoryModeData = () => {

		this.setState({ inventory_errors: {}, line_item_errors: [], ledger_errors: [] });
		let inventory_details = this.props.temp_inventory_data;
		let data = {
			"type": this.state.type,
			//"voucher_creation_date": inventory_details.voucher_creation_date ?  inventory_details.voucher_creation_date : this.state.date || null ,
			"voucher_creation_date": inventory_details.voucher_creation_date || this.state.date,
			"sgst_amount": this.state.sgst_amount ? parseFloat(this.state.sgst_amount) : null,
			"cgst_amount": this.state.cgst_amount ? parseFloat(this.state.cgst_amount) : null,
			"igst_amount": this.state.igst_amount ? parseFloat(this.state.igst_amount) : null,
			"is_igst": this.state.is_igst,
			"party_ledger_guid": inventory_details.party_ledger_guid || null,
			"common_item_ledger_guid": inventory_details.common_item_ledger_guid || null,
			"entry_status": this.state.entry_status,
			"accounting_status": this.state.accounting_status,
			"inventory_voucher_status": inventory_details.inventory_voucher_status,
			"is_common_item_ledger": inventory_details.is_common_item_ledger,
			"line_items": inventory_details.line_items,
			"ledgers": inventory_details.ledgers,
			"accounting_user": this.SNAPSHOT_LEDGER_ENTRIES_CODE,
			"tds_status": this.state.tds_status,
			"tds_details": this.state.tds_details,
			"tds_amount": this.state.tds_amount,
		}
		//console.log(data)
		let errors = validateInventoryModeData(data);
		console.log('ttttttttttttttttt------',errors);
		if (Object.keys(errors.errors).length == 0 && errors.line_item_errors.length == 0 && errors.ledger_errors.length == 0) {
			return true;
		} else {
			toast.error("Wrong input provided")
			this.setState({
				inventory_errors: { ...errors.errors, inventory_error_status: 'Please fill required mandatory fields.' },
				line_item_errors: errors.line_item_errors,
				ledger_errors: errors.ledger_errors
			})
			return false
		}
	}
	countDecimalDigits(number) {
		const decimalPart = number.toString().split('.')[1]; // Split the number at the decimal point
		return decimalPart ? decimalPart.length : 0; // Return the length of the decimal part or 0 if none
	}
	saveEvidence = async (e, first_time = true) => {
		this.setState({ save_button_clicked: true })
		let valid = true;

		if (this.state.entry_status == 2) {
			valid = this.checkForReadyStateValidity()
		}

		let valid_inventory = true;
		if (this.state.inventory_mode == 1 || this.state.inventory_mode) {
			valid_inventory = this.validateInventoryModeData();
		}

		//console.log(valid, valid_inventory, this.state.inventory_errors, this.state.line_item_errors)

		if (valid && valid_inventory) {
			//if (valid) {
			//return;
			let merchant_update = this.state.merchant_update || 0;
			this.setState({ inventory_errors: {}, line_item_errors: [], ledger_errors: [] });
			let merchant_tags = [];

			if (Object.keys(this.state.merchant_option).length > 0) {
				if (!!this.state.merchant_option.tags) {
					this.state.merchant_option.tags.forEach((tag, index) => {
						merchant_tags.push(tag.id)
					})
				}
			}

			let tagsEqual = _lodash.isEqual(merchant_tags, this.state.selected_tags);

			let category_id = this.state.category_id;

			if (category_id == "") {
				category_id = null;
			}

			let tds_percentage = this.state.tds_details;

			if (tds_percentage == "") {
				tds_percentage = null;
			}

			if (this.state.merchant_option.is_edit == 1 || this.state.merchant_option.value == 0 || this.state.merchant_option.category_id != category_id || this.state.merchant_option.tds_percentage != tds_percentage || !tagsEqual || this.state.new_tags.length > 0) {
				merchant_update = 1;
			}

			//console.log(merchant_update);
			//return;
			// change prediction statuses
			let gst_no_prediction_status = this.state.gst_no_prediction_status;
			let total_amount_prediction_status = this.state.total_amount_prediction_status;
			let igst_amount_prediction_status = this.state.igst_amount_prediction_status;
			let sgst_amount_prediction_status = this.state.sgst_amount_prediction_status;
			let cgst_amount_prediction_status = this.state.cgst_amount_prediction_status;
			let date_prediction_status = this.state.date_prediction_status;
			let merchant_name_prediction_status = this.state.merchant_name_prediction_status;
			let snapshot_number_prediction_status = this.state.snapshot_number_prediction_status;
			let merchant_id_prediction_status = this.state.merchant_id_prediction_status;
			let pan_number_prediction_status = this.state.pan_number_prediction_status;
			let snapshot_type_prediction_status = this.state.snapshot_type_prediction_status;
			let expense_prediction_status = this.state.expense_prediction_status;
			let category_id_prediction_status = this.state.category_id_prediction_status;
			let tds_percentage_prediction_status = this.state.tds_percentage_prediction_status;
			let tags_prediction_status = this.state.tags_prediction_status;
			let is_msme_prediction_status = this.state.is_msme_prediction_status;
			let is_entity_mismatch_prediction_status = this.state.is_entity_mismatch_prediction_status;
			//console.log(this.state.sgst_amount,this.state.cgst_amount, this.state.igst_amount)


			let payment_information = []
			if (this.state.payment_information) {
				payment_information = this.state.payment_information.map((payment_information, index) => {
					return { method: payment_information.method, id: payment_information.id, amount: payment_information.amount }
				})
			}

			let is_perquisite = 0;
			let is_gst_claim_eligible = 0;

			if (!!this.state.is_perquisite) {
				is_perquisite = this.state.is_perquisite ? 1 : 0;
			} else {
				is_perquisite = 0;
			}

			if (!!this.state.is_gst_claim_eligible) {
				is_gst_claim_eligible = this.state.is_gst_claim_eligible ? 1 : 0;
			} else {
				is_gst_claim_eligible = 0;
			}

			let approval_status = null;
			if (this.state.is_reimbursement) {
				if (this.CREATE_REIMBUSRMENT_SNAPSHOT && this.APPROVE_REIMBURSMENT_SNAPSHOT && this.state.entry_status == 2) {
					approval_status = 1
				} else if (this.state.accounting_status == 4 && this.APPROVE_REIMBURSMENT_SNAPSHOT && this.SNAPSHOT_LEDGER_ENTRIES_CODE) {
					approval_status = 1
				} else {
					approval_status = parseInt(this.state.approval_status)
				}
			}
			console.log("date type",this.state.date )
			//return
			let data = {
				"expense": this.state.expense ? 1 : 0,
				"merchant_type": this.state.merchant_type || this.state.snapshot_types.find(type => type.id == !this.state.type ? 8 : parseInt(this.state.type)).merchant_type,
				"type": !this.state.type ? 8 : parseInt(this.state.type),
				"date": !!this.state.date ? changeDateFormate(this.state.date) : changeDateFormate(new Date()),
				"merchant_update": merchant_update,
				"merchant_name": this.state.merchant_option.label || null,
				"merchant_id": this.state.merchant_option.value || 0,
				"gst_no": this.state.gst_no,
				"invoice_number": this.state.invoice_number,
				"total_amount": parseFloat(this.state.total_amount),
				"category_id": !!this.state.category_id ? parseInt(this.state.category_id) : null,
				"tags": this.state.selected_tags,
				"additional_information": this.state.additional_information,
				"payment_status": this.state.is_reimbursement ? 2 : this.state.payment_status ? 1 : 2,
				"payment_total": this.state.is_reimbursement ? 0 : this.state.payment_status ? parseFloat(this.state.payment_total) : 0,
				"payment_information": this.state.is_reimbursement ? [] : (this.state.payment_status) ? payment_information : [],
				"mode": this.state.is_smart && !!this.state.file_url ? 2 : 1,
				"file_url": this.state.file_url,
				"pan_number": this.state.pan_number,
				"gst_no_prediction_status": gst_no_prediction_status,
				"total_amount_prediction_status": total_amount_prediction_status,
				"igst_amount_prediction_status": igst_amount_prediction_status,
				"sgst_amount_prediction_status": sgst_amount_prediction_status,
				"cgst_amount_prediction_status": cgst_amount_prediction_status,
				"date_prediction_status": date_prediction_status,
				"merchant_name_prediction_status": merchant_name_prediction_status,
				"snapshot_number_prediction_status": snapshot_number_prediction_status,
				"merchant_id_prediction_status": merchant_id_prediction_status,
				"pan_number_prediction_status": pan_number_prediction_status,
				"snapshot_type_prediction_status": snapshot_type_prediction_status,
				"expense_prediction_status": expense_prediction_status,
				"category_id_prediction_status": category_id_prediction_status,
				"tds_percentage_prediction_status": tds_percentage_prediction_status,
				"tags_prediction_status": tags_prediction_status,
				"is_msme_prediction_status": is_msme_prediction_status,
				"is_entity_mismatch_prediction_status": is_entity_mismatch_prediction_status,
				"approval_comment": this.state.is_reimbursement ? this.state.approval_comment : null,
				"approval_status": approval_status,
				"approved_amount": this.state.is_reimbursement ? this.state.approved_amount ? parseFloat(this.state.approved_amount) : null : null,
				"new_tags": this.state.new_tags,
				"voucher_status": this.state.voucher_status && this.props.temp_snap_voucher_list.length > 0 ? 1 : 0,
				"is_duplicate": this.state.is_duplicate,
				"prediction_id": this.state.prediction_id,
				"source": this.state.xml_upload ? 7 : 3,
				"synced_by_user": this.state.accounting_status == 5 ? SessionManagement.getUserDetail().id : null,
				"entry_status": this.state.is_reimbursement ? (this.state.accounting_status == 4 && this.APPROVE_REIMBURSMENT_SNAPSHOT && this.SNAPSHOT_LEDGER_ENTRIES_CODE) ? 2 : this.state.approval_button == '1' ? 2 : this.state.entry_status : this.state.entry_status,
				"accounting_status": this.state.accounting_status,
				"is_msme": this.state.is_msme ? 1 : 0,
				"is_perquisite": is_perquisite,
				"is_same_invoice": this.state.is_same_invoice,
				"is_entity_mismatch": this.state.is_entity_mismatch ? 1 : 0,
				"is_reimbursement": this.state.is_reimbursement ? 1 : 0,
				"reimbursement_narration": this.state.reimbursement_narration,
				"request_id":this.state.request_id,
				"amount_round_off":this.props.amount_round_off,
				"is_star":0
				
			}
			
			//console.log("saveEvidence::::",data);
			//return;
			let is_valid_ledger = true;
			let billwise_allocation = 0;

			let vouchers = _.cloneDeep(this.props.temp_snap_voucher_list);
			vouchers = vouchers.map((voucher, index) => {
						console.log(":::::::voucher",voucher)
				//is_valid_ledger = !areGuidsUnique(voucher.ledgers);
			
				let temp_voucher = {
					"accounting_narration": voucher.accounting_narration,
					"parent_voucher_type_id":voucher.parent_voucher_type_id,
					"voucher_type_guid":voucher.voucher_type_guid,
					"voucher_type_name":voucher.voucher_type_name,
					"voucher_type_id":voucher.voucher_type_id,
					"voucher_creation_date": voucher.voucher_creation_date,
					"total_credit_amount": voucher.total_credit_amount,
					"total_debit_amount": voucher.total_debit_amount,
					"sync_datetime": changeDateTimeFormat(new Date()),
					"sync_status": 0,
					"ledgers": voucher.ledgers.map((ledger, index) => {
						let temp_ledger = {
							"amount": ledger.amount,
							"guid": ledger.guid,
							"name": ledger.name,
							"type": ledger.type,
							"ledger_id": ledger.ledger_id
						}

						let has_billwise = !!this.state.ledgerNameList.ledger_list.find((temp_ledger) => temp_ledger.guid == ledger.guid && temp_ledger.has_billwise == 1);

						if (has_billwise && (voucher.voucher_type == 4 || voucher.voucher_type == 5 || voucher.voucher_type == 6)) {
							billwise_allocation = billwise_allocation + 1;
						}


						if (ledger.cost_categories.length == 1 && !ledger.cost_categories[0].category_id) {
							temp_ledger.cost_categories = [];
							return temp_ledger;
						}
						temp_ledger.cost_categories = ledger.cost_categories.map((category, category_index) => {
							if (!category.category_id || !category.category_guid || !category.category_name) {
								is_valid_ledger = false;
							}
							let cost_center_total = 0;
							let temp_category = {
								"cost_category_id": category.category_id,
								"guid": category.category_guid,
								"name": category.category_name,
								"cost_centres": category.cost_centers.map((cost_center, cc_index) => {
									if (!cost_center.cost_center_name || !cost_center.cost_center_guid || !cost_center.cost_center_amount) {
										is_valid_ledger = false;
									}
									cost_center_total += parseFloat(cost_center.cost_center_amount);
									let tmp_cost_center = {
										"amount": cost_center.cost_center_amount,
										"cost_centre_id": cost_center.cost_center_id,
										"guid": cost_center.cost_center_guid,
										"name": cost_center.cost_center_name,
										"type": temp_ledger.type,
									}
									return tmp_cost_center;
								})
							}
							if(temp_ledger.amount != undefined){
								if(this.countDecimalDigits(temp_ledger.amount) == 1){
									cost_center_total = cost_center_total.toFixed(1)
								}else{
									cost_center_total = cost_center_total.toFixed(2)
								}
							}
							
							cost_center_total = this.props.amount_round_off ? roundOffValue(this.props.amount_round_off, cost_center_total) : cost_center_total
							
							if(temp_ledger.amount != undefined){
								if(this.countDecimalDigits(temp_ledger.amount) == 0 && (this.countDecimalDigits(cost_center_total) == 2 || this.countDecimalDigits(cost_center_total) == 1 )){
									cost_center_total = Math.floor(cost_center_total)
								}
							}
							

							console.log(":::::::",cost_center_total)
							if (cost_center_total != temp_ledger.amount) {
								is_valid_ledger = false;
							}
							return temp_category;
						})
						return temp_ledger;
					})
				}
				return temp_voucher;
			});

			data.inventory_mode = this.state.inventory_mode ? 1 : 0;
			let tdsStats = parseFloat(this.state.tds_amount) ? 1 : 0;
			if (this.state.inventory_mode) {
				let gstStus = ((this.state.sgst_amount && this.state.sgst_amount != 0) || (this.state.cgst_amount && this.state.cgst_amount != 0) || (this.state.igst_amount && this.state.igst_amount != 0)) ? 1 : 0;
				data.has_igst = gstStus == 0?0:this.state.is_igst ? 1 : 0;
				data.gst_status = gstStus;//((this.state.sgst_amount && this.state.sgst_amount != 0) || (this.state.cgst_amount && this.state.cgst_amount != 0) || (this.state.igst_amount && this.state.igst_amount != 0)) ? 1 : 0;
				data.sgst_amount = gstStus == 0?null:this.state.sgst_amount && this.state.sgst_amount != 0 ? parseFloat(this.state.sgst_amount) : null;
				data.cgst_amount =  gstStus == 0?null:this.state.cgst_amount && this.state.cgst_amount != 0 ? parseFloat(this.state.cgst_amount) : null;
				data.igst_amount =  gstStus == 0?null:this.state.igst_amount && this.state.igst_amount != 0 ? parseFloat(this.state.igst_amount) : null;
				data.gst_total_amount = this.state.gst_total_amount ? parseFloat(this.state.gst_total_amount) : null;
				data.tds_status = parseFloat(this.state.tds_amount) ? 1 : 0;
				data.tds_details = tdsStats?this.state.tds_details : [];
				data.tds_total_amount = parseFloat(this.state.tds_amount) || null;
		
				let inventory_details = this.props.temp_inventory_data;

				let ledgers = this.SNAPSHOT_LEDGER_ENTRIES_CODE ? inventory_details.ledgers : []

				ledgers = inventory_details.ledgers.filter((led, index1) => {
					if (parseFloat(led.amount) > 0 || led.rate || led.guid) {
						return led;
					}
				})
				//console.log(ledgers);
				let line_items = inventory_details.line_items.filter((item, index1) => {
					if (item.line_item_name || item.hsn_sac || item.rate || item.per || item.quantity || item.gst_rate || item.discount_rate || item.item_ledger_guid || item.common_item_ledger_guid) {
						return item;
					}
				})
				console.log("line_items payload",line_items)
				data.inventory_details = {
					"line_item_total": parseFloat(inventory_details.line_item_total) || null,
					"line_item_gst_total": parseFloat(inventory_details.line_item_gst_total) || null,
					"line_item_discount_total": parseFloat(inventory_details.line_item_discount_total) || null,
					"inventory_voucher_status": inventory_details.inventory_voucher_status ? 1 : 0,
					"is_common_item_ledger": inventory_details.is_common_item_ledger ? 1 : !inventory_details.inventory_voucher_status ? this.props.is_common_item_ledger : 0,
					"accounting_narration": inventory_details.inventory_voucher_status ? inventory_details.accounting_narration || null : null,
					"line_items": line_items.map((item, index) => {
						if (item.line_item_name || item.hsn_sac || item.rate || item.per || item.quantity || item.gst_rate || item.discount_rate || item.item_ledger_guid || item.common_item_ledger_guid) {

							let line_item_update = 1;

							let line_item = {
								"line_item_id": parseInt(item.line_item_id) >= 0 ? item.line_item_id : null,
								"line_item_name": item.line_item_name || "",
								"hsn_sac": item.hsn_sac || null,
								"rate": parseFloat(item.rate) || null,
								"per": item.per || null,
								"quantity": item.quantity || null,
								"gst_rate": parseFloat(item.gst_rate) || null,
								"discount_rate": parseFloat(item.discount_rate) || null,
								"amount": parseFloat(item.amount) || null,
								"item_ledger_guid":inventory_details.inventory_voucher_status ? item.item_ledger_guid || null : null,
								"item_ledger_id":inventory_details.inventory_voucher_status ? item.item_ledger_id || null : null,
								"item_ledger_name":inventory_details.inventory_voucher_status ? item.item_ledger_name || "" : "",
								"common_item_ledger_guid": item.common_item_ledger_guid || null,
								"common_item_ledger_id": item.common_item_ledger_id || null,
								"common_item_ledger_name": item.common_item_ledger_name || "",
								"line_item_update": line_item_update,
								"line_item_id_prediction_status":item.line_item_id_prediction_status || 0,
								"line_item_name_prediction_status":item.line_item_name_prediction_status || 0,
								"hsn_sac_prediction_status":item.hsn_sac_prediction_status || 0,
								"rate_prediction_status": item.rate_prediction_status || 0,
								"per_prediction_status":item.per_prediction_status || 0,
								"quantity_prediction_status":item.quantity_prediction_status || 0,
								"gst_rate_prediction_status":item.gst_rate_prediction_status || 0,
								"discount_rate_prediction_status":item.discount_rate_prediction_status || 0,
								"amount_prediction_status":item.amount_prediction_status || 0,
								"item_ledger_guid_prediction_status": inventory_details.inventory_voucher_status ? item.item_ledger_guid_prediction_status || 0 : 0,
								"item_ledger_id_prediction_status":  inventory_details.inventory_voucher_status ? item.item_ledger_id_prediction_status || 0 : 0,
								"item_ledger_name_prediction_status": inventory_details.inventory_voucher_status ? item.item_ledger_name_prediction_status || 0 : 0,
								"common_item_ledger_guid_prediction_status":item.common_item_ledger_guid_prediction_status || 0,
								"common_item_ledger_id_prediction_status":item.common_item_ledger_id_prediction_status || 0,
								"common_item_ledger_name_prediction_status":item.common_item_ledger_name_prediction_status || 0,


							}
							return line_item;
						}
					}) || [],
					"ledgers": ledgers.map((led, index) => {
						if (led.amount || led.rate || led.name) {
							let ledger = {
								"amount": parseFloat(led.amount),
								"rate": parseFloat(led.rate),
								"guid": led.guid,
								"ledger_id": led.ledger_id,
								"name": led.name,
							}
							return ledger;
						}
					}),
					"sync_datetime": inventory_details.inventory_voucher_status ? changeDateTimeFormat(new Date()) : null,
					"sync_error": null,
					"sync_status": 0,
					"voucher_total_amount": inventory_details.inventory_voucher_status ? parseFloat(inventory_details.voucher_total_amount) || null : null,
					"voucher_creation_date": inventory_details.voucher_creation_date ? changeDateFormate(new Date(inventory_details.voucher_creation_date)) : null,
					"party_ledger_guid": inventory_details.inventory_voucher_status ? inventory_details.party_ledger_guid || null : null,
					"party_ledger_id": inventory_details.inventory_voucher_status ? inventory_details.party_ledger_id || null : null,
					"party_ledger_name": inventory_details.inventory_voucher_status ? inventory_details.party_ledger_name || null : null,
					"common_item_ledger_guid": inventory_details.inventory_voucher_status ? inventory_details.common_item_ledger_guid || null : null,
					"common_item_ledger_id": inventory_details.inventory_voucher_status ? inventory_details.common_item_ledger_id || null : null,
					"common_item_ledger_name": inventory_details.inventory_voucher_status ? inventory_details.common_item_ledger_name || null : null,
					"party_ledger_guid_prediction_status":inventory_details.inventory_voucher_status ? inventory_details.party_ledger_guid_prediction_status || 0 : 0,
					"party_ledger_id_prediction_status":inventory_details.inventory_voucher_status ? inventory_details.party_ledger_id_prediction_status || 0 : 0,
					"party_ledger_name_prediction_status":inventory_details.inventory_voucher_status ? inventory_details.party_ledger_name_prediction_status || 0 : 0,
					"common_item_ledger_guid_prediction_status":inventory_details.inventory_voucher_status ? inventory_details.common_item_ledger_guid_prediction_status || 0 : 0,
					"common_item_ledger_id_prediction_status":inventory_details.inventory_voucher_status ? inventory_details.common_item_ledger_id_prediction_status || 0 : 0,
					"common_item_ledger_name_prediction_status":inventory_details.inventory_voucher_status ? inventory_details.common_item_ledger_name_prediction_status || 0 : 0,
					"parent_voucher_type_id":inventory_details.inventory_voucher_status ? inventory_details.parent_voucher_type_id || null : null ,
					"voucher_type_guid": inventory_details.inventory_voucher_status ?inventory_details.voucher_type_guid || null : null,
					"voucher_type_name": inventory_details.inventory_voucher_status ? inventory_details.voucher_type_name || null : null,
					"voucher_type_id": inventory_details.inventory_voucher_status ? inventory_details.voucher_type_id || null : null,
				
				}


				//SOC:: gst and hsn sac summary
				let gst_summary = [];
				let hsn_summary = [];
				let gst_details = [];
				let hsn_details = [];

				line_items.forEach((item, index) => {
					if (item.gst_rate) {
						if (!gst_summary[item.gst_rate]) {
							gst_summary[item.gst_rate] = 0;
						}
						gst_summary[item.gst_rate] += parseFloat(item.amount) || 0;
					}

					if (item.hsn_sac) {
						let hsn_sac = item.hsn_sac;
						if (!hsn_summary[item.hsn_sac]) {

							hsn_summary[hsn_sac] = {};
							hsn_summary[hsn_sac].amt = 0;
						}

						hsn_summary[hsn_sac].amt += parseFloat(item.amount) || 0;

						if (!hsn_summary[hsn_sac].cgst_rate)
							hsn_summary[hsn_sac].cgst_rate = this.state.is_igst ? 0 : item.gst_rate * 0.5;

						if (!hsn_summary[hsn_sac].sgst_rate)
							hsn_summary[hsn_sac].sgst_rate = this.state.is_igst ? 0 : item.gst_rate * 0.5;

						if (!hsn_summary[hsn_sac].igst_rate)
							hsn_summary[hsn_sac].igst_rate = this.state.is_igst ? item.gst_rate * 1 : 0;

						hsn_summary[hsn_sac].hsn_sac = item.hsn_sac;
					}
				})

				let numeric_hsn_array = new Array();
				for (var items in hsn_summary) {
					numeric_hsn_array.push(hsn_summary[items]);
				}

				gst_summary.forEach((amt, index) => {
					let cgst_amount = !this.state.is_igst ? amt * 0.5 : null;
					let sgst_amount = !this.state.is_igst ? amt * 0.5 : null;
					let igst_amount = this.state.is_igst ? amt : null;

					gst_details.push({
						gst_rate: index,
						gst_amount: parseFloat(parseFloat(amt).toFixed(2)),
						cgst_amount: this.state.is_igst ? null : parseFloat(parseFloat(cgst_amount).toFixed(2)),
						sgst_amount: this.state.is_igst ? null : parseFloat(parseFloat(sgst_amount).toFixed(2)),
						igst_amount: !this.state.is_igst ? null : parseFloat(parseFloat(igst_amount).toFixed(2)),
					})
				})

				numeric_hsn_array.forEach((data, index) => {
					let gst_amount = 0;
					let cgst_amount = !this.state.is_igst ? data.amt * data.cgst_rate / 100 : null;
					let sgst_amount = !this.state.is_igst ? data.amt * data.sgst_rate / 100 : null;
					let igst_amount = this.state.is_igst ? data.amt * data.igst_rate / 100 : null;

					gst_amount += this.state.is_igst ? igst_amount || 0 : (cgst_amount || 0) + (sgst_amount || 0),

						hsn_details.push({
							"cgst_rate": data.cgst_rate || null,
							"sgst_rate": data.sgst_rate || null,
							"igst_rate": data.igst_rate || null,
							"gst_amount": parseFloat(parseFloat(gst_amount).toFixed(2)),
							"cgst_amount": cgst_amount ? parseFloat(parseFloat(cgst_amount).toFixed(2)) : null,
							"sgst_amount": sgst_amount ? parseFloat(parseFloat(sgst_amount).toFixed(2)) : null,
							"igst_amount": igst_amount ? parseFloat(parseFloat(igst_amount).toFixed(2)) : null,
							"hsn_sac": data.hsn_sac,
							"basic_amount": parseFloat(parseFloat(data.amt).toFixed(2)),
						})
				})

				let gst_total = 0, total_cgst_amount = 0, total_sgst_amount = 0, total_igst_amount = 0;

				gst_details.map((data, index) => {
					total_cgst_amount += parseFloat(parseFloat(data.cgst_amount).toFixed(2)) || 0;
					total_sgst_amount += parseFloat(parseFloat(data.sgst_amount).toFixed(2)) || 0;
					total_igst_amount += parseFloat(parseFloat(data.igst_amount).toFixed(2)) || 0;
				})

				gst_total = parseFloat(parseFloat(total_cgst_amount + total_sgst_amount + total_igst_amount).toFixed(2));


				let hsn_sac_basic_total = 0, hsn_sac_cgst_total = 0, hsn_sac_sgst_total = 0, hsn_sac_igst_total = 0, hsn_sac_gst_total = 0;

				hsn_details.map((data, index) => {
					hsn_sac_basic_total += parseFloat(parseFloat(data.basic_amount).toFixed(2)) || 0;
					hsn_sac_cgst_total += parseFloat(parseFloat(data.cgst_amount).toFixed(2)) || 0;
					hsn_sac_sgst_total += parseFloat(parseFloat(data.sgst_amount).toFixed(2)) || 0;
					hsn_sac_igst_total += parseFloat(parseFloat(data.igst_amount).toFixed(2)) || 0;
					hsn_sac_gst_total += parseFloat(parseFloat(data.gst_amount).toFixed(2)) || 0;
				})

				data.gst_summary = gst_total > 0 ? {
					"cgst_total": this.state.is_igst ? null : total_cgst_amount,
					"sgst_total": this.state.is_igst ? null : total_sgst_amount,
					"igst_total": this.state.is_igst ? total_igst_amount : null,
					"gst_total": gst_total,
					"gst_details": gst_details
				} : null;

				data.hsn_sac_summary = hsn_sac_gst_total > 0 ? {
					"basic_total": hsn_sac_basic_total,
					"cgst_total": this.state.is_igst ? null : hsn_sac_cgst_total,
					"sgst_total": this.state.is_igst ? null : hsn_sac_sgst_total,
					"igst_total": this.state.is_igst ? hsn_sac_igst_total : null,
					"gst_total": hsn_sac_gst_total,
					"hsn_sac_details": hsn_details
				} : null;
				//EOC:: gst and hsn sac summary

				// data.gst_summary = inventory_details.gst_summary || null;
				// data.hsn_sac_summary = inventory_details.hsn_sac_summary || null;

				if (inventory_details.inventory_voucher_status && (this.state.merchant_option.party_ledger_guid != inventory_details.party_ledger_guid || (inventory_details.is_common_item_ledger && this.state.type == 1 && this.state.merchant_option.sales_ledger_guid != inventory_details.common_item_ledger_guid) || (inventory_details.is_common_item_ledger && this.state.type == 2 && this.state.merchant_option.purchase_ledger_guid != this.state.common_item_ledger_guid))) {
					data.merchant_update = 1;
				}

			} else {
				data.inventory_details = null
				data.gst_summary = null
				data.hsn_sac_summary = null

				let gstStus = this.state.gst_status ? 1 : 0;
				data.gst_status = gstStus;
				data.sgst_amount = gstStus == 0?null:this.state.gst_status ? this.state.sgst_amount && this.state.sgst_amount != 0 ? parseFloat(this.state.sgst_amount) : null : null;
				data.cgst_amount = gstStus == 0?null:this.state.gst_status ? this.state.cgst_amount && this.state.cgst_amount != 0 ? parseFloat(this.state.cgst_amount) : null : null;
				data.igst_amount = gstStus == 0?null:this.state.gst_status ? this.state.igst_amount && this.state.igst_amount != 0 ? parseFloat(this.state.igst_amount) : null : null;
				data.gst_total_amount = this.state.gst_status ? this.state.gst_total_amount ? parseFloat(this.state.gst_total_amount) : null : null;
				data.tds_status = this.state.tds_status ? 1 : 0;
				data.tds_details = this.state.tds_status ? this.state.tds_details : [];
				data.tds_total_amount = this.state.tds_status ? parseFloat(this.state.tds_amount) : null;
				data.has_igst = gstStus == 0?0:this.state.gst_status ? this.state.is_igst ? 1 : 0 : 0;
			}
			data.is_gst_override=data.gst_status == 0?0:this.state.is_gst_override ? 1 : 0;
			data.is_gst_claim_eligible= data.gst_status == 0?0:is_gst_claim_eligible;

			if (!is_valid_ledger) {
				this.setState({ is_valid_ledger: false, save_and_redirect: false, next_route: false, save_button_clicked: false })
				this.props.dispatch(setAppilcationLoder(false));
				toast.warning('Ledger data is missing')
				return;
			}

			data.vouchers = this.SNAPSHOT_LEDGER_ENTRIES_CODE ? vouchers : [];
			console.log(data.vouchers)
			data.amount_round_off = this.props.amount_round_off;
			let errors = validateCreateEvidence(data);
			//console.log(errors);

			if (Object.keys(errors).length === 0) {
				//console.log(data);
				//return
				//check valid voucher
				if (!this.removeEmptyVouchersAndLedgers()) {
					toast.warning('Ledger data is missing')
					this.setState((prevState, props) => ({
						submit_click: prevState.submit_click + 1
					}));
					this.setState({ is_valid_ledger: false, save_and_redirect: false, next_route: false })
					this.props.dispatch(setAppilcationLoder(false));
					return true;
				}
				if (!this.validateVouchers()) {
					this.setState({ is_valid_voucher: false, save_and_redirect: false, next_route: false })
					this.props.dispatch(setAppilcationLoder(false));
					return true;
				}

				//checking for correct merchant name
				let merchant_list = this.state.all_merchant_list;
				let merchant_data = merchant_list.filter(merchant => (merchant.pan_number == data.pan_number && merchant.gst_no == data.gst_no) || (merchant.pan_number == data.pan_number && data.gst_no == ""));

				if (merchant_data.length && !!data.pan_number) {
					this.props.dispatch(setAppilcationLoder(false));
					if (merchant_data[0].pan_number == data.pan_number && data.merchant_id != merchant_data[0].value) {
						if (await confirm({
							confirmation: 'Duplicate PAN detected. Click Yes if you would like to select the current supplier.'
						})) {
							// this.props.dispatch(setAppilcationLoder(true));
							this.setState({
								merchant_option: { label: merchant_data[0].label, value: merchant_data[0].value, gst_no: merchant_data[0].gst_no, pan_number: merchant_data[0].pan_number, is_msme: merchant_data[0].is_msme, is_perquisite: merchant_data[0].is_perquisite || 0, is_gst_claim_eligible: merchant_data[0].is_gst_claim_eligible || 0, snapshot_type: merchant_data[0].snapshot_type },
								// errors: { merchant_name: 'supplier name corrected for Gst no.' }
							}, () => {
							});
							data.merchant_name = merchant_data[0].label
							data.merchant_id = merchant_data[0].value;
							data.merchant_update = 1;
							data.is_msme = merchant_data[0].is_msme;
							data.is_perquisite = merchant_data[0].is_perquisite || 0;
							data.is_gst_claim_eligible = merchant_data[0].is_gst_claim_eligible || 0;
							//data.snapshot_type = merchant_data[0].snapshot_type
						} else {
							console.log("else")
							this.setState({
								errors: { gst_no: 'Duplicate GST/PAN detected.', save_button_clicked: false }
							}, () => {
							});
							return true;
						}

					}
				}

				if (first_time) {
					console.log("first time")
					//console.log(this.state.merchant_option)
					if (this.state.merchant_option.value && !this.state.is_reimbursement) {
						if (this.state.merchant_option.snapshot_type != null && this.state.merchant_option.snapshot_type != this.state.type) {
							this.props.dispatch(setAppilcationLoder(false));
							if (!await confirm({
								confirmation: "Do you want to update snapshot type for this party?"
							})) {
								data.snapshot_type_update = 0;
								this.setState({ snapshot_type_update: 0 })
								this.props.dispatch(setAppilcationLoder(true));
								//return true;
							} else {
								data.snapshot_type_update = 1;
								this.setState({ snapshot_type_update: 1 })
								this.props.dispatch(setAppilcationLoder(true));
							}
						} else {
							data.snapshot_type_update = 0;
							this.setState({ snapshot_type_update: 0 })
						}
					} else {
						data.snapshot_type_update = 0;
						this.setState({ snapshot_type_update: 0 })
					}

					if (this.state.merchant_option.value && !this.state.merchant_option.snapshot_type && !this.state.is_reimbursement) {
						data.snapshot_type_update = 1;
						this.setState({ snapshot_type_update: 1 })
					}
				} else {
					data.snapshot_type_update = this.state.snapshot_type_update;
				}

				if (data.accounting_status == 5 && data.vouchers.length > 0 && first_time) {
					this.props.dispatch(setAppilcationLoder(false));
					if (!await confirm({
						confirmation: "This action will save the record but vouchers if any will not be automatically synced with accounting software, do you want to continue?"
					})) {
						this.setState({ save_button_clicked: false })
						return true
					} else {
						this.props.dispatch(setAppilcationLoder(true));
					}
				}

				//START -  To show GST related error
				if (first_time) {
					if (!!data.gst_no && !!this.props.entity_gst_no) {
						let oldAccStatus = this.state.old_data.accounting_status
						let newAccStatus = data.accounting_status
						if (((newAccStatus == 3) && (oldAccStatus == 3 || oldAccStatus == 4 || oldAccStatus == 5)) || ((newAccStatus == 4 || newAccStatus == 5) && (oldAccStatus == 3))) {
							let res = await this.showGstError(data.gst_no, data.cgst_amount, data.sgst_amount, data.igst_amount)
							if (res) {
								this.setState({ save_button_clicked: false })
								return
							}
						}
					}
				}

				if (billwise_allocation > 0) {
					this.props.dispatch(setAppilcationLoder(false));
					await confirm({
						confirmation: "As you have selected bill-wise enabled ledger, bill-wise allocation will be done on background, according to invoice no and ledger amount.",
						options: { type: "alert" }
					})
				}

				let inventory_details_ledgers_length = 0;
				if (this.state.inventory_mode) {
					inventory_details_ledgers_length = data.inventory_details.ledgers.length;
				}

				if (data.accounting_status != 3 && !data.vouchers.length && inventory_details_ledgers_length == 0 && first_time) {
					this.props.dispatch(setAppilcationLoder(false));
					if (await confirm({
						confirmation: "No ledger entries specified. Do you want to proceed?"
					})) {
						this.props.dispatch(setAppilcationLoder(true));
					} else {
						this.setState({ save_button_clicked: false })
						return true;
					}
				}

				console.log(data)
				//return;

				this.props.dispatch(setAppilcationLoder(true));
				SnapshotService.createSnapshot(data).then(
					async (resp_data) => {
						this.props.dispatch(setAppilcationLoder(false));
						this.setState({
							loading: false,
							errors: {}
						});

						this.imageUploadChildRef.closePdfPopup();
						this.imageUploadChildRef.closeUploadedPdfPopup();

						if (resp_data.response_code == 200) {

							// send log code
							sendLog({ module_type: 1, module_id: resp_data.result.id, activity: ['Created New Snapshot'] })
							// send log code

							this.setState({
								line_items_data : [],
								prediction_party_ledger_guid:null,
								prediction_purchase_ledger_guid:null,
								prediction_sales_ledger_guid : null,
								inventory_party_ledger_id:null,
								inventory_party_ledger_name:null,
								inventory_common_item_ledger_guid:null,
								inventory_common_item_ledger_id:null,
								inventory_common_item_ledger_name:null,
								inventory_party_ledger_guid_prediction:0,
								inventory_party_ledger_id_prediction:0,
								inventory_party_ledger_name_prediction:0,
								inventory_common_item_ledger_guid_prediction:0,
								inventory_common_item_ledger_id_prediction:0,
								inventory_common_item_ledger_name_prediction:0,
								inventory_voucher_creation_date:"",
								inventory_voucher_creation_date_prediction:0,
								combined_inventory_array : [],
								auto_ready:false,
								auto_accounted:false,
								auto_ready_accounted:false,
								voucher_type_guid:"",
								voucher_type_id:"",
								voucher_type_name:"",
								parent_voucher_type_id:"",
								prediction_voucher_type_guid:null,
								vaild_voucher:false,
								is_base_amt_changed: false,
								xml_upload: false,
							})
							//Set merchant list received from api
							let merchants = resp_data.result.merchants.map(merchant => {
								return {
									value: merchant.id,
									label: merchant.name,
									gst_no: merchant.gst_no || "",
									pan_number: merchant.pan_number || "",
									tds_percentage: merchant.tds_percentage,
									snapshot_type: merchant.snapshot_type,
									is_gst_claim_eligible: merchant.is_gst_claim_eligible,
									is_msme: merchant.is_msme,
									is_perquisite: merchant.is_perquisite,
									type: merchant.type,
									category_id: merchant.category_id,
									tags: merchant.tags,
									party_ledger_guid: merchant.party_ledger_guid || "",
									purchase_ledger_guid: merchant.purchase_ledger_guid || "",
									sales_ledger_guid: merchant.sales_ledger_guid || "",
									purchase_voucher_type_guid : merchant.purchase_voucher_type_guid || "",
									sales_voucher_type_guid : merchant.sales_voucher_type_guid || ""
								}
							})

							this.setState({ all_merchant_list: merchants, all_old_merchant_list: _.cloneDeep(merchants) }, () => {
								let merchant_option = this.state.all_merchant_list.find(merchant => merchant.value == resp_data.result.merchant_id);
								if (resp_data.result.merchant_id) {
									this.setState({ merchant_option })
								} else {
									this.setState({ merchant_option: { value: "", label: "" } })
								}
							})

							this.setState({ is_smart: this.state.is_smart && !!this.state.file_url, is_new: false, new_value: null, save_button_clicked: false });

							let category_index = this.state.categories.findIndex(cat => cat.id == this.state.category_id);
							let tags = this.state.listed_tags.filter((tag) => this.state.selected_tags.indexOf(tag.id) != -1);

							let new_tags = resp_data.result.new_tags;
							let selected_tags = [...this.state.selected_tags, ...new_tags.map(tag => tag.id)];
							let listed_tags = [...this.state.listed_tags, ...new_tags];

							let type_index = this.state.snapshot_types.findIndex(type => type.id == data.type);

							let evidence = {
								attachment_status: data.file_url,
								category: category_index != -1 ? this.state.categories[category_index].name : "", // want from api
								date: data.date,
								expense: data.expense,
								gst_status: data.gst_status,
								id: resp_data.result.id,
								is_star: 0,
								message_status: 0,
								name: data.merchant_name,
								payment_information: [], // want from api
								payment_status: data.payment_status,
								status: data.accounting_status == 3 ? data.entry_status : data.accounting_status,
								tags: tags.map((tag) => tag.name),
								task_status: 0,
								tds_status: data.tds_status,
								total_amount: data.total_amount ? data.total_amount : "",
								type: this.state.snapshot_types[type_index].name || "",
								is_duplicate: resp_data.result.is_duplicate,
								is_linked: 0,
								pending_amount: data.pending_amount,
								accounting_status: data.accounting_status,
								entry_status: data.entry_status,
								new_tags: new_tags,
								categories: this.state.categories,
								created_at: displayDateFormate(new Date()),
								approval_status: data.approval_status,
								is_reimbursement: this.state.is_reimbursement
							}

							//console.log(evidence)
							this.setState({
								snapshot_id: resp_data.result.id,
								snapshot_no: resp_data.result.snapshot_no,
								report_url_pdf: resp_data.result.report_url_pdf,
								is_dirty: false,
								is_valid_ledger: true,
								system_suggested_status: "",
								selected_tags: selected_tags,
								listed_tags: listed_tags,
								new_tags: [],
								is_leder_recommended: 0,
								hashtag: "",
								is_duplicate: 0,
								is_same_invoice: 0,
								prediction_id: resp_data.result.prediction_id,
								line_items_list: resp_data.result.line_item_master,
								request_id :resp_data.result.request_id

							}, () => {
								if (StorageData.getTransactionId()) {
									//toast.info('Click "Back" button to go back to Money Link.')
									toast.success('Snapshot created. Click "BACK" button to go back to Money Link');
									this.setState({
										money_link_evidence: {
											snapshot_status: data.snapshot_status,
											id: resp_data.result.id,
											is_update: false
										}
									})

								} else {
									toast.success('Snapshot created');

									//console.log(this.state.save_and_redirect)
									if (this.state.save_and_redirect) {
										this.props.newSnapshot(evidence, 0, false)
										if (this.state.communication_bar_tasks_dirty) {
											this.comment_section.saveData('tasks', this.state.next_route);
										} else if (this.state.communication_bar_msgs_dirty) {
											this.comment_section.saveData('msgs', this.state.next_route);
										} else if (this.state.communication_bar_private_msgs_dirty) {
											this.comment_section.saveData('private_msgs', this.state.next_route);
										} else if (this.state.communication_bar_notes_dirty) {
											this.comment_section.saveData('notes', this.state.next_route);
										} else {
											this.props.history.push(this.state.next_route)
										}
									} else {

										this.props.newSnapshot(evidence, 0, this.state.save_and_next)
										// if (!this.state.save_and_next) {
										// 	this.props.history.push('/snapshot/evidence/create/' + resp_data.result.id)
										// }
									}
								}
							});
						} else if (resp_data.response_code == 400) {
							if (resp_data.reason.is_duplicate) {
								if (await confirm({
									confirmation: 'The snapshot is a duplicate of another snapshot. Do you still want to save?'
								})) {
									this.setState({
										is_duplicate: 1
									}, () => this.saveEvidence(e, false));
								}
								this.setState({ save_button_clicked: false })
								return true;
							}

							if (!!resp_data.reason.is_same_invoice) {
								if (await confirm({
									confirmation: 'Same invoice number and same party already exist. Do you still want to save?'
								})) {
									this.setState({
										is_same_invoice: 1
									}, () => this.saveEvidence(e, false));
								}
								this.setState({ save_button_clicked: false })
								return true;
							}

							if (!!resp_data.reason.new_tags) {
								this.setState({ errors: { new_tags: resp_data.reason.new_tags } })
								return true;
							}

							toast.error(resp_data.message);
							this.setState({ errors: resp_data.reason, save_and_redirect: false, next_route: false });
							if (!!resp_data.reason.merchants) {
								//console.log('in')
								let all_merchant_list = resp_data.reason.merchants.map(supp => {
									return { value: supp.id, label: supp.name, gst_no: supp.gst_no || "", pan_number: supp.pan_number || "", gst_no: supp.gst_no || "", tds_percentage: supp.tds_percentage || "", is_msme: supp.is_msme, is_perquisite: supp.is_perquisite, is_gst_claim_eligible: supp.is_gst_claim_eligible, snapshot_type: supp.snapshot_type, type: supp.type }
								})

								this.setState({ all_merchant_list })
							}
						} else {
							toast.error(resp_data.message);
							this.setState({ save_and_redirect: false, next_route: false, save_button_clicked: false });
						}
					});
			} else {
				this.props.dispatch(setAppilcationLoder(false));
				let errors_result = { ...errors, snapshot_status: 'Please fill the mandatory details.' };
				this.setState({
					loading: false,
					errors: errors_result,
					save_and_redirect: false,
					next_route: false,
					save_button_clicked: false
				}, () => {
				});
				toast.error("Wrong input provided")
			}

		} else {
			this.setState({ save_button_clicked: false })
		}
	}

	redirectToTaskManager = () => {
		this.props.history.push('/task-manager/create/' + StorageData.getTaskManagerId())
		// StorageData.deleteTaskManagerId()
	}

	redirectToMoneyLink = () => {
		let { snapshot_status, id, is_update } = this.state.money_link_evidence
		if (typeof snapshot_status === 'undefined') {
			this.props.history.push('/money-link/' + StorageData.getTransactionId())
		}
		// let payment_info_changed = true
		// this.state.payment_information.forEach(info => {
		// 	if (info.bank_name === "") {
		// 		payment_info_changed = false
		// 	}
		// })
		if (snapshot_status == 1 || snapshot_status == 3) {
			let evidence = {
				date: changeDateFormate(this.state.date),
				gst_status: this.state.gst_status ? 1 : 0,
				id: id,
				module: 1,
				status: snapshot_status,
				total: parseFloat(this.state.total_amount),
				vendor: this.state.merchant_name
			}
			//console.log("this.props.temp_linked_evidences", this.props.temp_linked_evidences)
			let temp_linked_evidences = [...this.props.temp_linked_evidences];
			if (is_update) {
				let index = temp_linked_evidences.findIndex((evidence) => (evidence.id || evidence.mod_id) == id)
				if (index !== -1) {
					temp_linked_evidences[index] = evidence
				} else {
					temp_linked_evidences.push(evidence)
				}
			} else {
				temp_linked_evidences.push(evidence)
			}
			this.props.dispatch(setTempLinkedEvidenceList(temp_linked_evidences));
			this.props.history.push('/money-link/' + StorageData.getTransactionId())
		} else if (snapshot_status == 2) {
			this.setState({ toggleBackConfirm: true })
		}


	}

	backToMoneyLink = (is_confirm) => {
		if (is_confirm) {
			let temp_linked_evidences = [...this.props.temp_linked_evidences];
			let index = temp_linked_evidences.findIndex((evidence) => (evidence.id || evidence.mod_id) == this.state.money_link_evidence.id)
			if (index !== -1) {
				temp_linked_evidences.splice(index, 1)
				this.props.dispatch(setTempLinkedEvidenceList(temp_linked_evidences));
			}
			//console.log("temp_linked_evidences---", temp_linked_evidences)
			this.props.history.push('/money-link/' + StorageData.getTransactionId())
		} else {
			this.setState({ toggleBackConfirm: false })
		}
	}

	leaveTaskManagerFlow = (is_confirm) => {
		this.setState({ show_leave_task_manager_popup: false })
		if (is_confirm) {
			this.props.history.push('/task-manager/create/' + StorageData.getTaskManagerId())	
			} else {
			StorageData.deleteTaskManagerId()
			this.props.history.push(`/snapshot/evidence`)
		}
	}


	//old function

	// leaveMoneyLinkFlow = (is_confirm) => {
	// 	if (is_confirm) {
	// 		StorageData.deleteTransactionId()
	// 		this.props.history.push(`/snapshot/${this.props.match.params.evidence_type}`)
	// 	} else {
	// 		this.setState({ show_leave_money_link_popup: false })
	// 	}
	// }

	// new function for bug BU1-I153
	leaveMoneyLinkFlow = (is_confirm) => {
		this.setState({ show_leave_money_link_popup: false })
		if (is_confirm) {
			this.props.history.push('/money-link/' + StorageData.getTransactionId())
		} else {
			StorageData.deleteTransactionId()
			this.props.history.push(`/snapshot/evidence`)
		}
	}

	selectSMS = (e, sms) => {
		let list = this.state.selected_sms_list
		if (e.target.checked) {
			list.push(sms.id)
		} else {
			list = list.filter((selected_sms_id, index) => {
				if (selected_sms_id !== sms.id)
					return sms
			})
		}

		this.setState({ selected_sms_list: list, is_dirty: true });
	}

	getSnapshotDetail = (snapshot_id) => {
		this.props.dispatch(setTempVoucherList([]));
		this.props.dispatch(setAppilcationLoder(true));
		SnapshotService.getSnapshotDetail(snapshot_id).then(
			async (data) => {
				if (data.response_code == 200) {
					data.result.id = snapshot_id;
					this.props.updateSnapshotDetail(data.result);
					let remove_cost_category = [];
					let remove_cost_center = [];

					let temp_snap_voucher_list = data.result.vouchers.map((voucher, index) => {

						let temp_voucher = {
							"accounting_narration": voucher.accounting_narration,
							"parent_voucher_type_id":voucher.parent_voucher_type_id,
							"voucher_type_guid":voucher.voucher_type_guid,
							"voucher_type_name":voucher.voucher_type_name,
							"voucher_type_id":voucher.voucher_type_id,
							"voucher_id": voucher.voucher_id,
							"voucher_creation_date": voucher.voucher_creation_date,
							"total_credit_amount": voucher.total_credit_amount,
							"total_debit_amount": voucher.total_debit_amount,
							"sync_datetime": voucher.sync_datetime,
							"sync_status": voucher.sync_status,
							"sync_error": voucher.sync_error,
							"ledgers": voucher.ledgers.map((ledger, ledger_index) => {
								let empty_cost_category = [{
									"category_guid": "",
									"category_id": 0,
									"category_name": "",
									"transaction_voucher_ledger_cost_category_id": 0,
									"cost_centers": [{
										"cost_center_guid": "",
										"cost_center_id": 0,
										"cost_center_name": "",
										"cost_center_amount": "",
										"transaction_voucher_ledger_cost_category_cost_centre_id": 0
									}]
								}];
								let temp_ledger = {
									"amount": ledger.amount,
									"guid": ledger.guid,
									"name": ledger.name,
									"type": ledger.type,
									"ledger_id": ledger.ledger_id,
									"transaction_voucher_ledger_id": ledger.voucher_ledger_id,
									"voucher_ledger_id": ledger.voucher_ledger_id
								}

								let is_cost_category_enable = !!this.state.ledgerNameList.ledger_list.find((temp_ledger) => temp_ledger.guid == ledger.guid && temp_ledger.has_cost_centre == 1);
								if (!ledger.cost_categories.length && is_cost_category_enable) {
									temp_ledger.cost_categories = empty_cost_category;
								} else {
									temp_ledger.cost_categories = ledger.cost_categories.map((category, category_index) => {
										let temp_category = {
											"category_id": category.cost_category_id,
											"category_guid": category.guid,
											"category_name": category.name,
											"transaction_voucher_ledger_cost_category_id": category.transaction_voucher_ledger_cost_category_id,
											"voucher_ledger_cost_category_id": category.voucher_ledger_cost_category_id,
											"cost_centers": category.cost_centres.map((cost_center, cc_index) => {
												let tmp_cost_center = {
													"cost_center_amount": cost_center.amount,
													"cost_center_id": cost_center.cost_centre_id,
													"cost_center_guid": cost_center.guid,
													"cost_center_name": cost_center.name,
													"transaction_voucher_ledger_cost_category_cost_centre_id": cost_center.transaction_voucher_ledger_cost_category_cost_centre_id,
													"voucher_ledger_cost_category_cost_centre_id": cost_center.voucher_ledger_cost_category_cost_centre_id
												}

												remove_cost_center.push(cost_center.voucher_ledger_cost_category_cost_centre_id)
												return tmp_cost_center;
											})
										}
										remove_cost_category.push(category.voucher_ledger_cost_category_id);
										return temp_category;
									})
								}

								return temp_ledger;
							})
						}
						return temp_voucher;
					});
					// console.log("temp_snap_voucher_list", temp_snap_voucher_list)
					this.props.dispatch(setTempVoucherList(temp_snap_voucher_list))
					this.props.dispatch(setPaymentInformation(data.result.payment_information))

					if (!this.state.snapshot_id ||
						(!!this.state.snapshot_id && this.MANAGE_OTHER_USER_SNAPSHOT) ||
						(!!this.state.snapshot_id && (this.CREATE_BUSSINESS_SNAPSHOT || this.CREATE_REIMBUSRMENT_SNAPSHOT) && data.result.created_by == SessionManagement.getUserDetail().id)
						|| this.SNAPSHOT_LEDGER_ENTRIES_CODE
					) {
						this.VIEW_ONLY_SNAPSHOT = false;
					} else {
						this.VIEW_ONLY_SNAPSHOT = true;
					}

					//console.log(this.state.all_merchant_list)
					let merchant_option = {}
					if (data.result.merchant_id) {
						let merchantFound = this.state.all_merchant_list.findIndex(merchant => merchant.value === data.result.merchant_id);
						merchant_option = { label: data.result.merchant_name, value: data.result.merchant_id }

						if (merchantFound != -1) {
							merchant_option.snapshot_type = this.state.all_merchant_list[merchantFound].snapshot_type
							merchant_option.type = this.state.all_merchant_list[merchantFound].type;

							merchant_option = this.state.all_merchant_list[merchantFound];
						}
					}
					//console.log(merchant_option)
					// if(Object.keys(data.result.inventory_details).length == 0){
					if (data.result.inventory_details == null) {
						this.emptyInventoryData();
					} else {
						//data.result.inventory_details.sync_status = 2;
						this.props.dispatch(setTempInventoryData(data.result.inventory_details));
						this.setState({ inventory_details: true });

					}
					let tds_arr = [...data.result.tds_details];
					this.setState({
						get_amount_round_off:data.result.amount_round_off,
						expense: !!data.result.expense,
						type: data.result.type,
						date: data.result.date ? new Date(data.result.date) : "",
						merchant_name: data.result.merchant_name,
						gst_no: data.result.gst_no || "",
						merchant_option: merchant_option,
						invoice_number: data.result.invoice_number,
						total_amount: data.result.total_amount ? data.result.total_amount : "",
						category_id: data.result.category_id || "",
						selected_tags: data.result.tags.map(tag => tag.id),
						additional_information: data.result.additional_information,
						voucher_status: data.result.voucher_status == 1 || data.result.accounting_status == 5 ? true : false,
						payment_status: data.result.payment_status == 1 ? true : false,
						db_payment_status_val: data.result.payment_status == 1 ? 1 : 0,
						payment_total: data.result.payment_total,
						payment_information: data.result.payment_information,
						gst_status: data.result.gst_status ? 1 : 0,
						sgst_amount: data.result.sgst_amount,
						cgst_amount: data.result.cgst_amount,
						igst_amount: data.result.igst_amount,
						gst_total_amount: data.result.gst_total_amount,
						tds_status: !!data.result.tds_status,
						is_base_amt_changed: !!data.result.tds_status,
						// tds_percentage: data.result.tds_percentage,
						tds_amount: data.result.tds_total_amount,
						tds_details: tds_arr,
						is_smart: data.result.mode == 2 ? true : false,
						snapshot_no: data.result.snapshot_no,
						file_url: data.result.file_url,
						qualified_url: data.result.qualified_url,
						pan_number: data.result.pan_number || "",
						snapshot_prediction_status: data.result.prediction_id ? true : false,
						approval_comment: data.result.approval_comment,
						approval_status: !!data.result.approval_status ? data.result.approval_status + "" : null,
						approval_button: !!data.result.approval_status ? data.result.approval_status : null,
						approved_amount: data.result.approved_amount,
						report_url_pdf: data.result.report_url_pdf,
						is_sms_evidence: data.result.from_sms,
						errors: {},
						is_dirty: false,
						created_by: data.result.created_by,
						source: data.result.source,
						email_date: data.result.email_date,
						email_from: data.result.email_from,
						email_subject: data.result.email_subject,
						is_msme: data.result.is_msme,
						is_perquisite: data.result.is_perquisite,
						is_gst_claim_eligible: data.result.is_gst_claim_eligible,
						merchant_type: data.result.merchant_type,
						entry_status: data.result.entry_status,
						accounting_status: data.result.accounting_status,
						is_linked: data.result.is_linked,
						synced_by_user: data.result.synced_by_user,
						linked_amount: data.result.linked_amount,
						transaction_ids: data.result.transaction_ids,
						voucher_status: data.result.voucher_status == 1 ? true : false,
						is_same_invoice: !!data.result.is_same_invoice ? data.result.is_same_invoice : 0,
						is_entity_mismatch: data.result.is_entity_mismatch,
						prediction_id: data.result.prediction_id,
						igst_amount_prediction_status: data.result.igst_amount_prediction_status,
						sgst_amount_prediction_status: data.result.sgst_amount_prediction_status,
						cgst_amount_prediction_status: data.result.cgst_amount_prediction_status,
						total_gst_amount_prediction_status: data.result.total_gst_amount_prediction_status,
						date_prediction_status: data.result.date_prediction_status,
						merchant_name_prediction_status: data.result.merchant_name_prediction_status,
						gst_no_prediction_status: data.result.gst_no_prediction_status,
						snapshot_type_prediction_status: data.result.snapshot_type_prediction_status,
						snapshot_number_prediction_status: data.result.snapshot_number_prediction_status,
						total_amount_prediction_status: data.result.total_amount_prediction_status,
						merchant_id_prediction_status: data.result.merchant_id_prediction_status,
						pan_number_prediction_status: data.result.pan_number_prediction_status,
						expense_prediction_status: data.result.expense_prediction_status,
						category_id_prediction_status: data.result.category_id_prediction_status,
						tds_percentage_prediction_status: data.result.tds_percentage_prediction_status,
						tags_prediction_status: data.result.tags_prediction_status,
						is_msme_prediction_status: data.result.is_msme_prediction_status,
						is_entity_mismatch_prediction_status: data.result.is_entity_mismatch_prediction_status,
						created_at: data.result.created_at,
						gst_summary: data.result.gst_summary || null,
						hsn_sac_summary: data.result.hsn_sac_summary || null,
						inventory_mode: data.result.inventory_mode ? true : false,
						is_igst: data.result.has_igst ? true : false,
						is_reimbursement: data.result.is_reimbursement == 0 ? false : true,
						reimbursement_narration: data.result.reimbursement_narration,
						request_id : data.result.request_id,
						is_gst_override : data.result.is_gst_override == 1 ? true : false,
					}, () => {
						
						let customer_gst = this.state.gst_no ? this.state.gst_no.slice(0, 2) : null || null;
						let entity_gst = (!!this.props.entity_gst_no) ? this.props.entity_gst_no.slice(0, 2) : null;

						if (customer_gst == entity_gst && customer_gst && entity_gst) {
							this.setState({ disable_igst: true,hide_gst_override:false })
						} else if (!customer_gst || !entity_gst) {
							this.setState({ disable_igst: false ,hide_gst_override:true})
						} else if (customer_gst != entity_gst && customer_gst && entity_gst) {
							this.setState({ disable_igst: true ,hide_gst_override:false})
						} else {
							this.setState({ disable_igst: false ,hide_gst_override:true})
						}

						//checking if gst override is true then we will make unable the igst
						if(this.state.is_gst_override){
							this.setState({disable_igst:false})
						}

						// if(Object.keys(data.result.inventory_details).length != 0) {
						// 	this.setState({inventory_details: data.result.inventory_details})
						// }
						this.hideShowOnTypeChange(data.result.type);
						
						if (this.state.payment_status) {
							this.paymentChildRef.setChildPaymentDetail(data.result.payment_information);
							this.paymentChildRef.setChildPaymentTotal(data.result.payment_total);


							data.result.payment_information.map((paymentinfo, index) => {
								if (paymentinfo.method == 3) {
									this.setState({ is_entity_mismatch: false, show_entity_mismatch_box: false })
									return;
								}
							})
						} else {
							let initialPaymentObj = [{ method: 0, id: 0, amount: 0, payment_id: 0 }];
							this.paymentChildRef.setChildPaymentDetail(initialPaymentObj);
							this.paymentChildRef.setChildPaymentTotal(0);
						}
						
						this.setState({ is_customer: true, is_supplier: true })

						if (this.state.merchant_type == 1) {
							this.handleSupplierCustomerToggle('is_customer', true)
							this.setState({ is_supplier: false });
						} else {
							this.handleSupplierCustomerToggle('is_supplier', true)
							this.setState({ is_customer: false });
						}
						
						if (data.result.is_linked == 1) { 
							this.setState({ editable: false })
						} else if (this.SNAPSHOT_LEDGER_ENTRIES_CODE) {  
							this.setState({ editable: true })
						} else if (data.result.entry_status == 1) { 
							this.setState({ editable: true })
						} else {  
							this.setState({ editable: false })
						}
						
						this.setState({ inventory_mode: data.result.inventory_mode ? true : false })

					});

					//create function to add preidiction and inventory details according to Bulk upload 
					// let bulk_prediction_data = data.result.predictions;
					if(data.result.hasOwnProperty('predictions') && data.result.predictions !== null){
						console.log("bulk",this.state.inventory_mode)
						if((this.state.type == 1 || this.state.type == 2) && this.props.accounting_mode == 2){
							this.setState({inventory_mode:true},()=>{
								 this.bulkLineItemPrediction(data.result.predictions);
							})
						}
						
					}
					




					//if (!!!this.state.approved_amount && this.APPROVE_REIMBURSMENT_SNAPSHOT && this.state.approval_status == null) {
					this.setState({ approved_amount: data.result.approved_amount });
					//}

					setTimeout(() => {
						let type_index = this.state.snapshot_types.findIndex(type => type.id == data.result.type);
						let category_index = this.state.categories.findIndex(cat => cat.id == this.state.category_id);

						let snapshot = {
							id: this.state.snapshot_id,
							attachment_status: data.result.file_url,
							category: category_index != -1 ? this.state.categories[category_index].name : "", // want from api
							date: data.result.date ? changeDateFormate(new Date(data.result.date)) : "",
							expense: !!data.result.expense,
							gst_status: data.result.gst_status,
							name: data.result.merchant_name,
							payment_status: data.result.payment_status,
							status: data.result.accounting_status == 3 ? data.result.entry_status : data.result.accounting_status,
							tds_status: data.result.tds_status,
							total_amount: data.result.total_amount,
							type: this.state.snapshot_types[type_index].name || "",
							is_duplicate: data.result.is_duplicate,
							is_linked: data.result.is_linked,
							entry_status:data.result.entry_status,
							is_star: data.result.is_star,
							pending_amount: data.result.pending_amount,
							tasks_status: data.result.tasks_status,
							private_msg_status: data.result.private_msg_status,
							general_msg_status: data.result.general_msg_status,
							notes_status: data.result.notes_status,
							new_tags: [],
							categories: [],
							// created_at: displayDateFormate(data.result.created_at)
							created_at: data.result.created_at
						}

						this.props.newSnapshot(snapshot, 2, false)
					}, 1000);



					// store old data for log
					//const oldData = { ...this.state.old_data };
					const oldData = {};
					oldData.type = data.result.type;
					oldData.date = changeDateFormate(new Date(data.result.date));
					oldData.invoice_number = data.result.invoice_number;
					oldData.total_amount = data.result.total_amount ? data.result.total_amount : "";
					oldData.category_id = !!data.result.category_id ? parseInt(data.result.category_id) : null;
					oldData.tags = data.result.tags.map(tag => tag.id);
					oldData.new_tags = {};
					oldData.is_perquisite = data.result.is_perquisite;
					oldData.additional_information = data.result.additional_information;
					oldData.merchant_name = data.result.merchant_name;
					oldData.gst_no = data.result.gst_no;
					oldData.pan_number = data.result.pan_number;
					oldData.is_msme = data.result.is_msme;
					oldData.payment_information = (data.result.payment_status) ? data.result.payment_information : [];
					oldData.sgst_amount = data.result.gst_status ? data.result.sgst_amount ? parseFloat(data.result.sgst_amount) : null : null;
					oldData.cgst_amount = data.result.gst_status ? data.result.cgst_amount ? parseFloat(data.result.cgst_amount) : null : null;
					oldData.igst_amount = data.result.gst_status ? data.result.igst_amount ? parseFloat(data.result.igst_amount) : null : null;
					oldData.is_gst_claim_eligible = data.result.is_gst_claim_eligible;
					oldData.tds_details = data.result.tds_status ? parseFloat(data.result.tds_details) : [];
					oldData.tds_total_amount = data.result.tds_status ? parseFloat(data.result.tds_total_amount) : null;
					oldData.evidence_file = data.result.file_url;
					oldData.approval_comment = data.result.approval_comment;
					oldData.approval_status = data.result.approval_status;
					oldData.approved_amount = this.state.approved_amount ? parseFloat(this.state.approved_amount) : null;
					oldData.vouchers = this.manipulateVouchersData(_.cloneDeep(temp_snap_voucher_list));
					oldData.entry_status = data.result.entry_status;
					oldData.accounting_status = data.result.accounting_status;
					oldData.entity_mismatch = data.result.is_entity_mismatch;
					oldData.inventory_mode = data.result.inventory_mode;
					oldData.inventory_details = data.result.inventory_details;

					this.setState({  tds_info_data_from_db: data.result.tds_details,voucher_list_from_db: data.result.vouchers, payment_info_data_from_db: data.result.payment_information, old_data: oldData, remove_cost_category, remove_cost_center })
					this.setLogData(data.result, temp_snap_voucher_list, 'old');
					//delete oldData.merchant_id
					//console.log(remove_cost_category, remove_cost_center)
					//this.setState({ })

					// this.checkForReadyState()
					//alert(this.state.editable)

					// BU1-T3959 : get merchant section details from additional information if present
					if (data.result.additional_information.length > 0) {

						if (!this.state.merchant_name) {
							let merchantPart1 = data.result.additional_information.split('Merchant Name: ')[1];

							if (merchantPart1) {
								let merchantPart2 = merchantPart1.substring(0, merchantPart1.indexOf(", GSTIN"));
								if (merchantPart2.length > 0) {
									this.setState({ is_new: true, new_value: merchantPart2, merchant_name: merchantPart2, merchant_option: { label: merchantPart2, value: 0 }, additional_information: "" })
								}
							}
						}

						if (!this.state.gst_no) {
							let gstPart1 = data.result.additional_information.split('GSTIN: ')[1];

							if (gstPart1) {
								let gstPart2 = gstPart1.substring(0, gstPart1.indexOf(", PAN"));
								if (gstPart2.length > 0) {
									this.setState({ gst_no: gstPart2, additional_information: "" })
								}
							}
						}

						if (!this.state.pan_number) {
							let panPart1 = data.result.additional_information.split('PAN: ')[1];

							if (panPart1) {
								if (panPart1.length > 0) {
									this.setState({ pan_number: panPart1, additional_information: "" })
								}
							}
						}
					}

					setTimeout(() => {
						this.props.dispatch(setAppilcationLoder(false));
					}, 2500)

				} else if (data.response_code == 404) {
					this.props.dispatch(setAppilcationLoder(false));
					//toast.error(data.message)

					this.setState({ is_dirty: false }, () => {
						//this.props.history.push('/snapshot/evidence')

						this.props.history.push({
							pathname: `/snapshot/evidence`,
							state: {
								showalert: true,
							}
						})
					})
				} else {
					toast.error(data.message);
					this.props.dispatch(setAppilcationLoder(false));
				}
			});

	}

	deleteEvidence = async () => {
		let showDeleteVoucherPopup = false;
		let activity = ['Deleted Snapshot'];

		let delete_log = ""
		if (this.state.voucher_list_from_db.length > 0) {
			delete_log += "Voucher with ID(s) ";
			this.state.voucher_list_from_db.forEach((voucher, index) => {
				if ((this.state.voucher_list_from_db.length - 1) == index) {
					delete_log += "#" + voucher.voucher_id;
				} else {
					delete_log += "#" + voucher.voucher_id + ",";
				}
				if (voucher.sync_status == 1 || voucher.sync_status == 2) {
					showDeleteVoucherPopup = true;
				}
			})
			delete_log += " deleted";
			activity.push(delete_log)
		}


		if (showDeleteVoucherPopup) {
			this.props.dispatch(setAppilcationLoder(false));
			if (!await confirm({
				confirmation: 'Once you delete the snapshot, vouchers will also be removed from your accounting software. Do you wish to continue?'
			})) {
				return true;
			}
		} else {
			if (!await confirm({
				confirmation: "Are you sure, you want to delete it?"
			})) {
				return true
			}
		}


		//console.log(activity)
		//sendLog({ module_type: 1, module_id: this.state.snapshot_id, activity: activity })
		//return;
		this.props.dispatch(setAppilcationLoder(true));
		SnapshotService.deleteEvidence(this.state.snapshot_id).then(data => {
			this.props.dispatch(setAppilcationLoder(false));
			if (data.response_code === 200) {
				StorageData.deleteTransactionId()
				toast.success('Evidence deleted')

				this.props.removeSnapshot(this.state.snapshot_id)
				// send log code
				sendLog({ module_type: 1, module_id: this.state.snapshot_id, activity: activity })
				// send log code
				this.emptyStateValue();
				this.setState({ snapshot_id: 0 })

				this.setState({ is_dirty: false }, () => {
					this.props.history.push('/snapshot/evidence/')
				})
			} else {
				toast.error(data.reason.error_message)
			}
			// this.toggleDeletePopup()
		})
	}

	toggleDeletePopup = () => {
		this.setState({ show_delete_popup: !this.state.show_delete_popup });
	}

	toggleFlowDisturb = () => {
		if (StorageData.getTaskManagerId()) {
			this.setState({ show_leave_task_manager_popup: true });
		} else {
			this.setState({ show_leave_money_link_popup: true });
		}
	}

	getItemLedgers = () => {
		SnapshotService.getItemLedgers().then((data) => {
			if (data.response_code == 200) {
				this.setState({
					item_ledger_list: data.result.item_ledgers,
				});
			}
		});
	}

	componentDidMount() {
		this.props.dispatch(setTempVoucherList([]));
		this.getSnapshotMasters();
		this.getItemLedgers();

		if (this.SNAPSHOT_LEDGER_ENTRIES_CODE || this.VIEW_ONLY_SNAPSHOT) {
			this.getLedgerNameList();
			this.getCostcenterCategory();
			this.getVoucherTypeList();
		}
		// if (this.SNAPSHOT_LEDGER_ENTRIES_CODE || this.VIEW_ONLY_SNAPSHOT) {
		// 	this.getLedgerNameList();
		// }
		if (!!this.state.snapshot_id) {
			this.getSnapshotDetail(this.state.snapshot_id);
		}
		else {
			let amt = parseFloat(this.state.total_amount || 0) - parseFloat(this.state.gst_total_amount || 0);
			amt = amt == 0?"":amt;
			let tds_information = [
			  {
				base_amount: amt,
				tds_amount: "",
				tds_percentage: "",
			  },
			];
			// tds_information.push({base_amount: 0, tds_percentage: "", tds_amount: 0 });
			this.setState({ tds_details: tds_information }, () => {});
		  }
		// shortcut key logic

		Mousetrap.bind('ctrl+alt+s',()=>{
			console.log("inside ",this.state.snapshot_id)
			if(this.state.snapshot_id){
				this.preUpdateEvidence()
			}
			else{
				this.saveEvidence()
			}
		})

		setTimeout(() => {
			this.props.dispatch(setAppilcationLoder(false));
		}, 3000)
	}

	getSnapshotMasters = () => {
		SnapshotService.getSnapshotMasters().then((data) => {
			if (data.response_code == 200) {

				let merchants = data.result.merchants.map(supp => {
					return {
						value: supp.id, label: supp.name, gst_no: supp.gst_no || "", pan_number: supp.pan_number || "", tds_percentage: supp.tds_percentage, snapshot_type: supp.snapshot_type, is_gst_claim_eligible: supp.is_gst_claim_eligible, is_msme: supp.is_msme, is_perquisite: supp.is_perquisite, type: supp.type, category_id: supp.category_id, tags: supp.tags || [], party_ledger_guid: supp.party_ledger_guid || "", purchase_ledger_guid: supp.purchase_ledger_guid || "", sales_ledger_guid: supp.sales_ledger_guid || "",
						purchase_voucher_type_guid: supp.purchase_voucher_type_guid || "", sales_voucher_type_guid:supp.sales_voucher_type_guid || ""
					}
				})

				this.setState({
					payment_details: data.result.financial_institutes,
					categories: data.result.categories,
					listed_tags: data.result.tags,
					all_merchant_list: merchants,
					all_old_merchant_list: _.cloneDeep(merchants),
					snapshot_types: data.result.snapshot_types,
					line_items_list: data.result.line_items
				});
			}
		});
	}

	getCostcenterCategory = () => {
		MoneyLinkService.getCostcenterCategory().then(
			(data) => {
				if (data.response_code == 200) {
					let cost_categories_list = data.result.cost_categories.map((category) => {
						category.label = decodeHtml(category.name);
						category.value = category.guid;
						return category;
					})
					this.props.dispatch(setCostCategoryList(cost_categories_list))
				} else {
					toast.error(data.message);
				}
			});
	}

	getSnapshotLedgerRecommendation = async () => {
		//Don't hit this api if party name is newly added(Not existing)
		if (this.state.merchant_option.value == 0) {
			if (this.props.temp_snap_voucher_list.length) {
				if (await confirm({
					confirmation: 'This action will remove existing vouchers. Do you want to continue?'
				})) {
					this.checkForAccountedState(false);
					this.props.dispatch(setTempVoucherList([]))
				} else {
					return true;
				}
			}
			toast.error("No recommended Ledger Found.");
			return;
		}

		//filter out empty payment data
		let payment_information = _.cloneDeep(this.state.payment_information);
		let payment_information_data = payment_information.filter((payment) => payment.method != 0);

		let req_data = {
			"type": this.state.type || null,
			"date": !!this.state.date ? changeDateFormate(this.state.date) : null,
			"merchant_id": this.state.merchant_option.value || 0,
			"total_amount": parseFloat(this.state.total_amount),
			"sgst_amount": this.state.gst_status ? parseFloat(this.state.sgst_amount) : null,
			"cgst_amount": this.state.gst_status ? parseFloat(this.state.cgst_amount) : null,
			"igst_amount": this.state.gst_status ? this.state.igst_amount ? parseFloat(this.state.igst_amount) : null : null,
			"tds_total_amount": this.state.tds_status ? parseFloat(this.state.tds_amount) : null,
			"category_id": !!this.state.category_id ? parseInt(this.state.category_id) : null,
			"payment_information": (this.state.payment_status) ? payment_information_data : [],
			"additional_information": this.state.additional_information || null,
			"invoice_number": this.state.invoice_number || null
		}
		let merchant_flag = (this.state.merchant_option.value == undefined) ? false : true
		// if (!!req_data.type && !!req_data.date && !!req_data.merchant_id && !!req_data.total_amount) {
		if (!!req_data.type && !!req_data.date && merchant_flag && !!req_data.total_amount) {
			if (this.props.temp_snap_voucher_list.length) {
				if (await confirm({
					confirmation: 'This action will remove existing vouchers. Do you want to continue?'
				})) {
					this.props.dispatch(setTempVoucherList([]))
				} else {
					return true;
				}
			}

			this.props.dispatch(setAppilcationLoder(true));
			SnapshotService.getSnapshotLedgerRecommendation(req_data).then(
				async (data) => {
					this.props.dispatch(setAppilcationLoder(false));
					if (data.response_code == 200) {
						if (data.result.vouchers.length) {
							this.setState({ is_leder_recommended: 1 });

						} else {
							toast.error("No recommended Ledger Found.");
						}
						let temp_snap_voucher_list = data.result.vouchers.map((voucher, index) => {
							let temp_voucher = {
								"accounting_narration": voucher.accounting_narration,
								"parent_voucher_type_id":voucher.parent_voucher_type_id,
								"voucher_type_guid":voucher.voucher_type_guid,
								"voucher_type_name":voucher.voucher_type_name || "Select voucher type",
								"voucher_type_id":voucher.voucher_type_id,
								"voucher_id": 0,
								"voucher_creation_date": voucher.voucher_creation_date,
								"total_credit_amount": roundOffValue(this.props.amount_round_off,voucher.total_credit_amount),
								"total_debit_amount": roundOffValue(this.props.amount_round_off,voucher.total_debit_amount),
								"is_leder_recommended": 1,
								"sync_status": 0,
								"sync_datetime": changeDateTimeFormat(new Date()),
								"sync_error": voucher.sync_error,
								"ledgers": voucher.ledgers.map((ledger, ledger_index) => {
									let empty_cost_category = [{
										"category_guid": "",
										"category_id": 0,
										"category_name": "",
										"transaction_voucher_ledger_cost_category_id": 0,
										"cost_centers": [{
											"cost_center_guid": "",
											"cost_center_id": 0,
											"cost_center_name": "",
											"cost_center_amount": "",
											"transaction_voucher_ledger_cost_category_cost_centre_id": 0
										}]
									}];
									let temp_ledger = {
										"amount": roundOffValue(this.props.amount_round_off,ledger.amount),
										"guid": ledger.guid,
										"name": ledger.name,
										"type": ledger.type,
										"ledger_id": ledger.ledger_id,
										"transaction_voucher_ledger_id": 0,
									}

									let is_cost_category_enable = !!this.state.ledgerNameList.ledger_list.find((temp_ledger) => temp_ledger.guid == ledger.guid && temp_ledger.has_cost_centre == 1);
									if (!ledger.cost_categories.length && is_cost_category_enable) {
										temp_ledger.cost_categories = empty_cost_category;
									} else {
										temp_ledger.cost_categories = ledger.cost_categories.map((category, category_index) => {
											let temp_category = {
												"category_id": category.cost_category_id,
												"category_guid": category.guid,
												"category_name": category.name,
												"transaction_voucher_ledger_cost_category_id": category.transaction_voucher_ledger_cost_category_id,
												"cost_centers": category.cost_centres.map((cost_center, cc_index) => {
													let tmp_cost_center = {
														"cost_center_amount": roundOffValue(this.props.amount_round_off,cost_center.amount),
														"cost_center_id": cost_center.cost_centre_id,
														"cost_center_guid": cost_center.guid,
														"cost_center_name": cost_center.name,
														"transaction_voucher_ledger_cost_category_cost_centre_id": cost_center.transaction_voucher_ledger_cost_category_cost_centre_id,
													}
													return tmp_cost_center;
												})
											}
											return temp_category;
										})
									}


									return temp_ledger;
								})
							}
							return temp_voucher;
						});
						await this.props.dispatch(setTempVoucherList(temp_snap_voucher_list))
						this.checkForReadyAccountedState()
					} else {
						toast.error(data.message);
					}
				});
		} else {
			await confirm({
				confirmation: 'Please provide mandatory information (type, date, amount, merchant) to get recommendation.',
				options: { type: "alert" }
			})
		}
	}

	getLedgerNameList = async () => {
		await MoneyLinkService.getLedgerNameList().then(data => {
			if (data.response_code == 200) {
				this.setState({ ledgerNameList: data.result })
			}
		})
	}
	getVoucherTypeList = async () =>{
		await MoneyLinkService.getVoucherTypeList().then(data => {
			if(data.response_code == 200){
				this.setState({voucherTypeList:data.result.voucher_types})
			}
		})
	}

	getLogs = () => {
		this.props.dispatch(setAppilcationLoder(true));
		let detail = { mod_id: this.state.snapshot_id, module: 1 }
		MasterService.getLogs(detail).then((data) => {
			this.props.dispatch(setAppilcationLoder(false));
			if (data.response_code === 200) {
				this.setState({ logs_list: data.result.change_logs })
			} else {
				toast.error(data.message)
			}
			this.setState({ show_log: true })
		})
	}

	toggleChangeLog = () => {
		this.setState({ show_log: !this.state.show_log })
	}

	saveBeforeLeave = (nextRoute) => {
		this.setState({ save_and_redirect: true, next_route: nextRoute }, function () {
			if (this.state.is_dirty) {
				if (this.state.snapshot_id) {
					this.preUpdateEvidence()
				} else {
					this.saveEvidence()
				}
			} else if (this.state.communication_bar_tasks_dirty) {
				this.setState({ communication_bar_tasks_dirty: false })
				this.comment_section.saveData('tasks', nextRoute);
			} else if (this.state.communication_bar_notes_dirty) {
				this.setState({ communication_bar_notes_dirty: false })
				this.comment_section.saveData('notes', nextRoute);
			} else if (this.state.communication_bar_msgs_dirty) {
				this.setState({ communication_bar_msgs_dirty: false })
				this.comment_section.saveData('msgs', nextRoute);
			} else if (this.state.communication_bar_private_msgs_dirty) {
				this.setState({ communication_bar_private_msgs_dirty: false })
				this.comment_section.saveData('private_msgs', nextRoute);
			}
		});
	}

	closeAction = () => {
		this.setState({ closed: false })
	}

	hideCategoryModal = () => {
		this.setState({ showCategory: false })
	}

	showCategoryModal = () => {
		this.setState({ showCategory: true })
	}

	preUpdateEvidence = () => {
		if (this.state.old_data.entry_status == 2 && this.state.entry_status == 1 && !this.SNAPSHOT_LEDGER_ENTRIES_CODE && !this.APPROVE_REIMBURSMENT_SNAPSHOT) {
			this.setState({ snapshop_status_reverted: true })
		} else {
			this.updateEvidence();
		}
	}

	confirmChangeStatus = (is_confirm) => {
		if (is_confirm) {
			this.updateEvidence();
			this.setState({ editable: true })
		}

		this.setState({ snapshop_status_reverted: false })
	}

	update_communication_bar_tasks_dirty = (status) => {
		this.setState({ communication_bar_tasks_dirty: status })
	}

	update_communication_bar_notes_dirty = (status) => {
		this.setState({ communication_bar_notes_dirty: status })
	}

	update_communication_bar_msgs_dirty = (status) => {
		this.setState({ communication_bar_msgs_dirty: status })
	}

	update_communication_bar_private_msgs_dirty = (status) => {
		this.setState({ communication_bar_private_msgs_dirty: status })
	}

	resetForm = () => { this.emptyStateValue() }

	isFormDirty = () => {
		this.setState({ is_dirty: true })
	}

	removeLedger = (id) => {
		if (id != 0) {
			let to_be_remove = this.state.remove_ledgers
			to_be_remove.push(id)
			this.setState({ remove_ledgers: to_be_remove, is_dirty: true })
		}
	}

	onLenderStatusChange = async () => {

		if (this.state.voucher_status && this.props.temp_snap_voucher_list.length) {
			if (await confirm({
				confirmation: 'Do you want to remove ledger data?'
			})) {
				this.checkForAccountedState(false);
				this.props.dispatch(setTempVoucherList([]))
			} else {
				return true;
			}
		}

		//check voucher status is off to on *********
		if (!this.state.voucher_status) {
			this.props.dispatch(setTempVoucherList([]))
			let req_data = {
				//"type": !this.state.type && this.state.expense ? 8 : this.state.type,
				"type": this.state.type || null,
				"date": !!this.state.date ? changeDateFormate(this.state.date) : null,
				"merchant_id": this.state.merchant_option.value || 0,
				"total_amount": parseFloat(this.state.total_amount),
				"sgst_amount": this.state.gst_status ? parseFloat(this.state.sgst_amount) : null,
				"cgst_amount": this.state.gst_status ? parseFloat(this.state.cgst_amount) : null,
				"igst_amount": this.state.gst_status ? this.state.igst_amount ? parseFloat(this.state.igst_amount) : null : null,
				"tds_amount": this.state.tds_status ? parseFloat(this.state.tds_amount) : null,
				"category_id": !!this.state.category_id ? parseInt(this.state.category_id) : null,
				"payment_information": (this.state.payment_status) ? this.state.payment_information : []
			}

			let merchant_flag = (this.state.merchant_option.value == undefined) ? false : true
			// let valid_ledger_req = (!!req_data.type && !!req_data.date && !!req_data.merchant_id && !!req_data.total_amount);
			let valid_ledger_req = (!!req_data.type && merchant_flag && !!req_data.merchant_id && !!req_data.total_amount);
			//console.log(valid_ledger_req)
			// if (!valid_ledger_req && (this.props.ledger_recommendation_param == 1 || this.props.ledger_recommendation_param == 2)) {
			if ((!valid_ledger_req || this.state.merchant_option.value == undefined) && (this.props.ledger_recommendation_param == 1 || this.props.ledger_recommendation_param == 2)) {
				if (this.state.merchant_option.value == 0) {
					toast.error("No recommended Ledger Found.");
				} else {
					await confirm({
						confirmation: 'Please provide mandatory information (type, date, amount, merchant) to get recommendation.',
						options: { type: "alert" }
					})
				}
			} else if (valid_ledger_req && this.props.ledger_recommendation_param == 1) {
				this.getSnapshotLedgerRecommendation();
				// } else if (valid_ledger_req && this.props.ledger_recommendation_param == 2) {
			} else if ((valid_ledger_req || merchant_flag) && this.props.ledger_recommendation_param == 2) {

				if (await confirm({
					confirmation: 'Would you like a ledger recommendation?'
				})) {
					if (this.state.merchant_option.value == 0) {
						toast.error("No recommended Ledger Found.");
					} else {
						this.getSnapshotLedgerRecommendation();
					}
					// this.getSnapshotLedgerRecommendation();
				}
			}
		}
		this.setState({ voucher_status: !this.state.voucher_status, is_dirty: true })
	}

	toggleSupplierDetail = async () => {
		console.log("on crosss icon click ---------------",this.state.merchant_option);

		// if(!this.VIEW_ONLY_SNAPSHOT ){

		// 	if (!this.state.is_edit_supplier)
		// 	if (!await confirm({
		// 		//confirmation: 'Do you want to edit ' + (this.state.expense ? "Supplier" : "Customer") + ' details ?'
		// 		confirmation: 'Do you want to edit Party Details?'
		// 	})) {				
		// 		return true;
		// 	}

		// 	let merchant_option = this.state.merchant_option;
		// 	if (!this.state.is_edit_supplier) {
		// 		merchant_option.is_edit = 1;
		// 	}
		// 	this.setState({ 
		// 		gst_no: merchant_option.gst_no || (merchant_option.__isNew__ || merchant_option.is_edit == 1 ? this.state.gst_no : ""),
		// 		pan_number: merchant_option.pan_number || (merchant_option.__isNew__ || merchant_option.is_edit == 1 ? this.state.pan_number : ""),
		// 		is_msme: merchant_option.is_msme || (merchant_option.__isNew__ || merchant_option.is_edit == 1 ? this.state.is_msme : false),
		// 		is_edit_supplier: !this.state.is_edit_supplier, 
		// 		merchant_option,
		// 		 is_dirty: true })	

		// }


		if(!this.VIEW_ONLY_SNAPSHOT ){

			if (!this.state.is_edit_supplier){
				if (!await confirm({
					//confirmation: 'Do you want to edit ' + (this.state.expense ? "Supplier" : "Customer") + ' details ?'
					confirmation: 'Do you want to edit Party Details?'
				})) {				
					return true;
				}
	
				let merchant_option = this.state.merchant_option;
				if (!this.state.is_edit_supplier) {
					merchant_option.is_edit = 1;
				}
				console.log("edit supplier",this.state.is_edit_supplier)
				this.setState({ is_edit_supplier: !this.state.is_edit_supplier, merchant_option, is_dirty: true })	
			}else{
				console.log("merchnat when",this.state.all_old_merchant_list)
				console.log("merchnat when",this.state.all_merchant_list)
				let merchnat_list = _.cloneDeep(this.state.all_old_merchant_list)
				let merchant_option = merchnat_list.filter((merchant)=> merchant.value == this.state.merchant_option.value)
				console.log("merchnat when cross",merchant_option)
				if(merchant_option.length > 0){
					this.setState({all_merchant_list:this.state.all_old_merchant_list})
					this.setState({ is_edit_supplier: !this.state.is_edit_supplier, 
						merchant_option:merchant_option[0], is_dirty: true,
						gst_no:merchant_option[0].gst_no,
						pan_number:merchant_option[0].pan_number,
						is_msme: merchant_option[0].is_msme
						},()=>{
						if(!!this.state.merchant_option.gst_no){
							this.setState({ gst_status: true, is_gst_claim_eligible: true })
						}else{
							this.setState({ gst_status: false, is_gst_claim_eligible: false })
						}
					})	
				}
			}
			

		}
		
	}

	// Intro js user guid   	
	toggleSteps = () => {
		this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
	};
	onExit = () => {
		this.setState(() => ({ stepsEnabled: false }));
	};


	getNarration = () => {
		let narration = "";
		if (this.state.invoice_number) {
			narration += "Invoice#: " + this.state.invoice_number;
		}
		if (this.state.date) {
			narration += " Date: " + displayDateFormate(this.state.date);
		}
		// if(this.state.line_items_data.length > 0 && this.state.inventory_mode == 0){
		// 	let line_data = ""
		// 	this.state.line_items_data.map((item) =>{
		// 		line_data += ` ${item.line_item_name} ,`
		// 	})
		// 	narration += " Line Items: " + line_data
			
		// }
		if (this.state.additional_information) {
			narration += " Addl Info: " + this.state.additional_information;
		}
		console.log("narrration",narration)
		return narration
	}

	handleSaveAndNext = (e) => {
		this.setState({ save_and_next: e.target.checked })
	}

	handleSupplierCustomerToggle = (name, value) => {

		this.setState({ [name]: value }, () => {

			let merchant_list = this.state.all_merchant_list.filter((merchant, index) => {
				if (merchant.type == 3 || (this.state.is_customer && merchant.type == 1) || (this.state.is_supplier && merchant.type == 2) || (!this.state.is_customer && !this.state.is_supplier)) {
					return merchant;
				}
			})

			this.setState({ merchants: merchant_list })
		})
	}

	getInvoiceName = () => {
		if (this.state.type == 6 || this.state.type == 2 || this.state.type == 5) {
			return 'Bill No.'
		} else if (this.state.type == 4) {
			return 'PO No.'
		} else if (this.state.type == 7 || this.state.type == 8 || this.state.type == 3 || this.state.type == 11) {
			return 'Ref No.'
		} else if (this.state.type == 1 || this.state.type == 9 || this.state.type == 10) {
			return 'Invoice No.'
		} else {
			return 'No.'
		}
	}

	saveLineItemData = (data) => {
		console.log(":::::::",data)
		let inventory_details = {
			"line_item_total": data.line_item_total,
			"line_item_gst_total": data.line_item_gst_total,
			"line_item_discount_total": data.line_item_discount_total,
			"inventory_voucher_status": data.inventory_voucher_status,
			"is_common_item_ledger": data.is_common_item_ledger,
			"accounting_narration": data.accounting_narration,
			"line_items": data.line_items,
			"ledgers": data.ledgers,
			"sync_datetime": data.sync_datetime,
			"sync_error": data.sync_error,
			"sync_status": data.sync_status,
			"voucher_total_amount": data.voucher_total_amount,
			"voucher_creation_date": data.voucher_creation_date,
			"party_ledger_guid": data.party_ledger_guid,
			"party_ledger_id": data.party_ledger_id,
			"party_ledger_name": data.party_ledger_name,
			"common_item_ledger_guid": data.common_item_ledger_guid,
			"common_item_ledger_id": data.common_item_ledger_id,
			"common_item_ledger_name": data.common_item_ledger_name,
			"party_ledger_guid_prediction_status": data.party_ledger_guid_prediction_status,
			"party_ledger_id_prediction_status":data.party_ledger_id_prediction_status,
			"party_ledger_name_prediction_status":data.party_ledger_id_prediction_status,
			"common_item_ledger_guid_prediction_status":data.common_item_ledger_guid_prediction_status,
			"common_item_ledger_id_prediction_status":data.common_item_ledger_id_prediction_status,
			"common_item_ledger_name_prediction_status":data.common_item_ledger_name_prediction_status,
			"voucher_creation_date_prediction_status":data.voucher_creation_date_prediction_status,
			"parent_voucher_type_id":data.parent_voucher_type_id,
			"voucher_type_guid":data.voucher_type_guid,
			"voucher_type_name":data.voucher_type_name,
			"voucher_type_id":data.voucher_type_id,
			// "gst_summary": data.gst_summary,
			// "hsn_sac_summary": data.hsn_sac_summary
		}
		if(!data.total_amount)
			this.setState({total_amount:data.line_item_total});
		console.log("line_item_total ----------------" , data.line_item_total);
		if (data.inventory_voucher_id) {
			inventory_details.inventory_voucher_id = data.inventory_voucher_id;
		}

		inventory_details.remove_line_items = data.remove_line_items || [];
		inventory_details.remove_ledgers = data.remove_ledgers || [];

		this.props.dispatch(setTempInventoryData(inventory_details))

		let cgst_amount = data.cgst_amount != undefined ? data.cgst_amount : this.state.cgst_amount;
		let sgst_amount = data.sgst_amount != undefined ? data.sgst_amount : this.state.sgst_amount;
		let igst_amount = data.igst_amount != undefined ? data.igst_amount : this.state.igst_amount;
		let is_igst = data.is_igst != undefined ? data.is_igst ? 1 : 0 : this.state.is_igst;

		let gst_total_amount = data.gst_total_amount != undefined ? data.gst_total_amount : this.state.gst_total_amount;
		let is_gst_claim_eligible = data.is_gst_claim_eligible != undefined ? data.is_gst_claim_eligible : this.state.is_gst_claim_eligible;
		let tds_details = data.tds_details != undefined ? data.tds_details : this.state.tds_details;
   		let tds_amount = data.tds_amount != undefined ? data.tds_amount : this.state.tds_amount;
		let tds_status = tds_amount > 0 ? true : false;
		// let tds_status = this.state.tds_status
		// if (data.tds_status != undefined) {
		// 	tds_status = data.tds_status;
		// }

		
		this.emptyPredictionLineItems();
		this.setState({ inventory_errors: {}, line_item_errors: [], ledger_errors: [], cgst_amount, sgst_amount, igst_amount, gst_total_amount,  tds_details,tds_amount,
			is_base_amt_changed: data.is_base_amt_changed, merchant_update: data.merchant_update, is_gst_claim_eligible, tds_status, inventory_details: true, is_igst,is_gst_override:data.is_gst_override ? true : false,disable_igst:data.disable_igst});
		
		if (data.is_dirty)
			this.setState({ is_dirty: data.is_dirty })
	}

	addNewlineItem = (val) => {
		let line_items_list = [...this.state.line_items_list];
		line_items_list.push({ name: val, id: 0 })
		this.setState({ line_items_list })
	}

	setLogData = (data, temp_voucher_list, type) => {
		//console.log(type, data)
		let log_data = {};
		log_data.snapshot_type = data.type;
		log_data.date = changeDateFormate(new Date(data.date));
		log_data.invoice_number = data.invoice_number;
		log_data.total_amount = data.total_amount ? data.total_amount : "";
		log_data.category = !!data.category_id ? parseInt(data.category_id) : null;
		log_data.entry_status = data.entry_status;
		log_data.accounting_status = data.accounting_status;
		log_data.additional_information = data.additional_information;
		log_data.party_name = data.merchant_name;
		log_data.party_GST_no = data.gst_no;
		log_data.party_PAN_number = data.pan_number;
		log_data.is_perquisite = data.is_perquisite ? 1 : 0;
		log_data.is_msme = data.is_msme ? 1 : 0;
		//log_data.entity_mismatch = data.is_entity_mismatch;
		log_data.tds_percentage = data.tds_status ? parseFloat(data.tds_percentage) : null;
		log_data.tds_amount = data.tds_status ? parseFloat(data.tds_amount) : null;
		log_data.payment_information = (data.payment_status) ? data.payment_information : [];
		log_data.sgst_amount = data.gst_status ? data.sgst_amount ? parseFloat(data.sgst_amount) : null : null;
		log_data.cgst_amount = data.gst_status ? data.cgst_amount ? parseFloat(data.cgst_amount) : null : null;
		log_data.igst_amount = data.gst_status ? data.igst_amount ? parseFloat(data.igst_amount) : null : null;
		log_data.GST_eligible = data.is_gst_claim_eligible;
		log_data.vouchers = type == 'new' ? this.manipulateNewVouchersData(_.cloneDeep(temp_voucher_list)) : this.manipulateVouchersData(_.cloneDeep(temp_voucher_list));
		log_data.tags = type == 'new' ? data.selected_tags : data.tags.map(tag => tag.id);
		log_data.new_tags = type == 'old' ? {} : data.new_tags

		//console.log(log_data)
		if(type == 'new') {
			this.setState({new_data_log: log_data})
		} else {
			this.setState({ old_data_log: log_data })
		}
	}

	handleReimbursementChange=(newVal)=> {}

	addTdsRow = () => {
		console.log(" clieck on add buttion ");
		let tds_information = [...this.state.tds_details];
		if (this.state.snapshot_id) tds_information.push({ id: 0, base_amount: "", tds_percentage: "", tds_amount: "" });
		else tds_information.push({ base_amount: "", tds_percentage: "", tds_amount: "" });
		this.setState({ tds_details: tds_information }, () => {
		  // this.payment_focus.current.scrollIntoView({
		  //   behavior: "smooth",
		  //   block: "nearest",
		  // });
		});
		this.setIsdirty();
	  };
	  removeTds = (index) => {
		console.log("index ------------------- ", index);
		let tds_information = [...this.state.tds_details];
		// const isEmpty = Object.values(tds_information[index]).every(x => (x === 0 || x === ''));
		// if (isEmpty) {
		tds_information.splice(index, 1);
		// } else {
		// 	tds_information.splice(index, 1);
		// }
		this.setState({ tds_details: tds_information }, () => {
		  this.calculateTDS();
		});
		this.setIsdirty();
	  };

	  formatEmailDate(emailDate) {
		// Parse the date in 'DD-MM-YYYY HH:mm' format
		const [day, month, yearAndTime] = emailDate.split('-');
		const [year, time] = yearAndTime.split(' ');
	
		// Return a properly formatted ISO string
		return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${time}`;
	}

	render() {	
		console.log("edit supplier render",this.state.is_edit_supplier)
		console.log("amount round off",this.props.amount_round_off)
		console.log("errors",this.state.errors)
		console.log("is igst override",this.state.is_gst_override)
		console.log("expense ",this.state.expense)

		
	
		let is_dirty = false
		if (this.state.is_dirty) {
			is_dirty = true
		} else if (this.state.is_dirty && (this.state.communication_bar_tasks_dirty || this.state.communication_bar_notes_dirty || this.state.communication_bar_msgs_dirty || this.state.communication_bar_private_msgs_dirty)) {
			is_dirty = true
		} else if (!this.state.is_dirty && (this.state.communication_bar_tasks_dirty || this.state.communication_bar_notes_dirty || this.state.communication_bar_msgs_dirty || this.state.communication_bar_private_msgs_dirty)) {
			is_dirty = true
		} else {
			is_dirty = false
		}

		const editable = this.state.editable;

		// Intro js user guid 	
		const {
			stepsEnabled,
			steps,
			initialStep,
			hintsEnabled,
			hints
		} = this.state;

		// End Intro js user guid 

		let merchants = this.state.all_merchant_list.filter((merchant, index) => {
			if (merchant.type == 3 || (this.state.is_customer && merchant.type == 1) || (this.state.is_supplier && merchant.type == 2) || (!this.state.is_customer && !this.state.is_supplier) || (!merchant.type)) {
				return merchant;
			}
		})


		//console.log( (!this.APPROVE_REIMBURSMENT_SNAPSHOT && !this.state.approval_status && this.state.is_reimbursement))

		//console.log(this.state.approval_status, this.state.approval_button)
		return (<>
			<div className="col-xl-9 col-lg-8 col-md-8 col-sm-7 main-content-sec-new">
				{(StorageData.getTransactionId() || StorageData.getTaskManagerId()) ?
					null
					:
					<RouteLeavingGuard
						when={is_dirty}
						saveChanges={this.saveBeforeLeave}
						closed={this.state.closed}
						closeAction={this.closeAction}
						resetForm={this.resetForm}
					/>
				}
				<ChangeLog
					show_log={this.state.show_log}
					toggleChangeLog={this.toggleChangeLog}
					logs={this.state.logs_list}
				/>
				{this.state.snapshop_status_reverted && <ConfirmPopup
					toggleConfirmPopup={this.state.snapshop_status_reverted}
					message="Snapshot status will be reverted, and form will be editable. Do you still want to continue?"
					confirmRemove={this.confirmChangeStatus}
				/>}
				{this.state.show_leave_task_manager_popup &&
					<ConfirmPopup
						toggleConfirmPopup={this.state.show_leave_task_manager_popup}
						message="Would you like to go back to task manager page?"
						confirmRemove={this.leaveTaskManagerFlow}
					/>
				}
				{this.state.show_leave_money_link_popup ?
					<ConfirmPopup
						toggleConfirmPopup={this.state.show_leave_money_link_popup}
						message="Would you like to go back to link evidence page?"
						confirmRemove={this.leaveMoneyLinkFlow}
					/> :
					<ConfirmPopup
						toggleConfirmPopup={this.state.toggleBackConfirm}
						message="Snapshot is not in ready status, evidence is not able to link. Do you still want to continue?"
						confirmRemove={this.backToMoneyLink}
					/>
				}
				<DeletePopUp showModal={this.state.show_delete_popup} deleteLoading={this.state.deleteLoading}
					toggleDeletePopUp={this.toggleDeletePopup} deleteItem={this.deleteEvidence} />

				{/* main-content-inner-new */}
				<div className="main-content-inner-new">

					{/* Intro js user guid */}
					<Steps
						enabled={stepsEnabled}
						steps={steps}
						initialStep={initialStep}
						onExit={this.onExit}
					/>
					{/* End Intro js user guid */}

					{/* Sub Header Inner */}
					<div className="sub-header-inner-new">
						<div className="row">
							<div className="col-sm-12">
								<div className="sub-header-lr-new">
									<div className="shleft-new">
										<h3 className="heading-shi">{!!this.state.snapshot_id ? "Edit" : "Create"} Snapshot</h3>

										<div onClick={this.toggleSteps}>
											<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Help</Tooltip>}>
												<span class="help-icon"><span class="icon-Information help-icon"></span></span>
											</OverlayTrigger>
										</div>

										{this.state.snapshot_no && <div className="multi-new-badges">
											<div className={this.state.is_dirty ? "modified badges-new-text" : "saved badges-new-text"}>
												{this.state.is_dirty ? "Modified" : "Saved"}
											</div>
										</div>}
									</div>

									<div className="shright-new">
										<div className="sh-new-error-text">
											<p className="dark-green-text s-reg">
												{this.state.system_suggested_status}
											</p>
											{!!Object.keys(this.state.errors).length &&
												<p className="red-text error_right mb-0">
													Please fill the mandatory details
												</p>
											}
											{(!this.SNAPSHOT_LEDGER_ENTRIES_CODE && this.state.old_data.accounting_status != 3 && this.state.old_data.entry_status == 2) || (!this.SNAPSHOT_LEDGER_ENTRIES_CODE && (this.state.is_linked == 1)) ?
												<p className="red-text error_right mb-0">
													This record is read only. Contact admin if you wish to edit.
												</p>
												: null}
											{(this.SNAPSHOT_LEDGER_ENTRIES_CODE && this.state.is_linked == 1) &&
												<p className="red-text error_right mb-0">
													This record is read only except vouchers as it is linked with bank transaction(s)
												</p>
											}
										</div>

										<h3 className="heading-shi mb-0">
											{this.state.snapshot_no && <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Download Snapshot</Tooltip>}>
												<a href={this.state.report_url_pdf} className='download_snapcr'>
													<span class="icon-pdf-icon blue-icon"></span>
												</a>
											</OverlayTrigger>}

											{this.state.snapshot_no && <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reload Snapshot</Tooltip>}>
												<a href="#" onClick={async (e) => {
													e.preventDefault()
													if (this.state.is_dirty) {
														if (!await confirm({
															confirmation: 'You will lost unsaved data. Do you want to refresh ?'
														})) {
															return true;
														}
													}
													this.getSnapshotMasters();
													if (this.SNAPSHOT_LEDGER_ENTRIES_CODE || this.VIEW_ONLY_SNAPSHOT) {
														this.getLedgerNameList();
													}
													if (!!this.state.snapshot_id) {
														this.setState({auto_accounted:false,auto_ready:false,auto_ready_accounted:false,is_new:false})
														this.getSnapshotDetail(this.state.snapshot_id);
													}

													if (this.SNAPSHOT_LEDGER_ENTRIES_CODE || this.VIEW_ONLY_SNAPSHOT) {
														this.getLedgerNameList();
														this.getCostcenterCategory();
														this.getItemLedgers();
														this.getVoucherTypeList();
													}
												}}>
													<span class="icon-refresh green-icon"></span>
												</a>
											</OverlayTrigger>}


											{(StorageData.getTransactionId() || StorageData.getTaskManagerId()) ?
												<Link to="#" onClick={() => this.toggleFlowDisturb()}>
													<span className="icon-Cross-with-grey-circle "><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
												</Link>
												:
												<Link to={`/snapshot/${this.props.match.params.evidence_type}`} onClick={() => this.setState({ closed: true })}>
													<span className="icon-Cross-with-grey-circle "><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
												</Link>
											}
										</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* End Sub Header Inner */}


					<div className="form-content-sec-new">
						<div className="row">
							<UploadFile
								global_preidction_status = {this.state.global_preidction_status}
								ref={this.upload_file}
								handleChange={this.handleChange}
								uploadFileUrl={this.uploadFileUrl}
								newUploadFileUrl = {this.newUploadFileUrl}
								gcp_subpath={this.state.file_url}
								onRef={ref => (this.imageUploadChildRef = ref)}
								qualified_url={this.state.qualified_url}
								is_smart={this.state.is_smart}
								removeQualifiedUrl={this.removeQualifiedUrl}
								SMART_SCAN_SNAPSHOT={this.SMART_SCAN_SNAPSHOT}
								VIEW_ONLY_SNAPSHOT={this.VIEW_ONLY_SNAPSHOT}
								snapshot_id={this.state.snapshot_id}
								editable={this.state.editable}
								type={this.state.type}
								onTypeChange={this.handleTypeChange}
								errors={this.state.errors}
								snapshot_type_prediction_status={this.state.snapshot_type_prediction_status}
								error={this.state.errors.file_url ? this.state.errors.file_url : false}
								snapshot_types={this.state.snapshot_types}
								is_entity_mismatch={this.state.is_entity_mismatch}
								changeEntityMismacth={val => {
									this.setState({ is_entity_mismatch: val.target.checked, is_dirty: true, is_reimbursement:this.state.is_reimbursement });
									// this.setState({is_reimbursement:!this.state.is_reimbursement})
								}}
								changeEntityReimbursement={async(e) => {
									// this.setState({is_reimbursement: e.target.checked, type: e.target.checked ? 6 : "", is_dirty: true});
									this.setState({is_reimbursement: e.target.checked, is_dirty: true});

									if (this.state.inventory_mode && this.state.inventory_details && this.state.inventory_mode) {
										let confirm_msg = await confirm({ confirmation: 'Inventory data will be lost if any. Do you want to continue?' });
							
										if (!confirm_msg) {
											this.setState({is_reimbursement: !e.target.checked, is_dirty: true});
											return true
										} else {
											this.emptyInventoryData();
										}
									}

									if(e.target.checked){
										this.setState({type:6,snapshot_type_prediction_status : 0,expense:true})
										
									}else{
										this.setState({type:"",snapshot_type_prediction_status : 0})
									}

									if(!e.target.checked){
										this.setState({expense : true})
									}

									if(e.target.checked) {
										this.setState({approved_amount: this.state.total_amount});
									} else {
										this.setState({approved_amount: null, approval_status: null, approval_comment: null, reimbursement_narration: null});
									}
									// this.setState({is_entity_mismatch:this.state.is_entity_mismatch})
								}}
								show_entity_mismatch_box={this.state.show_entity_mismatch_box}
								handleSmartToggle={val => this.setState({ is_smart: val })}
								updateDisplayFileUrl={(val) => this.setState({ display_file_url: val })}
								is_reimbursement={this.state.is_reimbursement}
								// raj add
								create_reimbursement={this.CREATE_REIMBUSRMENT_SNAPSHOT}
								create_business_snapshot={this.CREATE_BUSSINESS_SNAPSHOT}
								approvalStatus={this.state.approval_status}
								entryStatus={this.state.entry_status}
								is_perquisite = {this.state.is_perquisite}
								expense={this.state.expense}
								handleXmlData={this.handleXmlData}
								// newHandleXmlData={this.newHandleXmlData}
							/>

							<div className="col-xl-8 col-sm-12 form-sec-inner-right">
								<div className="form-sec-inner-new">
									{/* Item Details */}
									<div className="row mb-2">
										<div className="col-sm-12">
											<div className="form-main-title-new">
												<h4 className="form-title-heading-new d-flex align-items-center gap-10">
													Snapshot Details
													{/* <div className="toggle-switch-btn toggle-btn-bordered toggle-switch-center">
														<span className="toggle-switch-center">
															<span className="toggle-btn-sec-text">Business</span>
															<label className="switch">
																<input
																	type="checkbox"
																	name="is_reimbursement"
																	disabled={this.VIEW_ONLY_SNAPSHOT || !editable || (!this.CREATE_REIMBUSRMENT_SNAPSHOT || !this.CREATE_BUSSINESS_SNAPSHOT) || !!this.state.approval_status || this.state.entry_status == 2}
																	checked={this.state.is_reimbursement}
																	onChange={(e) => {
																		this.setState({is_reimbursement: e.target.checked, type: e.target.checked ? 6 : "", is_dirty: true});

																		if(e.target.checked) {
																			this.setState({approved_amount: this.state.total_amount});
																		} else {
																			this.setState({approved_amount: null, approval_status: null, approval_comment: null, reimbursement_narration: null});
																		}
																	}}
																/>
																<span className={"slider round " + (this.VIEW_ONLY_SNAPSHOT || !editable || (!this.CREATE_REIMBUSRMENT_SNAPSHOT || !this.CREATE_BUSSINESS_SNAPSHOT) || !!this.state.approval_status || this.state.entry_status == 2 ? "disable-any disable-opacity" : "")}></span>
															</label>
															<span className="toggle-btn-sec-text">Reimbursement</span>
														</span>
													</div> */}
												</h4>
												<div className="fth-rights">
													<span className="form-id-new">{this.state.snapshot_no ? "ID #" + this.state.snapshot_no : "NEW"}</span>

													{((this.LINKING_CODE || this.LEDGER_ENTRIES) && this.state.is_linked == 1) && <>
														<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Snapshot is linked with Money Link ID(s): {this.state.transaction_ids.map((item, i, row) => (<>#{item} {(i + 1 === row.length) ? '' : ','}</>))} </Tooltip>}>
															<span className="icon-linked-icon form-changelog-new" onClick={(this.state.is_linked == 1) ? this.snapshotFilterOnMoneyLink : () => { }}><span className="path1 blue-icon"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span>
														</OverlayTrigger>
													</>
													}

													{((this.LINKING_CODE || this.LEDGER_ENTRIES) && (this.state.is_linked == 0) && (this.state.snapshot_id > 0)) && <>
														<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Snapshot is not linked with Money Link ID(s)</Tooltip>}>
															<span className="icon-linked-icon gray-st-icon form-changelog-new"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span>
														</OverlayTrigger>
													</>
													}

													<div className={this.state.source == 2 ? " d-flex align-items-center " : "d-none"}>
														<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">
															<b>Date:</b> {this.state.email_date}<br></br>
															<b>From:</b> {this.state.email_from}<br></br>
															<b>Subject:</b> {this.state.email_subject}<br></br>
														</Tooltip>}>
															<span className="icon-email form-changelog-new"></span>
														</OverlayTrigger>
													</div>

													<div className={this.state.source == 7 ? " d-flex align-items-center " : "d-none"} style={{ fontSize: '23px' }}>
													<OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-sbold">
														<b>Date:</b> {this.state.email_date ? this.state.email_date : ''}<br></br>
													</Tooltip>}>
													{/* <span class="icon-file-icon blue-icon icon-dt-td"></span> */}
													<span class="icon-document-file-xml"></span>
													</OverlayTrigger>
													</div>

													<div className={this.state.source == 4 ? " d-flex align-items-center " : "d-none"} style={{ fontSize: '21px' }}>
													<OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-sbold">
														<b>File Name:</b> {this.state.email_subject ? this.state.email_subject : "No File Selected"}<br></br>
														<b>Date:</b> {this.state.email_date ? this.state.email_date : ''}<br></br>
													</Tooltip>}>
													<span class="icon-excel"></span>
													</OverlayTrigger>
													</div>

													<div className={this.state.source == 5 ? " d-flex align-items-center " : "d-none"} style={{ fontSize: '24px' }}>
													<OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-sbold">
                                                    <b>Month:</b> {this.state.email_date ? new Date(this.formatEmailDate(this.state.email_date)).toLocaleString('default', { month: 'long' }) : ''}<br></br>
                                                    <b>Year:</b> {this.state.email_date ? new Date(this.formatEmailDate(this.state.email_date)).getFullYear() : ''}<br></br>
                                                </Tooltip>}>
													<span className="icon-GST-icon icon-dt-td"><span class="path1 grayc-light-icons"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>
														</OverlayTrigger>
													</div> 

													{this.state.snapshot_id ?
														<span className="icon-log icon-round-info-button form-changelog-new cpointer" onClick={this.getLogs}></span> : null}
												</div>
											</div>
										</div>
									</div>

									{/* <div className="row justify-content-end"> */}
									{/* <div className="row right-side-form-mb mb-0"> */}
									<div className="row">
										{/* <div className="col-lg-5"></div> */}
										{/* <div className="col-lg-7"> */}
										<input type="hidden"
											checked={this.state.expense}
											onChange={this.handleChangeExpense}
											onBlur={() => this.checkForReadyState()}
											disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
											name="expense"
										/>

										<div className="col-lg-4 form-group">
											<lable className="lable-input">Date<span className="red-star">*</span></lable>
											<CustomDatepicker
												dateFormat={DatePickerFormate()}
												getSeletedValue={date => this.setDate(date, "date")}
												onBlur={() => this.checkForReadyState()}
												className={"form-control datepicker-icon " + (this.state.errors.date ? " wrong-red " : "") + (this.state.global_preidction_status && !!this.state.date_prediction_status ? " input-bg-green " : "")}
												initialValue={this.state.date}
												todaysDate = {this.todaysDate}
												autoComplete="off"
												disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
												name="date" />

											{this.state.errors.date && <small className="form-error-text">{this.state.errors.date}</small>}
											{/* <small className="form-error-text">error</small> */}
										</div>

										<div className="col-lg-4 form-group">
											<lable className="lable-input">{this.getInvoiceName()}<span className="red-star">*</span></lable>
											<input type="text"
												className={"form-control " + (this.state.errors.invoice_number ? " wrong-red " : "") + (this.state.global_preidction_status && !!this.state.snapshot_number_prediction_status ? " input-bg-green " : "")}
												value={this.state.invoice_number}
												onChange={this.handleChange}
												disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
												onBlur={() => this.checkForReadyState()}
												name="invoice_number" />
											{this.state.errors.invoice_number && <small className="form-error-text">{this.state.errors.invoice_number}</small>}
										</div>

										<div className="col-lg-4 form-group">
											<lable className="lable-input">Total Amount<span className="red-star">*</span></lable>
											<CurrencyInput
												type="text"
												className={"form-control form-text-right" + (this.state.errors.total_amount ? " wrong-red " : "") + (this.state.global_preidction_status && !!this.state.total_amount_prediction_status ? " input-bg-green " : "")}
												name="total_amount"
												value={this.state.total_amount}
												allowNegativeValue={false}
												autoComplete="off"
												onBlur={(e) => {
													this.setState({total_amount : this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,this.state.total_amount) : this.state.total_amount})
													this.fetchSMS()
													this.calculateTDS(e)
													this.checkForReadyState()
													this.setApprovalAmt(e)
												}}
												disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
												decimalsLimit={2}
												prefix="&#8377; "
												placeholder='&#8377; 0'
												onValueChange={(value, name) => this.amountChange(name, value)}
											/>
											{this.state.errors.total_amount && <small className="form-error-text">{this.state.errors.total_amount}</small>}
										</div>

										<div className="col-lg-8 cattag_snapcr">
											<div className='row'>
											<div className="col-lg-6 form-group">
												<div className="form-group d-flex align-items-end">
													<div className="mr-auto w-100">
														<lable className="lable-input">Category</lable>
														<select
															className={"form-control" + (this.state.global_preidction_status &&  this.state.category_id_prediction_status ? " input-bg-green" : "")}
															value={this.state.category_id}
															onChange={this.handleChange}
															disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
															name="category_id">
															<option value="">Select Category</option>
															{
																this.state.categories.map((category, index) => {
																	return <option key={index} value={category.id}>{category.name}</option>
																})
															}
														</select>
														{this.state.errors.category_id && <small className="form-error-text">{this.state.errors.category_id}</small>}
													</div>

													<span className={"icon-plus-circled size-xxx cpointer ml-2 " + ((this.VIEW_ONLY_SNAPSHOT || !editable) ? " disable-opacity " : " ")}
														onClick={() => { (this.VIEW_ONLY_SNAPSHOT || !editable) ? "" : this.showCategoryModal() }}
														disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
													></span>
												</div>
											<AddCategoryModal
												show={this.state.showCategory}
												addCategory={this.addCategory}
												addCategoryHandler={this.addCategoryHandler}
												onHide={this.hideCategoryModal} />
										</div>

										<div className="col-lg-6">
											<div className="form-group">
												<lable className="lable-input">Tags</lable>
												<div className="add-tag-new">
													<form onSubmit={this.addNewHashtag} noValidate>
														{/* <div class="d-flex align-items-end form-control p-0 border-0 mb-2" > */}
															<input type="text" autoComplete="on"
																// className={"form-control " + ((1 == 1 ? 'd-block' : 'd-none'))}
																className="form-control mb-2"
																name="hashtag" value={this.state.hashtag}
																disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
																onChange={this.handleChange}
																placeholder="Add Tag +"
																// style={{ width: this.state.hashtag.length + 'ch' }} 
															/>
															{/* <span className={"icon-plus-circled size-xxx cpointer "}></span> */}
														{/* </div> */}
													</form>

													{/* <div class="px-1" > */}
														<ul className="tag-list">
															{/* <li class="mb-2">
																<form onSubmit={this.addNewHashtag} noValidate>
																	<div class="d-flex align-items-end form-control p-0 border-0" >
																		<input type="text" autoComplete="on"
																			className={"form-control mr-2 " + ((1 == 1 ? 'd-block' : 'd-none'))}
																			name="hashtag" value={this.state.hashtag}
																			disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
																			onChange={this.handleChange}
																		// placeholder="Add Tag +"
																		// style={{ width: this.state.hashtag.length + 'ch' }} 
																		/>
																		<span className={"icon-plus-circled size-xxx cpointer "}></span>
																	</div>
																</form>
															</li> */}

															{/* <li class="mb-2">
																<form onSubmit={this.addNewHashtag} noValidate>
																	<div class="d-flex align-items-end form-control border-0 ">
																		<input type="text" autoComplete="on"
																			className={"form-control " + ((this.state.tagInputShow ? 'd-block' : 'd-none'))}
																			name="hashtag" value={this.state.hashtag}
																			disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
																			onChange={this.handleChange}
																		// placeholder="Add Tag +"
																		// style={{ width: this.state.hashtag.length + 'ch' }} 
																		/>
																		<span className={"icon-plus-circled size-xxx cpointer ml-2"} onClick={this.tagInputShowtoggle}></span>
																	</div>
																</form>
															</li> */}
															{
																this.state.listed_tags.map((tag, index) => {
																	let tag_index = this.state.selected_tags.indexOf(tag.id);
																	return tag_index != -1 ? <li key={index}
																		// className="tags"
																		className={"tags" + ((this.VIEW_ONLY_SNAPSHOT) ? " disable-opacity" : " ")}
																	>
																		<span>{tag.name}</span>
																		<span class="icon-Cross-with-grey-circle cpointer ml-1" onClick={() => !this.VIEW_ONLY_SNAPSHOT && editable && this.removeTag(tag_index, tag.id)}><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
																	</li> : ""
																})
															}
															{
																// change it to green colour
																this.state.new_tags.map((hashtag, hashtag_index) => {
																	return <li key={hashtag_index}
																		// className="tags green-bg"
																		className={"tags green-bg " + ((this.VIEW_ONLY_SNAPSHOT) ? " disable-opacity" : " ")}
																	>
																		<span>{hashtag}</span>
																		<span class="icon-Cross-with-grey-circle cpointer ml-1" onClick={() => !this.VIEW_ONLY_SNAPSHOT && editable && this.removeNewHashtag(hashtag_index)}><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
																	</li>
																})
															}
														</ul>
														
														{
															this.state.listed_tags.slice(0, this.state.itemsToShow).map((tag, index) => {
																// this.state.listed_tags.map((tag, index) => {
																let tag_index = this.state.selected_tags.indexOf(tag.id);
																return tag_index == -1 ?
																	<div onClick={() => !this.VIEW_ONLY_SNAPSHOT && editable && this.tagSelected(index)} key={index}
																		// className="tags"
																		className={"tags" + ((this.VIEW_ONLY_SNAPSHOT) ? " disable-opacity" : " ")}>
																		{tag.name}
																	</div> : ""
															})
														}
														<p class="size-xiv s-bold dark-green-text d-inline cpointer" onClick={this.showMore}>
															{this.state.expanded ? (
																<span>Less</span>
															) : (
																<span>More</span>
															)}
														</p>
													{/* </div> */}

													{/* old list code */}
													{/* {this.state.listed_tags.slice(0, 2).map((tag, index) => {
															// this.state.listed_tags.map((tag, index) => {
															let tag_index = this.state.selected_tags.indexOf(tag.id);
															return tag_index == -1 ?
																<div onClick={() => !this.VIEW_ONLY_SNAPSHOT && editable && this.tagSelected(index)} key={index}
																	// className="tags"
																	className={"tags" + ((this.VIEW_ONLY_SNAPSHOT) ? " disable-opacity" : " ")}>
																	<span className="icon-tag-black-shape"></span>
																	{tag.name}
																</div> : ""
														})
													}  */}
												</div>
											</div>
										</div>
											</div>
										</div>

										{/* <div className="col-lg-4 form-group">
											<div className="form-group">
												<div className="sup-cust-check lable-input">
													<input
														className="styled-checkbox"
														id="styled-checkbox-perquisite"
														type="checkbox"
														name="is_perquisite"
														checked={this.state.is_perquisite}
														onChange={this.handleChange}
														disabled={this.VIEW_ONLY_SNAPSHOT || !editable || !!!this.state.expense}
													// disabled={this.VIEW_ONLY_SNAPSHOT || !editable || (!this.state.is_edit_supplier && !!this.state.merchant_option.value)}
													/><label for="styled-checkbox-perquisite"
														className={((this.VIEW_ONLY_SNAPSHOT || !editable || !!!this.state.expense) ? "disable-before-opacity" : " ")}
													>Perquisite</label>
												</div>
											</div>
										</div> */}

										<div className="col-lg-4 form-group">
											<div className="form-group mb-0 addinfo_snapcr">
												<lable className="lable-input">Additional Information</lable>
												<TextareaAutosize type="text" maxLength="1000"
													className={"form-control " + (this.state.errors.additional_information ? " wrong-red " : "")}
													value={this.state.additional_information}
													minRows={1}
													onChange={this.handleChange}
													disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
													name="additional_information"></TextareaAutosize>
												{this.state.errors.additional_information && <small className="form-error-text">{this.state.errors.additional_information}</small>}
											</div>
										</div>
										{/* </div> */}
									</div>
									{/* End Item Details */}

									{/* Party Details */}
									<div className="row">
										<div className="col-sm-12">
											<div className="form-main-title-new">
												<h4 className="form-title-heading-new">Party Details</h4>
												{
													<span className={this.VIEW_ONLY_SNAPSHOT ? "cnotallowed + disable-before-opacity": "cpointer"} onClick={() => this.toggleSupplierDetail()} disabled ={this.props.VIEW_ONLY_SNAPSHOT || !editable} style={(editable && !_.isEmpty(this.state.merchant_option) && this.state.merchant_option.value != 0) ? {} : { visibility: "hidden" }}>{this.state.is_edit_supplier ? <span className="icon-Cross-with-grey-circle size-xxii"><span className="path1"></span><span className="path2 cpointer"></span><span className="path3"></span></span> : <span className="icon-edit size-xxii"></span>}</span>
												}
											</div>
										</div>
									</div>
									{/* <div className="col-lg-12"> */}
									{/* <div className="bordered-form mb-3"> */}
									<div className="row">
										<div className="col-lg-8 form-group mb-1">
											<div className="d-flex align-items-center gap-10">
												<div className="sup-cust-check lable-input">
													<input
														className="styled-checkbox"
														id="styled-checkbox-customer"
														type="checkbox"
														name="is_customer"
														checked={this.state.is_customer}
														onChange={(e) => {
															this.handleSupplierCustomerToggle('is_customer', e.target.checked)
														}}
														disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
													/><label for="styled-checkbox-customer"
														className={((this.VIEW_ONLY_SNAPSHOT || !editable) ? "disable-before-opacity" : " ")}
													>Customer</label>
												</div>
												<div className="sup-cust-check lable-input">
													<input
														className="styled-checkbox"
														id="styled-checkbox-supplier"
														type="checkbox"
														name="is_supplier"
														checked={this.state.is_supplier}
														onChange={(e) => {
															this.handleSupplierCustomerToggle('is_supplier', e.target.checked)
														}}
														disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
													/><label for="styled-checkbox-supplier" className={((this.VIEW_ONLY_SNAPSHOT || !editable) ? "disable-before-opacity" : " ")}>Supplier</label>
												</div>
											</div>
										</div>

										<div className="col-lg-4 form-group">
											<div className="sup-cust-check lable-input justify-content-end">
												<input
													className="styled-checkbox"
													id="styled-checkbox-msme"
													type="checkbox"
													name="is_msme"
													checked={this.state.is_msme}
													onChange={this.handleChange}
													disabled={this.VIEW_ONLY_SNAPSHOT || !editable || (!this.state.is_edit_supplier && !!this.state.merchant_option.value)}
												/>
												<label for="styled-checkbox-msme"
													className={((this.VIEW_ONLY_SNAPSHOT || !editable || (!this.state.is_edit_supplier && !!this.state.merchant_option.value)) ? "disable-before-opacity" : " ")}
												>MSME</label>
											</div>
										</div>

										<div className="col-lg-5 form-group">
											<lable className="lable-input">Party Name<span className="red-star">*</span></lable>
											<MerchantDropdown
												// ref={this.supplier_ref}
												global_preidction_status = {this.state.global_preidction_status}
												onRef={ref => (this.supplier_ref = ref)}
												merchant_id_prediction_status={this.state.merchant_id_prediction_status}
												merchant_name_prediction_status = {this.state.merchant_name_prediction_status}
												isDisabled={this.VIEW_ONLY_SNAPSHOT || !editable}
												selected_option={this.state.merchant_option}
												selected_value={this.state.merchant_option.value || ""}
												onMerchantChanged={this.onMerchantChanged}
												merchant_list={merchants}
												is_edit={this.state.is_edit_supplier}
												is_new={this.state.is_new}
												new_value={this.state.new_value}
												changeAddNew={(val) => this.setState({ is_new: val })}
												onBlur={this.checkForReadyState}
											/>
											{!this.state.errors.merchant_name && <small className="error_right">{this.state.errors.merchant_name}</small>}
										</div>

										<div className="col-lg form-group">
											<lable className="lable-input">Party GST No.</lable>
											<input type="text"
												className={"form-control " + (this.state.errors.gst_no ? " wrong-red " : "") + (this.state.global_preidction_status && !!this.state.gst_no_prediction_status ? " input-bg-green " : "")}
												value={this.state.gst_no}
												onChange={this.handleChange}
												onBlur={(e) => { this.checkForReadyState(); this.onGSTChange(e) }}
												disabled={this.VIEW_ONLY_SNAPSHOT || !editable || (!this.state.is_edit_supplier && !!this.state.merchant_option.value)}
												name="gst_no" />
											{this.state.errors.gst_no && <small className="error_right">{this.state.errors.gst_no}</small>}
										</div>

										<div className="col-lg form-group">
											<lable className="lable-input">PAN</lable>
											<input type="text"
												className={"form-control " + (this.state.errors.pan_number ? " wrong-red " : "") + (this.state.global_preidction_status && !!this.state.pan_number_prediction_status ? " input-bg-green " : "")}
												value={this.state.pan_number}
												onChange={this.handleChange}
												name="pan_number"
												disabled={this.VIEW_ONLY_SNAPSHOT || !editable || (!this.state.is_edit_supplier && !!this.state.merchant_option.value) || checkValidGSTNo(this.state.gst_no)} />
											{this.state.errors.pan_number && <small className="error_right">{this.state.errors.pan_number}</small>}
										</div>
										{/* </div> */}
									</div>
									{/* </div> */}
									{/* End Party Details */}
								</div>

								{/* Inventory Accounting */}
								<div className="form-sec-inner-new inventory-section">
									{((this.props.accounting_mode == 2 || this.state.inventory_mode) && this.SNAPSHOT_LEDGER_ENTRIES_CODE && (this.state.type == 1 || this.state.type == 2)) && 
									<div className="row">
										<div className="col-sm-12">
											<div className="form-main-title-new">
												{this.SNAPSHOT_LEDGER_ENTRIES_CODE && <h4 className="form-title-heading-new">Inventory Accounting</h4>}
												{((this.state.type == 1 || this.state.type == 2) && (this.props.accounting_mode == 2 || this.state.inventory_mode) && this.SNAPSHOT_LEDGER_ENTRIES_CODE) &&
													<div className="toggle-switch-btn toggle-switch-center">
														<span className="toggle-switch-center">
															{/* <span className="toggle-btn-sec-text">No</span> */}
															<label className="switch m-0">
																<input type="checkbox"
																	checked={this.state.inventory_mode}
																	onChange={async (e) => {

																		const target = e.target;
																		const value = target.type === 'checkbox' ? target.checked : target.value;

																		if (this.state.inventory_mode !== value && this.state.inventory_mode && this.state.inventory_details && this.state.inventory_mode) {
																			let confirm_msg = await confirm({ confirmation: 'Inventory data will be lost if any. Do you want to continue?' });

																			if (confirm_msg) {
																				this.setState({ inventory_mode: value, is_dirty: true });
																				this.emptyInventoryData();
																			}
																		} else {
																			this.setState({ inventory_mode: value })
																		}
																	}}
																	disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
																	name="inventory_mode"
																/>
																<span className={"slider round " + (this.VIEW_ONLY_SNAPSHOT || !editable ? "disable-opacity" : "")}></span>
															</label>
															{/* <span className="toggle-btn-sec-text">Yes</span> */}
														</span>
													</div>
												}
											</div>
										</div>
									</div>
									}

									{(this.state.inventory_mode == 1 || this.state.inventory_mode == true) && <InventoryAccounting
										remove_tds_details={this.state.remove_tds_details}
										setRemoveTdsInformation={(to_be_remove) =>
										this.setState({
											remove_tds_details: to_be_remove,
										})
										}
										snapshot_id={this.state.snapshot_id}
										is_base_amt_changed={this.state.is_base_amt_changed}
										newLineitemPrediction = {this.newLineitemPrediction}
										predictionlineitem = {this.predictionlineitem}
										xml_upload = {this.state.xml_upload}
										line_items_prediction_status = {this.state.global_preidction_status}
										onRef={ref => (this.inventoryChildRef = ref)}
										editable={editable}
										VIEW_ONLY_SNAPSHOT={this.VIEW_ONLY_SNAPSHOT}
										SNAPSHOT_LEDGER_ENTRIES_CODE={this.SNAPSHOT_LEDGER_ENTRIES_CODE}
										line_items={this.state.line_items}
										gst_summary={this.state.gst_summary}
										sgst_amount={this.state.sgst_amount}
										cgst_amount={this.state.cgst_amount}
										igst_amount={this.state.igst_amount}
										gst_total_amount={this.state.gst_total_amount}
										tds_status={this.state.tds_status}
										tds_details={this.returnTDsDetailsData()}
										tds_amount={this.state.tds_amount}
										is_gst_claim_eligible={this.state.is_gst_claim_eligible}
										line_item_total={this.state.line_item_total}
										line_item_gst_total={this.state.line_item_gst_total}
										file_url={this.state.display_file_url}
										qualified_url={this.state.qualified_url}
										invoice_number={this.state.invoice_number}
										type={this.state.type}
										date={!!this.state.date ? new Date(this.state.date) : new Date()}
										total_amount={this.state.total_amount}
										narration={this.getNarration()}
										inventory_details={this.props.temp_inventory_data}
										ledger_data={this.state.ledgerNameList}
										getLedgerData={() => {
											this.getLedgerNameList();
											//this.getSnapshotMasters();
										}}
										is_common_item_ledger={this.props.is_common_item_ledger}
										line_items_list={this.state.line_items_list}
										saveLineItemData={this.saveLineItemData}
										merchant_option={this.state.merchant_option}
										addNewlineItem={this.addNewlineItem}
										entry_status={this.state.entry_status}
										accounting_status={this.state.accounting_status}
										errors={this.state.inventory_errors}
										line_item_errors={this.state.line_item_errors}
										ledger_errors={this.state.ledger_errors}
										file_type={this.state.file_type}
										entity_gst_no={this.props.entity_gst_no}
										item_ledger_list={this.state.item_ledger_list}
										closePreviewPopup={() => {
											this.imageUploadChildRef.closePdfPopup();
											this.imageUploadChildRef.closeUploadedPdfPopup();
										}}
										disable_igst={this.state.disable_igst}
										is_igst={this.state.is_igst}
										hsn_sac_summary={this.state.hsn_sac_summary}
										sgst_amount_prediction_status = {this.state.sgst_amount_prediction_status}
										cgst_amount_prediction_status = {this.state.cgst_amount_prediction_status}
										igst_amount_prediction_status = {this.state.igst_amount_prediction_status}
										tds_percentage_prediction_status = {this.state.tds_percentage_prediction_status}
										combined_inventory_array = {this.state.combined_inventory_array}
										emptyPredictionLineItems = {this.emptyPredictionLineItems}
										inventory_common_item_ledger_guid = {this.state.inventory_common_item_ledger_guid}
										inventory_common_item_ledger_id = {this.state.inventory_common_item_ledger_id}
										inventory_common_item_ledger_name = {this.state.inventory_common_item_ledger_name}
										inventory_party_ledger_guid = {this.state.inventory_party_ledger_guid}
										inventory_party_ledger_id = {this.state.inventory_party_ledger_id}
										inventory_party_ledger_name = {this.state.inventory_party_ledger_name}
										inventory_voucher_creation_date = {this.state.inventory_voucher_creation_date}
										inventory_common_item_ledger_guid_prediction = {this.state.inventory_common_item_ledger_guid_prediction}
										inventory_common_item_ledger_id_prediction = {this.state.inventory_common_item_ledger_id_prediction}
										inventory_common_item_ledger_name_prediction = {this.state.inventory_common_item_ledger_name_prediction}
										inventory_party_ledger_guid_prediction = {this.state.inventory_party_ledger_guid_prediction}
										inventory_party_ledger_id_prediction = {this.state.inventory_party_ledger_id_prediction}
										inventory_party_ledger_name_prediction = {this.state.inventory_party_ledger_name_prediction}
										inventory_voucher_creation_date_prediction = {this.state.inventory_voucher_creation_date_prediction}
										bulk_prediction_status = {this.state.bulk_prediction_status}
										prediction_party_ledger_guid = {this.state.prediction_party_ledger_guid}
										prediction_purchase_ledger_guid = {this.state.prediction_purchase_ledger_guid}
										prediction_sales_ledger_guid = {this.state.prediction_sales_ledger_guid}
										is_gst_override = {this.state.is_gst_override}
										gst_no = {this.state.gst_no}
										hide_gst_override = {this.state.hide_gst_override}
										amount_round_off = {this.props.amount_round_off}
										voucherTypeList = {this.state.voucherTypeList}
										voucher_type_guid={this.state.voucher_type_guid}
										voucher_type_id={this.state.voucher_type_id}
										voucher_type_name={this.state.voucher_type_name}
										parent_voucher_type_id = {this.state.parent_voucher_type_id}
										prediction_voucher_type_guid = {this.state.prediction_voucher_type_guid}

									></InventoryAccounting>}

									{/* End Inventory Accounting */}


									{/* GST Info */}
									{!this.state.inventory_mode && <span className='gsttds_snapcr'>
										<div className="row">
											<div className="col-sm-12">
												<div className="form-main-title-new">
													<h4 className="form-title-heading-new">GST</h4>
													<div className="toggle-switch-btn toggle-switch-center">
														<span className="toggle-switch-center">
															{/* <span className="toggle-btn-sec-text">No</span> */}
															<label className="switch m-0">
																<input type="checkbox"
																	checked={this.state.gst_status}
																	onChange={(e) => {
																		if (!e.target.checked) {
																			this.setState({ is_igst: false })
																		} else {
																			let customer_gst = this.state.gst_no ? this.state.gst_no.slice(0, 2) : null || null;
																			let entity_gst = (!!this.props.entity_gst_no) ? this.props.entity_gst_no.slice(0, 2) : null;

																			if (customer_gst == entity_gst && customer_gst && entity_gst) {
																				this.setState({ is_igst: false, igst_amount: "", disable_igst: true,hide_gst_override:false }, () => {
																					this.calculateTotalGst();
																				})
																			} else if (!customer_gst || !entity_gst) {
																				console.log("here in gst")
																				this.setState({ is_igst: true, sgst_amount: "", cgst_amount: "", disable_igst: false,hide_gst_override:true }, () => {
																					this.calculateTotalGst();
																				})
																			} else if (customer_gst != entity_gst && customer_gst && entity_gst) {
																				this.setState({ is_igst: true, disable_igst: true, sgst_amount: "", cgst_amount: "",hide_gst_override:false }, () => {
																					this.calculateTotalGst();
																				})
																			} else {
																				this.setState({ is_igst: false, igst_amount: "", disable_igst: false,hide_gst_override:true }, () => {
																					this.calculateTotalGst();
																				})
																			}
																		}
																		this.handleChange(e)
																		console.log("gst status ---------------",this.state.gst_status);
																		
																	}}
																	onBlur={() => this.checkForReadyState()}
																	disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
																	name="gst_status"
																/>
																<span className={"slider round " + (this.VIEW_ONLY_SNAPSHOT || !editable ? "disable-opacity" : "")}></span>
															</label>
															{/* <span className="toggle-btn-sec-text">Yes</span> */}
														</span>
													</div>
												</div>
											</div>
										</div>

										{!!this.state.gst_status &&
											<div className="row right-side-form-mb justify-content-end">
												{/* <div className="col-lg-7"> */}
												<div className="col-lg form-group">
													<lable className="lable-input">CGST<span className="red-star">*</span></lable>
													<CurrencyInput type="text"
														className={"form-control form-text-right" + (this.state.errors.total_amount ? " wrong-red " : "") + (this.state.global_preidction_status && !!this.state.cgst_amount_prediction_status ? " input-bg-green " : "")}
														name="cgst_amount"
														value={this.state.cgst_amount}
														allowNegativeValue={false}
														autoComplete="off"
														onBlur={() => {
															this.setState({cgst_amount : this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,this.state.cgst_amount) : this.state.cgst_amount})
															this.calculateTotalGst()
															this.checkForReadyState()
														}}
														disabled={this.VIEW_ONLY_SNAPSHOT || !editable || this.state.is_igst}
														decimalsLimit={2}
														prefix="&#8377; "
														placeholder='&#8377; 0'
														onValueChange={(value, name) => this.amountChange(name, value)}
													/>
													{(this.state.errors.cgst_amount && !this.state.is_igst) && <small className="error_right">{this.state.errors.cgst_amount}</small>}
												</div>
												<div className="col-lg form-group">
													<lable className="lable-input">SGST<span className="red-star">*</span></lable>
													<CurrencyInput
														// disabled={!!this.state.igst_amount ? "disabled" : ""}
														className={"form-control form-text-right" + (this.state.errors.sgst_amount ? " wrong-red " : "") + (this.state.global_preidction_status && !!this.state.sgst_amount_prediction_status ? " input-bg-green " : "")}
														value={this.state.sgst_amount}
														allowNegativeValue={false}
														decimalsLimit={2}
														prefix="&#8377; "
														placeholder='&#8377; 0'
														onValueChange={(value, name) => this.amountChange(name, value)}
														is_amount={1}
														onBlur={() => {
															this.setState({sgst_amount : this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,this.state.sgst_amount) : this.state.sgst_amount})
															this.calculateTotalGst()
															this.checkForReadyState()
														}}
														disabled={this.VIEW_ONLY_SNAPSHOT || !editable || this.state.is_igst}
														name="sgst_amount" />
													{(this.state.errors.sgst_amount && !this.state.is_igst) && <small className="error_right">{this.state.errors.sgst_amount}</small>}
												</div>


												<div className="col-lg form-group ">
													<div className="">

														<div className="sup-cust-check lable-input">
															<input
																className="styled-checkbox"
																id="styled-checkbox-igst"
																type="checkbox"
																name="is_igst"
																checked={this.state.is_igst}
																disabled={this.VIEW_ONLY_SNAPSHOT || !editable || this.state.disable_igst }
																onChange={(e) => {
																	const target = e.target;
																	const value = target.checked;
																	this.setState({ is_igst: value, is_dirty: true }, () => {
																		if (value) {
																			this.setState({ cgst_amount: "", sgst_amount: "" }, () => {
																				this.calculateTotalGst();
																			})
																		} else {
																			this.setState({ igst_amount: "" }, () => {
																				this.calculateTotalGst();
																			})
																		}
																	})
																}}
															/>
															<label for="styled-checkbox-igst"
																className={((this.VIEW_ONLY_SNAPSHOT || !editable) ? "disable-before-opacity" : " ")}>IGST<span className="red-star">*</span></label>
														</div>

														<CurrencyInput type="text"
															// disabled={(!!this.state.cgst_amount || !!this.state.sgst_amount) ? "disabled" : ""}
															className={"form-control form-text-right mt-1 " + (this.state.errors.igst_amount ? " wrong-red " : "") + (this.state.global_preidction_status && !!this.state.igst_amount_prediction_status ? " input-bg-green " : "")}
															value={this.state.igst_amount}
															allowNegativeValue={false}
															decimalsLimit={2}
															prefix="&#8377; "
															placeholder='&#8377; 0'
															onValueChange={(value, name) => this.amountChange(name, value)}
															onBlur={() => {
																this.setState({igst_amount : this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,this.state.igst_amount) : this.state.igst_amount})
																this.calculateTotalGst()
																this.checkForReadyState()
															}}
															disabled={this.VIEW_ONLY_SNAPSHOT || !editable || !this.state.is_igst}
															name="igst_amount" />
														{(this.state.errors.igst_amount && this.state.is_igst)&& <small className="error_right">{this.state.errors.igst_amount}</small>}
														{(!this.state.errors.igst_amount && this.state.errors.same_amount) && <small className="error_right">{this.state.errors.same_amount}</small>}
													</div>
												</div>
												<div className="col-lg-4 form-group">
													<lable className="lable-input">GST Total Amount<span className="red-star">*</span></lable>
													<CurrencyInput type="text"
														className={"form-control form-text-right" + (this.state.errors.gst_total_amount ? " wrong-red " : "") + (this.state.global_preidction_status && !!this.state.total_gst_amount_prediction_status ? " input-bg-green " : "")}
														value={this.state.gst_total_amount}
														allowNegativeValue={false}
														decimalsLimit={2}
														prefix="&#8377; "
														placeholder='&#8377; 0'
														onValueChange={(value, name) => this.amountChange(name, value)}
														is_amount={1}
														disabled
														name="gst_total_amount" />
													{this.state.errors.gst_total_amount && <small className="error_right">{this.state.errors.gst_total_amount}</small>}
												</div>
												<div className="col-lg-6">
													<div className="sup-cust-check lable-input ">
														<input
															className="styled-checkbox"
															id="styled-checkbox-gstclaim"
															type="checkbox"
															name="is_gst_override"
															checked={this.state.is_gst_override}
															onChange={this.handleChange}
															disabled={this.VIEW_ONLY_SNAPSHOT || !editable || this.state.hide_gst_override}
														/>
														<label for="styled-checkbox-gstclaim"
															className={((this.VIEW_ONLY_SNAPSHOT || !editable) ? "disable-before-opacity" : " ")}>GST Override</label>
													</div>
												</div>

												<div className="col-lg-6">
													<div className="sup-cust-check lable-input justify-content-end">
														<input
															className="styled-checkbox"
															id="styled-checkbox-gstclaim"
															type="checkbox"
															name="is_gst_claim_eligible"
															checked={this.state.is_gst_claim_eligible}
															onChange={this.handleChange}
															disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
														/>
														<label for="styled-checkbox-gstclaim"
															className={((this.VIEW_ONLY_SNAPSHOT || !editable) ? "disable-before-opacity" : " ")}>GST Eligible</label>
													</div>
												</div>
												{/* </div> */}
											</div>
										}
										{/* End GST Info */}


										{/* TDS Applicable */}
										{!this.state.hide_tds && <div className="row">
											<div className="col-sm-12">
												<div className="form-main-title-new">
													<h4 className="form-title-heading-new">TDS</h4>
													<div className="toggle-switch-btn toggle-switch-center">
														<span className="toggle-switch-center">
															{/* <span className="toggle-btn-sec-text">No</span> */}
															<label className="switch m-0">
																<input type="checkbox"
																	checked={this.state.tds_status}
																	onChange={this.handleChange}
																	onBlur={() => this.checkForReadyState()}
																	disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
																	name="tds_status"
																/>
																<span className={"slider round " + (this.VIEW_ONLY_SNAPSHOT || !editable ? "disable-opacity" : "")}></span>
															</label>
															{/* <span className="toggle-btn-sec-text">Yes</span> */}
														</span>
													</div>
												</div>
											</div>
										</div>}

										<div className="payment-status-scroll">
                          {this.state.tds_status && !this.state.hide_tds && (
                            <>
                              {this.state.tds_details.map((tds, index) => {
                                {
                                  console.log("Tds Item --------------", tds);
                                }
                                return (
                                  <>
                                    {/* <div className="payment-status-scroll"> */}
                                    <div className="main-pay-method-new bordered-form" key={index}>
                                      {/* <div className="row right-side-form-mb snap_form_row_col"> */}
                                      <div className="row">
                                        {index != 0 && (
                                          <span class="ss-cross" onClick={() => this.removeTds(index)}>
                                            <span class="icon-Cross-with-grey-circle size-xxii">
                                              <span class="path1"></span>
                                              <span class="path2 cpointer"></span>
                                              <span class="path3"></span>
                                            </span>
                                          </span>
                                        )}
                                        {/* <div className="col-lg-7"> */}
                                        {/* <div className="col-lg"></div>
                                      <div className="col-lg"></div> */}

                                        <div className="col-4 form-group">
                                          <lable className="lable-input">
                                            TDS Base Amount<span className="red-star">*</span>
                                          </lable>
                                          <CurrencyInput
                                            type="text"
                                            className={"form-control form-text-right"}
                                            value={tds.base_amount}
                                            allowNegativeValue={false}
                                            decimalsLimit={2}
                                            prefix="&#8377; "
                                            placeholder="&#8377; 0"
                                            onValueChange={(value, name) => this.amountChange(name, value, index)}
                                            onBlur={() => {
												let tds_arr = [...this.state.tds_details];
												tds_arr[index].base_amount =  this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,tds.base_amount):tds.base_amount;
												this.setState({ tds_details: tds_arr},()=>{
														this.calculateTDS(index);
												});
											}}
                                            is_amount={1}
                                            // disabled={this.VIEW_ONLY_SNAPSHOT || editable}
                                            name="base_amount"
                                          />
                                          {this.state.errors.tds_details && (
                                            <small className="error_right">
                                              {this.state.errors?.tds_details[index]?.base_amount}
                                            </small>
                                          )}
                                        </div>
                                        <div className="col-4 form-group">
                                          <lable className="lable-input">
                                            TDS %<span className="red-star">*</span>
                                          </lable>
                                          <CurrencyInput
                                            type="text"
                                            className={
                                              "form-control form-text-right" +
                                              (this.state.errors.tds_details &&
                                              this.state.errors?.tds_details[index]?.tds_percentage
                                                ? " wrong-red "
                                                : "") +
                                              (this.state.global_preidction_status &&
                                              !!this.state.tds_percentage_prediction_status
                                                ? " input-bg-green "
                                                : "")
                                            }
                                            value={tds.tds_percentage}
                                            allowNegativeValue={false}
                                            decimalsLimit={2}
                                            onValueChange={(value, name) => {
												value > 100 ? "" : this.amountChange(name, value, index);
                                            }}
                                            onBlur={() => {
												let tds_arr = [...this.state.tds_details];
												tds_arr[index].tds_percentage =  this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,tds.tds_percentage):tds.tds_percentage;
												this.setState({ tds_details: tds_arr},()=>{
													
												let tdsP = this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,((((this.state.tds_details[index].tds_amount || 0) * 100 )/(this.state.tds_details[index].base_amount || 0)).toFixed(2))):((((this.state.tds_details[index].tds_amount || 0) * 100 )/(this.state.tds_details[index].base_amount || 0)).toFixed(2));
												console.log("prev after ----------------------------------" , this.state.tds_details[index].tds_percentage,tdsP )
													if(this.state.tds_details[index].tds_percentage != tdsP)
													this.calculateTDS(index);
												});	
                                            }}
                                            is_amount={1}
                                            disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
                                            name="tds_percentage"
                                          />
                                          {this.state.errors.tds_details && (
                                            <small className="error_right">
                                              {this.state.errors?.tds_details[index]?.tds_percentage}
                                            </small>
                                          )}
                                          {/* {this.state.errors?.tds_details && this.state.errors?.tds_details[index]?.tds_percentage && (
                                          <small className="error_right">
                                            {this.state.errors.tds_details[index]?.tds_percentage}
                                          </small>
                                        )} */}
                                        </div>

                                        <div className="col-4 form-group">
                                          <lable className="lable-input">
                                            TDS Amount<span className="red-star">*</span>
                                          </lable>
                                          <CurrencyInput
                                            type="text"
                                            className={
                                              "form-control form-text-right" +
                                              (this.state.errors.tds_details &&
                                              this.state.errors.tds_details[index]?.tds_amount
                                                ? " wrong-red "
                                                : "")
                                            }
                                            value={tds?.tds_amount}
                                            allowNegativeValue={false}
                                            decimalsLimit={2}
                                            prefix="&#8377; "
                                            placeholder="&#8377; 0"
                                            onValueChange={(value, name) => this.amountChange(name, value, index)}
                                            is_amount={1}
                                          	onBlur={() => {
												let tds_arr = [...this.state.tds_details];
												tds_arr[index].tds_amount =  this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,tds.tds_amount):tds.tds_amount;
												this.setState({ tds_details: tds_arr},()=>{
														this.calculateTdsTotal(index);
												});
											}}
                                            name="tds_sub_amount"
                                          />
                                          {this.state.errors.tds_details &&
                                            this.state.errors?.tds_details[index]?.tds_amount && (
                                              <small className="error_right">
                                                {this.state.errors.tds_details[index].tds_amount}
                                              </small>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                    {/* </div> */}
                                  </>
                                );
                              })}

                              <div ref={this.payment_focus} />

                              {/* {!editable && ( */}
                              <div className="row justify-content-end align-items-end mb-2">
                                <div className="col-lg-3 pl-lg-2 pr-lg-0">
                                  <div className="form-group">
                                    <lable className="lable-input">TDS Total</lable>
                                    <input
                                      disabled
                                      type="text"
                                      className={"form-control form-text-right"}
                                      value={this.state.tds_amount}
                                      decimalsLimit={2}
                                      prefix="&#8377; "
                                      placeholder="&#8377; 0"
                                      name="tds_total"
                                      // disabled
                                    />
                                  </div>
                                </div>
                                <div className="col-1 form-group text-right">
                                  <span
                                    class="icon-plus-circled size-xxx cpointer "
                                    disabled={this.props.VIEW_ONLY_SNAPSHOT}
                                    onClick={() => this.addTdsRow()}
                                  ></span>
                                </div>
								
								{this.state.errors.total_base_amount && <div className="col-12 form-group text-right"><small className="error_right">{this.state.errors.total_base_amount}</small></div>}	 
								
							 </div>
							 
                            </>
                          )}
                        </div>
										{/* End TDS Applicable */}
									</span>}

									{/* SMS ------ */}
									{
										this.ACCESS_BUSSINESS_SMS_SNAPSHOT &&
										<>
											{/* <div className="d-none"> */}
											<div className="d-none">
												<div className="row">
													<div className="col-sm-12">
														<div className="form-main-title-new">
															<h4 className="form-title-heading-new">SMS</h4>

															{this.state.sms_list.length === 0 &&
																<small className="error_right">Select date & amount to SMS's</small>
															}
														</div>
													</div>
												</div>

												<div className="row right-side-form-mb snap_form_row_col">
													<div className="col-lg-7">
														<div className={"scroll-sms-details " + (this.state.sms_list.length === 0 && 'd-none')}>
															{this.state.sms_list.map((sms, index) => {
																let is_attached_readonly = (this.state.is_sms_evidence && index === 0) ? true : false

																let is_checked = false
																if (this.state.selected_sms_list.length !== 0) {
																	is_checked = this.state.selected_sms_list.includes(sms.id)
																}
																return <div key={index} className={"sms-details " + (is_attached_readonly ? ' bg-color' : ' ')}>
																	<div className="">
																		{/* <div className="radio-form-text-right-sec"> */}
																		<div className="">
																			<label>
																				{is_attached_readonly ?
																					<input type="checkbox" name="radio1" checked={is_attached_readonly} readOnly />
																					:
																					<input type="checkbox" name="radio1" checked={is_checked} disabled={this.VIEW_ONLY_SNAPSHOT || !editable} onChange={(e) => this.selectSMS(e, sms)} />
																				}
																			</label>
																			<div className="form-text-right-sec">
																				<p className="date s-sbold font-colr d-flex align-items-center">
																					<span>{sms.date}</span>
																					<span className="ml-auto">₹{sms.amount}</span>
																				</p>
																				<p className="account-text font-colr">
																					Your a/c no. XXXXXXXXXXX5023 is debited for Rs. 580.00 on 29-11-2020</p>
																			</div>
																		</div>
																	</div>
																</div>
															})}
														</div>
													</div>
												</div>
											</div>
										</>
									}
									{/* End SMS ----- */}

									{/* Approval */}

									{(this.state.is_reimbursement) &&
										<>
											<div className="row">
												<div className="col-sm-12">
													<div className="form-main-title-new">
														<h4 className="form-title-heading-new">Reimbursement</h4>
														<>{!!this.state.approval_status ? "" : <small className="error_right">Pending for approval</small>}</>
													</div>
												</div>
											</div>

											{this.state.is_reimbursement &&
												<div className="row right-side-form-mb">
													<div className="col-lg-4">
														<div className="form-group">
															<lable className="lable-input">Amount<span className="red-star">*</span></lable>
															<CurrencyInput
																type="text"
																className={"form-control form-text-right" + (this.state.errors.approved_amount ? " wrong-red " : "")}
																value={this.state.approved_amount}
																allowNegativeValue={false}
																decimalsLimit={2}
																prefix="&#8377; "
																onValueChange={(value, name) => this.amountChange(name, value)}
																is_amount={1}
																//disabled={!this.APPROVE_REIMBURSMENT_SNAPSHOT || (this.state.snapshot_status == 3 || this.state.snapshot_status == 5 || this.state.snapshot_status == 6)}
																disabled={(!this.CREATE_REIMBUSRMENT_SNAPSHOT && !this.APPROVE_REIMBURSMENT_SNAPSHOT) || (this.state.approval_status == 1 && this.state.approval_button == 1) || this.state.accounting_status > 3}
																name="approved_amount" />
															{this.state.errors.approved_amount && <small className="error_right">{this.state.errors.approved_amount}</small>}
														</div>
													</div>
													<div className="col-lg-4">
														<div className="form-group">
															<lable className="lable-input">Narration</lable>
															<textarea
																className="form-control"
																rows="1"
																value={this.state.reimbursement_narration || ""}
																onChange={this.handleChange}
																//disabled={!this.APPROVE_REIMBURSMENT_SNAPSHOT || (this.state.snapshot_status == 3 || this.state.snapshot_status == 5 || this.state.snapshot_status == 6)}
																disabled={!this.CREATE_REIMBUSRMENT_SNAPSHOT || this.state.approval_status == 1 || this.state.accounting_status > 3}
																name="reimbursement_narration">
															</textarea>
														</div>
													</div>
													<div className="col-lg-4">
														<div className="form-group">
															<lable className="lable-input">Remark</lable>
															<textarea
																className="form-control"
																rows="1"
																value={this.state.approval_comment || ""}
																onChange={this.handleChange}
																//disabled={!this.APPROVE_REIMBURSMENT_SNAPSHOT || (this.state.snapshot_status == 3 || this.state.snapshot_status == 5 || this.state.snapshot_status == 6)}
																disabled={!this.APPROVE_REIMBURSMENT_SNAPSHOT || (this.state.approval_status == 1 && this.state.approval_button == 1) || this.state.accounting_status > 3}
																name="approval_comment">
															</textarea>
														</div>
													</div>

													<div className="col-lg-12">
														{/* {(this.APPROVE_REIMBURSMENT_SNAPSHOT && (this.state.approval_status != "1" && this.state.approval_status != "2")) ? (this.APPROVE_REIMBURSMENT_SNAPSHOT && this.CREATE_REIMBUSRMENT_SNAPSHOT) ? null : <div className="text-right"> */}
														{(this.APPROVE_REIMBURSMENT_SNAPSHOT && this.CREATE_REIMBUSRMENT_SNAPSHOT && !this.state.snapshot_id) || !this.state.snapshot_id ? null :
															<div className="text-right">
																<ToggleButtonGroup
																	type="radio"
																	value={!!this.state.approval_button ? this.state.approval_button.toString() : null}
																	onChange={(values, e) => {
																		if (!this.APPROVE_REIMBURSMENT_SNAPSHOT || this.state.is_linked == 1 || this.state.accounting_status > 3) {
																			return
																		} else {
																			this.handleChange(e);
																			if (e.target.value == 1) {
																				this.setState({ entry_status: 2 })
																			} else {
																				this.setState({ entry_status: 1 })
																			}
																		}
																	}}
																	name="approval_button"
																	className="toogle-btn-ra"
																>
																	<ToggleButton style={!this.APPROVE_REIMBURSMENT_SNAPSHOT || this.state.is_linked == 1 || this.state.accounting_status > 3 ? { cursor: "not-allowed" } : {}} value={"2"}>{!!this.state.approval_status && this.state.approval_status == "2" ? 'Rejected' : 'Reject'}</ToggleButton>
																	<ToggleButton style={!this.APPROVE_REIMBURSMENT_SNAPSHOT || this.state.is_linked == 1 || this.state.accounting_status > 3 ? { cursor: "not-allowed" } : {}} value={"1"}>{!!this.state.approval_status && this.state.approval_status == "1" ? 'Approved' : 'Approve'}</ToggleButton>
																</ToggleButtonGroup>
																{this.state.errors.approval_status && <small className="error_right">{this.state.errors.approval_status}</small>}
															</div>}
														{/* {(this.state.snapshot_id && !!this.state.approval_status) ? <div className="text-right">
																<ToggleButtonGroup
																	type="radio"
																	value={this.state.approval_status.toString()}
																	name="approval_button"
																	className="toogle-btn-ra"
																	disabled={true}
																>
																	<ToggleButton style={{ cursor: "not-allowed" }} value={"2"}>Rejected</ToggleButton>
																	<ToggleButton style={{ cursor: "not-allowed" }} value={"1"}>Approved</ToggleButton>
																</ToggleButtonGroup>															
															</div>:null} */}

													</div>
												</div>
											}
										</>
									}
									{/* End Approval */}

									{/* Payment Status */}
									<span className={"payment_snapcr" + (this.state.is_reimbursement || this.state.hide_payment_status ? " d-none": "")}>
									{
										!this.state.hide_payment_status && <span className='payment_snapcr'>
											<div className="row">
												<div className="col-sm-12">
													<div className="form-main-title-new">
														<h4 className="form-title-heading-new">
															{this.state.expense ? "Payment Status" : "Receipt"}
														</h4>
														<div className="toggle-switch-btn toggle-switch-center">
															<span className="toggle-switch-center">
																{/* <span className="toggle-btn-sec-text">Unpaid</span> */}
																<label className="switch m-0">
																	<input type="checkbox"
																		checked={(this.state.payment_status || this.state.is_linked)}
																		onChange={this.handlePaymentStatus}
																		disabled={this.VIEW_ONLY_SNAPSHOT || !editable}
																		name="payment_status"
																	/>
																	<span className={"slider round " + (this.VIEW_ONLY_SNAPSHOT || !editable ? "disable-opacity" : "")}></span>
																</label>
																{/* <span className="toggle-btn-sec-text">Paid</span> */}
															</span>
														</div>
													</div>
												</div>
											</div>
											<span className={(this.state.payment_status || this.state.is_linked) ? "" : "d-none"}>
												{/* <div className="row right-side-form-mb  "> */}
												<div className="row">
													<div className="col-lg-12">
														{/* <div className="bordered-form"></div> */}
														<PaymentMethod
															setPaymentDetail={this.setPaymentDetail}
															snapshot_id={this.state.snapshot_id}
															payment_details={this.state.payment_details}
															is_sms_evidence={this.state.is_sms_evidence}
															onRef={ref => (this.paymentChildRef = ref)}
															// ref = {this.paymentChildRef}
															expense={this.state.expense}
															CREATE_BUSSINESS_SNAPSHOT={this.CREATE_BUSSINESS_SNAPSHOT}
															CREATE_REIMBUSRMENT_SNAPSHOT={this.CREATE_REIMBUSRMENT_SNAPSHOT}
															VIEW_ONLY_SNAPSHOT={this.VIEW_ONLY_SNAPSHOT}
															MANAGE_OTHER_USER_SNAPSHOT={this.MANAGE_OTHER_USER_SNAPSHOT}
															errors={this.state.errors}
															setIsdirty={this.setIsdirty}
															editable={editable}
															remove_payment_information={this.state.remove_payment_information}
															setRemovePaymentInformation={(to_be_remove) => this.setState({ remove_payment_information: to_be_remove })}
															linked_amount={this.state.linked_amount}
															transaction_ids={this.state.transaction_ids}
															is_linked={this.state.is_linked}
															snapshotFilterOnMoneyLink={this.snapshotFilterOnMoneyLink}
															db_payment_status_val={this.state.db_payment_status_val}
															changePaymentMethod={(payment_information) => {
																this.setState({ show_entity_mismatch_box: true })
																payment_information.map((paymentinfo, index) => {
																	if (paymentinfo.method == 3) {
																		console.log(payment_information)
																		this.setState({ is_entity_mismatch: false, show_entity_mismatch_box: false })
																		return;
																	}
																})
															}}
														/>
													</div>
												</div>
											</span>
										</span>
									}
									</span>
									{/* End Payment Status */}

									{/* Ledger */}
									{
										(this.SNAPSHOT_LEDGER_ENTRIES_CODE || this.VIEW_ONLY_SNAPSHOT) && ((this.state.is_reimbursement) ? this.state.approval_status == 1 : true) &&
										<span className='ledger_snapcr'>
											<div className="row">
												<div className="col-sm-12">
													<div className="form-main-title-new">
														<h4 className="form-title-heading-new">{((this.props.accounting_mode == 2 || this.state.inventory_mode) && (this.state.type == 1 || this.state.type == 2)) ? "Ledger - Additional Vouchers" : "Ledger"}</h4>
														<div className="toggle-switch-btn toggle-switch-center">
															<span className="toggle-switch-center">
																{/* <span className="toggle-btn-sec-text">No</span> */}
																<label className="switch m-0">
																	<input type="checkbox"
																		checked={this.state.voucher_status}
																		onChange={this.onLenderStatusChange}
																		disabled={this.VIEW_ONLY_SNAPSHOT && !this.SNAPSHOT_LEDGER_ENTRIES_CODE}
																		name="voucher_status"
																	/>
																	<span className={"slider round " + (this.VIEW_ONLY_SNAPSHOT && !this.SNAPSHOT_LEDGER_ENTRIES_CODE ? "disable-opacity" : "")}></span>
																</label>
																{/* <span className="toggle-btn-sec-text">Yes</span> */}
															</span>
														</div>
													</div>
												</div>
											</div>

											{
												this.state.voucher_status &&
												<SnapshotLedger
													isFormDirty={this.isFormDirty}
													ledgerNameList={this.state.ledgerNameList}
													removeLedger={this.removeLedger}
													is_valid_voucher={this.state.is_valid_voucher}
													is_valid_ledger={this.state.is_valid_ledger}
													read_only={!this.state.ledgers_editable || this.VIEW_ONLY_SNAPSHOT}
													transactionDate={!!this.state.date ? new Date(this.state.date) : new Date()}
													transactionAmount={(this.state.payment_information.length ? this.state.payment_information[0].method == 3 : false) && this.state.approval_status == "1" ? this.state.approved_amount : this.state.total_amount}
													transactionType={this.state.expense ? 1 : 0}
													submit_click={this.state.submit_click}
													narration={this.getNarration()}
													getSnapshotLedgerRecommendation={this.getSnapshotLedgerRecommendation}
													checkForAccountedState={this.checkForAccountedState}
													getLedgerNameList={this.getLedgerNameList}
													getCostcenterCategory={this.getCostcenterCategory}
													getVoucherTypeList = {this.getVoucherTypeList}
													snap_voucher_list={this.props.temp_snap_voucher_list}
													amount_round_off = {this.props.amount_round_off}
													voucherTypeList = {this.state.voucherTypeList}
													vaild_voucher = {this.state.vaild_voucher}
													handleAccountingStatusChangeSyncToNotSync = {this.handleAccountingStatusChangeSyncToNotSync}
												/>
											}
										</span>
									}
									{/* End Ledger */}
								</div>
							</div>
							{/* End col-8 */}
						</div>
					</div>

					{/* Buttons */}
					<div className="row mt-3">
						<div className="col-sm-12">
							<div className="page-button-bar">
								<div className="btn-flex-left mr-lg-auto">
									{StorageData.getTransactionId() &&
										<Button variant="outline-success reset_btn_new" onClick={() => this.redirectToMoneyLink()}>Back</Button>
									}

									{StorageData.getTaskManagerId() &&
										<Button variant="outline-success reset_btn_new" onClick={() => this.redirectToTaskManager()}>Back</Button>
									}
									{this.state.snapshot_id !== 0 &&
										<>
											{
												(((this.CREATE_REIMBUSRMENT_SNAPSHOT || this.CREATE_BUSSINESS_SNAPSHOT || this.APPROVE_REIMBURSMENT_SNAPSHOT || this.ACCESS_BUSSINESS_SMS_SNAPSHOT) && !!this.state.snapshot_id && this.state.created_by == SessionManagement.getUserDetail().id) || this.MANAGE_OTHER_USER_SNAPSHOT) &&
												<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete Snapshot</Tooltip>}>
													<span class="icon-delete blue-icon btn-bar-delete" onClick={this.deleteEvidence}></span>
												</OverlayTrigger>
											}
										</>
									}
									<div className="sup-cust-check btn-bar-save-text">
										<input className="styled-checkbox" id="styled-checkbox-11" type="checkbox"
											name="save_and_next" checked={this.state.save_and_next}
											disabled = {this.VIEW_ONLY_SNAPSHOT || !editable}
											onChange={(e) => {
												this.handleSaveAndNext(e)
											}} />
										<label for="styled-checkbox-11" className="label-text"><span className='smtext'>Save and move to next</span></label>
									</div>
								</div>

								<div className="btn-flex-right">
									<div className="global_new_rn_dd redyDraft_snapcr">
										<FormControl>
											<Select
												value={this.state.entry_status}
												onChange={this.handleEntryStatusChange}
												name="entry_status"
												className={"mr-0 " + (this.state.auto_ready || this.state.auto_ready_accounted ? " global_new_dd_selected" : "")}
												disabled={(!this.SNAPSHOT_LEDGER_ENTRIES_CODE && this.state.accounting_status != 3 && this.state.entry_status == 2) || (this.VIEW_ONLY_SNAPSHOT && !this.APPROVE_REIMBURSMENT_SNAPSHOT) || this.state.is_linked == 1}
											>
												<MenuItem value={1} disabled={ this.state.accounting_status > 3 || (this.state.is_reimbursement && !this.SNAPSHOT_LEDGER_ENTRIES_CODE && !this.APPROVE_REIMBURSMENT_SNAPSHOT && this.state.approval_status == "1") || (this.CREATE_REIMBUSRMENT_SNAPSHOT && this.state.approval_status == "1" && !this.APPROVE_REIMBURSMENT_SNAPSHOT) || this.state.approval_button == "1"}>Draft</MenuItem>
												<MenuItem value={2} disabled={this.state.approval_button == "2"}>Ready</MenuItem>
											</Select>
										</FormControl>
									</div>

									{this.SNAPSHOT_LEDGER_ENTRIES_CODE &&
										<div className="global_new_rn_dd status_snapcr">
											<FormControl>
												<Select
													value={this.state.accounting_status}
													onChange={this.handleAccountingStatusChange}
													name="accounting_status"
													className={"mr-0 " + (this.state.auto_accounted ? "global_new_dd_selected" : "")}
													disabled={this.VIEW_ONLY_SNAPSHOT}
												>
													<MenuItem value={3} >Pending</MenuItem>
													<MenuItem value={4} disabled={((this.state.is_reimbursement) && !(this.state.approval_status == "1") && !this.SNAPSHOT_LEDGER_ENTRIES_CODE) || this.state.approval_status == "2" || this.state.approval_button == "2" || (!this.APPROVE_REIMBURSMENT_SNAPSHOT && !this.state.approval_status && this.state.is_reimbursement)}>Accounted</MenuItem>
													<MenuItem value={5} disabled={true}>Synced</MenuItem>
												</Select>
											</FormControl>
										</div>
									}

									{
										<Button variant="success apply_btn_new save_snapcr"
											disabled={this.state.loading || !this.state.is_dirty || (this.VIEW_ONLY_SNAPSHOT && !this.APPROVE_REIMBURSMENT_SNAPSHOT && !this.SNAPSHOT_LEDGER_ENTRIES_CODE)}
											onClick={this.state.snapshot_id ? this.preUpdateEvidence : this.saveEvidence}>
											{this.state.snapshot_id ? 'Update' : 'Save'}
										</Button>
									}
								</div>
							</div>
						</div>
					</div>
					{/* End Buttons */}

					<span className="commbar_snapcr">
						<CommentSection
							context_no={1}
							context_name={"snapshot"}
							view_only={this.VIEW_ONLY_SNAPSHOT}
							context_id={this.state.snapshot_id}
							update_communication_bar_tasks_dirty={this.update_communication_bar_tasks_dirty}
							communication_bar_tasks_dirty={this.state.communication_bar_tasks_dirty}
							update_communication_bar_notes_dirty={this.update_communication_bar_notes_dirty}
							communication_bar_notes_dirty={this.state.communication_bar_notes_dirty}
							update_communication_bar_msgs_dirty={this.update_communication_bar_msgs_dirty}
							communication_bar_msgs_dirty={this.state.communication_bar_msgs_dirty}
							update_communication_bar_private_msgs_dirty={this.update_communication_bar_private_msgs_dirty}
							communication_bar_private_msgs_dirty={this.state.communication_bar_private_msgs_dirty}
							onRef={ref => (this.comment_section = ref)} />
					</span>
				</div>
				{/* End main-content-inner-new */}
			</div >



		</>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.Snapshot;
	const temp_linked_evidences = state.MoneyLink.temp_linked_evidences;
	const temp_snap_voucher_list = all_value.temp_snap_voucher_list || [];
	const payment_information = all_value.payment_info || {};
	const widget_count = all_value.widget_count || { total: 0, not_ready: 0, ready: 0, linked: 0, processed: 0, synced: 0, pending_for_approval: 0 };
	const suggested_evidence_ids = all_value.suggested_evidence_ids || [];
	const session_values = state.Session || {};
	const features_permissions_list = session_values.features_permissions_list || [];
	const communication_bar_dirty_state = all_value.communication_bar_dirty_state || "";
	const ledger_recommendation_param = session_values.entity_config_param.ledger_recommendation || 0;
	const entity_gst_no = session_values.entity_config_param.gst_no || null;
	const temp_inventory_data = all_value.temp_inventory_data || []
	//accounting related configurations
	const accounting_mode = session_values.entity_config_param.accounting_mode || 1;
	const is_common_item_ledger = session_values.entity_config_param.is_common_item_ledger;
	const is_new_model = session_values.entity_config_param.is_ner_model;
	const amount_round_off = session_values.entity_config_param.amount_round_off


	return {
		amount_round_off,payment_information, widget_count, temp_linked_evidences, features_permissions_list,
		communication_bar_dirty_state, temp_snap_voucher_list, ledger_recommendation_param, entity_gst_no, accounting_mode, is_common_item_ledger, temp_inventory_data,is_new_model
	};
}

export default connect(mapStateToProps)(withRouter(CreateEvidenceForm));
